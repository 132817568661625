import {
  PanelContainer,
  PanelFooter,
} from 'appLayout/ardoqStudio/saveAsViewpoint/atoms';
import { GhostButton } from '@ardoq/button';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import { hideViewSidePanel } from 'views/mainApp/viewSidePanel/actions';
import { FlexBox, Stack } from '@ardoq/layout';
import styled from 'styled-components';
import { useRef } from 'react';
import { ViewpointBuilderViewState } from 'viewpointBuilder/types';
import { viewpointBuilderCommands } from 'viewpointBuilder/viewpointBuilderCommands';
import { exploreGraph$ } from './exploreGraph$';
import { exploreGraphRoutines } from './exploreGraphRoutines';
import { SimpleGraphContainerWithGreyBackground } from 'viewpointBuilder/components/SimpleGraph/SimpleGraphViewContainer';
import { viewpointBuilderEventListeners } from 'viewpointBuilder/viewpointBuilderEventListeners';
import { FloatingClickOtherComponentsHint } from 'viewpointBuilder/traversals/FloatingClickOtherComponentsHint';
import SimpleGraphView from 'viewpointBuilder/components/SimpleGraph/SimpleGraphView';
import { ModalText } from '@ardoq/modal';
import { ErrorNotification } from '@ardoq/status-ui';
import { createGraphInterface } from 'viewpointBuilder/components/SimpleGraph/GraphInterface';
import { ExplorerGraphOptionsPanel } from 'viewpointBuilder/components/ExploreGraphOptionsPanel';

const PanelContent = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
`;

const PreviewContent = styled.div`
  height: 280px;
`;

const OptionContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

const ExploreGraph = (vpState: ViewpointBuilderViewState) => {
  const graphInterface = useRef(createGraphInterface());
  const {
    graphNodes,
    graphEdges,
    graphGroups,
    errorState,
    hasTripleOptions,
    filteredTripleOptions,
    selectedGraphNodeId,
    triplesSortAndFiltersState,
    shouldShowDeleteCurrentNodeButton,
    shouldIncludeClickOtherComponentTypeNodesNotification,
  } = vpState.editTraversalsState;

  return (
    <PanelContainer>
      <PanelContent>
        {errorState === 'NONE' ? (
          <PreviewContent>
            <SimpleGraphContainerWithGreyBackground
              $margin="0"
              onClick={
                viewpointBuilderEventListeners.toggleGraphNodeSelectionInTraversalEdit
              }
            >
              {shouldIncludeClickOtherComponentTypeNodesNotification && (
                <FloatingClickOtherComponentsHint
                  onClickNeverShowAgainHints={
                    viewpointBuilderCommands.editTraversalCommands
                      .skipClickOtherComponentTypeNodesHints
                  }
                />
              )}
              <SimpleGraphView
                viewModel={{ graphNodes, graphEdges, graphGroups }}
                graphInterface={graphInterface.current}
              />
            </SimpleGraphContainerWithGreyBackground>
          </PreviewContent>
        ) : (
          <ModalText>
            <ErrorNotification>{'TODO'}</ErrorNotification>
          </ModalText>
        )}
        <OptionContainer>
          <ExplorerGraphOptionsPanel
            selectedGraphNodeId={selectedGraphNodeId}
            tripleOptions={filteredTripleOptions}
            hasTripleOptions={hasTripleOptions}
            commands={viewpointBuilderCommands.editTraversalCommands}
            triplesSortAndFiltersState={triplesSortAndFiltersState}
            shouldShowDeleteCurrentNodeButton={
              shouldShowDeleteCurrentNodeButton
            }
            isFullWidth={true}
          />
        </OptionContainer>
      </PanelContent>
      <PanelFooter $padding="s16" $alignToRight>
        <Stack align="flex-end">
          <FlexBox gap="medium" align="flex-end">
            <GhostButton
              onClick={() => {
                dispatchAction(hideViewSidePanel());
              }}
            >
              Cancel
            </GhostButton>
          </FlexBox>
        </Stack>
      </PanelFooter>
    </PanelContainer>
  );
};

export default connect(ExploreGraph, exploreGraph$, exploreGraphRoutines);
