import { reducedStream } from '@ardoq/rxbeach';
import { combineLatest, map, Observable } from 'rxjs';
import manageUserRolesViewModel$ from 'admin/accessControl/ManageUserRoles/streams/manageUserRolesViewModel$';
import { commands } from './commands';
import { accessControlPageStreamOperations } from './accessControlPageStreamOperations';
import { AccessControlPageProps, AccessControlPageStream } from './types';
import { reducers } from './reducers';
import { Features, hasFeature } from '@ardoq/features';
import { serviceAccounts$ } from 'admin/serviceAccounts/serviceAccounts$';

const accessControlPage$: Observable<AccessControlPageStream> = reducedStream(
  'accessControlPage$',
  accessControlPageStreamOperations.emptyState(),
  reducers
);

export const accessControlPageViewModel$: Observable<AccessControlPageProps> =
  combineLatest([
    accessControlPage$,
    // HACK - Rafaa: We want it to start reducing once the access control page is loaded.
    // This can be fixed by merging all the streams into the accessControlPage$ stream.
    manageUserRolesViewModel$,
    serviceAccounts$,
  ]).pipe(
    map(([{ viewModel, resources }]) => {
      return {
        ...viewModel,
        resources,
        commands,
        hasNewJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      };
    })
  );
