import {
  ViewpointBuilderContext,
  ViewpointBuilderViewState,
} from 'viewpointBuilder/types';
import {
  GetViewpointBuilderStreamArgs,
  getViewpointBuilderStream,
} from './getViewpointBuilderStream';
import {
  getUpdatePerspectivesOptionsRoutine,
  graphDataForMetaInfoVisualisationRoutine,
  navigationTabDetailsOnCollapsedPaths,
  navigationTabDetailsOnCollapsedPathsUnselect,
  navigationTabDetailsOnFormattingRoutine,
  navigationTabDetailsOnGraphRoutine,
  navigationTabDetailsOnGroupingRoutine,
  navigationTabDetailsOnMetaInfoRoutine,
  navigationTabDetailsOnRequiredComponents,
  navigationTabDetailsOnSelectedComponentsRoutine,
  updateAdvancedSearchQueryRoutine,
  updateInstanceCountsRoutine,
  updateTraversalStartContextRoutine,
} from './viewpointBuilderRoutines';
import {
  action$,
  collectRoutines,
  dispatchAction,
  Routine,
  subscribeRoutine,
} from '@ardoq/rxbeach';
import { viewpointBuilderClosed } from './viewpointBuilderOpened$';
import { resetViewpointBuilder } from '../resetViewpointBuilder';

type SubscribeToStreamsAndRoutinesArgs = GetViewpointBuilderStreamArgs & {
  setState: React.Dispatch<React.SetStateAction<ViewpointBuilderViewState>>;
  onFirstSubscriptionCallback?: () => void;
};

export const subscribeToViewpointBuilderStreamAndRoutines = ({
  activeTab,
  context,
  initialConfiguration,
  folderId,
  viewpointBuilderCommands,
  setState,
  getAdditionalRoutines = () => [],
  onFirstSubscriptionCallback,
}: SubscribeToStreamsAndRoutinesArgs) => {
  const viewpointBuilderStream = getViewpointBuilderStream({
    activeTab,
    context,
    initialConfiguration,
    folderId,
    viewpointBuilderCommands,
    onFirstSubscriptionCallback,
  });

  const subscriptions = [
    viewpointBuilderStream.subscribe(setState),
    subscribeRoutine(
      action$,
      getViewpointBuilderRoutines(
        context,
        getAdditionalRoutines(viewpointBuilderStream)
      )
    ),
  ];

  return () => {
    subscriptions.forEach(subscription => subscription.unsubscribe());
    dispatchAction(viewpointBuilderClosed());
    resetViewpointBuilder();
  };
};

export const getViewpointBuilderRoutines = (
  context: ViewpointBuilderContext,
  additionalRoutines: Routine<unknown>[] = []
) =>
  collectRoutines(
    /**
     * Temporary state routines, updating stream input from another stream
     * output (e.g. route search component stream output to traversal edit
     * input) or trigger async updates (e.g. fetch instance counts).
     */
    getUpdatePerspectivesOptionsRoutine(context),
    updateInstanceCountsRoutine,
    updateTraversalStartContextRoutine,
    updateAdvancedSearchQueryRoutine,
    navigationTabDetailsOnSelectedComponentsRoutine,
    navigationTabDetailsOnGraphRoutine,
    navigationTabDetailsOnFormattingRoutine,
    navigationTabDetailsOnGroupingRoutine,
    navigationTabDetailsOnMetaInfoRoutine,
    graphDataForMetaInfoVisualisationRoutine,
    navigationTabDetailsOnCollapsedPaths,
    navigationTabDetailsOnRequiredComponents,
    navigationTabDetailsOnCollapsedPathsUnselect,
    ...additionalRoutines
  );
