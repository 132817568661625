import {
  APIEntityType,
  ArdoqId,
  AllSupportedAPIAttributes,
  AllSupportedEntityTypes,
  MergeComponentApiResponse,
  MergeReferenceApiResponse,
} from '@ardoq/api-types';
import { toByIdDictionary } from '@ardoq/common-helpers';
import {
  AllSupportedAPIAttrsArray,
  DataSource,
} from 'components/EntityMerge/types';
import { DiffTableRowType } from 'components/DiffMergeTable/types';
import _ from 'lodash';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import { ENTITY_DIFF_UTILS_CONST, getEntityById } from '@ardoq/renderers';
import type { EnhancedScopeData } from '@ardoq/data-model';

const { COMPONENT_STYLE_NAMES, DEFAULT_FIELD_NAMES } = ENTITY_DIFF_UTILS_CONST;

export const prepareMergeData = (
  entities: AllSupportedAPIAttrsArray,
  dataSource: DataSource,
  enhancedScopeData: EnhancedScopeData,
  entityType: AllSupportedEntityTypes
) => {
  const dateRangeFieldsByName = enhancedScopeData.dateRangeFieldMap?.get(
    enhancedScopeData.workspaces[0]._id
  );
  const entitiesById = toByIdDictionary<AllSupportedAPIAttributes>(entities);
  const entityIds = entities.map(({ _id }) => _id);
  // Take the component id that is on the top of the hierarchy to avoid bugs from infinite loops created when swapping children and parent
  const baseEntityId =
    entityType === APIEntityType.COMPONENT
      ? (_.minBy(entityIds, componentInterface.getLevel) ?? entityIds[0])
      : entityIds[0];

  const selectedAttributes = dataSource.reduce<Record<string, unknown>>(
    (acc, { status, fieldName, rowType }) => {
      if (rowType !== DiffTableRowType.PROPERTY_ROW) {
        return acc;
      } else if (dateRangeFieldsByName?.[fieldName]) {
        const dateRangeField = dateRangeFieldsByName[fieldName];
        const { start, end } = entitiesById[status!][fieldName] ?? {
          start: null,
          end: null,
        };
        acc[dateRangeField.start.name] = start;
        acc[dateRangeField.end.name] = end;
      } else {
        acc[fieldName] = entitiesById[status!][fieldName];
      }
      return acc;
    },
    {}
  );

  const relevantCustomFields = new Set(
    enhancedScopeData.fields.map(field => field.name)
  ); // sometimes components contains data that's not really relevant anymore. I.e. a calculated field that doesn't apply to the component type anymore. This can cause errors if not filtered

  const mergedEntity = _.pickBy(
    {
      ...getEntityById(entityType, baseEntityId, enhancedScopeData),
      ...selectedAttributes,
    },
    (_, fieldName) => {
      const isDateRangeField = dateRangeFieldsByName?.[fieldName];
      const isCalculatedField =
        enhancedScopeData.calculatedFields.has(fieldName);
      return (
        !isCalculatedField &&
        !isDateRangeField &&
        (relevantCustomFields.has(fieldName) ||
          DEFAULT_FIELD_NAMES.has(fieldName) ||
          COMPONENT_STYLE_NAMES.includes(fieldName))
      );
    }
  ) as AllSupportedAPIAttributes;

  return {
    mergedEntity,
    entityIdsToDelete: entityIds.filter(_id => _id !== baseEntityId),
  };
};

export enum InvalidEntityMergeReason {
  TYPE_MISMATCH,
  ROOT_WORKSPACE_MISMATCH,
  SOURCE_TARGET_MISMATCH,
}

export const validateSelection = (
  entityIds: ArdoqId[],
  entityType: AllSupportedEntityTypes
) => {
  if (entityType === APIEntityType.REFERENCE) {
    const source = referenceInterface.getSourceComponentId(entityIds[0]);
    const target = referenceInterface.getTargetComponentId(entityIds[0]);
    const refsHaveSourceTargetMismatch = entityIds
      .slice(1)
      .some(
        entityId =>
          referenceInterface.getSourceComponentId(entityId) !== source ||
          referenceInterface.getTargetComponentId(entityId) !== target
      );
    if (refsHaveSourceTargetMismatch) {
      return {
        isValidSelection: false,
        validationErrorReason: InvalidEntityMergeReason.SOURCE_TARGET_MISMATCH,
      };
    }
  }

  const getTypeId =
    entityType === APIEntityType.COMPONENT
      ? componentInterface.getTypeId
      : referenceInterface.getGlobalTypeId;
  const typeId = getTypeId(entityIds[0]);
  const hasTypeMismatch = entityIds
    .slice(1)
    .some(entityId => getTypeId(entityId) !== typeId);
  if (hasTypeMismatch) {
    return {
      isValidSelection: false,
      validationErrorReason: InvalidEntityMergeReason.TYPE_MISMATCH,
    };
  }

  if (entityType === APIEntityType.COMPONENT) {
    const rootWorkspaceId = componentInterface.getWorkspaceId(entityIds[0]);
    const hasRootWorkspaceMismatch = entityIds
      .slice(1)
      .some(
        entityId =>
          componentInterface.getWorkspaceId(entityId) !== rootWorkspaceId
      );
    if (hasRootWorkspaceMismatch) {
      return {
        isValidSelection: false,
        validationErrorReason: InvalidEntityMergeReason.ROOT_WORKSPACE_MISMATCH,
      };
    }
  }

  return { isValidSelection: true };
};

const shouldUpdate = (existingVersion: number | null, APIVersion: number) =>
  Boolean(existingVersion && APIVersion > existingVersion);

export const handleComponentMergeResponse = (
  response: MergeComponentApiResponse
) => {
  response['updated-tags'].forEach(({ _id, components, _version }) => {
    if (shouldUpdate(tagInterface.getVersion(_id), _version!)) {
      tagInterface.setAttributes({ components, _version, _id });
    }
  });
  response['updated-references'].forEach(
    ({ _version, _id, source, target }) => {
      if (shouldUpdate(referenceInterface.getVersion(_id), _version)) {
        referenceInterface.setAttributes({
          _id,
          _version,
          ...(referenceInterface.getSourceComponentId(_id) !== source
            ? { source }
            : {}),
          ...(referenceInterface.getTargetComponentId(_id) !== target
            ? { target }
            : {}),
        });
      }
    }
  );
  const updatedComponent = response['updated-component'];
  if (
    shouldUpdate(
      componentInterface.getVersion(updatedComponent._id),
      updatedComponent._version!
    )
  ) {
    componentInterface.setAttributes(updatedComponent);
  }
  Object.entries(response['new-parents']).forEach(
    ([componentId, { parent, _version }]) => {
      if (shouldUpdate(componentInterface.getVersion(componentId), _version)) {
        componentInterface.setAttributes({
          parent,
          _version,
          _id: componentId,
        });
      }
    }
  );
  response['deleted-component-ids'].forEach(
    componentInterface.removeFromCollection
  );
};

export const handleReferenceMergeResponse = (
  response: MergeReferenceApiResponse
) => {
  response['updated-tags'].forEach(({ _id, references, _version }) => {
    if (shouldUpdate(tagInterface.getVersion(_id), _version!)) {
      tagInterface.setAttributes({ references, _version, _id });
    }
  });

  const updatedReference = response['updated-reference'];
  if (
    shouldUpdate(
      referenceInterface.getVersion(updatedReference._id),
      updatedReference._version
    )
  ) {
    referenceInterface.setAttributes(updatedReference);
  }
  response['deleted-reference-ids'].forEach(
    referenceInterface.removeFromCollection
  );
};
