import { Icon, IconName } from '@ardoq/icons';
import React from 'react';
import { Step, StepProps, StepState } from '@ardoq/steppers';

export function AIStep(props: StepProps) {
  if (props.state === StepState.ACTIVE || props.state === StepState.MODIFIED) {
    return (
      <Step
        {...props}
        color={{
          initial: 'brand50',
          selected: 'brand50',
        }}
        icon={<Icon iconName={IconName.SPARKLES} iconSize={16} />}
      />
    );
  }
  return <Step {...props} />;
}
