import { DirectedTripleWithFilters } from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestLoadMetaModel } from 'architectureModel/actions';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { contextToolBar$ } from 'contextToolBar/contextToolBar$';
import { loadedState$ } from 'loadedState/loadedState$';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import graphModel$ from 'modelInterface/graphModel$';
import {
  firstValueFrom,
  combineLatest,
  switchMap,
  Observable,
  filter,
  withLatestFrom,
} from 'rxjs';
import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { initViewpointBuilder } from 'viewpointBuilder/initViewpointBuilder';
import { viewpointBuilder$ } from 'viewpointBuilder/openViewpointBuilder/viewpointBuilder$';
import { getInitialConfiguration } from 'viewpointBuilder/routines';
import { isReadyToUpdate } from 'viewpointBuilder/traversals/editTraversalActions';
import {
  InitialViewpointBuilderConfiguration,
  ViewpointBuilderViewState,
} from 'viewpointBuilder/types';
import { viewpointBuilderCommands } from 'viewpointBuilder/viewpointBuilderCommands';
import { selectViewpointTypeNodeWithContextToolbarSelection } from './selectViewpointTypeNodeWithContextToolbarSelection';
import { isSameTriple } from 'traversals/pathAndGraphUtils';

const getInitialConfiguration$: Observable<
  InitialViewpointBuilderConfiguration | undefined
> = loadedState$.pipe(
  switchMap(([mainLoadedState]) =>
    getInitialConfiguration({
      loadedTraversal:
        loadedStateOperations.toTraversalLoadedState(mainLoadedState),
      context: 'editSubgraph',
      isMainLoadedState: true,
    })
  )
);

const reloadMetamodelWithPaths = (
  initialConfiguration?: InitialViewpointBuilderConfiguration
) =>
  dispatchAction(
    requestLoadMetaModel({
      currentTriples: metaModelOperations.getTriplesFromPaths(
        initialConfiguration?.initialPaths ?? []
      ),
    })
  );

const areSamePathLists = (
  pathList1: DirectedTripleWithFilters[][],
  pathList2: DirectedTripleWithFilters[][]
) =>
  pathList1.length === pathList2.length &&
  pathList1.every((path, index) => areSamePaths(path, pathList2[index]));

const areSamePaths = (
  paths1: DirectedTripleWithFilters[],
  paths2: DirectedTripleWithFilters[]
) =>
  paths1.length === paths2.length &&
  paths1.every((triple, index) => isSameTriple(triple, paths2[index]));

const getGraphWithInitialPaths$ = (
  exploreGraph$: Observable<ViewpointBuilderViewState>,
  initialPaths: DirectedTripleWithFilters[][]
) =>
  exploreGraph$.pipe(
    filter(
      ({
        editTraversalsState: {
          stateAsSavableAttributes: { paths },
        },
      }) => areSamePathLists(paths, initialPaths)
    ),
    withLatestFrom(loadedGraph$, graphModel$)
  );

const onFirstSubscription = async () => {
  dispatchAction(isReadyToUpdate(false));
  const [initialConfiguration, { currentContext, isMultipleTypesSelected }] =
    await firstValueFrom(
      combineLatest([getInitialConfiguration$, contextToolBar$])
    );
  reloadMetamodelWithPaths(initialConfiguration);
  initViewpointBuilder({
    activeTab: 'SUBGRAPH_TAB',
    context: 'editSubgraph',
    initialConfiguration,
    folderId: null,
    viewpointBuilderCommands,
  });
  if (initialConfiguration?.initialPaths) {
    const { initialPaths } = initialConfiguration;
    const [
      { editTraversalsState },
      {
        hierarchyDefinition: [mainHierarchyDefinition],
      },
      graphModel,
    ] = await firstValueFrom(
      getGraphWithInitialPaths$(viewpointBuilder$, initialPaths)
    );
    if (
      !(mainHierarchyDefinition && currentContext && !isMultipleTypesSelected)
    ) {
      return;
    }
    selectViewpointTypeNodeWithContextToolbarSelection({
      editTraversalsState,
      mainHierarchyDefinition,
      currentContext,
      graphModel,
    });

    setTimeout(() => dispatchAction(isReadyToUpdate(true)), 16);
  }
};

export const exploreGraph$ = withOnFirstSubscription(
  viewpointBuilder$,
  onFirstSubscription
);
