import { ReactNode } from 'react';
import { IconName } from '@ardoq/icons';
import fscreen from 'fscreen';
import { dispatchAction } from '@ardoq/rxbeach';
import { openAddToScenarioDialog } from 'scope/actions';
import { trackClickStartCreatingNewScenario } from 'scope/tracking';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { forceUpdateComponent } from 'streams/components/ComponentActions';
import {
  APIEntityType,
  ArdoqId,
  ReferenceDirection,
  ResourceType,
} from '@ardoq/api-types';
import { alert } from '@ardoq/modal';
import {
  ComponentPropertiesGetContentOptions,
  GetContentOptions,
  GetContentOptionsType,
  ReferencePropertiesGetContentOptions,
} from 'appModelStateEdit/legacyTypes';
import { showRightPane } from 'appContainer/actions';
import {
  DropdownItem,
  DropdownOption,
  DropdownOptionType,
  DropdownSubmenu,
} from '@ardoq/dropdown-menu';
import { getActiveScenarioId } from 'streams/activeScenario/activeScenario$';
import { TrackingFunction, deleteModels } from './utils';
import { allComponentsInScopeAreSelected } from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/ScenarioRelatedContextMenu';
import {
  addComponentsToScenario,
  removeComponentsFromScenario,
} from 'appContainer/MainAppModule/MainAppModuleSidebar/scenarioRelated/navigator/actionWrappers';
import {
  TrackedCompAndRefContextMenuLabels,
  TrackedComponentContextMenuLabels,
  TrackedReferenceContextMenuLabels,
  TrackedScenarioContextComponentContextMenuLabels,
  TrackedTagContextMenuLabels,
  TrackedWorkspaceContextMenuLabels,
} from './tracking';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { includeComponentsOfType } from './utils';
import { selectReference } from 'streams/references/ReferenceActions';
import {
  createReferencesFromSourceFilter,
  createReferencesToTargetFilter,
  excludeChildComponents,
  excludeComponentsOfType,
  excludeContentFromWorkspace,
  excludeReferencesFromWorkspace,
  excludeReferencesOfType,
  excludeReferencesToWorkspace,
  excludeSelected,
  includeReferencesOfType,
} from './utils';
import { initEntityMerge } from 'components/EntityMerge/actions';
import { reverseReferencesById } from 'components/Dialogs/reverseReferences/reverseReferences';
import { Features, hasFeature } from '@ardoq/features';
import { openResourcePermissionDialog } from 'resourcePermissions/actions';
import { deleteTag, editTag, filterTag } from 'streams/tags/TagActions';
import {
  excludeWorkspaceFromScenario,
  showWorkspaceModelEditor,
} from 'workspaceModelEditor/streams/actions';
import { openViewpointBuilderWithComponents } from 'viewpointBuilder/actions';
import { openApplySavedViewpointModal } from '../traversals/selectTraversalForContextComponentsModal/actions';
import { uniq } from 'lodash';
import { WithPopover } from '@ardoq/popovers';
import styled from 'styled-components';
import { searchTraversalInterface } from 'traversals/selectTraversalForContextComponentsModal/searchTraversalInterface';
import {
  navigateToAuditLog,
  navigateToOrganizationMetamodel,
} from 'router/navigationActions';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { trackAuditLogEntryPoint } from '../auditLog/tracking';
import { openDetailsDrawer } from 'appLayout/ardoqStudio/detailsDrawer/actions';
import { openViewInferenceWindow } from 'traversals/viewInference/actions';
import { CurrentContext } from 'modelInterface/types';
import { Paragraph } from '@ardoq/typography';
import { Stack } from '@ardoq/layout';
import { startCreateReferences } from 'createReferences2024/createReferencesActions';
import { openDocumentPicker } from 'integrations/lucidchart/actions';
import { NewBadge } from '@ardoq/status-ui';
import { getNameFromIdAndCollection } from '../auditLog/utils';
import { TOGGLE_LOCK_MENU_ITEM_KEY } from './consts';

export const getAddSelectionToScenarioMenuItems = (
  componentIds: ArdoqId[],
  context: CurrentContext
) => [
  {
    name: `add-selection-to-scenario`,
    testId: `add-selection-to-scenario-item`,
    label: `Add selection to scenario`,
    iconName: IconName.SCENARIO,
    onClick: () => {
      dispatchAction(
        openAddToScenarioDialog({
          context,
          componentIds,
          addToExistingScenario: true,
        })
      );
      trackClickStartCreatingNewScenario('contextmenu');
    },
    type: DropdownOptionType.OPTION,
  },
];

export const getCreateScenarioFromSelectionMenuItems = (
  componentIds: ArdoqId[],
  context: CurrentContext
) => [
  {
    name: `create-scenario-from-selection`,
    testId: `create-scenario-from-selection-item`,
    label: `Create scenario from selection`,
    iconName: IconName.SCENARIO,
    onClick: () => {
      dispatchAction(
        openAddToScenarioDialog({
          context,
          componentIds,
        })
      );
      trackClickStartCreatingNewScenario('contextmenu');
    },
    type: DropdownOptionType.OPTION,
  },
];

export const getEditPropertiesMenuItem = (
  componentIds: ArdoqId[],
  referenceIds: ArdoqId[],
  trackingFn: TrackingFunction
): DropdownOption | DropdownSubmenu | null => {
  const isHybrid = componentIds.length > 0 && referenceIds.length > 0;
  const name = 'edit-properties';
  const label = 'Edit properties';
  const iconName = IconName.EDIT;
  const editComponentPropertiesArgs: ComponentPropertiesGetContentOptions | null =
    componentIds.length
      ? {
          type: GetContentOptionsType.COMPONENT_PROPERTIES,
          componentIds,
        }
      : null;
  const editReferencePropertiesArgs: ReferencePropertiesGetContentOptions | null =
    referenceIds.length
      ? {
          type: GetContentOptionsType.REFERENCE_PROPERTIES,
          referenceIds,
        }
      : null;

  if (isHybrid) {
    return {
      name,
      label,
      iconName,
      type: DropdownOptionType.SUBMENU,
      options: [
        {
          name: 'edit-component-properties',
          testId: 'edit-component-properties-item',
          label: 'Edit selected components',
          iconName: IconName.NONE,
          type: DropdownOptionType.OPTION,
          onClick: () => {
            trackingFn(
              TrackedCompAndRefContextMenuLabels.EDIT_SELECTED_COMPONENT_PROPS
            );
            dispatchAction(showRightPane(editComponentPropertiesArgs!));
          },
        },
        {
          name: 'edit-reference-properties',
          testId: 'edit-reference-properties-item',
          label: 'Edit selected references',
          iconName: IconName.NONE,
          type: DropdownOptionType.OPTION,
          onClick: () => {
            trackingFn(
              TrackedCompAndRefContextMenuLabels.EDIT_SELECTED_REFERENCE_PROPS
            );
            dispatchAction(showRightPane(editReferencePropertiesArgs!));
          },
        },
      ],
    };
  }
  if (componentIds.length > 0) {
    return {
      name,
      label,
      iconName,
      testId: 'edit-components-properties-item',
      onClick: () => {
        trackingFn(TrackedComponentContextMenuLabels.EDIT_COMPONENT);
        dispatchAction(showRightPane(editComponentPropertiesArgs!));
      },
      type: DropdownOptionType.OPTION,
    };
  }
  return {
    name,
    label,
    iconName,
    testId: 'edit-references-properties-item',
    onClick: () => {
      trackingFn(TrackedReferenceContextMenuLabels.EDIT_REFERENCE);
      dispatchAction(showRightPane(editReferencePropertiesArgs!));
    },
    type: DropdownOptionType.OPTION,
  };
};
export const getEditStyleMenuItem = (
  componentIds: ArdoqId[],
  referenceIds: ArdoqId[],
  trackingFn: TrackingFunction
) => {
  const isHybrid = componentIds.length && referenceIds.length;
  return {
    name: 'edit-style',
    label: 'Edit style',
    iconName: IconName.PALETTE,
    type: isHybrid ? DropdownOptionType.SUBMENU : DropdownOptionType.OPTION,
    ...(isHybrid
      ? {
          options: [
            {
              name: 'edit-component-style',
              testId: 'edit-style-item',
              label: 'Edit selected components',
              iconName: IconName.PALETTE,
              type: DropdownOptionType.OPTION,
              onClick: () => {
                trackingFn(
                  TrackedCompAndRefContextMenuLabels.EDIT_SELECTED_COMPONENT_STYLE
                );
                dispatchAction(
                  showRightPane({
                    type: GetContentOptionsType.COMPONENT_STYLE,
                    componentIds,
                  })
                );
              },
            },
          ],
        }
      : {
          testId: 'edit-style-item',
          onClick: () => {
            trackingFn(TrackedComponentContextMenuLabels.EDIT_COMPONENT_STYLE);
            dispatchAction(
              showRightPane({
                type: GetContentOptionsType.COMPONENT_STYLE,
                componentIds,
              })
            );
          },
        }),
  };
};

export const getAddChildComponentMenuItem = (
  componentId: ArdoqId,
  trackingFn: TrackingFunction,
  isViewpointMode: boolean
) => ({
  name: `add-child`,
  testId: `add-child-item`,
  label: isViewpointMode ? 'Add child component' : `Add child`,
  iconName: IconName.ADD,
  isDisabled: !componentInterface.canComponentHaveChildren(componentId),
  onClick: () => {
    trackingFn(TrackedComponentContextMenuLabels.ADD_CHILD_TO_COMPONENT);
    const payload: GetContentOptions = {
      type: GetContentOptionsType.CREATE_COMPONENT,
      parentId: componentId,
      workspaceId: componentInterface.getWorkspaceId(componentId) ?? '',
    };
    dispatchAction(showRightPane(payload));
  },
  type: DropdownOptionType.OPTION,
});

export const addComponentToScenario = (
  componentIds: ArdoqId[],
  trackingFn: TrackingFunction
) => ({
  name: 'include-in-scenario',
  testId: 'include-in-scenario-item',
  label: 'Include in scenario',
  iconName: IconName.ADD,
  onClick: () => {
    trackingFn(
      TrackedScenarioContextComponentContextMenuLabels.INCLUDE_IN_SCENARIO
    );
    addComponentsToScenario(componentIds);
  },
  type: DropdownOptionType.OPTION,
});

export const getToggleLockMenuItem = (
  componentIds: ArdoqId[],
  referenceIds: ArdoqId[],
  trackingFn: TrackingFunction
) => {
  const areSomeComponentsCantBeUnlocked = componentIds.some(
    componentId => !componentInterface.canComponentBeUnlocked(componentId)
  );

  const areSomeReferencesCantBeUnlocked = referenceIds.some(
    referenceId => !referenceInterface.canReferenceBeUnlocked(referenceId)
  );

  const hasLockedEntity =
    componentIds.some(componentInterface.isLocked) ||
    referenceIds.some(referenceInterface.isLocked);

  const isDisabled =
    hasLockedEntity &&
    (areSomeComponentsCantBeUnlocked || areSomeReferencesCantBeUnlocked);

  const isMixedEntities = referenceIds.length > 0 && componentIds.length > 0;
  const isOnlyReferences = referenceIds.length > 0 && componentIds.length === 0;
  const isSingleReference = isOnlyReferences && referenceIds.length === 1;

  const labelLineEnd = isSingleReference
    ? 'reference'
    : isOnlyReferences
      ? 'references'
      : '';

  const trackingOptionTitle = isMixedEntities
    ? TrackedCompAndRefContextMenuLabels.TOGGLE_LOCK
    : isOnlyReferences
      ? TrackedReferenceContextMenuLabels.LOCK_REFERENCE
      : TrackedComponentContextMenuLabels.LOCK_COMPONENT;

  return {
    name: TOGGLE_LOCK_MENU_ITEM_KEY,
    testId: `toggle-lock-item`,
    label: `${hasLockedEntity ? 'Unlock' : 'Lock'} ${labelLineEnd}`,
    iconName: hasLockedEntity ? IconName.LOCK_OPEN : IconName.LOCK,
    onClick: () => {
      trackingFn(trackingOptionTitle);
      componentIds.forEach(id =>
        componentInterface.setLock(id, !hasLockedEntity)
      );
      referenceIds.forEach(id =>
        referenceInterface.setLock(id, !hasLockedEntity)
      );
    },
    type: DropdownOptionType.OPTION,
    isDisabled,
  };
};

export const getCreateReferenceMenuItem = (
  componentIds: ArdoqId[],
  event: Event | null,
  x: number,
  y: number,
  trackingFn: TrackingFunction,
  eventTargetModelId?: string,
  isStartInNavigator?: boolean
) => {
  if (fscreen.fullscreenElement instanceof HTMLElement) {
    return null;
  }

  const multiselect = componentIds.length > 1;

  if (multiselect) {
    return {
      name: 'create-references',
      label: 'Create reference',
      iconName: IconName.REFERENCE,
      type: DropdownOptionType.SUBMENU,
      options: [
        {
          name: 'create-outgoing-reference',
          testId: 'create-outgoing-reference-item',
          label: 'Create outgoing reference',
          iconName: IconName.ARROW_FORWARD,
          type: DropdownOptionType.OPTION,
          onClick: () => {
            trackingFn(
              TrackedComponentContextMenuLabels.CREATE_OUTGOING_REFERENCE
            );
            dispatchAction(
              startCreateReferences({
                event,
                linkSourceIds: componentIds,
                startPosition: { x, y },
                refDirection: ReferenceDirection.OUTGOING,
                linkSourceNodeId: eventTargetModelId,
                isStartInNavigator,
              })
            );
          },
        },
        {
          name: 'create-incoming-reference',
          testId: 'create-incoming-reference-item',
          label: 'Create incoming reference',
          iconName: IconName.ARROW_BACK,
          type: DropdownOptionType.OPTION,
          onClick: () => {
            trackingFn(
              TrackedComponentContextMenuLabels.CREATE_INCOMING_REFERENCE
            );
            dispatchAction(
              startCreateReferences({
                event,
                linkSourceIds: componentIds,
                startPosition: { x, y },
                refDirection: ReferenceDirection.INCOMING,
                linkSourceNodeId: eventTargetModelId,
                isStartInNavigator,
              })
            );
          },
        },
      ],
    };
  }

  return {
    name: `create-reference`,
    testId: `create-reference-item`,
    label: 'Create reference',
    iconName: IconName.REFERENCE,
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackingFn(TrackedComponentContextMenuLabels.CREATE_REFERENCE);
      dispatchAction(
        startCreateReferences({
          event,
          linkSourceIds: componentIds,
          startPosition: { x, y },
          refDirection: ReferenceDirection.OUTGOING,
          linkSourceNodeId: eventTargetModelId,
          isStartInNavigator,
        })
      );
    },
  };
};

const withTruncateTitle = (option: DropdownOption) => ({
  ...option,
  truncateTitle: true,
});

export const getComponentNavigateToMenuItem = (
  componentId: ArdoqId,
  trackingFn: TrackingFunction,
  isScenarioContextComponent?: boolean
): DropdownSubmenu | null => {
  const componentName = componentInterface.getDisplayName(componentId);
  const componentParentId = componentInterface.getParentId(componentId);
  const componentParentName =
    componentParentId && componentInterface.getDisplayName(componentParentId);

  const TrackedMenuLabels = isScenarioContextComponent
    ? TrackedScenarioContextComponentContextMenuLabels
    : TrackedComponentContextMenuLabels;

  if (loadedGraph$.state.isViewpointMode) {
    return null;
  }
  const navigateToProps = { name: 'navigate-to', iconName: IconName.NAVIGATE };
  const navigateToButtonProps = {
    type: DropdownOptionType.OPTION,
    testId: 'go-to-target-item',
    onClick: () => {
      trackingFn(TrackedMenuLabels.SELECT);
      dispatchAction(
        forceUpdateComponent({
          cid: componentId,
          trackingLocation: 'contextMenu',
        })
      );
    },
  };

  const navigateToParentMenuItem = componentParentId && {
    name: 'go-to-parent',
    testId: 'go-to-parent-item',
    label: `Go to parent ${componentParentName}`,
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackingFn(TrackedMenuLabels.SELECT_PARENT);
      dispatchAction(
        forceUpdateComponent({
          cid: componentParentId,
          trackingLocation: 'contextMenu',
        })
      );
    },
  };

  const GO_TO_COMPONENTNAME = `Go to ${componentName}`;

  const workspaceId = componentInterface.getWorkspaceId(componentId);
  const navigateToWorkspaceMenuItem: DropdownOption = {
    name: 'go-to-workspace',
    testId: 'go-to-workspace-item',
    label: 'Go to workspace',
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackingFn(TrackedMenuLabels.SELECT_WORKSPACE);
      workspaceInterface.setCurrentWorkspace(workspaceId || '', 'contextMenu');
    },
  };

  return {
    ...navigateToProps,
    label: 'Navigate to',
    type: DropdownOptionType.SUBMENU,
    options: [
      {
        ...navigateToButtonProps,
        name: 'go-to-target',
        label: GO_TO_COMPONENTNAME,
      },

      navigateToParentMenuItem || navigateToWorkspaceMenuItem,
    ].map(withTruncateTitle),
  };
};

export const getTraversalMenuItems = (
  componentIds: ArdoqId[]
): DropdownOption[] => {
  if (!hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
    return [];
  }
  const componentTypeNames = uniq(
    componentIds.map(componentInterface.getTypeName)
  ).filter(ExcludeFalsy);
  const multipleComponentTypesSelected =
    areMultipleComponentTypesSelected(componentIds);
  const componentType = componentTypeNames[0];

  const selectedComponents = componentIds.map(
    searchTraversalInterface.prepareComponentFromId
  );

  return [
    {
      name: 'start-traversal',
      testId: 'start-traversal-item',
      label: (
        <LabelWithMultipleComponentTypesSelectedForTraversalPopover
          shouldDisplayPopover={multipleComponentTypesSelected}
        >
          Open new dataset from selection
        </LabelWithMultipleComponentTypesSelectedForTraversalPopover>
      ),
      iconName: IconName.ACCOUNT_TREE,
      type: DropdownOptionType.OPTION,
      isDisabled: multipleComponentTypesSelected,
      onClick: () => {
        dispatchAction(openViewpointBuilderWithComponents({ componentIds }));
      },
    },
    {
      name: 'apply-saved-traversal',
      testId: 'apply-saved-traversal-item',
      label: (
        <LabelWithMultipleComponentTypesSelectedForTraversalPopover
          shouldDisplayPopover={multipleComponentTypesSelected}
        >
          Apply a saved viewpoint
        </LabelWithMultipleComponentTypesSelectedForTraversalPopover>
      ),
      iconName: IconName.ROCKET,
      type: DropdownOptionType.OPTION,
      isDisabled: multipleComponentTypesSelected,
      onClick: () => {
        dispatchAction(
          openApplySavedViewpointModal({
            selectedComponents,
            componentType,
          })
        );
      },
    },
  ].filter(ExcludeFalsy);
};

export const seeDetailsDrawer = (componentIds: ArdoqId[]) => {
  const multipleComponentTypesSelected =
    areMultipleComponentTypesSelected(componentIds);
  const selectedComponents = componentIds.map(
    searchTraversalInterface.prepareComponentFromId
  );
  return {
    name: 'see-details-drawer',
    testId: 'see-details-drawer',
    label: 'See component details',
    iconName: IconName.FIELD,
    type: DropdownOptionType.OPTION,
    isDisabled: multipleComponentTypesSelected,
    onClick: () => {
      dispatchAction(
        openDetailsDrawer(selectedComponents.map(component => component.id))
      );
    },
  };
};

export const OPEN_TRAVERSAL_VIEW_INFERENCE_NAME =
  'open-traversal-view-inference';

export const getTraversalInferenceMenuItems = (
  componentIds: ArdoqId[]
): DropdownItem[] => {
  if (!hasFeature(Features.AZURE_OPENAI_VIEW_INFERENCE)) return [];
  const multipleComponentTypesSelected =
    areMultipleComponentTypesSelected(componentIds);

  return [
    {
      name: OPEN_TRAVERSAL_VIEW_INFERENCE_NAME,
      label: (
        <LabelWithMultipleComponentTypesSelectedForTraversalPopover
          shouldDisplayPopover={multipleComponentTypesSelected}
        >
          Add new dataset with AI
        </LabelWithMultipleComponentTypesSelectedForTraversalPopover>
      ),
      iconName: IconName.VIEW_INFERENCE,
      type: DropdownOptionType.OPTION,
      isDisabled: multipleComponentTypesSelected,
      onClick: event => {
        dispatchAction(
          openViewInferenceWindow({
            componentIds,
            initialPosition: { x: event.clientX, y: event.clientY },
          })
        );
      },
    },
    {
      name: 'divider',
      type: DropdownOptionType.DIVIDER,
    },
  ];
};

export const getLinkToLucidChartMenuItem = (
  componentId: ArdoqId
): DropdownItem[] => {
  if (!hasFeature(Features.LUCID_CHART_ENABLE)) return [];

  return [
    {
      name: 'link-lucidchart-document',
      label: 'Link Lucidchart document',
      iconName: IconName.LUCIDCHART,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        dispatchAction(openDocumentPicker({ componentId }));
      },
      rightContent: <NewBadge />,
    },
    {
      name: 'divider',
      type: DropdownOptionType.DIVIDER,
    },
  ];
};

function mapToComponentTypeNames(componentIds: string[]) {
  return uniq(componentIds.map(componentInterface.getTypeName)).filter(
    ExcludeFalsy
  );
}

function areMultipleComponentTypesSelected(componentIds: string[]) {
  const componentTypeNames = mapToComponentTypeNames(componentIds);
  return componentTypeNames.length > 1;
}

type LabelWithMultipleComponentTypesSelectedForTraversalPopoverProps = {
  shouldDisplayPopover: boolean;
  children: ReactNode;
};

const LabelWithMultipleComponentTypesSelectedForTraversalPopover = ({
  shouldDisplayPopover,
  children,
}: LabelWithMultipleComponentTypesSelectedForTraversalPopoverProps) => {
  const popoverContent = () => {
    if (!shouldDisplayPopover) return null;

    return (
      <Stack>
        <Paragraph variant="text2Bold">
          Unable to add new dataset with AI
        </Paragraph>
        <Paragraph variant="text2">
          The selected component isn&apos;t connected to other components.
        </Paragraph>
        <Paragraph variant="text2">
          Please select a different component to add a new dataset.
        </Paragraph>
      </Stack>
    );
  };

  return (
    <WithPopover content={popoverContent}>
      <EnablePointerEventsWrapper>{children}</EnablePointerEventsWrapper>
    </WithPopover>
  );
};

const EnablePointerEventsWrapper = styled.div`
  pointer-events: all;
`;

export const getReferenceNavigateToMenuItem = (
  referenceId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownSubmenu => {
  const referenceName = referenceInterface.getRawName(referenceId);

  const sourceComponentId =
    referenceInterface.getSourceComponentId(referenceId);
  const targetComponentId =
    referenceInterface.getTargetComponentId(referenceId);

  const referenceSourceFullPathName =
    sourceComponentId && componentInterface.getFullPathName(sourceComponentId);
  const referenceTargetFullPathName =
    targetComponentId && componentInterface.getFullPathName(targetComponentId);

  return {
    name: 'navigate-to',
    label: 'Navigate to',
    iconName: IconName.NAVIGATE,
    type: DropdownOptionType.SUBMENU,
    options: [
      {
        name: 'go-to-reference',
        testId: 'go-to-reference-item',
        label: `Go to ${referenceName}`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(TrackedReferenceContextMenuLabels.SELECT_REFERENCE);
          dispatchAction(selectReference({ cid: referenceId }));
        },
      },
      sourceComponentId && {
        name: 'go-to-source-component',
        testId: 'go-to-source-component-item',
        label: `Go to source ${referenceSourceFullPathName}`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(TrackedReferenceContextMenuLabels.SELECT_SOURCE_COMPONENT);
          dispatchAction(
            forceUpdateComponent({
              cid: sourceComponentId,
              trackingLocation: 'contextMenu',
            })
          );
        },
      },
      targetComponentId && {
        name: 'go-to-target-component',
        testId: 'go-to-target-component-item',
        label: `Go to target ${referenceTargetFullPathName}`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(TrackedReferenceContextMenuLabels.SELECT_TARGET_COMPONENT);
          dispatchAction(
            forceUpdateComponent({
              cid: targetComponentId,
              trackingLocation: 'contextMenu',
            })
          );
        },
      },
    ]
      .filter(ExcludeFalsy)
      .map(option => ({ ...option, truncateTitle: true })),
  };
};

export const getDropdownDivider = (): DropdownOption => ({
  label: '',
  type: DropdownOptionType.DIVIDER,
});

export const getDeleteComponentMenuItems = (
  componentIds: ArdoqId[],
  trackingFn: TrackingFunction,
  getNextTreeSelectionCandidate: () => ArdoqId | null
): DropdownOption[] => {
  if (componentIds.some(componentInterface.isLocked)) {
    return [];
  }

  const scenarioId = getActiveScenarioId();
  const isMultiselect = componentIds.length > 1;
  const label =
    scenarioId && !isMultiselect
      ? `Delete component in scenario`
      : `Delete ${isMultiselect ? 'components' : 'component'}`;

  const trackingTitle = scenarioId
    ? TrackedComponentContextMenuLabels.DELETE_COMPONENT_IN_SCENARIO
    : TrackedComponentContextMenuLabels.DELETE_COMPONENT;

  const onRemoveFromScenarioClick = (componentIds: ArdoqId[]) => {
    if (allComponentsInScopeAreSelected(componentIds)) {
      return alert({
        title: 'Excluding all components not allowed',
        text: 'Excluding all the components in a scenario will break the scenario and is not allowed. Start a new scenario if you want to have a new scenario without any components.',
      });
    }

    trackingFn(
      TrackedComponentContextMenuLabels.EXCLUDE_COMPONENT_FROM_SCENARIO
    );
    removeComponentsFromScenario(componentIds);
  };

  return [
    scenarioId && {
      name: 'exclude-item-from-scenario',
      testId: 'exclude-component-from-scenario-item',
      label: `Exclude ${
        isMultiselect ? 'components' : 'component'
      } from scenario`,
      iconName: IconName.REMOVE,
      type: DropdownOptionType.OPTION,
      onClick: () => onRemoveFromScenarioClick(componentIds),
    },

    {
      name: 'delete-item',
      testId: 'delete-component-item',
      label,
      iconName: IconName.DELETE,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackingFn(trackingTitle);
        deleteModels({
          componentIds,
          getNextTreeSelectionCandidate,
        });
      },
    },
  ].filter(ExcludeFalsy);
};

export const getDeleteReferenceMenuItem = (
  referenceIds: ArdoqId[],
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'delete-references',
  testId: 'delete-reference-item',
  label: `Delete ${referenceIds.length === 1 ? 'reference' : 'references'}`,
  iconName: IconName.DELETE,
  type: DropdownOptionType.OPTION,
  onClick: () => {
    trackingFn(TrackedReferenceContextMenuLabels.DELETE_REFERENCE);
    deleteModels({ referenceIds });
  },
});

export const getDeleteMixedEntitiesItem = (
  componentIds: ArdoqId[],
  referenceIds: ArdoqId[],
  trackingFn: TrackingFunction
) => ({
  name: 'delete-mixed-entities',
  testId: 'delete-mixed-entities-item',
  label: `Delete`,
  iconName: IconName.DELETE,
  type: DropdownOptionType.OPTION,
  onClick: () => {
    trackingFn(TrackedCompAndRefContextMenuLabels.DELETE);
    deleteModels({ referenceIds, componentIds });
  },
});

export const getHistoryMenuItem = (
  entityId: ArdoqId,
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'view-history',
  testId: 'view-history-item',
  label: 'View history',
  iconName: IconName.HISTORY,
  type: DropdownOptionType.OPTION,
  isDisabled: isScenarioMode(),
  onClick: () => {
    trackingFn(TrackedComponentContextMenuLabels.VIEW_DIFF);
    trackAuditLogEntryPoint(`${entityType} history`);
    dispatchAction(
      navigateToAuditLog({
        entities: [
          {
            id: entityId,
            name:
              getNameFromIdAndCollection(entityId, entityType) ??
              'Missing name', // getNameFromIdAndCollection can potentially return null if the entity is not loaded in the backbone collection
          },
        ],
        entityType,
      })
    );
  },
});

type GetFilterMenuItemArgs = {
  componentId?: ArdoqId;
  trackingFunction: TrackingFunction;
  componentTypeGroupName?: string | null;
  isScenarioContextComponent?: boolean;
};
/**
 * If components are grouped by component type, the group node is not a component.
 * If (!componentId && componentTypeGroupName), then only filter options for com-
 * ponent type will be returned.
 */
export const getFilterMenuItem = ({
  componentId = '',
  trackingFunction,
  componentTypeGroupName,
  isScenarioContextComponent,
}: GetFilterMenuItemArgs) => {
  const componentName = componentInterface.getDisplayName(componentId);
  const componentTypeName =
    componentTypeGroupName ?? componentInterface.getTypeName(componentId);
  const workspaceId = componentInterface.getWorkspaceId(componentId);
  const workspaceName = workspaceId
    ? workspaceInterface.getWorkspaceName(workspaceId)
    : null;

  return {
    name: 'filter-list',
    label: (
      <div
        style={{
          wordBreak: 'keep-all',
        }}
      >
        Filter
      </div>
    ),
    intercomTarget: 'Filter',
    iconName: IconName.FILTER_LIST,
    type: DropdownOptionType.SUBMENU,
    options: getFilterMenuItemOptions({
      componentId,
      componentName,
      componentTypeName,
      workspaceId,
      workspaceName,
      trackingFunction,
      isScenarioContextComponent,
    }),
  };
};

type GetFilterMenuItemOptionsArgs = {
  componentId?: ArdoqId;
  componentName?: string | null;
  componentTypeName?: string | null;
  workspaceId?: string | null;
  workspaceName?: string | null;
  trackingFunction?: TrackingFunction;
  isScenarioContextComponent?: boolean;
};

const getFilterMenuItemOptions = ({
  componentId,
  componentName,
  componentTypeName,
  workspaceId,
  workspaceName,
  trackingFunction,
  isScenarioContextComponent,
}: GetFilterMenuItemOptionsArgs) => {
  const TrackedMenuLabels = isScenarioContextComponent
    ? TrackedScenarioContextComponentContextMenuLabels
    : TrackedComponentContextMenuLabels;

  return [
    componentId && {
      name: 'exclude-selected',
      testId: 'exclude-from-context-item',
      label: `Exclude "${componentName}"`,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackingFunction?.(TrackedMenuLabels.EXCLUDE_FROM_CONTEXT);
        excludeSelected(componentId, false);
      },
    },
    componentTypeName && {
      name: 'include-component-type',
      testId: 'show-components-of-type-item',
      label: `Show component type "${componentTypeName}"`,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackingFunction?.(TrackedMenuLabels.SHOW_ONLY_COMPONENTS_OF_THIS_TYPE);
        includeComponentsOfType(componentTypeName);
      },
    },
    componentTypeName && {
      name: 'exclude-component-type',
      testId: 'exclude-components-of-type-item',
      label: `Exclude component type "${componentTypeName}"`,
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackingFunction?.(TrackedMenuLabels.EXCLUDE_COMPONENTS_OF_THIS_TYPE);
        excludeComponentsOfType(componentTypeName);
      },
    },
    componentId &&
      componentName && {
        name: 'exclude-child-components',
        testId: 'exclude-child-components-item',
        label: `Exclude child components "${componentName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFunction?.(TrackedMenuLabels.EXCLUDE_CHILD_COMPONENTS);
          excludeChildComponents(componentId);
        },
      },
    workspaceId &&
      !loadedGraph$.state.isViewpointMode && {
        name: 'exclude-content-from-workspace',
        testId: 'exclude-content-from-workspace-item',
        label: `Exclude content from workspace "${workspaceName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFunction?.(TrackedMenuLabels.EXCLUDE_CONTENT_FROM_WORKSPACE);
          excludeContentFromWorkspace(workspaceId);
        },
      },
  ]
    .filter(ExcludeFalsy)
    .map(option => ({ ...option, truncateTitle: true }));
};

export const getFilterReferenceMenuItem = (
  referenceId: ArdoqId,
  trackingFn: TrackingFunction
) => {
  const referenceTypeName =
    referenceInterface.getGlobalReferenceType(referenceId)?.name;
  const sourceId = referenceInterface.getSourceComponentId(referenceId);
  const targetId = referenceInterface.getTargetComponentId(referenceId);
  const sourceName =
    sourceId && componentInterface.getAttribute(sourceId, 'name');
  const targetName =
    targetId && componentInterface.getAttribute(targetId, 'name');
  const referenceTargetWorkspaceId =
    targetId && componentInterface.getWorkspaceId(targetId);
  const referenceTargetWorkspaceName =
    referenceTargetWorkspaceId &&
    workspaceInterface.getWorkspaceName(referenceTargetWorkspaceId);

  return {
    name: 'filter-references',
    label: 'Filter',
    iconName: IconName.FILTER_LIST,
    type: DropdownOptionType.SUBMENU,
    options: [
      {
        name: 'exclude-reference',
        testId: 'exclude-reference-item',
        label: 'Exclude this reference',
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(TrackedReferenceContextMenuLabels.EXCLUDE_FROM_CONTEXT);
          excludeSelected(referenceId, true);
        },
      },
      referenceTypeName && {
        name: 'exclude-references-of-type',
        testId: 'exclude-references-of-type-item',
        label: `Exclude references of type "${referenceTypeName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedReferenceContextMenuLabels.EXCLUDE_REFERENCES_OF_TYPE
          );
          excludeReferencesOfType(referenceTypeName);
        },
      },
      referenceTypeName && {
        name: 'show-references-of-type',
        testId: 'show-references-of-type-item',
        label: `Show references of type "${referenceTypeName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedReferenceContextMenuLabels.SHOW_ONLY_REFERNCES_OF_TYPE
          );
          includeReferencesOfType(referenceTypeName);
        },
      },
      targetId && {
        name: 'show-references-to-target',
        testId: 'show-references-to-target-item',
        label: `Show references to target "${targetName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedReferenceContextMenuLabels.SHOW_ONLY_REFERNCES_TO_TARGET
          );
          createReferencesToTargetFilter(referenceId);
        },
      },
      sourceId && {
        name: 'show-references-from-source',
        testId: 'show-references-from-source-item',
        label: `Show references from source "${sourceName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedReferenceContextMenuLabels.SHOW_ONLY_REFERENCES_FROM_SOURCE
          );
          createReferencesFromSourceFilter(referenceId);
        },
      },
      referenceTargetWorkspaceId && {
        name: 'exclude-content-from-reference-target-workspace',
        testId: 'exclude-content-from-reference-target-workspace-item',
        label: `Exclude content from "${referenceTargetWorkspaceName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedReferenceContextMenuLabels.EXCLUDE_CONTENT_FROM_WORKSPACE
          );
          excludeContentFromWorkspace(referenceTargetWorkspaceId);
        },
      },
    ]
      .filter(ExcludeFalsy)
      .map(option => ({ ...option, truncateTitle: true })),
  };
};

export const getMergeEntitiesMenuItem = (
  entityIds: ArdoqId[],
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  trackingFn: TrackingFunction
): DropdownOption => {
  const isComponent = entityType === APIEntityType.COMPONENT;
  const trackingOptionTitle = isComponent
    ? TrackedComponentContextMenuLabels.MERGE_COMPONENTS
    : TrackedReferenceContextMenuLabels.MERGE_REFERENCES;

  return {
    name: 'merge-entities',
    testId: 'merge-entities-item',
    label: `Merge ${isComponent ? 'components' : 'references'}`,
    iconName: IconName.SCENARIO_MERGE,
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackingFn(trackingOptionTitle);
      dispatchAction(initEntityMerge({ entityIds, entityType }));
    },
  };
};

export const getReverseReferenceMenuItem = (
  referenceIds: ArdoqId[],
  trackingFn: TrackingFunction,
  canEditAllReferencesTargets: boolean
): DropdownOption | null => {
  if (!canEditAllReferencesTargets) {
    return null;
  }
  return {
    name: 'reverse-references',
    testId: 'reverse-references-item',
    label: `Reverse ${referenceIds.length === 1 ? 'reference' : 'references'}`,
    iconName: IconName.SWAP_HORIZONTAL,
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackingFn(TrackedReferenceContextMenuLabels.REVERSE);
      reverseReferencesById(referenceIds);
    },
  };
};

export const getPermissionsMenuItem = (
  workspaceId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownOption | null => {
  const workspaceName = workspaceInterface.getWorkspaceName(workspaceId);

  if (!workspaceName) {
    return null;
  }

  return {
    name: 'permissions',
    testId: 'permissions-item',
    label: `Permissions`,
    iconName: IconName.PERSON,
    isDisabled: isScenarioMode(),
    type: DropdownOptionType.OPTION,
    onClick: () => {
      trackingFn(TrackedWorkspaceContextMenuLabels.PERMISSIONS);
      dispatchAction(
        openResourcePermissionDialog({
          resources: [
            {
              resourceId: workspaceId,
              resourceName: workspaceName,
              resourceType: ResourceType.WORKSPACE,
            },
          ],
          originPage: 'workspace',
        })
      );
    },
  };
};

export const getEditWorkspaceMenuItem = (
  workspaceId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'edit-workspace',
  testId: 'edit-workspace-item',
  label: `Edit workspace`,
  iconName: IconName.EDIT,
  type: DropdownOptionType.OPTION,
  isDisabled: isScenarioMode(),
  onClick: () => {
    trackingFn(TrackedWorkspaceContextMenuLabels.EDIT_WORKSPACE);
    dispatchAction(
      showRightPane({
        type: GetContentOptionsType.WORKSPACE_PROPERTIES,
        workspaceId,
      })
    );
  },
});

export const getEditMetamodelMenuItem = (
  workspaceId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'edit-metamodel',
  testId: 'edit-metamodel-item',
  label: `Edit metamodel`,
  iconName: IconName.EDIT,
  type: DropdownOptionType.OPTION,
  isDisabled: isScenarioMode(),
  onClick: () => {
    trackingFn(TrackedWorkspaceContextMenuLabels.EDIT_METAMODEL);
    if (
      hasFeature(Features.METAMODEL_EDITOR_ALPHA) ||
      hasFeature(Features.METAMODEL_EDITOR_BETA)
    ) {
      dispatchAction(
        navigateToOrganizationMetamodel({
          route:
            '/organization-metamodel/workspaces/:workspaceId/component-types',
          params: {
            workspaceId,
          },
        })
      );
    } else {
      dispatchAction(showWorkspaceModelEditor({ workspaceId }));
    }
  },
});

export const getExcludeWorkspaceMenuItem = (
  scenarioId: ArdoqId,
  workspaceId: ArdoqId,
  isDisabled: boolean,
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'exclude-workspace-from-scenario',
  testId: 'exclude-workspace-from-scenario-item',
  label: 'Exclude workspace from scenario',
  iconName: IconName.REMOVE,
  type: DropdownOptionType.OPTION,
  isDisabled,
  onClick: () => {
    trackingFn(
      TrackedWorkspaceContextMenuLabels.EXCLUDE_WORKSPACE_FROM_SCENARIO
    );
    dispatchAction(excludeWorkspaceFromScenario({ scenarioId, workspaceId }));
  },
});

export const getFilterWorkspaceMenuItem = (
  workspaceId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownSubmenu | null => {
  const workspaceName = workspaceInterface.getWorkspaceName(workspaceId);

  if (!workspaceName) {
    return null;
  }

  return {
    name: 'workspace-filter',
    label: 'Filter',
    iconName: IconName.FILTER_LIST,
    type: DropdownOptionType.SUBMENU,
    options: [
      {
        name: 'exclude-content-from-workspace-filter',
        testId: 'exclude-workspace-content-item',
        label: `Filter out content from "${workspaceName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedWorkspaceContextMenuLabels.EXCLUDE_CONTENT_FROM_WORKSPACE
          );
          excludeContentFromWorkspace(workspaceId);
        },
      },
      {
        name: 'exclude-references-to-workspace-filter',
        testId: 'exclude-references-to-item',
        label: `Filter out references to workspace "${workspaceName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedWorkspaceContextMenuLabels.EXCLUDE_REFERENCES_TO_WORKSPACE
          );
          excludeReferencesToWorkspace(workspaceId);
        },
      },
      {
        name: 'exclude-references-from-workspace-filter',
        testId: 'exclude-references-from-item',
        label: `Filter out references from workspace "${workspaceName}"`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(
            TrackedWorkspaceContextMenuLabels.EXCLUDE_REFERENCES_FROM_WORKSPACE
          );
          excludeReferencesFromWorkspace(workspaceId);
        },
      },
    ],
  };
};

export const getEditTagMenuItem = (
  tagId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'edit-tag',
  testId: 'edit-tag-item',
  label: `Edit tag`,
  iconName: IconName.EDIT,
  type: DropdownOptionType.OPTION,
  onClick: () => {
    dispatchAction(editTag(tagId));
    trackingFn(TrackedTagContextMenuLabels.EDIT_TAG);
  },
});

export const getDeleteTagMenuItem = (
  tagId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownOption => ({
  name: 'delete-tag',
  testId: 'delete-tag-item',
  label: `Delete tag`,
  iconName: IconName.EDIT,
  type: DropdownOptionType.OPTION,
  onClick: () => {
    dispatchAction(deleteTag(tagId));
    trackingFn(TrackedTagContextMenuLabels.DELETE_TAG);
  },
});

export const getFilterTagMenuItem = (
  tagId: ArdoqId,
  trackingFn: TrackingFunction
): DropdownSubmenu | null => {
  return {
    name: 'tag-filter',
    label: 'Filter',
    iconName: IconName.FILTER_LIST,
    type: DropdownOptionType.SUBMENU,
    options: [
      {
        name: 'filter-show-content-with-tag',
        testId: 'show-content-with-tag-item',
        label: `Show content with tag`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(TrackedTagContextMenuLabels.SHOW_CONTENT_WITH_TAG);
          dispatchAction(filterTag({ id: tagId, exclude: false }));
        },
      },
      {
        name: 'filter-exclude-content-with-tag',
        testId: 'exclude-content-with-tag-item',
        label: `Exclude content with tag`,
        type: DropdownOptionType.OPTION,
        onClick: () => {
          trackingFn(TrackedTagContextMenuLabels.EXCLUDE_CONTENT_WITH_TAG);
          dispatchAction(filterTag({ id: tagId, exclude: true }));
        },
      },
    ],
  };
};
