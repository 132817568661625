import {
  EMPTY_COLUMN_CUSTOM_NAME,
  getColumnIconNameByType,
  ReportBuilderDataPresentationSectionProps,
  reportBuilderOperations,
  ReportBuilderSectionErrorProps,
  ReportTemplate,
  RESETED_COLUMN_CUSTOM_NAME,
} from '@ardoq/report-builder';
import { Maybe } from '@ardoq/common-helpers';
import {
  Aggregate,
  APIOrganizationUser,
  APISearchAggregatesResponse,
  ArdoqId,
  ReportColumn,
  ReportColumnType,
} from '@ardoq/api-types';
import {
  aggregatesWithLabel,
  EnhancedSearchResponse,
  getColumnOriginalLabel,
  isColumnSortAble,
} from '@ardoq/report-reader';
import { SelectOption, SelectOptionsOrGroups } from '@ardoq/select';
import { compact, isEmpty } from 'lodash';
import { Locale } from '@ardoq/locale';
import { StatusType } from '@ardoq/status-ui';

// Note: this is different than reportBuilderOperations.getColumnsWithUpdatedNames,
// the return value is for displaying in ColumnRenameSubsection,
// the function will keep values as what user typed in to display,
// like '', trailing spaces, original name etc
const getColumnRenameOptions = (reportTemplate: ReportTemplate) => {
  const { columns, updatedColumnNames } = reportTemplate;
  if (isEmpty(updatedColumnNames)) {
    return columns;
  }

  return columns.map(column => {
    const newName =
      updatedColumnNames[
        reportBuilderOperations.getUpdatedColumnNameKey(column)
      ];

    if (newName === RESETED_COLUMN_CUSTOM_NAME) {
      const updatedColumn = {
        ...column,
        label: getColumnOriginalLabel(column),
      };
      delete updatedColumn.originalLabel;
      return updatedColumn;
    }

    if (newName === EMPTY_COLUMN_CUSTOM_NAME || newName) {
      return {
        ...column,
        originalLabel: getColumnOriginalLabel(column),
        label: newName,
      };
    }

    return column;
  });
};

const getAggregateOptions = (
  reportTemplate: ReportTemplate,
  searchAggregatesResults: Maybe<APISearchAggregatesResponse>
): SelectOption<Aggregate>[] => {
  if (!searchAggregatesResults?.aggregates) {
    return [];
  }
  const selectedColumnNames = new Set(
    reportTemplate.columns.map(column => column.key)
  );
  const aggregatesResults = Object.entries(searchAggregatesResults.aggregates);

  return Object.entries(aggregatesWithLabel)
    .filter(([value]) =>
      aggregatesResults.some(
        ([fieldName, aggregateForField]) =>
          selectedColumnNames.has(fieldName) && value in aggregateForField
      )
    )
    .map(([value, label]) => ({
      label,
      value: value as Aggregate,
    }));
};

const getSortOptions = (columns: ReportColumn[]): SelectOption<string>[] =>
  columns.filter(isColumnSortAble).map(({ key, label }) => ({
    value: key,
    label,
  }));

const getColumnOptions = (
  searchResult: EnhancedSearchResponse | null
): SelectOptionsOrGroups<number> => {
  if (!searchResult) return [];

  const columnsWithIndexValues = searchResult.columns.map((col, i) => ({
    ...col,
    value: i,
  }));
  if (columnsWithIndexValues.some(column => !column.type)) {
    // this one happens for the search results that doesn't return any scopeData
    return columnsWithIndexValues;
  }

  const referenceColumnOptions = columnsWithIndexValues.filter(
    ({ type }) =>
      type === ReportColumnType.REFERENCE_TYPE_OUTGOING ||
      type === ReportColumnType.REFERENCE_TYPE_INCOMING
  );

  const customFieldNames = new Set(
    searchResult.scopeData?.fields.map(field => field.name) ?? []
  );

  const customFieldOptions = columnsWithIndexValues.filter(
    ({ key, type }) =>
      (type === ReportColumnType.FIELD && customFieldNames.has(key)) ||
      type === ReportColumnType.CUSTOM
  );
  const defaultFieldOptions = columnsWithIndexValues.filter(
    ({ key, type }) =>
      type === ReportColumnType.FIELD && !customFieldNames.has(key)
  );
  return compact([
    referenceColumnOptions.length && {
      label: 'Reference types',
      options: referenceColumnOptions,
    },
    customFieldOptions.length && {
      label: 'Custom fields',
      options: customFieldOptions,
    },
    defaultFieldOptions.length && {
      label: 'Default fields',
      options: defaultFieldOptions,
    },
  ]).map(({ label, options }) => ({
    label,
    options: options.map(({ type, label, originalLabel, value }) => {
      const rightIconName = getColumnIconNameByType(type);
      return {
        value: value,
        label: originalLabel ?? label, // original column name is used for report columns selector
        type,
        rightIcon: rightIconName ? { name: rightIconName } : undefined,
      };
    }),
  }));
};

const getSelectedColumnOptions = (
  selectedColumns: ReportColumn[],
  searchResult: EnhancedSearchResponse | null
): Array<SelectOption<number>> => {
  if (!searchResult) return [];

  const goneColumnKeys = (searchResult?.goneColumns ?? []).map(col => col.key);
  return selectedColumns
    .filter(Boolean)
    .map(({ key, type, label, originalLabel }) => {
      const rightIconName = getColumnIconNameByType(type);
      return {
        type,
        label: originalLabel ?? label, // original column name is used for report columns selector
        value: searchResult.columns.findIndex(
          column => column.key === key && column.type === type
        ),
        rightIcon: rightIconName ? { name: rightIconName } : undefined,
        statusType: goneColumnKeys.includes(key) ? StatusType.ERROR : undefined,
      };
    });
};

type GetDataPresentationSectionArgs = {
  errorFromSearchAggregates: Maybe<string>;
  reportTemplate: ReportTemplate;
  searchResults: Maybe<EnhancedSearchResponse>;
  isColumnRenameNotificationVisible: boolean;
  searchAggregatesResults: Maybe<APISearchAggregatesResponse>;
  isSearchBeingExecuted: boolean;
  isSearchAggregatesBeingExecuted: boolean;
  currentUserLocale: Locale;
  users: Record<ArdoqId, APIOrganizationUser>;
  hasGoneColumnsSelected: boolean;
} & ReportBuilderSectionErrorProps;

export const getDataPresentationSectionProps = ({
  errorFromSearchAggregates,
  reportTemplate,
  searchResults,
  isColumnRenameNotificationVisible,
  searchAggregatesResults,
  isSearchAggregatesBeingExecuted,
  isSearchBeingExecuted,
  showErrorsOnInputs,
  users,
  currentUserLocale,
  hasGoneColumnsSelected,
}: GetDataPresentationSectionArgs): ReportBuilderDataPresentationSectionProps => {
  const columnSelectorHasError =
    (showErrorsOnInputs &&
      reportBuilderOperations.hasNoColumns(reportTemplate)) ||
    hasGoneColumnsSelected; // gone columns can be present initially before form is submitted

  const columnsWithUpdatedNames =
    reportBuilderOperations.getColumnsWithUpdatedNames(reportTemplate);

  const sortOptions = getSortOptions(columnsWithUpdatedNames);

  const columnRenameErrors = reportBuilderOperations.getColumnsRenameErrors(
    columnsWithUpdatedNames
  );
  const columnRenameSubSectionHasError =
    showErrorsOnInputs && !isEmpty(columnRenameErrors);

  return {
    selectedSort: reportBuilderOperations.getSortKeyAndOrder(reportTemplate),
    selectedAggregate: reportTemplate.selectedAggregate,
    aggregateOptions: getAggregateOptions(
      reportTemplate,
      searchAggregatesResults
    ),
    hasGoneColumnsSelected,
    sortOptions,
    sortInputHasWarning: Boolean(
      searchResults && !sortOptions.length && !isSearchBeingExecuted
    ),
    isPreviewReportButtonDisabled:
      !searchResults ||
      !searchResults.totalNumberOfRows ||
      !reportTemplate.columns,
    columnOptions: getColumnOptions(searchResults),
    selectedColumnOptions: getSelectedColumnOptions(
      reportTemplate.columns,
      searchResults
    ),
    columnRenameOptions: getColumnRenameOptions(reportTemplate),
    columnRenameErrors,
    columnRenameSubSectionHasError,
    isColumnRenameSubSectionEnabled:
      reportBuilderOperations.isAdvancedSearchBased(reportTemplate) &&
      !hasGoneColumnsSelected,
    isColumnRenameNotificationVisible,
    columnSelectorHelperText: !searchResults
      ? 'To select columns, complete a search in the previous step'
      : undefined,
    isAddAllColumnsButtonDisabled:
      !searchResults?.columns.length ||
      searchResults.columns.every(searchResultsColumn =>
        reportTemplate.columns.some(
          reportColumn =>
            searchResultsColumn.key === reportColumn.key &&
            searchResultsColumn.type === reportColumn.type
        )
      ),
    columnSelectorHasError,
    columnSelectorErrorMessage:
      columnSelectorHasError && !hasGoneColumnsSelected
        ? 'Select at least one column to analyze your data.'
        : undefined,
    aggregateSubSectionHasError: Boolean(errorFromSearchAggregates),
    isSearchBeingExecuted,
    isSearchAggregatesBeingExecuted,
    users,
    currentUserLocale,
    searchResultsPreview: searchResults
      ? {
          ...searchResults,
          ...searchAggregatesResults,
          columns: columnsWithUpdatedNames,
          results: searchResults.results.slice(0, 5),
        }
      : null,
  };
};
