import { fields$ } from './fields$';
import { map, shareReplay } from 'rxjs/operators';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { APIFieldAttributes, ArdoqId } from '@ardoq/api-types';
import { pick } from 'lodash';
import { dateRangeOperations, getDateRangeType } from '@ardoq/date-range';
import { modelInterface } from 'modelInterface/models/modelInterface';

export type GlobalFieldAttributes = Pick<
  APIFieldAttributes,
  '_id' | 'name' | 'label' | 'type' | 'calculatedFieldSettings'
> & {
  modelIds: Set<ArdoqId>;
  // Workspaces the field is used in
  workspaceIds: Set<ArdoqId>;
  // Templates the field is used in
  templateIds: Set<ArdoqId>;
  allFieldIdsWithSameName: Set<ArdoqId>;
};

export type GlobalFieldsByName = Record<string, GlobalFieldAttributes>;

type DateRangeFieldName = string;

type UnmatchedSiblingMap = Map<DateRangeFieldName, APIFieldAttributes>;

type ParsedFieldsData = {
  byName: GlobalFieldsByName;
  unmatchedStarts: UnmatchedSiblingMap;
  unmatchedEnds: UnmatchedSiblingMap;
};

const fieldToDateRangeField = (
  field: APIFieldAttributes
): APIFieldAttributes => ({
  ...field,
  label: dateRangeOperations.extractDateRangeFieldLabel(field.label),
  name: dateRangeOperations.createCombinedDateRangeFieldNames(
    dateRangeOperations.extractDateRangeFieldName(field.name)
  ),
  type: getDateRangeType(field.type),
});

const getGlobalFieldAttributes = (
  field: APIFieldAttributes
): GlobalFieldAttributes => ({
  ...pick(field, '_id', 'name', 'label', 'type', 'calculatedFieldSettings'),
  modelIds: new Set([field.model]),
  workspaceIds: new Set(),
  allFieldIdsWithSameName: new Set([field._id]),
  templateIds: new Set(),
});

const isTemplateField = (field: APIFieldAttributes) => {
  const model = modelInterface.get(field.model);

  if (!model) {
    return false;
  }

  return model.common || model.useAsTemplate;
};

const addToGlobalFields = (
  byName: GlobalFieldsByName,
  field: APIFieldAttributes
): GlobalFieldsByName => {
  const existingEntry = byName[field.name];
  if (existingEntry) {
    existingEntry.allFieldIdsWithSameName.add(field._id);
  } else {
    byName[field.name] = getGlobalFieldAttributes(field);
  }
  if (isTemplateField(field)) {
    byName[field.name].templateIds.add(field.model);
  } else {
    const fieldWorkspaceId = workspaceInterface.getWorkspaceIdByModelId(
      field.model
    );
    if (fieldWorkspaceId) byName[field.name].workspaceIds.add(fieldWorkspaceId);
  }
  return byName;
};

const includeOrphanedDateRangeFields = ({
  byName,
  unmatchedEnds,
  unmatchedStarts,
}: ParsedFieldsData) => {
  const orphanedDateRangeFields = [
    ...unmatchedStarts.values(),
    ...unmatchedEnds.values(),
  ];
  return orphanedDateRangeFields.reduce(addToGlobalFields, byName);
};

const matchDateRangeSiblings = (
  acc: ParsedFieldsData,
  field: APIFieldAttributes
) => {
  if (!dateRangeOperations.isPartOfDateRangeField(field)) {
    return { ...acc, byName: addToGlobalFields(acc.byName, field) };
  }
  const dateRangeFieldName = dateRangeOperations.extractDateRangeFieldName(
    field.name
  );
  const isStart = dateRangeOperations.fieldNameIsDateRangeStartField(
    field.name
  );
  const siblingMap = isStart ? acc.unmatchedEnds : acc.unmatchedStarts;
  const foundSibling = siblingMap.get(dateRangeFieldName);
  if (foundSibling) {
    siblingMap.delete(dateRangeFieldName);
    acc.byName = [field, foundSibling]
      .map(fieldToDateRangeField)
      .reduce(addToGlobalFields, acc.byName);
  } else {
    const addToMap = isStart ? acc.unmatchedStarts : acc.unmatchedEnds;
    addToMap.set(dateRangeFieldName, field);
  }
  return acc;
};

export const globalFields$ = fields$.pipe(
  map(({ byId }) => {
    const rawFields = Object.values(byId);
    const parsedFieldData = rawFields.reduce(matchDateRangeSiblings, {
      byName: {},
      unmatchedEnds: new Map(),
      unmatchedStarts: new Map(),
    });
    return includeOrphanedDateRangeFields(parsedFieldData);
  }),
  shareReplay({ bufferSize: 1, refCount: true })
);
