import {
  ApiCountInstancesRequest,
  APICountInstancesResponse,
  DirectedTriple,
  QueryBuilderQuery,
  ReferenceDirection,
  TraversalPathMatchingType,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import {
  DisableableDirectedTripleWithFilters,
  TripleOptions,
  TripleSortOrder,
} from '../types';
import { Result } from '@ardoq/common-helpers';

export type SetStartTypeAndTraversalStartSetOrCount = {
  startType: string | null;
  traversalStartSet?: string[];
  traversalStartQueryResultCount?: number;
  traversalStartQuery?: QueryBuilderQuery | null;
};
export const setStartTypeAndTraversalStartSetOrCount =
  actionCreator<SetStartTypeAndTraversalStartSetOrCount>(
    '[viewpointBuilderTraversal] SET_START_TYPE'
  );

export type ToggleTripleOptionPayload = {
  tripleId: string;
  direction: ReferenceDirection;
  namedDirectedTriple: DirectedTriple;
  isSelected: boolean;
};

export const toggleTripleOption = actionCreator<ToggleTripleOptionPayload>(
  '[viewpointBuilderTraversal] TOGGLE_TRIPLE_OPTION'
);
export const removeNodeFromGraph = actionCreator<string>(
  '[viewpointBuilderTraversal] REMOVE_NODE_FROM_GRAPH'
);

export type ToggleIsSelectedGraphNodePayload = {
  graphNodeId: string;
  isForceSelected?: boolean;
};

export type ToggleIsSelectedGraphEdgePayload = { graphEdgeId: string };

export const toggleIsSelectedGraphNode =
  actionCreator<ToggleIsSelectedGraphNodePayload>(
    '[viewpointBuilderTraversal] TOGGLE_IS_SELECTED'
  );

export const toggleIsSelectedGraphEdge =
  actionCreator<ToggleIsSelectedGraphEdgePayload>(
    '[viewpointBuilderTraversal] TOGGLE_IS_EDGE_SELECTED'
  );

export type SetSectionFilterPayload = {
  filterTerm: string;
};

export const setSectionFilter = actionCreator<SetSectionFilterPayload>(
  '[viewpointBuilderTraversal] SET_SECTION_FILTER'
);

export type SetTraversalsPayload = {
  paths: DisableableDirectedTripleWithFilters[][];
  filters: ViewpointBuilderFilters | null;
};

export const setTraversals = actionCreator<SetTraversalsPayload>(
  '[viewpointBuilderTraversal] SET_TRAVERSALS'
);

export type SetTripleSortOrderPayload = {
  tripleSortOrder: TripleSortOrder;
};

export const setTripleSortOrder = actionCreator<SetTripleSortOrderPayload>(
  '[viewpointBuilderTraversal] SET_TRIPLE_SORT_ORDER'
);

export const requestFetchInstanceCounts =
  actionCreator<ApiCountInstancesRequest>(
    '[viewpointBuilderTraversal] REQUEST_FETCH_INSTANCE_COUNTS'
  );

export const requestFetchOptionsInstanceCounts =
  actionCreator<ApiCountInstancesRequest>(
    '[viewpointBuilderTraversal] REQUEST_FETCH_OPTIONS_INSTANCE_COUNTS'
  );

export const responseInstanceCounts = actionCreator<
  // TODO (chriskr): Use the Result type when it's ready.
  Result<APICountInstancesResponse>
>('[viewpointBuilderTraversal] RESPONSE_INSTANCE_COUNTS');

export const responseOptionsInstanceCounts = actionCreator<
  // TODO (chriskr): Use the Result type when it's ready.
  Result<APICountInstancesResponse>
>('[viewpointBuilderTraversal] RESPONSE_OPTIONS_INSTANCE_COUNTS');

export const setOptionCountLoadingState = actionCreator<
  TripleOptions['optionCountLoadingState']
>('[viewpointBuilderTraversal] SET_OPTION_COUNT_LOADING_STATE');

export const toggleShowOnlyOptionsWithInstanceCounts = actionCreator(
  '[viewpointBuilderTraversal] TOGGLE_SHOW_ONLY_OPTIONS_WITH_INSTANCE_COUNTS'
);

export const dontShowAgainClickOtherComponentTypeNodesHints = actionCreator(
  '[viewpointBuilderTraversal] DONT_SHOW_AGAIN_CLICK_OTHER_COMPONENT_TYPE_NODES_HINTS'
);

export type UpdateTraversalStartContextPayload = {
  traversalStartSet: string[] | null;
  traversalStartQuery: QueryBuilderQuery | null;
  startComponentType: string | null;
};

export const updateTraversalStartContext =
  actionCreator<UpdateTraversalStartContextPayload>(
    '[viewpointBuilderTraversal] UPDATE_START_CONTEXT'
  );

export const setPathMatchingType = actionCreator<TraversalPathMatchingType>(
  '[viewpointBuilderTraversal] SET_PATH_MATCHING_TYPE'
);

export const setShouldIncludeInstanceCounts = actionCreator<boolean>(
  '[viewpointBuilderTraversal] SET_SHOULD_INCLUDE_INSTANCE_COUNTS'
);

export const resetTraversalBuilderState = actionCreator(
  '[viewpointBuilderTraversal] RESET_TRAVERSAL_BUILDER_STATE'
);

export const isReadyToUpdate = actionCreator<boolean>(
  '[viewpointBuilderTraversal] IS_READY_TO_UPDATE'
);
