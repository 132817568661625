import { CSSProperties, useEffect, useRef } from 'react';
import { colors } from '@ardoq/design-tokens';
import styled from 'styled-components';
import ViewPaneElement from 'atomicComponents/ViewPaneElement';
import { MAIN_PANE_LEFT, PaneLocation } from 'streams/views/mainContent/types';
import TabBar from './TabBar';
import ViewContainer from 'tabview/ViewContainer';
import MainPane from './MainPaneView';
import { dispatchAction } from '@ardoq/rxbeach';
import { leftPaneCreated, rightPaneCreated } from './actions';
import { connect } from '@ardoq/rxbeach';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import DetailsDrawer from './ardoqStudio/detailsDrawer/DetailsDrawer';
import ViewSidePanel from 'views/mainApp/viewSidePanel/ViewSidePanel';
import { HasViewpointMode } from '@ardoq/graph';
import ViewPaneHeader from './ardoqStudio/viewPaneHeader/ViewPaneHeader';
import ComponentViewpointFab from 'views/mainApp/fab/componentFab/ComponentViewpointFab';
import { ResizableSidebarEditorAsOverlay } from './ResizableSidebarEditorAsOverlay';
import ContextToolBar from '../contextToolBar/ContextToolBar';
import { Features, hasFeature } from '@ardoq/features';
import { RightPane } from 'views/mainApp/rightPane/rightPane';

const ScrollContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  height: 100%;
  overflow: auto;
  width: 100%;
`;

const Container = styled(ViewPaneElement)`
  @media print {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: unset;
  flex: unset;
  background-color: ${colors.white};
  /* Needed to be above the grid editor iframe. We should find a better
     way to deal with this issue. */
  z-index: 1;
  max-width: 100%;
`;

const ViewpointModeContainer = styled(Container)`
  right: 0;
`;

type ViewPaneContainerOwnProps = {
  style: CSSProperties;
  location: PaneLocation;
  isSplitPane: boolean;
  isRightEditorPaneOpen?: boolean;
  isDetailsDrawerOpen: boolean;
};

type ViewPaneContainerStreamedProps = HasViewpointMode;
type ViewPaneContainerProps = ViewPaneContainerOwnProps &
  ViewPaneContainerStreamedProps;

const widthOfSidePanelHeaderButtons = 368;

const ViewPaneContainer = ({
  style,
  location,
  isSplitPane,
  isRightEditorPaneOpen = false,
  isDetailsDrawerOpen,
  isViewpointMode,
}: ViewPaneContainerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const action =
      location === MAIN_PANE_LEFT ? leftPaneCreated : rightPaneCreated;
    dispatchAction(action(ref));
    return () => dispatchAction(action(null));
  }, [ref, location]);

  const isVisualCanvasPrototypeEnabled =
    isViewpointMode && hasFeature(Features.CANVAS_PROTOTYPE);

  if (isViewpointMode) {
    return (
      <ViewpointModeContainer style={style} data-test-id={location}>
        <ScrollContainer>
          <ViewPaneHeader />
          <ViewContainer ref={ref}>
            <MainPane location={location} isViewpointMode={isViewpointMode} />
          </ViewContainer>
          <ViewSidePanel />
          <ResizableSidebarEditorAsOverlay
            initialWidth={widthOfSidePanelHeaderButtons}
            isRightEditorPaneOpen={isRightEditorPaneOpen}
          >
            <RightPane isViewpointMode={isViewpointMode} />
          </ResizableSidebarEditorAsOverlay>
          {isDetailsDrawerOpen && <DetailsDrawer />}
          <ComponentViewpointFab />
        </ScrollContainer>
        {isVisualCanvasPrototypeEnabled && <ContextToolBar />}
      </ViewpointModeContainer>
    );
  }

  return (
    <Container style={style} data-test-id={location}>
      <TabBar
        location={location}
        isSplitPane={isSplitPane}
        isRightEditorPaneOpen={isRightEditorPaneOpen}
      />
      <ViewContainer ref={ref}>
        <MainPane location={location} isViewpointMode={isViewpointMode} />
      </ViewContainer>
      <ViewSidePanel />
    </Container>
  );
};

const ConnectedViewPaneContainer = connect<
  ViewPaneContainerProps,
  ViewPaneContainerStreamedProps
>(ViewPaneContainer, isViewpointMode$);
export default ConnectedViewPaneContainer;
