import { APIComponentAttributes, ArdoqId, LoadedState } from '@ardoq/api-types';
import { EnhancedScopeData, GraphModelShape } from '@ardoq/data-model';
import {
  dispatchAction,
  persistentReducedStream,
  reducer,
} from '@ardoq/rxbeach';
import { hideDetailsDrawer, setDrawerData } from './actions';
import { detailsDrawerOperations } from './detailsDrawerOperations';
import { combineLatest, map, filter, debounceTime } from 'rxjs';
import { activeScenario$ } from 'streams/activeScenario/activeScenario$';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { isPresentationMode } from 'appConfig';
import { componentOverviewPageOperations } from 'appContainer/componentOverviewPage/componentOverviewPageOperations';
import {
  setShouldIncludeInstanceCounts,
  setStartTypeAndTraversalStartSetOrCount,
  setTraversals,
} from 'viewpointBuilder/traversals/editTraversalActions';
import { loadedState$ } from 'loadedState/loadedState$';
import { editTraversals$ } from 'viewpointBuilder/traversals/editTraversals$';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import {
  DisableableDirectedTripleWithFilters,
  EditTraversalState,
} from 'viewpointBuilder/types';
import { viewpointBuilderOpened$ } from 'viewpointBuilder/openViewpointBuilder/viewpointBuilderOpened$';
import { toggleReferenceTypesInViewCommands } from './triplesTogglerCommands';
import { appLayout$ } from '../../appLayout$';
import { getPathsFromStartSetToSelectedComponents } from 'viewpointBuilder/getPathsFromStartSetToSelectedComponents';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import graphModel$ from 'modelInterface/graphModel$';
import { uniqWith, isEqual } from 'lodash';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

export type DetailsDrawerCommands = {
  hideDetailsDrawer: VoidFunction;
};

const detailsDrawerCommands: DetailsDrawerCommands = {
  hideDetailsDrawer: () => dispatchAction(hideDetailsDrawer()),
};

export type DetailsDrawerState = {
  scopeData: EnhancedScopeData | null;
  componentId: ArdoqId | null;
};

const defaultState: DetailsDrawerState = {
  scopeData: null,
  componentId: null,
};

export const detailsDrawerState$ = persistentReducedStream<DetailsDrawerState>(
  'detailsDrawer$',
  defaultState,
  [reducer(setDrawerData, detailsDrawerOperations.setDrawerData)]
);

const isTraversalStateNotBasedOnCurrentComponent = (
  editTraversalState: EditTraversalState,
  componentId: ArdoqId
) => {
  return (
    !editTraversalState.startComponentType ||
    componentId !== editTraversalState.traversalStartSet?.[0]
  );
};

/**
 * If we open viewpoint builder and a context component is opened in the side panel, the initial paths are the same,
 * but do not include the `isDisabled` flag, therefore we need to reset those.
 */
const shouldInitTraversalPathsToDisableSelectedTriples = (
  paths: DisableableDirectedTripleWithFilters[][],
  editTraversalState: EditTraversalState
) => {
  const isAnyTripleDisabledBasedOnLoadedStates = paths
    .flatMap(path => path)
    .some(triple => triple.isDisabled);

  const isAnyTripleDisabledInEditTraversalState =
    editTraversalState.initialPaths
      ?.flatMap(path => path)
      .some(triple => triple.isDisabled);

  return (
    editTraversalState.initialPaths?.length &&
    isAnyTripleDisabledBasedOnLoadedStates &&
    !isAnyTripleDisabledInEditTraversalState
  );
};

const initTraversalPathsIfNecessary = (
  editTraversalState: EditTraversalState,
  selectedComponent: APIComponentAttributes,
  loadedStates: LoadedState[],
  graphModel: GraphModelShape
) => {
  const pathsFromStartSetToSelectedComponents =
    getPathsFromStartSetToSelectedComponents({
      loadedStates,
      selectedComponentId: selectedComponent._id,
      graphModel,
      getComponentTypeName: componentInterface.getTypeName,
      getReferenceTypeName: (id: ArdoqId) =>
        referenceInterface.getReferenceTypeNameByReferenceId(id),
    });

  const paths =
    loadedStateOperations.getPathsForSelectedComponentTypeFromTraversalsContainingComponentId(
      loadedStates,
      selectedComponent._id,
      uniqWith(pathsFromStartSetToSelectedComponents, isEqual)
    );

  if (
    isTraversalStateNotBasedOnCurrentComponent(
      editTraversalState,
      selectedComponent._id
    ) ||
    shouldInitTraversalPathsToDisableSelectedTriples(paths, editTraversalState)
  ) {
    dispatchAction(
      setStartTypeAndTraversalStartSetOrCount({
        startType: selectedComponent.type,
        traversalStartSet: [selectedComponent._id],
      })
    );
    dispatchAction(setTraversals({ paths, filters: null }));
    dispatchAction(setShouldIncludeInstanceCounts(true));
  }
};

export const detailsDrawer$ = combineLatest({
  detailsDrawerState: detailsDrawerState$,
  activeScenarioState: activeScenario$,
  permissionContext: currentUserPermissionContext$,
  subdivisionsContext: subdivisions$,
  editTraversalState: editTraversals$,
  loadedStates: loadedState$,
  viewpointBuilderOpenedState: viewpointBuilderOpened$,
  appLayoutState: appLayout$,
  graphModel: graphModel$,
}).pipe(
  filter(({ viewpointBuilderOpenedState }) => {
    return !viewpointBuilderOpenedState.isOpened;
  }),
  debounceTime(100),
  map(
    ({
      detailsDrawerState,
      activeScenarioState,
      permissionContext,
      subdivisionsContext,
      editTraversalState,
      loadedStates,
      appLayoutState,
      graphModel,
    }) => {
      const { scopeData, componentId } = detailsDrawerState;
      const selectedComponent =
        componentId && scopeData?.componentsById[componentId];

      if (!selectedComponent) {
        return {
          ...detailsDrawerState,
          scopeData: scopeData,
          editTraversalState,
          toggleReferenceTypesInViewCommands,
          commands: detailsDrawerCommands,
        };
      }

      const scopeDataWithFilteredFields =
        componentOverviewPageOperations.filterFieldsInScopeDataWithSubdivisionsAndModes(
          {
            scopeData,
            subdivisionsContext,
            permissionContext,
            component: scopeData.componentsById[componentId],
            isScenarioMode:
              activeScenarioOperations.isInScenarioMode(activeScenarioState),
            isPresentationMode: isPresentationMode(),
          }
        );

      initTraversalPathsIfNecessary(
        editTraversalState,
        selectedComponent,
        loadedStates,
        graphModel
      );

      return {
        ...detailsDrawerState,
        scopeData: scopeDataWithFilteredFields,
        editTraversalState,
        toggleReferenceTypesInViewCommands,
        bottomOffsetHeight: appLayoutState.leftPaneStyle.bottom ?? 0,
        commands: detailsDrawerCommands,
      };
    }
  )
);
