import { modal } from '@ardoq/modal';
import { Reference } from 'aqTypes';
import ReverseReferenceDialogContent from './ReverseReferenceDialogContent';
import {
  groupReferencesBySourceAndType,
  partitionSafeAndUnsafeReferences,
  prepareEntriesWithProps,
} from './helpers';
import { ReferenceGroup } from './types';
import { isValidRefType } from 'models/utils/refTypeUtils';
import { ArdoqId } from '@ardoq/api-types';
import { referenceInterface } from '@ardoq/reference-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';

export const reverseReferences = (references: Reference[]) => {
  const referenceIds = references.map(reference => reference.getId());

  return reverseReferencesById(referenceIds);
};

export const reverseReferencesById = async (referenceIds: ArdoqId[]) => {
  const [safeReferences, unsafeReferences] = referenceIds.reduce(
    partitionSafeAndUnsafeReferences,
    [[], []]
  );

  safeReferences.forEach(({ id, equivalentTypeID }) => {
    referenceInterface.flipReferenceSourceAndTarget(
      id,
      equivalentTypeID ?? undefined
    );
  });

  if (unsafeReferences.length > 0) {
    const unsafeReferenceIDs = unsafeReferences.map(({ id }) => id);
    const groupedUnsafeReferences = unsafeReferenceIDs.reduce<ReferenceGroup>(
      groupReferencesBySourceAndType,
      {}
    );

    const entries = Object.values(groupedUnsafeReferences)
      .map(prepareEntriesWithProps)
      .filter(ExcludeFalsy);

    if (entries.length === 0) {
      return;
    }
    const reverseReferencesFlipTypes = await modal<
      Record<number, { referenceIds: ArdoqId[]; typeValue: string }>
    >((resolve: any) => (
      <ReverseReferenceDialogContent resolve={resolve} entries={entries} />
    ));

    if (reverseReferencesFlipTypes) {
      const flattenReferenceFlipTypes = Object.values(
        reverseReferencesFlipTypes
      )
        .map(({ referenceIds, typeValue }) =>
          referenceIds.map((referenceId: ArdoqId) => ({
            referenceId,
            typeValue,
          }))
        )
        .flat()
        .filter(({ typeValue }) => isValidRefType(typeValue));

      flattenReferenceFlipTypes.forEach(({ referenceId, typeValue }) =>
        referenceInterface.flipReferenceSourceAndTarget(referenceId, typeValue)
      );
    }
  }
};
