import { withOnFirstSubscription } from 'streams/utils/streamUtils';
import { ViewpointBuilderTab } from 'viewpointBuilder/viewpointBuilderNavigation/types';
import {
  InitialViewpointBuilderConfiguration,
  ViewpointBuilderCommands,
  ViewpointBuilderContext,
  ViewpointBuilderViewState,
} from 'viewpointBuilder/types';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestLoadMetaModel } from 'architectureModel/actions';
import { initViewpointBuilder } from '../initViewpointBuilder';
import { viewpointBuilder$ } from './viewpointBuilder$';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { Observable } from 'rxjs';

export type GetViewpointBuilderStreamArgs = {
  activeTab: ViewpointBuilderTab;
  context: ViewpointBuilderContext;
  initialConfiguration: InitialViewpointBuilderConfiguration | undefined;
  viewpointBuilderCommands: ViewpointBuilderCommands;
  folderId?: string | null;
  getAdditionalRoutines?: (
    viewpointBuilderStream: Observable<ViewpointBuilderViewState>
  ) => Observable<unknown>[];
};

export const getViewpointBuilderStream = ({
  onFirstSubscriptionCallback,
  ...getViewpointBuilderStreamArgs
}: GetViewpointBuilderStreamArgs & {
  onFirstSubscriptionCallback?: () => void;
}) =>
  withOnFirstSubscription(
    viewpointBuilder$,
    onFirstSubscriptionCallback ??
      getDefaultOnFirstSubscriptionCallback(getViewpointBuilderStreamArgs)
  );

const getDefaultOnFirstSubscriptionCallback =
  ({
    activeTab,
    context,
    initialConfiguration,
    folderId,
    viewpointBuilderCommands,
  }: GetViewpointBuilderStreamArgs) =>
  () => {
    dispatchAction(
      requestLoadMetaModel({
        currentTriples: metaModelOperations.getTriplesFromPaths(
          initialConfiguration?.initialPaths ?? []
        ),
      })
    );

    initViewpointBuilder({
      activeTab,
      context,
      initialConfiguration,
      folderId,
      viewpointBuilderCommands,
    });
  };
