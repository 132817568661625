import { ArdoqId, Entity } from '@ardoq/api-types';
import { cloneDeep, debounce, keyBy } from 'lodash';
import {
  Action,
  ActionCreator,
  ActionCreatorWithoutNamespace,
  action$,
  actionCreator,
  connect,
  dispatchAction,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import {
  Observable,
  ObservableInput,
  Subscriber,
  Subscription,
  combineLatest,
} from 'rxjs';
import { tag } from 'rxjs-spy/operators';
import { debounceTime, first, shareReplay, tap } from 'rxjs/operators';

const serializeModel = <T extends Entity>(
  backboneModel: Backbone.Model,
  attributes?: string[]
): T => {
  if (!attributes) {
    return structuredClone(backboneModel.attributes) as T;
  }
  const serializedModel: Record<string, unknown> = {
    _id: backboneModel.get('_id'),
  };

  for (const attribute of attributes) {
    serializedModel[attribute] = cloneDeep(backboneModel.get(attribute));
  }
  serializedModel.lastUpdated = backboneModel.get('lastUpdated');
  serializedModel.created = backboneModel.get('created');
  return serializedModel as T;
};

const serializeCollection = (
  backboneObj: Backbone.Collection<any>,
  attributes?: string[]
) => {
  const models = backboneObj.map(m => serializeModel(m, attributes));

  return {
    byId: keyBy(models, '_id'),
    sortIds: models.map(m => m._id),
    models,
  };
};

/**
 * Be aware that when emitting this event, no debouncing is applied.
 * Also, don't really use this at all. It's a hack.
 */
export const forceUpdateStream = '_forceUpdateStream';

const createObservableFromBackbone = <T>(
  backboneObj: Backbone.Model | Backbone.Collection<any>,
  serializer: (x: any) => any
): Observable<T> =>
  Observable.create((observer: Subscriber<T>) => {
    observer.next(serializer(backboneObj));
    backboneObj.on(
      'sync destroy remove add',
      debounce(
        () => observer.next(serializer(backboneObj)),
        48 /* ~ three frames */,
        {
          leading: false,
          trailing: true,
        }
      ),
      observer
    );
    backboneObj.on(forceUpdateStream, () => {
      observer.next(serializer(backboneObj));
    });

    return () => backboneObj.off(undefined, undefined, observer);
  }).pipe(
    tag(`collection$-bb-${backboneObj.url}`),
    shareReplay({ bufferSize: 1, refCount: true })
  );

export const createStreamFromBackboneModel = <T extends Entity>(
  model: Backbone.Model,
  attributes: string[]
): Observable<T> => {
  return createObservableFromBackbone(model, model =>
    serializeModel(model, attributes)
  );
};

export type StreamCollection<T extends Entity> = {
  byId: Record<ArdoqId, T>;
  sortIds: string[];
  models: T[];
};

export const getStreamCollectionInitialState = <
  T extends Entity,
>(): StreamCollection<T> => ({
  byId: {},
  sortIds: [],
  models: [],
});

// if attributes are not passed, we will return _all_ of the attributes of the entity.
export const createStreamFromBackboneCollection = <T extends Entity>(
  collection: Backbone.Collection<any>,
  attributes?: string[]
): Observable<StreamCollection<T>> => {
  return createObservableFromBackbone<StreamCollection<T>>(
    collection,
    collection => serializeCollection(collection, attributes)
  );
};

const getNamespaceStream = (namespace?: string) =>
  namespace ? action$.pipe(withNamespace(namespace)) : action$;

export const subscribeToAction = <T>(
  action: ActionCreator<T>,
  handler: (payload: T) => void,
  namespace?: string
): Subscription =>
  getNamespaceStream(namespace)
    .pipe(ofType(action))
    .subscribe((action: Action<T>) => {
      handler(action.payload);
    });

export const onFirstAction = <Payload>(
  action: ActionCreator<Payload>,
  func: VoidFunction,
  namespace?: string
) =>
  getNamespaceStream(namespace).pipe(ofType(action), first()).subscribe(func);

export const subscribeToActionOnce = <T>(
  action: ActionCreator<T>,
  handler: (payload?: T) => void,
  namespace?: string
) => {
  const wrappedHandler = (payload?: T) => {
    subscription.unsubscribe();
    handler(payload);
  };
  const subscription = subscribeToAction(action, wrappedHandler, namespace);
};

export const clearSubscriptions = (subscriptions: Subscription[]) => {
  subscriptions.forEach(subscription => subscription.unsubscribe());
  return [];
};

type DebouncedCombineLatest = {
  <A>(dependencies: [ObservableInput<A>], time?: number): Observable<[A]>;
  <A, B>(
    dependencies: [ObservableInput<A>, ObservableInput<B>],
    time?: number
  ): Observable<[A, B]>;
  <A, B, C>(
    dependencies: [ObservableInput<A>, ObservableInput<B>, ObservableInput<C>],
    time?: number
  ): Observable<[A, B, C]>;
  <A, B, C, D>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
    ],
    time?: number
  ): Observable<[A, B, C, D]>;
  <A, B, C, D, E>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
      ObservableInput<E>,
    ],
    time?: number
  ): Observable<[A, B, C, D, E]>;
  <A, B, C, D, E, F>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
      ObservableInput<E>,
      ObservableInput<F>,
    ],
    time?: number
  ): Observable<[A, B, C, D, E, F]>;
  <A, B, C, D, E, F, G>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
      ObservableInput<E>,
      ObservableInput<F>,
      ObservableInput<G>,
    ],
    time?: number
  ): Observable<[A, B, C, D, E, F, G]>;
  <A, B, C, D, E, F, G, H>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
      ObservableInput<E>,
      ObservableInput<F>,
      ObservableInput<G>,
      ObservableInput<H>,
    ],
    time?: number
  ): Observable<[A, B, C, D, E, F, G, H]>;
  <A, B, C, D, E, F, G, H, I>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
      ObservableInput<E>,
      ObservableInput<F>,
      ObservableInput<G>,
      ObservableInput<H>,
      ObservableInput<I>,
    ],
    time?: number
  ): Observable<[A, B, C, D, E, F, G, H, I]>;
  <A, B, C, D, E, F, G, H, I, J>(
    dependencies: [
      ObservableInput<A>,
      ObservableInput<B>,
      ObservableInput<C>,
      ObservableInput<D>,
      ObservableInput<E>,
      ObservableInput<F>,
      ObservableInput<G>,
      ObservableInput<H>,
      ObservableInput<I>,
      ObservableInput<J>,
    ],
    time?: number
  ): Observable<[A, B, C, D, E, F, G, H, I, J]>;
  (
    dependencies: ObservableInput<unknown>[],
    time?: number
  ): Observable<unknown>;
};

/**
 * Combine the input streams and debounce their emission.
 * The method can be used to prevent glitches / redundant calculations.
 *
 * @param dependencies The dependencies of this stream
 * @param time Number of milliseconds to debounce, defaults to 0
 * @see combineLatest
 */
export const debouncedCombineLatest: DebouncedCombineLatest = (
  dependencies: ObservableInput<any>[],
  time = 0
): Observable<any> => combineLatest(dependencies).pipe(debounceTime(time));

export const getActionNamespacer =
  (namespace: string) =>
  (label: string): Parameters<typeof actionCreator>[0] =>
    `[${namespace}] ${label}`;

/**
 * An util to wrap a stream that returns basically the same stream, but it lets
 * you pass in a callback which will be called on the first subscription.
 * Typical use case is a non-persistent stream which requires some
 * initialization to build the start state, e.g. fetching a resource or just
 * feeding initial state built at the moment of subscription. This can replace
 * what is often done in e.g. useEffect.
 *
 * @param observable
 * @param callback
 * @returns observable |
 */
export const withOnFirstSubscription = <T>(
  observable: Observable<T>,
  callback: () => void
) => {
  return observable.pipe(
    tap({
      subscribe: () => {
        // It's good practice to dispatch any action which could affect the stream
        // we are dealing with asynchronously. Doing it synchronously can lead to
        // very unexpected behavior
        // (e.g. like this https://chriskr.github.io/test-rxjs/). It's very likely
        // that the callback is affecting the subscribe stream directly here,
        // that's after all the main purpose of the callback.
        setTimeout(callback);
      },
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
};

export const lazyConnect = <
  Props extends object,
  Observed extends Partial<Props>,
>(
  reactComponent: React.ComponentType<Props>,
  stream: Observable<Observed>,
  action: ActionCreatorWithoutNamespace
) =>
  connect<Props, Observed>(
    reactComponent,
    withOnFirstSubscription(stream, () => dispatchAction(action()))
  );

export type CollectionStream<T> = {
  list: T[];
  byId: Record<ArdoqId, T>;
};
export const toCollectionStream = <T>(list: T[]): CollectionStream<T> => ({
  byId: keyBy(list, '_id'),
  list,
});

export const emptyCollectionStream = <T>(): CollectionStream<T> => ({
  byId: {},
  list: [],
});
