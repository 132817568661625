import {
  APIComponentAttributes,
  APIEntityType,
  APIReferenceAttributes,
  ArdoqId,
  ScopeDataCollection,
  AllSupportedEntityTypes,
} from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  enhanceScopeData,
  enhanceScopeDataWithBranchName,
  getEntityById,
} from '@ardoq/renderers';
import { Branch } from 'components/DiffMergeTable/Branch';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { documentArchiveInterface } from 'modelInterface/documentArchiveInterface';
import { filterUnique } from 'utils/collectionUtil';
import { toDataSource } from './toDataSource';
import { AllSupportedAPIAttrsArray } from './types';
import { dateRangeOperations } from '@ardoq/date-range';

const getReferences = (
  entityIds: ArdoqId[],
  entityType: AllSupportedEntityTypes
) =>
  entityType === APIEntityType.REFERENCE
    ? entityIds.map(referenceInterface.getReferenceData).filter(ExcludeFalsy)
    : [];

const getComponents = (
  entityIds: ArdoqId[],
  entityType: AllSupportedEntityTypes,
  referenceData: APIReferenceAttributes[]
) => {
  if (entityType === APIEntityType.COMPONENT) {
    return entityIds.flatMap(componentId => {
      const component = componentInterface.getComponentData(componentId);
      const parent = component?.parent
        ? componentInterface.getComponentData(component.parent)
        : null;
      return [component, parent].filter(ExcludeFalsy);
    });
  }
  return referenceData
    .flatMap(({ source, target }) => [
      componentInterface.getComponentData(source),
      componentInterface.getComponentData(target),
    ])
    .filter(ExcludeFalsy);
};

const getFields = (
  entityIds: ArdoqId[],
  entityType: AllSupportedEntityTypes
) => {
  const getFieldsInterface =
    entityType === APIEntityType.COMPONENT
      ? componentInterface.getFields
      : referenceInterface.getFields;
  return getFieldsInterface(entityIds[0]);
};

const getTags = (entityIds: ArdoqId[]) =>
  entityIds
    .flatMap(entityId =>
      tagInterface.getTagsForModel(entityId).map(tag => tag.id)
    )
    .filter(filterUnique)
    .map(tagInterface.getTagData)
    .filter(ExcludeFalsy);

const getEnhancedScopeData = (
  entityIds: ArdoqId[],
  entityType: AllSupportedEntityTypes
) => {
  const references = getReferences(entityIds, entityType);
  const components = getComponents(entityIds, entityType, references);
  const workspaceId = components[0]?.rootWorkspace;
  const fields = getFields(entityIds, entityType);
  const tags = getTags(entityIds);
  return enhanceScopeDataWithBranchName(
    enhanceScopeData(
      dateRangeOperations.getMergedDateRangeFieldForScopeData({
        [ScopeDataCollection.COMPONENTS]: components,
        [ScopeDataCollection.REFERENCES]: references,
        [ScopeDataCollection.FIELDS]: fields,
        [ScopeDataCollection.TAGS]: tags,
        [ScopeDataCollection.PERMISSIONS]: [],
        [ScopeDataCollection.MODELS]: [
          workspaceId && workspaceInterface.getModelData(workspaceId),
        ].filter(ExcludeFalsy),
        workspaces: [
          workspaceId && workspaceInterface.getWorkspaceData(workspaceId),
        ].filter(ExcludeFalsy),
        attachments:
          documentArchiveInterface.getWorkspaceAndOrgAttachments(workspaceId),
        scopeComponents: [],
        connected: {
          components: [],
          fields: [],
          models: [],
          permissions: [],
          references: [],
          tags: [],
          workspaces: [],
          connectedComponents: [],
        },
      })
    ),
    Branch.MAIN // Only to please ts, not actually useful here
  );
};

export default (entityIds: ArdoqId[], entityType: AllSupportedEntityTypes) => {
  const enhancedScopeData = getEnhancedScopeData(entityIds, entityType);
  const entities = entityIds
    .map(entityId => getEntityById(entityType, entityId, enhancedScopeData))
    .filter(ExcludeFalsy) as AllSupportedAPIAttrsArray;
  const invalidParentIds = new Set(
    entityIds.flatMap(id => [...componentInterface.getSubtreeIds(id), id])
  );
  const entitiesWithInvalidParent = new Set(
    entityType === APIEntityType.COMPONENT
      ? (entities as Array<APIComponentAttributes>)
          .filter(({ parent }) => parent && invalidParentIds.has(parent))
          .map(({ _id }) => _id)
      : []
  );
  return {
    dataSource: toDataSource(
      entities,
      entityType,
      entitiesWithInvalidParent,
      enhancedScopeData
    ),
    enhancedScopeData,
    entities,
    entitiesWithInvalidParent,
  };
};
