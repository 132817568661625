import { connect } from '@ardoq/rxbeach';
import { ViewSidePanelContentIds, ViewSidePanelState } from './types';
import styled from 'styled-components';
import { viewSidePanel$ } from './viewSidePanel$';
import { colors, r8, s8, shadowM, zIndex } from '@ardoq/design-tokens';
import { exhaustiveCheck } from '@ardoq/common-helpers';
import SaveAsViewpoint from 'appLayout/ardoqStudio/saveAsViewpoint/SaveAsViewpoint';
import ViewOptions from 'appLayout/ardoqStudio/viewPaneHeader/viewOptions/ViewOptions';
import ViewOptionsPerspectivesGroups from 'appLayout/ardoqStudio/viewPaneHeader/viewOptions/perspectives/ViewOptionsPerspectivesGroups';
import ViewOptionsPerspectivesConditionalFormat from 'appLayout/ardoqStudio/viewPaneHeader/viewOptions/perspectives/ViewOptionsPerspectivesConditionalFormat';
import ViewOptionsPerspectivesLabels from 'appLayout/ardoqStudio/viewPaneHeader/viewOptions/perspectives/ViewOptionsPerspectivesLabels';
import {
  RIGHT_PANEL_BOTTOM_INSET,
  VIEW_PANE_BAR_HEIGHT_WITH_PADDING,
} from 'appLayout/ardoqStudio/viewPaneHeader/consts';
import SaveAsBookmark from '../../../bookmarks/SaveAsBookmark';
import ViewpointSwitcher from '../../../contextToolBar/sidebars/ViewpointSwitcher';
import ExploreGraph from 'contextToolBar/sidebars/ExploreGraph';
import { ResizableSidebarEditorAsOverlay } from 'appLayout/ResizableSidebarEditorAsOverlay';

enum SIDE_PANEL_SIZE {
  DEFAULT = 454,
  NARROW = 400,
  MIN = 300,
}

const ViewSidePanelContainer = styled.div<{
  $isNarrow?: boolean;
  $isFullHeight?: boolean;
}>`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: ${zIndex.VIEW_SIDE_PANEL};
  inset: ${VIEW_PANE_BAR_HEIGHT_WITH_PADDING}px ${s8}
    ${RIGHT_PANEL_BOTTOM_INSET}px auto;
  width: ${({ $isNarrow }) =>
    $isNarrow ? SIDE_PANEL_SIZE.NARROW : SIDE_PANEL_SIZE.DEFAULT}px;
  min-width: ${SIDE_PANEL_SIZE.MIN}px;
  background-color: ${colors.white};
  box-shadow: ${shadowM};
  border-radius: ${r8};
  top: ${({ $isFullHeight }) => ($isFullHeight ? '8px' : undefined)};
`;

const isNarrow = (contentId: ViewSidePanelContentIds): boolean =>
  [
    ViewSidePanelContentIds.PERSPECTIVES_GROUPS,
    ViewSidePanelContentIds.PERSPECTIVES_CONDITIONAL_FORMAT,
    ViewSidePanelContentIds.PERSPECTIVES_LABELS,
  ].includes(contentId);

const isFullHeight = (contentId: ViewSidePanelContentIds): boolean =>
  [
    ViewSidePanelContentIds.EXPLORE_GRAPH,
    ViewSidePanelContentIds.VIEWPOINT_SWITCHER,
  ].includes(contentId);

const isResizable = (contentId: ViewSidePanelContentIds): boolean =>
  [
    ViewSidePanelContentIds.EXPLORE_GRAPH,
    ViewSidePanelContentIds.VIEWPOINT_SWITCHER,
  ].includes(contentId);

const getContent = (contentId: ViewSidePanelContentIds) => {
  switch (contentId) {
    case ViewSidePanelContentIds.SAVE_VIEWPOINT:
      return <SaveAsViewpoint />;
    case ViewSidePanelContentIds.SAVE_BOOKMARK:
      return <SaveAsBookmark />;
    case ViewSidePanelContentIds.VIEW_OPTIONS:
      return <ViewOptions />;
    case ViewSidePanelContentIds.PERSPECTIVES_GROUPS:
      return <ViewOptionsPerspectivesGroups />;
    case ViewSidePanelContentIds.PERSPECTIVES_CONDITIONAL_FORMAT:
      return <ViewOptionsPerspectivesConditionalFormat />;
    case ViewSidePanelContentIds.PERSPECTIVES_LABELS:
      return <ViewOptionsPerspectivesLabels />;
    case ViewSidePanelContentIds.VIEWPOINT_SWITCHER:
      return <ViewpointSwitcher />;
    case ViewSidePanelContentIds.EXPLORE_GRAPH:
      return <ExploreGraph />;
    default:
      return exhaustiveCheck(contentId);
  }
};

type ViewSidePanelProperties = ViewSidePanelState;
const ViewSidePanel = ({
  contentId,
  isViewpointMode,
}: ViewSidePanelProperties) => {
  if (!contentId || !isViewpointMode) {
    return null;
  }

  return isResizable(contentId) ? (
    <ResizableSidebarEditorAsOverlay
      isRightEditorPaneOpen={true}
      initialWidth={
        isNarrow(contentId) ? SIDE_PANEL_SIZE.NARROW : SIDE_PANEL_SIZE.DEFAULT
      }
      isFullHeight={isFullHeight(contentId)}
      minWidth={SIDE_PANEL_SIZE.MIN}
    >
      {getContent(contentId)}
    </ResizableSidebarEditorAsOverlay>
  ) : (
    <ViewSidePanelContainer
      $isNarrow={isNarrow(contentId)}
      $isFullHeight={isFullHeight(contentId)}
    >
      {getContent(contentId)}
    </ViewSidePanelContainer>
  );
};

export default connect(ViewSidePanel, viewSidePanel$);
