import {
  type TableMapping,
  type TransferConfig,
  ColumnMappingComponents,
  ColumnMappingReferences,
  TableMappingType,
  ComponentMapping,
  ComponentTypeMapping,
  ParentMapping,
  FieldMapping,
  ReferenceMapping,
  CustomIdMapping,
  CustomIdReferenceFormat,
  CustomIdReferenceMapping,
  ArdoqIdReferenceMapping,
  PathReferenceMapping,
  ReferenceFormat,
  CustomIdParentMapping,
  RootReferenceMapping,
  TargetReferenceMapping,
  IntegrationReferenceDirection,
  ReferenceTypeMapping,
  TableMappingCommon,
  TableMappingOptions,
  MissingComponentsStrategy,
  ArdoqIdMapping,
  IntegrationWorkspace,
  PathReferenceFormat,
} from '@ardoq/api-types/integrations';
import { Field as FieldType, ColumnMapping } from '../transferConfigs/types';
import type { TablePreview, TablePreviews } from '../tablePreviews/types';
import {
  TabularMapping,
  TableId,
  TableMappingMap,
  ColumnMappingMap,
  ColumnMappingComponentsMap,
  ColumnMappingReferencesMap,
  TableMappingComponentsMap,
  TableMappingReferencesMap,
} from './types';
import {
  isComponentsTableConfig as isComponentsTableMapping,
  isReferencesTableConfig as isReferencesTableMapping,
} from '../workspaces/util';
import * as fp from 'lodash/fp';
import { range } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { workspaceInterface } from '@ardoq/workspace-interface';
import { Field as IntegrationField } from 'integrations/common/streams/fields/types';
import {
  MapColumnsBy,
  MapTablesBy,
} from 'integrations/common/streams/activeIntegrations/types';
import {
  APIFieldAttributes,
  Workspace as OrgWorkspace,
} from '@ardoq/api-types';
import { fieldInterface } from '@ardoq/field-interface';
import {
  filterFieldsByType,
  toPascalCase,
} from 'integrations/common/utils/common';
import { CUSTOM_ID_ALLOWED_TYPES } from 'integrations/common/streams/fields/constants';
import { Maybe } from '@ardoq/common-helpers';
import { AffectedWorkspace } from '../transferState/types';
import { TabularMappingErrors } from '../tabularMappingErrors/types';

/**
 * TabularMapping
 */

const fillCustomIdField = <T extends { fieldLabel?: string }>({
  fields,
  columnMapping,
}: {
  fields: IntegrationField[];
  columnMapping: T;
}): T => {
  const matchingGlobalField = fields.find(
    field => field.label === columnMapping.fieldLabel
  );

  const fieldMapping = {
    ...columnMapping,
    ...(matchingGlobalField
      ? {
          fieldName: matchingGlobalField.name,
          fieldLabel: matchingGlobalField.label,
        }
      : {}),
  };

  return fieldMapping;
};

const getReferenceDirection = (
  referenceMapping: ReferenceMapping
): IntegrationReferenceDirection | null =>
  referenceMapping.referenceDirection || IntegrationReferenceDirection.OUTGOING;

const fillColumnMapping = <Column extends ColumnMapping>(
  allFields: APIFieldAttributes[],
  allWorkspaces: OrgWorkspace[],
  columnMapping: Column
): Column => {
  const customIdFields = filterFieldsByType(CUSTOM_ID_ALLOWED_TYPES, allFields);

  if (isFieldColumnMapping(columnMapping)) {
    const matchingGlobalField =
      !columnMapping.fieldName &&
      allFields.find(field => field.label === columnMapping.fieldLabel);

    const fieldMapping = {
      ...columnMapping,
      ...(matchingGlobalField
        ? {
            fieldName: matchingGlobalField.name,
            fieldLabel: matchingGlobalField.label,
            fieldType: matchingGlobalField.type,
          }
        : {}),
    };

    return {
      ...fieldMapping,
      // Due to historical inconsistent exported mappings,
      // There exists configurations where fieldTypes are in kebab-case
      // Below function makes these fieldType conforms to PascalCase schema
      // Schema: https://github.com/ardoq/ardoq-api/blob/8833c54248bf092c3ec906f28e702efe6446f338/src/ardoq/schemas.clj#L371-L373
      fieldType: toPascalCase(fieldMapping.fieldType),
    };
  }

  if (isCustomIdMapping(columnMapping) && !columnMapping.fieldName) {
    return fillCustomIdField({
      fields: customIdFields,
      columnMapping,
    });
  }

  if (
    isRootReferenceMapping(columnMapping) ||
    isTargetReferenceMapping(columnMapping)
  ) {
    if (
      columnMapping.referenceFormat === 'custom-id' &&
      !columnMapping.fieldName
    ) {
      return fillCustomIdField({
        fields: customIdFields,
        columnMapping,
      });
    }

    if (
      columnMapping.referenceFormat === 'path' &&
      !columnMapping.referencePathSeparator
    ) {
      return {
        ...columnMapping,
        referencePathSeparator: '::',
      };
    }
  }

  if (
    isParentMapping(columnMapping) &&
    columnMapping.parentFormat === 'custom-id' &&
    !columnMapping.fieldName
  ) {
    return fillCustomIdField({
      fields: customIdFields,
      columnMapping,
    });
  }
  if (isReferenceColumnMapping(columnMapping)) {
    const referenceDirection = getReferenceDirection(columnMapping);
    return {
      ...columnMapping,
      ...(referenceDirection ? { referenceDirection } : {}),
      // custom-id field should be filled with fieldName and fieldLabel
      ...(columnMapping.referenceFormat === 'custom-id'
        ? fillCustomIdField({
            fields: customIdFields,
            columnMapping,
          })
        : {}),

      // targetWorkspace should be filled with id and name
      ...(columnMapping.targetWorkspace
        ? {
            targetWorkspace: fillWorkspace(
              columnMapping.targetWorkspace,
              allWorkspaces
            ),
          }
        : {}),
      ...(columnMapping.referenceFormat === 'path'
        ? {
            referencePathSeparator:
              columnMapping.referencePathSeparator || '::',
          }
        : {}),
    };
  }

  return columnMapping;
};

const fillWorkspace = (
  workspace: IntegrationWorkspace,
  allWorkspaces: OrgWorkspace[]
): IntegrationWorkspace => {
  if (!workspace.id) {
    const existingWorkspace = allWorkspaces.find(
      w => w.name === workspace.name
    );

    return existingWorkspace
      ? { id: existingWorkspace._id, name: existingWorkspace.name }
      : workspace;
  }
  // even if we have an id, if it does not exisits in the org
  // it should be considered as a new workspace
  const existingWorkspace = allWorkspaces.find(w => w._id === workspace.id);

  const filledWorkspace = existingWorkspace
    ? { id: existingWorkspace._id, name: existingWorkspace.name }
    : { id: null, name: workspace.name };

  return filledWorkspace;
};

export const extractWorkspaceFromTabularMappings = (
  tabularMapping: TabularMapping
): IntegrationWorkspace[] => {
  const workspaces: IntegrationWorkspace[] = [];
  Object.values(tabularMapping).forEach(tableMappingMap => {
    if (
      tableMappingMap.rootWorkspace &&
      !workspaces.includes(tableMappingMap.rootWorkspace)
    ) {
      workspaces.push(tableMappingMap.rootWorkspace);
    }
    if (
      tableMappingMap.targetWorkspace &&
      !workspaces.includes(tableMappingMap.targetWorkspace)
    ) {
      workspaces.push(tableMappingMap.targetWorkspace);
    }
  });
  return workspaces;
};

export const mergeConfiguredAffectedWorkspaces = (
  configuredWorkspaces: IntegrationWorkspace[],
  affectedWorkspaces: AffectedWorkspace[]
) => {
  const workspaceMap: Record<string, AffectedWorkspace> = {};

  const addWorkspace = (ws: IntegrationWorkspace | AffectedWorkspace) => {
    if (!ws.id || workspaceMap[ws.id]) return;

    workspaceMap[ws.id] = { id: ws.id, name: ws.name };
  };

  affectedWorkspaces.forEach(addWorkspace);
  configuredWorkspaces.forEach(addWorkspace);

  return Object.values(workspaceMap);
};

const fillEmptyTableMapping = (
  tableMapping: TableMapping,
  allFields: APIFieldAttributes[],
  allWorkspaces: OrgWorkspace[],
  tablePreview: TablePreview,
  mapColumnsBy: MapColumnsBy = 'index'
): TableMapping => {
  // TS needs little help to narrow the types
  if (isTableMappingMapComponents(tableMapping)) {
    return {
      ...tableMapping,
      id: tablePreview.id,
      rootWorkspace: fillWorkspace(tableMapping.rootWorkspace, allWorkspaces),
      mappedColumns: recalculateIndexes(
        tableMapping.mappedColumns,
        mapColumnsBy,
        tablePreview.sourceFieldNames || []
      ).map(mapping => fillColumnMapping(allFields, allWorkspaces, mapping)),
    };
  }

  return {
    ...tableMapping,
    id: tablePreview.id,
    rootWorkspace: fillWorkspace(tableMapping.rootWorkspace, allWorkspaces),
    targetWorkspace: tableMapping.targetWorkspace
      ? fillWorkspace(tableMapping.targetWorkspace, allWorkspaces)
      : undefined,
    mappedColumns: recalculateIndexes(
      tableMapping.mappedColumns,
      mapColumnsBy,
      tablePreview.sourceFieldNames || []
    ).map(mapping => fillColumnMapping(allFields, allWorkspaces, mapping)),
  };
};

export const transferConfigToTabularMapping = ({
  transferConfig,
  tablePreviews,
  allFields,
  allWorkspaces,
  mapTablesBy,
  mapColumnsBy,
}: {
  transferConfig: TransferConfig;
  tablePreviews: TablePreviews;
  allFields: APIFieldAttributes[];
  allWorkspaces: OrgWorkspace[];
  mapTablesBy: MapTablesBy;
  mapColumnsBy: MapColumnsBy;
}): TabularMapping => {
  const mappingTables: Record<TableId, TableMappingMap> = {};

  transferConfig.tables.forEach(transferConfigTable => {
    // we match TransferConfig table
    // with the current uploaded/imported TablePreview by their indexes
    const tablePreview = Object.values(tablePreviews).find(
      tablePreview =>
        transferConfigTable[mapTablesBy] === tablePreview[mapTablesBy]
    );

    if (!tablePreview) return;

    const filledTransferConfigTable = fillEmptyTableMapping(
      transferConfigTable,
      allFields,
      allWorkspaces,
      tablePreview,
      mapColumnsBy
    );

    const tableConfig = tableMappingToMap(filledTransferConfigTable);
    if (tablePreview && tableConfig) {
      mappingTables[tablePreview.id] = tableConfig;
    }
  });
  return mappingTables;
};

export const isEmptyTabularMapping = (tabularMapping: TabularMapping) =>
  fp.every(fp.isEmpty, tabularMapping);

export const isConfigurableTable = (tableMapping?: TableMappingMap) => {
  if (!tableMapping || !tableMapping.rootWorkspace) {
    return false;
  }

  if (
    tableMapping.rowRepresentation === TableMappingType.REFERENCES &&
    !tableMapping.targetWorkspace
  ) {
    return false;
  }
  return true;
};

/**
 * TableMapping
 */
export const isTableMappingMapComponents = (
  config?: TableMappingMap
): config is TableMappingComponentsMap => {
  return config?.rowRepresentation === TableMappingType.COMPONENTS;
};

export const isTableMappingMapReferences = (
  config?: TableMappingMap
): config is TableMappingReferencesMap => {
  return config?.rowRepresentation === TableMappingType.REFERENCES;
};

const tableMappingToMap = (tableMapping: TableMapping): TableMappingMap => {
  if (isComponentsTableMapping(tableMapping)) {
    const mappedColumns = columnMappingToMap<
      ColumnMappingComponents,
      ColumnMappingComponentsMap
    >(tableMapping.mappedColumns);

    return {
      ...tableMapping,
      mappedColumns,
    };
  }

  if (isReferencesTableMapping(tableMapping)) {
    const mappedColumns = columnMappingToMap<
      ColumnMappingReferences,
      ColumnMappingReferencesMap
    >(tableMapping.mappedColumns);

    return {
      ...tableMapping,
      mappedColumns,
    };
  }

  throw Error('Unknown TableMapping type');
};

const tableMappingMapToTableMapping = (
  tableMap: TableMappingMap
): TableMapping | null => {
  if (isTableMappingMapComponents(tableMap)) {
    return {
      ...tableMap,
      mappedColumns: Object.values(tableMap.mappedColumns).map(
        getTransformedComponentsColumnMapping
      ),
    };
  }

  if (isTableMappingMapReferences(tableMap)) {
    return {
      ...tableMap,
      mappedColumns: Object.values(tableMap.mappedColumns).map(
        getTransformedReferencesColumnMapping
      ),
    };
  }

  return null;
};

export const isEmptyTableMappingMap = (tableMapping?: TableMappingMap) =>
  fp.isEmpty(tableMapping?.mappedColumns);

export const isValidTableMappingMap = (tableMapping?: TableMappingMap) =>
  !isEmptyTableMappingMap(tableMapping) &&
  !!tableMapping?.rootWorkspace &&
  Object.values(tableMapping?.mappedColumns || {}).length > 0;

const tableMappingsFromMap = (
  tabularMapping: TabularMapping
): TableMapping[] => {
  const tables = Object.values(tabularMapping);

  const tableMappings: TableMapping[] = tables
    .filter(isValidTableMappingMap)
    .map(tableMappingMapToTableMapping)
    .filter(ExcludeFalsy);

  return tableMappings;
};

export const tabularMappingIntoTransferConfig = <T extends TransferConfig>(
  tabularMapping: TabularMapping,
  transferConfig: T
): T => {
  return {
    ...transferConfig,
    tables: tableMappingsFromMap(tabularMapping),
  };
};

export const adjustTabularMapping = ({
  tablePreviews,
  tabularMapping,
}: {
  tablePreviews: TablePreviews;
  tabularMapping: TabularMapping;
}) =>
  fp.flow(
    fp.toPairs,
    fp.map(([tableId, mapping]) => {
      if (mapping?.id && mapping.id in tablePreviews) {
        const selectedFieldsIds =
          tablePreviews[mapping.id].sourceFieldNames || [];
        return [
          tableId,
          {
            ...mapping,
            mappedColumns: fp.flow(
              fp.toPairs,
              fp.filter(
                ([_, col]) =>
                  'sourceFieldName' in col &&
                  selectedFieldsIds.includes(col.sourceFieldName)
              ),
              fp.fromPairs
            )(mapping.mappedColumns),
          },
        ];
      }
    }),
    fp.compact,
    fp.fromPairs
  )(tabularMapping);

/**
 * ColumnMapping
 */

// column type predicates

export const isFieldColumnMapping = (
  columnMapping?: ColumnMapping
): columnMapping is FieldMapping => {
  return columnMapping?.columnType === 'field';
};

export const isReferenceColumnMapping = (
  columnMapping?: ColumnMapping
): columnMapping is ReferenceMapping => {
  return columnMapping?.columnType === 'reference';
};

export const isIncomingReferenceColumnMapping = (
  columnMapping?: ColumnMapping
): columnMapping is ReferenceMapping => {
  return (
    isReferenceColumnMapping(columnMapping) &&
    getReferenceDirection(columnMapping) ===
      IntegrationReferenceDirection.INCOMING
  );
};

export const isRootReferenceColumnMapping = (
  columnMapping?: ColumnMapping
): columnMapping is RootReferenceMapping => {
  return columnMapping?.columnType === 'root-reference';
};

export const isCustomIdReferenceMapping = (
  columnMapping?: ColumnMapping
): columnMapping is CustomIdReferenceMapping => {
  return (
    isReferenceColumnMapping(columnMapping) &&
    columnMapping.referenceFormat === 'custom-id'
  );
};

export const isReferenceFormat = (
  columnMapping?: ColumnMapping
): columnMapping is ColumnMapping & ReferenceFormat => {
  return Boolean(columnMapping && 'referenceFormat' in columnMapping);
};

export const isPathReferenceFormat = (
  columnMapping?: ReferenceFormat
): columnMapping is PathReferenceFormat => {
  return columnMapping?.referenceFormat === 'path';
};

export const isCustomIdReferenceFormat = (
  columnMapping?: ReferenceFormat
): columnMapping is CustomIdReferenceFormat => {
  return columnMapping?.referenceFormat === 'custom-id';
};

export const isArdoqIdReferenceMapping = (
  columnMapping?: ColumnMapping
): columnMapping is ArdoqIdReferenceMapping => {
  return (
    isReferenceColumnMapping(columnMapping) &&
    columnMapping.referenceFormat === 'ardoq-oid'
  );
};

export const isPathReferenceMapping = (
  columnMapping?: ColumnMapping
): columnMapping is PathReferenceMapping => {
  return (
    isReferenceColumnMapping(columnMapping) &&
    columnMapping.referenceFormat === 'path'
  );
};

export const isComponentMapping = (
  mapping?: ColumnMapping
): mapping is ComponentMapping => {
  return mapping?.columnType === 'component';
};

export const isComponentTypeMapping = (
  mapping?: ColumnMapping
): mapping is ComponentTypeMapping => {
  return mapping?.columnType === 'component-type';
};

export const isReferenceTypeMapping = (
  mapping?: ColumnMapping
): mapping is ReferenceTypeMapping => {
  return mapping?.columnType === 'reference-type';
};

export const isParentMapping = (
  mapping?: ColumnMapping
): mapping is ParentMapping => {
  return mapping?.columnType === 'parent';
};

export const isCustomIdParentMapping = (
  columnMapping?: ColumnMapping
): columnMapping is CustomIdParentMapping => {
  return (
    isParentMapping(columnMapping) && columnMapping.parentFormat === 'custom-id'
  );
};

export const isCustomIdMapping = (
  mapping?: ColumnMapping
): mapping is CustomIdMapping => {
  return mapping?.columnType === 'custom-id';
};

export const isRootReferenceMapping = (
  mapping?: ColumnMapping
): mapping is RootReferenceMapping => {
  return mapping?.columnType === 'root-reference';
};

export const isTargetReferenceMapping = (
  mapping?: ColumnMapping
): mapping is TargetReferenceMapping => {
  return mapping?.columnType === 'target-reference';
};

export const isArdoqIdMapping = (
  mapping?: ColumnMapping
): mapping is ArdoqIdMapping => {
  return mapping?.columnType === 'ardoq-oid';
};

export const hasAnIdColumnMapping = (
  mappedColumns: TableMappingMap['mappedColumns']
): boolean => {
  return Object.values(mappedColumns).some(
    mappedColumn =>
      isCustomIdMapping(mappedColumn) || isArdoqIdMapping(mappedColumn)
  );
};

const columnMappingToMap = <
  Mapping extends ColumnMapping,
  Return extends ColumnMappingMap,
>(
  mappedColumns: Mapping[]
): Return => {
  const items = mappedColumns.map(column => [column.index, column]);
  const columns: Return = Object.fromEntries(items);

  return columns;
};

export const getTransformedComponentsColumnMapping = (
  columnMapping: ColumnMappingComponents
): ColumnMappingComponents => {
  if (columnMapping.columnType === 'description') {
    return {
      ...columnMapping,
      columnType: 'field',
      fieldLabel: 'Description',
      fieldName: 'description',
      fieldType: 'Text',
      index: columnMapping.index,
    };
  }

  if (
    columnMapping.columnType === 'reference' &&
    columnMapping.missingComponents !== MissingComponentsStrategy.CREATE
  ) {
    return fp.omit(['componentType'], columnMapping) as ColumnMappingComponents;
  }

  return columnMapping;
};

export const getTransformedReferencesColumnMapping = (
  columnMapping: ColumnMappingReferences
): ColumnMappingReferences => {
  if (columnMapping.columnType === 'description') {
    return {
      ...columnMapping,
      columnType: 'field',
      fieldLabel: 'Description',
      fieldName: 'description',
      fieldType: 'Text',
      index: columnMapping.index,
    };
  }
  if (columnMapping.columnType === 'display-text') {
    return {
      ...columnMapping,
      columnType: 'field',
      fieldLabel: 'Display text',
      fieldName: null,
      fieldType: 'Text',
      index: columnMapping.index,
    };
  }

  return columnMapping;
};

export const isTabularMappingErrorsExist = (
  tabularErrors?: TabularMappingErrors
): boolean => {
  const getAllErrors = fp.flow(
    fp.values,
    fp.flatMap(fp.values),
    fp.flatMap(fp.values)
  );

  const isNotHaveErrors = (error: string | null) => !error;

  return fp.every(isNotHaveErrors, getAllErrors(tabularErrors));
};

export function isValidTabularMapping(
  tabularMapping: TabularMapping,
  tabularMappingErrors?: TabularMappingErrors
): boolean {
  return (
    Object.values(tabularMapping).some(isValidTableMappingMap) &&
    isTabularMappingErrorsExist(tabularMappingErrors)
  );
}

export function withoutEmptyTableMappings(
  tabularMapping: TabularMapping
): TabularMapping {
  return fp.reduce(
    (ret, [k, v]) => (isValidTableMappingMap(v) ? { ...ret, [k]: v } : ret),
    {},
    fp.toPairs(tabularMapping)
  );
}

export function equivalentTabularMappings(
  a: TabularMapping,
  b: TabularMapping
): boolean {
  return fp.isEqual(withoutEmptyTableMappings(a), withoutEmptyTableMappings(b));
}

/** Column mapping utils */

const hasMappingColumn = fp.curry(
  (
    predicateFn: (mapping: ColumnMapping | undefined) => boolean,
    tableMappingMap: TableMappingMap
  ) => {
    const mappedColumns: ColumnMappingMap =
      tableMappingMapToTableMapping(tableMappingMap)?.mappedColumns ?? {};
    return fp.some(predicateFn, Object.values(mappedColumns));
  }
);

export const hasComponentTypeColumn = hasMappingColumn(isComponentTypeMapping);

export const hasParentColumn = hasMappingColumn(isParentMapping);

export const hasReferenceTypeColumn = hasMappingColumn(isReferenceTypeMapping);

export function rootWorkspaceExists(tableMappingMap: TableMappingMap): boolean {
  return Boolean(tableMappingMap.rootWorkspace.id);
}

export function getWorkspaceComponentTypeOptions(
  tableMappingMap: TableMappingMap
): { label: string; value: any }[] {
  const rootWorkspaceId = tableMappingMap?.rootWorkspace?.id;
  const types =
    (rootWorkspaceId &&
      workspaceInterface.getComponentTypes(rootWorkspaceId)) ||
    [];
  return types.map(t => ({
    label: t.name,
    value: t.name,
  }));
}

export function fillHierarchyLevelGap(
  tableMapping: TableMappingMap,
  missingLevel: number
) {
  if (missingLevel < 0) {
    return tableMapping;
  }
  const topLevel = getHierarchyTopLevel(tableMapping) || 0;
  return fp.reduce(
    (tableMapping, level) => {
      const affectedColumn = getColumnMappingByHierararchyLevel(
        tableMapping,
        level
      );
      return affectedColumn
        ? fp.update(
            ['mappedColumns', affectedColumn.index, 'hierarchyLevel'],
            lvl => lvl - 1,
            tableMapping
          )
        : tableMapping;
    },
    tableMapping,
    range(missingLevel, topLevel + 1)
  );
}

export const getDefaultWorkspaceField = ({
  columnName,
  tableMappingMap,
}: {
  columnName: string;
  tableMappingMap: TableMappingMap;
}): FieldType => {
  const wsFields = tableMappingMap.rootWorkspace.id
    ? fieldInterface.getAllFieldsOfWorkspace(tableMappingMap.rootWorkspace.id)
    : [];
  const firstWsField = wsFields[0];
  const defaultField = firstWsField
    ? { id: firstWsField.name, label: firstWsField.label }
    : { id: null, label: columnName };
  return defaultField;
};

// hierarchy

export function getHierarchyTopLevel(
  tableMappingMap: TableMappingMap
): number | undefined {
  const mappedColumns: ColumnMappingMap =
    tableMappingMapToTableMapping(tableMappingMap)?.mappedColumns ?? {};
  const componentColumns: ComponentMapping[] =
    Object.values(mappedColumns).filter(isComponentMapping);
  const levels: number[] = componentColumns.map(m => m.hierarchyLevel);
  return fp.max(levels);
}

export function getNextHierarchyLevel(
  tableMappingMap: TableMappingMap
): number {
  const topLevel = getHierarchyTopLevel(tableMappingMap);
  return fp.isNil(topLevel) ? 0 : topLevel + 1;
}

export function getDefaultHierarchyLevel(
  tableMappingMap: TableMappingMap
): number {
  return getHierarchyTopLevel(tableMappingMap) || 0;
}

export function getHierarchyLevelOptions(tableMappingMap: TableMappingMap) {
  const topLevel = getHierarchyTopLevel(tableMappingMap) || 0;
  const availableLevels: number[] = range(topLevel + 1);
  return availableLevels.map(n => ({
    label: `Level ${n + 1}`,
    value: n,
  }));
}

export function getColumnMappingByHierararchyLevel(
  tableMapping: TableMappingMap,
  level: number
): Maybe<ComponentMapping> {
  const mappedColumns: ColumnMappingMap =
    tableMappingMapToTableMapping(tableMapping)?.mappedColumns ?? {};
  const componentColumns: ComponentMapping[] =
    Object.values(mappedColumns).filter(isComponentMapping);
  return (
    componentColumns.find(({ hierarchyLevel }) => hierarchyLevel === level) ??
    null
  );
}

export const recalculateIndexes = <T extends ColumnMapping>(
  columns: T[],
  mapColumnsBy: string,
  sourceFieldNames: string[]
): T[] => {
  return columns
    .map(column => {
      if (mapColumnsBy === 'sourceFieldName' && column.sourceFieldName) {
        const sourceFieldNameIndex = sourceFieldNames.indexOf(
          column.sourceFieldName
        );
        // if the sourceFieldName is available in the column but not found,
        // column mapping is not applicable and should be removed
        const index = sourceFieldNameIndex >= 0 ? sourceFieldNameIndex : -1;
        return {
          ...column,
          index,
          sourceFieldName: sourceFieldNames[index],
        };
      }

      return {
        ...column,
        index: column.index,
        sourceFieldName: sourceFieldNames[column.index],
      };
    })
    .filter(column => column.index >= 0)
    .sort((a, b) => a.index - b.index);
};

export const removeTableMappingOption = (
  option: keyof TableMappingOptions,
  tableMappingOptions: TableMappingCommon['options']
) => fp.dissoc(option, tableMappingOptions);

export const recalculateFiltersIndexes = (
  oldTablePreviews: TablePreview[],
  newTablePreviews: TablePreview[],
  mapping: TabularMapping
): TabularMapping => {
  const tablesToUpdate: Record<
    string,
    Record<string, string>
  > = oldTablePreviews.reduce((tables, oldTable) => {
    const newTableIndex = newTablePreviews.findIndex(
      newTable => newTable.id === oldTable.id
    );

    if (newTableIndex === -1) {
      return tables;
    }

    const filtersIndexes = Object.keys(
      mapping[oldTable.id]?.columnFilters ?? {}
    );
    const oldColumns = oldTable.sourceFieldNames || [];
    const newColumns = newTablePreviews[newTableIndex]?.sourceFieldNames || [];

    const columnsToUpdate = oldColumns.reduce((columns, oldColumnId, index) => {
      if (!filtersIndexes.includes(String(index))) {
        return columns;
      }

      const newColumnIndex = newColumns.findIndex(
        columnId => columnId === oldColumnId
      );

      if (newColumnIndex === -1) {
        return columns;
      }

      return {
        ...columns,
        [index]: newColumnIndex,
      };
    }, {});

    if (Object.keys(columnsToUpdate).length) {
      return { ...tables, [oldTable.id]: columnsToUpdate };
    }

    return tables;
  }, {});

  const recalculatedTables = Object.entries(tablesToUpdate).reduce(
    (acc, [tableId, table]) => {
      return {
        ...acc,
        [tableId]: {
          ...mapping[tableId],
          columnFilters: Object.keys(table).reduce((acc, oldIndex) => {
            return {
              ...acc,
              [table[oldIndex]]:
                mapping[tableId].columnFilters[Number(oldIndex)],
            };
          }, {}),
        },
      };
    },
    {}
  );

  return fp.assign(mapping, recalculatedTables);
};

export const removeIndexOutOfBoundFilters = (
  tabularMapping: TabularMapping,
  tablePreviews: TablePreviews
) => {
  return fp.flow(
    fp.toPairs,
    fp.map(([tableId, table]) => [
      tableId,
      {
        ...table,
        columnFilters: fp.pickBy(
          (_, filterId) =>
            tablePreviews[tableId]?.sourceFieldNames
              ? Number(filterId) <
                (tablePreviews[tableId].sourceFieldNames || []).length
              : true,
          table.columnFilters
        ),
      },
    ]),
    fp.fromPairs
  )(tabularMapping);
};
