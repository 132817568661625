import {
  Field,
  FormSize,
  FormWrapper,
  TextArea,
  TextInput,
} from '@ardoq/forms';
import { FlexBox } from '@ardoq/layout';
import { Text } from '@ardoq/typography';
import {
  BrandButton,
  Button,
  ButtonType,
  SecondaryButton,
} from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import {
  RichTextEditorTransition,
  RichTextEditorExtension,
} from '@ardoq/rich-text-editor-2024';
import { isUndefined } from 'lodash';
import {
  ErrorNotification,
  InfoNotification,
  SuccessNotification,
} from '@ardoq/status-ui';

type AugmentationModalProps = VSGViewProps &
  VPGViewProps & {
    company: string;
    setCompany: (value: string) => void;
    description: string;
    setDescription: (value: string) => void;
    industry?: string;
    setIndustry?: (value: string) => void;
    handleSubmit: (closeModal: () => void) => void;
    closeModal: () => void;
    disableSubmit: boolean;
    simpleValueProposition?: boolean;
    modalDescription: string;
    submitText: string;
  };

const AugmentationModal = (props: AugmentationModalProps) => (
  <FlexBox justify={'space-between'} flexDirection={'column'} height={'full'}>
    <FormWrapper
      $formSize={FormSize.DEFAULT}
      $paddingLeft={'s24'}
      $paddingRight={'s24'}
    >
      <Text color="textSubtle">{props.modalDescription}</Text>
      <Field label="Company Name" formSize={FormSize.FULL}>
        <TextInput value={props.company} onValueChange={props.setCompany} />
      </Field>
      <Field label="Company Description" formSize={FormSize.FULL}>
        <TextArea
          value={props.description}
          onValueChange={props.setDescription}
        />
      </Field>
      {props.setIndustry && (
        <Field label="Industry" formSize={FormSize.FULL}>
          <TextArea value={props.industry} onValueChange={props.setIndustry} />
        </Field>
      )}
      {isUndefined(props.showVP) && (
        <Field label="Value Proposition" formSize={FormSize.FULL}>
          <TextArea
            value={props.valueProposition}
            onValueChange={props.setValueProposition}
          />
        </Field>
      )}
      {props.handleGenerateValueProposition && (
        <ValuePropositionGenerationView {...props} />
      )}
      {props.handleGenerate && <GenerationView {...props} />}
    </FormWrapper>
    <FlexBox
      style={{
        borderTopWidth: 1,
        borderTopColor: colors.borderSubtle00,
        borderTopStyle: 'solid',
      }}
      justify="end"
      gap="medium"
      paddingTop="large"
      paddingBottom="large"
      marginTop="large"
      paddingRight="medium"
    >
      <SecondaryButton onClick={props.closeModal}>Cancel</SecondaryButton>
      <BrandButton
        type={'submit'}
        isDisabled={props.disableSubmit}
        onClick={() => props.handleSubmit(props.closeModal)}
      >
        {props.submitText}
      </BrandButton>
    </FlexBox>
  </FlexBox>
);

type GenerationControlProps = {
  disableGenerate?: boolean;
  loading: boolean;
};

type VSGViewProps = GenerationControlProps & {
  handleGenerate?: () => void;
  isGenerationSuccess?: boolean;
  isGenerationError?: boolean;
  generateName?: string;
  slowGeneration?: boolean;
};

const GenerationView = (props: VSGViewProps) =>
  props.loading ? (
    <>
      <Icon
        iconName={IconName.SPINNER}
        style={{ color: colors.brand50, marginTop: '16px' }}
      />
      {props.slowGeneration && (
        <InfoNotification>
          Your Business Capability Map is being generated. This process takes
          around 30 seconds
        </InfoNotification>
      )}
    </>
  ) : props.isGenerationSuccess ? (
    <SuccessNotification>
      {props.generateName} generated successfully.
    </SuccessNotification>
  ) : (
    <>
      <BrandButton
        onClick={props.handleGenerate}
        style={{ marginTop: '16px' }}
        isDisabled={props.disableGenerate}
      >
        <Icon iconName={IconName.SPARKLES} color={colors.white} />
        Generate {props.generateName}
      </BrandButton>
      {props.isGenerationError && (
        <ErrorNotification>
          {props.generateName} could not be generated. Please try again.
        </ErrorNotification>
      )}
    </>
  );

type VPGViewProps = RTEFormProps & {
  showVP?: boolean;
};

const ValuePropositionGenerationView = (props: VPGViewProps) =>
  props.showVP ? (
    <RTEForm {...props} />
  ) : props.loading ? (
    <Icon
      iconName={IconName.SPINNER}
      style={{ color: colors.brand50, marginTop: '16px' }}
    />
  ) : (
    <BrandButton
      onClick={props.handleGenerateValueProposition}
      style={{ marginTop: '16px' }}
      isDisabled={props.disableGenerateVP}
    >
      <Icon iconName={IconName.SPARKLES} color={colors.white} />
      Generate Value Proposition
    </BrandButton>
  );

type RTEFormProps = GenerationControlProps & {
  handleGenerateValueProposition?: () => void;
  toolbarExtensions?: RichTextEditorExtension[];
  valueProposition: string;
  setValueProposition: (value: string) => void;
  disableGenerateVP?: boolean;
};

const RTEForm = (props: RTEFormProps) => (
  <Field label="Value Proposition" formSize={FormSize.DEFAULT}>
    <RichTextEditorTransition
      content={props.valueProposition}
      onInputChange={props.setValueProposition}
      toolbarExtensions={props.toolbarExtensions}
    >
      <Button
        isDisabled={props.disableGenerateVP || props.loading}
        buttonType={ButtonType.SECONDARY}
        onClick={props.handleGenerateValueProposition}
        disabled={props.loading}
        style={{
          width: 'fit-content',
        }}
      >
        <Icon
          iconName={IconName.SPARKLES}
          color={colors.surfaceActionSecondary}
        />
        Regenerate Value Proposition
      </Button>
    </RichTextEditorTransition>
  </Field>
);

export default AugmentationModal;
