import AppContainer from 'appContainer/appContainer';
import AQ from 'ardoq';
import Components from 'collections/components';
import Fields from 'collections/fields';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import Perspectives from 'collections/perspectives';
import References from 'collections/references';
import Tags from 'collections/tags';
import Workspaces from 'collections/workspaces';
import Context from 'context';
import {
  loadApplicationData,
  loadStreamBasedApplicationData,
} from 'dataLoader';
import { initializeModel as initializeSimpleGraphModel } from 'models/simpleGraphModel';
import { ReactElement, createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { action$, dispatchAction, stateStreamRegistry } from '@ardoq/rxbeach';
import { splashScreenTrigger } from 'splashScreenTrigger';
import SaveManager from 'sync/saveManager';
import { logError } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';
import privileges from 'admin/privileges';
import { ensureEULAAccepted } from 'admin/terms/acceptTermsView';
import { AccessError } from 'auth/AccessError';
import ApiError from 'auth/ApiError';
import { getErrorData, getErrorMesssage } from 'authentication/errorUtil';
import { initBroadcasts } from 'broadcasts/actions';
import GlobalPaneLoader from 'components/GlobalPaneLoader/GlobalPaneLoader';
import { loadExternalBeamerScript } from 'getBeamer';
import { initGlobalEvents } from 'initGlobalEvents';
import { initializeArdoqPackages } from 'initializeArdoqPackages';
import { startLegacyBackboneViewRoutine } from 'legacyBackboneViewRoutine';
import { initializeModelInterfaces } from 'modelInterface/initializeModelInterfaces';
import { fetchPrivileges } from 'privileges/actions';
import { renderToStaticMarkup } from 'react-dom/server';
import { startAppRouter } from 'router/appRouter';
import { clearLoginRoutePrefix } from 'router/routerUtils';
import { startLegacyApplicationStateStreams } from 'startLegacyApplicationStateStreams';
import { startStateRoutines } from 'startStateRoutines';
import { apiFetchMetamodels } from 'streams/metamodels/metamodelActions';
import { fetchQueries } from 'streams/queries/actions';
import { setupContinuousFetch } from 'sync/continuousFetch';
import { installFocusAuthenticatedChecker } from 'sync/focusAuthenticatedChecker';
import { initializeTracking } from 'tracking/tracking';
import { startHandlingCss } from 'utils/modelCssManager/ardoqModelCSSManager';
import { viewpointsFetch } from 'viewpoints/actions';
import startBackboneDispatchers from './startBackboneDispatchers';
import startRoutines, { startEarlyRoutines } from './startRoutines';
import * as profiling from '@ardoq/profiling';
import {
  Redirect,
  validateUserSessionAndRedirectIfNeeded,
} from 'authentication';
import { startArdoqFrontTargetPortalHub } from 'postMessageBridge/targetPortalHubArdoqFront';
import references from 'collections/references';
import components from 'collections/components';
import fields from 'collections/fields';
import filters from 'collections/filters';
import { PrivilegeLabel } from '@ardoq/api-types';
import { applicationStarted } from 'streams/context/ContextActions';
import {
  fetchAllSubdivisionsPromise,
  fetchAllZonesPromise,
} from 'streams/subdivisions/utils';
import { modelInterface } from 'modelInterface/models/modelInterface';
import { permissionsOperations } from '@ardoq/access-control';
import { initSubdivisionCreationContextEventListeners } from 'subdivisions/subdivisionCreationContext/subdivisionCreationContextEvents';
import { isDevelopmentMode } from 'appConfig';
import { loadedGraphOperations } from 'traversals/loadedGraphOperations';

startBackboneDispatchers();

function initGlobals() {
  AQ.context = Context;
  AQ.filters = Filters;
  AQ.perspectives = Perspectives;
  AQ.groupByCollection = GroupByCollection;
  AQ.globalComponents = Components.collection;
  AQ.globalFields = Fields.collection;
  AQ.workspaces = Workspaces.collection;
  AQ.tags = Tags.collection;
  AQ.references = References.collection;
  AQ.loadedState = [];
  AQ.loadedGraph = loadedGraphOperations.getEmpty();
}

function setupApplicationRouting() {
  clearLoginRoutePrefix();
  startAppRouter();
}

if (module.hot) {
  module.hot.accept('router/appRouter', () => {
    startAppRouter();
  });
}

const mountGlobalPaneLoader = () => {
  const globalPaneLoaderEl = document.getElementById('globalPaneLoader');
  const globalPaneLoaderRoot = createRoot(globalPaneLoaderEl!);
  globalPaneLoaderRoot.render(createElement(GlobalPaneLoader));
};

const renderErrorPage = (element: ReactElement) => {
  const html = renderToStaticMarkup(element);
  const root = document.querySelector('#root');
  if (root) {
    root.innerHTML = html;
  }
};

async function bootstrapApplication() {
  const transaction = profiling.startTransaction(
    'start application',
    5000,
    profiling.Team.CORE
  );
  const user = await validateUserSessionAndRedirectIfNeeded(Redirect.LOGIN);
  if (!user) {
    // we've been redirected to login or some other such such
    // and since window.location and friends don't stop the execution
    // we need to get out of here
    return;
  }

  if (!permissionsOperations.isOrgReader(user)) {
    // If the user is not at least an org reader, then they are a contributors.
    // Note: We are assuming that the user is a user in the org (since the `current-user` api succeeded).
    renderErrorPage(
      createElement(AccessError, {
        user,
      })
    );
    return;
  }

  startEarlyRoutines(action$);
  initializeSimpleGraphModel({
    References: References.collection,
  });

  profiling.runWithSpan(transaction, 'setup continuous fetch', () =>
    setupContinuousFetch()
  );

  try {
    await profiling.runWithSpanAsync(
      transaction,
      'load application data',
      loadApplicationData
    );

    initGlobals();
    startLegacyApplicationStateStreams();
    stateStreamRegistry.startReducing();
    startRoutines(action$);
    startStateRoutines();
    let cleanupPostMessagePortal = startArdoqFrontTargetPortalHub();
    startLegacyBackboneViewRoutine();
    await loadStreamBasedApplicationData();
    fetchAllZonesPromise();
    fetchAllSubdivisionsPromise();
    dispatchAction(apiFetchMetamodels());
    dispatchAction(fetchQueries());
    if (!isDevelopmentMode()) {
      loadExternalBeamerScript();
    }
    if (hasFeature(Features.BROADCASTS)) {
      dispatchAction(initBroadcasts());
    }
    if (hasFeature(Features.PERMISSION_ZONES)) {
      initSubdivisionCreationContextEventListeners();
      if (module.hot) {
        module.hot.accept(
          'subdivisions/subdivisionCreationContext/subdivisionCreationContextEvents',
          () => {
            initSubdivisionCreationContextEventListeners();
          }
        );
      }
    }

    if (privileges.hasPrivilege(PrivilegeLabel.ACCESS_DISCOVER)) {
      dispatchAction(viewpointsFetch());
    }
    if (privileges.hasPrivilege(PrivilegeLabel.CONFIGURE_PRIVILEGES)) {
      dispatchAction(fetchPrivileges());
    }

    if (module.hot) {
      module.hot.accept('startLegacyApplicationStateStreams', () =>
        startLegacyApplicationStateStreams()
      );
      module.hot.accept('startStateRoutines', startStateRoutines);
      module.hot.accept('startRoutines', () => {
        startEarlyRoutines(action$);
        startRoutines(action$);
      });
      module.hot.accept('postMessageBridge/targetPortalHubArdoqFront', () => {
        cleanupPostMessagePortal();
        cleanupPostMessagePortal = startArdoqFrontTargetPortalHub();
      });
    }

    installFocusAuthenticatedChecker();
    startHandlingCss();

    initGlobalEvents();

    mountGlobalPaneLoader();

    const container = document.getElementById('root');
    const root = createRoot(container!);

    root.render(createElement(AppContainer));
    if (module.hot) {
      module.hot.accept('appContainer/appContainer', () => {
        root.render(createElement(AppContainer));
      });
    }

    ensureEULAAccepted();

    dispatchAction(applicationStarted());

    splashScreenTrigger();
    new SaveManager([
      Components.collection,
      Tags.collection,
      References.collection,
    ]);
    setupApplicationRouting();

    profiling.endTransaction(transaction, {
      metadata: {
        countWorkspacesLoaded: Workspaces.collection.length,
        countModelsLoaded: modelInterface.getModelCount(),
        countTagsLoaded: Tags.collection.length,
      },
    });
  } catch (error) {
    const status = (error as { status?: number }).status;

    if (status !== 401) {
      if (status === 403) {
        renderErrorPage(
          createElement(AccessError, {
            user,
          })
        );
      } else {
        renderErrorPage(
          createElement(ApiError, {
            errorMessage: getErrorMesssage(error),
            context: 'Initial data load',
          })
        );

        logError(
          error instanceof Error ? error : Error('Initial data load failed'),
          'Initial data load failed',
          getErrorData(error)
        );
      }
    }
  }
}

const getDynamicMetadata = () => ({
  countReferencesLoaded: references.collection.length,
  countComponentsLoaded: components.collection.length,
  countFieldsLoaded: fields.collection.length,
  countFiltersLoaded: filters.length,
});

export const startApplication = async () => {
  initializeArdoqPackages(getDynamicMetadata);
  initializeTracking();
  initializeModelInterfaces();
  bootstrapApplication();
};
