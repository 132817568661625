import { componentInterface } from '@ardoq/component-interface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { action$, ofType } from '@ardoq/rxbeach';
import { filter } from 'rxjs/operators';
import { notifyComponentsUpdated } from 'streams/components/ComponentActions';

const relevantProps = new Set<string>([
  'name',
  'type',
  'typeId',
  'parent',
  'displayText',
  'shape',
  'image',
  'icon',
]);
const defaultHasRelevantChanges = (changedKeys: string[]) => {
  const labelFormattingFields =
    filterInterface.getComponentLabelFormattingFields();
  const otherLabelFormattingFields = new Set(
    filterInterface.getLabelFormatting().flatMap(({ fields }) => fields)
  );
  const urlFields = fieldInterface.getUrlFieldNames();
  return changedKeys.some(
    key =>
      labelFormattingFields.has(key) ||
      otherLabelFormattingFields.has(key) ||
      relevantProps.has(key) ||
      urlFields.has(key)
  );
};
const relationshipDiagramComponentUpdate$ = (
  hasRelevantChanges = defaultHasRelevantChanges
) =>
  action$.pipe(
    ofType(notifyComponentsUpdated),
    filter(({ payload: { componentIds } }) => {
      const changedKeys = Array.from(
        new Set(componentIds.flatMap(componentInterface.getChangedAttributes))
      );
      return hasRelevantChanges(changedKeys);
    })
  );
export default relationshipDiagramComponentUpdate$;
