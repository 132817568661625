import { APIComponentType, ArdoqId } from '@ardoq/api-types';
import {
  ensureContrast,
  getDefaultCSSColor,
  getLightenedColor,
  getShadedColor,
} from '@ardoq/color-helpers';
import { ArdoqIconCategory, getIcon, IconName } from '@ardoq/icons';
import { graphics } from '@ardoq/renderers';
import type { ComponentRepresentationData } from '@ardoq/graph';
import { componentInterface } from 'modelInterface/components/componentInterface';

export const getComponentTypeRepresentationDataFromComponentType = (
  componentType: Pick<APIComponentType, 'color' | 'level' | 'image' | 'icon'>
): ComponentRepresentationData | null => {
  const color =
    (componentType &&
      (componentType.color || getDefaultCSSColor(componentType.level))) ||
    'black';

  if (componentType.image) {
    return {
      icon: graphics.icons.none,
      isImage: true,
      value: componentType.image,
      color,
    };
  }
  if (componentType.icon) {
    return {
      icon: getIcon(componentType.icon),
      isImage: false,
      value: componentType.icon,
      color,
    };
  }
  return null;
};

const DEFAULT_COMPONENT_REPRESENTATION_DATA: ComponentRepresentationData = {
  isImage: false,
  value: '',
  icon: {
    category: ArdoqIconCategory.FontAwesome,
    id: IconName.SHOW_COMPONENT,
    label: 'Component Type',
  },
  color: 'black',
};

export const getComponentRepresentationDataWithColorShades = ({
  color,
  level,
  image,
  icon,
}: Pick<APIComponentType, 'color' | 'level' | 'image' | 'icon'>) => {
  const lightenedColor = getLightenedColor(color ?? 'black');
  const contrastColor = ensureContrast(
    lightenedColor,
    getShadedColor(color ?? 'black')
  );
  /**
   *  If a color is "null", it defaults to "white",
   *  so we should use the contrastColor to maintain contrast
   */
  const shadedColor = color ? getShadedColor(color) : contrastColor;

  const componentRepresentationData =
    getComponentTypeRepresentationDataFromComponentType({
      color,
      level,
      image,
      icon,
    }) ?? DEFAULT_COMPONENT_REPRESENTATION_DATA;

  return {
    isImage: componentRepresentationData.isImage,
    value: componentRepresentationData.value,
    icon: componentRepresentationData.icon,
    color: color ?? 'black',
    lightenedColor,
    shadedColor,
    contrastColor,
  };
};

export const getDefaultRepresentationData =
  DEFAULT_COMPONENT_REPRESENTATION_DATA;

export const componentTypeRepresentationData = (componentId?: ArdoqId) => {
  if (!componentId) {
    return null;
  }
  const componentType = componentInterface.getType(componentId);
  if (!componentType) {
    return null;
  }
  return getComponentTypeRepresentationDataFromComponentType(componentType);
};
