import { useEffectOnce } from '@ardoq/hooks';
import { setManageUsersState } from 'admin/orgMembers/manageUsers$';
import { dispatchAction, connect } from '@ardoq/rxbeach';
import { clearInvitationsAlert } from 'streams/invitations/utils';
import { OrgMembersView } from '../orgMembers/orgMembersView';
import orgMembersView$, {
  OrgMembersViewStreamShape,
} from 'admin/orgMembers/orgMembersView$';
import { Island } from '@ardoq/page-layout';
import { organizationOperations } from '@ardoq/core';
import { accessControlNavigationOperations } from 'admin/accessControl/navigation/accessControlPageNavigationOperations';
import { UserProfilePage } from 'admin/accessControl/UserProfilePage/UserProfilePage';
import { AccessControlPageProps } from 'appContainer/AccessControlPage/types';

export type ManageAndInviteUsersProps = OrgMembersViewStreamShape & {
  accessControlPageViewModel: AccessControlPageProps;
};

const ManageAndInviteUsers = ({
  organization,
  accessControlPageViewModel,
  users,
  ...props
}: ManageAndInviteUsersProps) => {
  useEffectOnce(() => {
    dispatchAction(
      setManageUsersState({
        hasInternalUserManagement:
          organizationOperations.hasInternalUserManagement(organization),
        hasInternalAndExternalUserManagement:
          organizationOperations.hasInternalAndExternalUserManagement(
            organization
          ),
      })
    );

    return () => {
      clearInvitationsAlert();
    };
  });

  if (
    accessControlNavigationOperations.isUserProfilePage(
      accessControlPageViewModel
    ) &&
    accessControlPageViewModel?.userPageViewModel
  ) {
    return (
      <UserProfilePage
        {...accessControlPageViewModel.userPageViewModel}
        {...accessControlPageViewModel}
      />
    );
  }

  return (
    <Island>
      <OrgMembersView {...props} organization={organization} users={users} />
    </Island>
  );
};

export default connect(ManageAndInviteUsers, orgMembersView$);
