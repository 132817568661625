import {
  PersistedReferenceQuestion,
  UnpersistedReferenceQuestion,
  PersistedSurveyQuestion,
  UnpersistedSurveyQuestion,
  SurveyQuestionType,
  PersistedFieldQuestion,
  UnpersistedFieldQuestion,
  UnpersistedHiddenQuestion,
  PersistedHiddenQuestion,
  PersistedAttributeQuestion,
  UnpersistedAttributeQuestion,
  PersistedTagQuestion,
  UnpersistedTagQuestion,
  PersistedParentQuestion,
  PartiallyPersistedConditionalQuestion,
  PersistedConditionFieldQuestion,
  PersistedFieldSubQuestion,
  UnpersistedFieldSubQuestion,
} from '@ardoq/api-types';
import { AllPossibleQuestions } from 'surveyAdmin/types';

export const isReferenceQuestion = (
  question?: AllPossibleQuestions
): question is PersistedReferenceQuestion | UnpersistedReferenceQuestion =>
  Boolean(question?.type === SurveyQuestionType.REFERENCE);

export const isConditionQuestion = (
  question?: AllPossibleQuestions
): question is PersistedConditionFieldQuestion =>
  isFieldQuestion(question) && Boolean(question.hasConditionals);

type ValidFieldQuestion =
  | PersistedFieldQuestion
  | UnpersistedFieldQuestion
  | PartiallyPersistedConditionalQuestion;
export const isFieldQuestion = (
  question?: AllPossibleQuestions
): question is ValidFieldQuestion =>
  question?.type === SurveyQuestionType.FIELD;

export const isDependentQuestion = (
  question?: AllPossibleQuestions
): question is PersistedFieldSubQuestion | UnpersistedFieldSubQuestion =>
  Boolean(
    question &&
      'displayConditions' in question.properties &&
      question?.properties.displayConditions !== undefined
  );

export const isHiddenFieldQuestion = (
  question: AllPossibleQuestions
): question is UnpersistedHiddenQuestion | PersistedHiddenQuestion =>
  question.type === SurveyQuestionType.HIDDEN_FIELD;

type ValidAttributeQuestion =
  | PersistedAttributeQuestion
  | UnpersistedAttributeQuestion;
export const isAttributeQuestion = (
  question: AllPossibleQuestions
): question is ValidAttributeQuestion =>
  question.type === SurveyQuestionType.ATTRIBUTE;

export const isParentQuestion = (
  question:
    | PersistedSurveyQuestion
    | UnpersistedSurveyQuestion
    | PartiallyPersistedConditionalQuestion
): question is PersistedParentQuestion =>
  isAttributeQuestion(question) &&
  question.properties.attributeName === 'parent';

export const isTagQuestion = (
  question: AllPossibleQuestions
): question is PersistedTagQuestion | UnpersistedTagQuestion =>
  question.type === SurveyQuestionType.TAG;
