import {
  DoqLayout,
  DoqLoadingAnimation,
  DoqSize,
  DoqType,
  DoqWithSpeechBubble,
} from '@ardoq/doq';
import { PageHeader, PageWrapper } from '@ardoq/page-layout';
import { ErrorBoundary } from '@ardoq/error-boundary';
import { logError } from '@ardoq/logging';
import { connect, dispatchAction } from '@ardoq/rxbeach';
import ModuleContainer from 'appContainer/ModuleContainer';
import MainToolbar from 'menus/topbar/MainToolbar';
import styled from 'styled-components';
import Navbar from 'views/navbar/Navbar';
import { openHome } from '../../components/AppMainSidebar/utils';
import { FlexBox, Stack } from '@ardoq/layout';
import { accessControlPageViewModel$ } from './accessControlPage$';
import { AccessControlMainPage } from './AccessControlMainPage';
import { AccessControlPageProps } from './types';
import { useEffectOnce } from '@ardoq/hooks';
import { fetchPermissionGroups } from 'admin/accessControl/PermissionGroups/streams/actions';
import { fetchRoles } from 'roles/actions';
import { fetchPrivileges } from 'privileges/actions';
import { fetchInvitations } from 'streams/invitations/actions';
import { fetchOrgUsers } from 'streams/orgUsers/actions';
import {
  getAggregatedSSOMappings,
  getSSOAttributeMapping,
} from 'admin/ssoAttributeMapping/actions';
import { getServiceAccounts } from 'admin/serviceAccounts/actions';
import { clearIPAlert } from 'admin/manageIPAllowlist/utils';
import { fetchIPEntries } from 'admin/manageIPAllowlist/ipAllowlist$/actions';
import { organizationInterface } from 'modelInterface/organization/organizationInterface';
import { useState } from 'react';
import { header2 } from '@ardoq/typography';
import {
  getAccessControlMenuOptionById,
  getAccessControlMenuOptions,
  getAccessControlMenuOptionsArgs,
} from './accessControlMenuOptions';
import currentUser from 'models/currentUser';
import PageTitle from './components/PageTitle';
import {
  AccessControlMenuOptions,
  AccessControlMenuSingleOption,
} from './accessControlMenuOptions/types';

const DoqiContainer = styled(FlexBox)`
  height: '100%';
`;

const LoadingContainer = styled(Stack)`
  width: 100%;
  height: inherit;
  ${header2}
`;

const ToolbarContent = (
  props: AccessControlPageProps & {
    selectedTab: AccessControlMenuSingleOption | undefined;
  }
) => {
  return (
    <FlexBox gap="small">
      <MainToolbar shouldUseNewJourneyVersion={true} />
      {props.selectedTab?.actionButton && (
        <props.selectedTab.actionButton {...props} />
      )}
    </FlexBox>
  );
};

const AccessControlPageLayout = (
  props: React.PropsWithChildren<AccessControlPageProps>
) => {
  const { accessControlTab } = props;

  const options = getAccessControlMenuOptions(
    ...getAccessControlMenuOptionsArgs(props)
  );

  const selectedTab = getAccessControlMenuOptionById(options, accessControlTab);

  return (
    <ModuleContainer $overflow="hidden">
      <ErrorBoundary logError={logError}>
        <PageWrapper>
          {props.hasNewJourneyFeature ? (
            <Navbar
              primaryContent={
                selectedTab ? (
                  <PageTitle pageOption={selectedTab} />
                ) : (
                  'Access control'
                )
              }
              secondaryContent={selectedTab ? 'Access control' : undefined}
              toolbarContent={
                <ToolbarContent {...props} selectedTab={selectedTab} />
              }
            />
          ) : (
            <PageHeader title="Access control" />
          )}
          <AccessControlPage
            {...props}
            selectedTab={selectedTab}
            options={options}
          />
        </PageWrapper>
      </ErrorBoundary>
    </ModuleContainer>
  );
};

const AccessControlPage = (
  props: AccessControlPageProps & {
    selectedTab: AccessControlMenuSingleOption | undefined;
    options: AccessControlMenuOptions[];
  }
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffectOnce(() => {
    organizationInterface.fetchCurrentOrganization().finally(() => {
      setIsLoading(false);
    });
    dispatchAction(fetchRoles());
    dispatchAction(fetchPermissionGroups());
    dispatchAction(fetchPrivileges());
    dispatchAction(fetchOrgUsers());
    dispatchAction(fetchInvitations());
    dispatchAction(getSSOAttributeMapping());
    dispatchAction(getServiceAccounts());
    dispatchAction(fetchIPEntries());
    clearIPAlert();
    dispatchAction(getAggregatedSSOMappings());
  });
  const currentUserIsOrgAdmin = currentUser.isOrgAdmin();

  if (!currentUserIsOrgAdmin) {
    return (
      <DoqiContainer align="center" justify="center">
        <DoqWithSpeechBubble
          layout={DoqLayout.PAGE}
          doqType={DoqType.ALARM}
          buttonLabel="Take me back"
          onButtonClick={openHome}
          title="Access denied"
          message="You do not have access to this page. If you believe this is a mistake please contact an admin or customer support."
        />
      </DoqiContainer>
    );
  }

  if (isLoading) {
    return (
      <LoadingContainer justify="center" align="center">
        Retrieving data...
        <DoqLoadingAnimation
          delay={700}
          doqSize={DoqSize.MEDIUM}
          doqs={[
            DoqType.SIMPLE,
            DoqType.ALGORITHM,
            DoqType.ERROR,
            DoqType.SUCCESS,
            DoqType.COMPONENT_MERGE_EMPTY,
          ]}
        />
      </LoadingContainer>
    );
  }
  return <AccessControlMainPage {...props}></AccessControlMainPage>;
};
export default connect(AccessControlPageLayout, accessControlPageViewModel$);
