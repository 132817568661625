import { APIReferenceType } from '@ardoq/api-types';
import { RelationshipsViewSettings } from '../types';
import reduceNodes from './reduceNodes';
import { PARENT_CHILD_REFERENCE } from '@ardoq/global-consts';
import {
  type CollapsibleGraphGroup,
  type LegendReferenceType,
  ParentChildGraphEdge,
  getComponentTypesInfo,
  reduceLegendReferenceTypes,
  type RelationshipDiagramViewModelStreamState,
  addedAndUpdatedGraphGroups,
  addedAndUpdatedGraphNodes,
  addedAndUpdatedGraphEdges,
  mapByExcludingNull,
  getUrlFieldValuesByComponentId,
} from '@ardoq/graph';
import graphEdgesToReferenceTypes from 'tabview/relationshipDiagrams/graphEdgesToReferenceTypes';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import type {
  CreateRelationshipsLink,
  CreateRelationshipsNode,
  GraphNodesToRelationshipsNodesReducerState,
  RelationshipsViewNode,
  RelationshipsViewPropertiesBase,
} from 'tabview/relationshipDiagrams/types';

export const PARENT_CHILD_REFERENCE_LEGEND_REFERENCE_TYPE: LegendReferenceType =
  {
    ...ParentChildGraphEdge.getType(),
    className: '',
    globalTypeIds: [PARENT_CHILD_REFERENCE],
  };

export const getLegendReferenceTypes = (
  referenceTypes: Map<string, APIReferenceType>,
  hasParentChildLinks: boolean
): LegendReferenceType[] => [
  ...[...referenceTypes.entries()]
    .map(([globalTypeId, referenceType]) => ({
      ...referenceType,
      globalTypeId,
      className: '',
    }))
    .reduce(reduceLegendReferenceTypes, {
      result: [],
      keyedResults: new Map(),
      considerLineCaps: true,
    }).result,
  ...(hasParentChildLinks
    ? [PARENT_CHILD_REFERENCE_LEGEND_REFERENCE_TYPE]
    : []),
];

export const augmentNodeWithLinks = <
  TNode extends RelationshipsViewNode<TNode, TLink>,
  TLink extends { source: TNode; target: TNode },
>(
  node: TNode,
  nodeLinks: Map<TNode, TLink[]>
) => {
  node.links = nodeLinks.get(node) ?? null;
  node.children?.forEach(childNode =>
    augmentNodeWithLinks(childNode, nodeLinks)
  );
};
/**
 * builds nodes in a tree structure from a relationship diagram view model.
 * @param createNode a function that returns whatever type of node you want.
 * @param createLink a function that returns whatever type of link you want.
 */
const buildRelationshipsViewBaseProperties = <
  TViewSettings extends RelationshipsViewSettings,
  TNode extends RelationshipsViewNode<TNode, TLink>,
  TLink extends { source: TNode; target: TNode },
>(
  {
    viewSettings,
    viewModel,
    viewInstanceId,
  }: RelationshipDiagramViewModelStreamState<TViewSettings>,
  createNode: CreateRelationshipsNode<TNode>,
  createLink: CreateRelationshipsLink<TLink, TNode>
): Omit<
  RelationshipsViewPropertiesBase<TViewSettings, TNode, TLink>,
  'disconnectedChildren'
> => {
  const {
    groups: graphGroups,
    traversedIncomingReferenceTypes,
    traversedOutgoingReferenceTypes,
    referenceTypes,
    noConnectedComponents,
    errors,
    hasClones,
  } = viewModel;
  const allGraphGroups = addedAndUpdatedGraphGroups(viewModel);
  const allGraphLeaves = addedAndUpdatedGraphNodes(viewModel);
  const allGraphNodes = [...allGraphGroups, ...allGraphLeaves];
  const groupChildren = mapByExcludingNull(
    allGraphNodes,
    graphNode => graphNode.parent as CollapsibleGraphGroup | null
  );
  const rootGraphGroups = allGraphGroups.filter(({ parent }) => !parent);
  const componentIds = allGraphNodes
    .filter(node => node.isComponent())
    .map(({ modelId }) => modelId);
  const urlFieldValuesByComponentId =
    getUrlFieldValuesByComponentId(componentIds);
  const { map: nodeMap } = rootGraphGroups.reduce<
    GraphNodesToRelationshipsNodesReducerState<TNode>
  >(reduceNodes<TNode>, {
    map: new Map(),
    groupChildren,
    parent: null,
    viewInstanceId,
    createNode,
    urlFieldValuesByComponentId,
  });

  const nodes = rootGraphGroups.map(graphGroup => nodeMap.get(graphGroup.id)!);

  const rootGraphLeaves = allGraphLeaves.filter(({ parent }) => !parent);
  if (rootGraphLeaves.length) {
    const { map: rootLeavesMap } = rootGraphLeaves.reduce<
      GraphNodesToRelationshipsNodesReducerState<TNode>
    >(reduceNodes<TNode>, {
      map: new Map(),
      groupChildren,
      parent: null,
      viewInstanceId,
      createNode,
      urlFieldValuesByComponentId,
    });
    rootGraphLeaves.forEach(leaf =>
      nodeMap.set(leaf.id, rootLeavesMap.get(leaf.id)!)
    );

    const rootLeafNodes = Array.from(rootLeavesMap.values());
    if (rootLeafNodes.length === 1) {
      // no need to create a root leaf group to host only one child.
      nodes.push(rootLeafNodes[0]);
    } else {
      const rootLeafGroup = createNode(
        'rootLeafGroup',
        null,
        undefined,
        null,
        urlFieldValuesByComponentId
      );
      rootLeafGroup.children = rootLeafNodes;
      rootLeafGroup.children.forEach(node => (node.parent = rootLeafGroup));
      nodes.push(rootLeafGroup);
    }
  }

  const allGraphEdges = addedAndUpdatedGraphEdges(viewModel);

  const { referenceModelTypes, referenceTypes: allEdgesReferenceTypes } =
    graphEdgesToReferenceTypes(allGraphEdges);

  const links = allGraphEdges.map(graphEdge => {
    return createLink(
      graphEdge,
      nodeMap,
      allEdgesReferenceTypes.get(graphEdge.modelId) ?? null
    );
  });
  const hasParentChildLinks = allGraphEdges.some(
    graphEdge => !allEdgesReferenceTypes.get(graphEdge.modelId)?.line // infer if there's no modelType, then this is a parent-child reference
  );

  const nodeLinks = links.reduce((result: Map<TNode, TLink[]>, link) => {
    [link.source, link.target].forEach(node => {
      if (!result.has(node)) {
        result.set(node, []);
      }
      result.get(node)!.push(link);
    });
    return result;
  }, new Map());

  let rootNode = nodes && nodes.length === 0 ? null : nodes[0];

  if (nodes && nodes.length > 1) {
    rootNode = createNode(
      'root',
      null,
      undefined,
      null,
      urlFieldValuesByComponentId
    );
    rootNode.children = nodes;
    rootNode.children.forEach(child => (child.parent = rootNode));
  }

  if (rootNode) {
    nodes.forEach(node => augmentNodeWithLinks(node, nodeLinks));
  }
  const { legendComponentTypes } = getComponentTypesInfo(componentIds);
  return {
    rootNode,
    links,
    viewSettings,
    traversedIncomingReferenceTypes,
    traversedOutgoingReferenceTypes,
    referenceTypes,
    viewInstanceId,
    componentTypes: legendComponentTypes,
    referenceModelTypes: getLegendReferenceTypes(
      referenceModelTypes,
      hasParentChildLinks
    ),
    noConnectedComponents,
    errors,
    hasClones,
    groups: graphGroups,
    isViewpointMode: loadedGraph$.state.isViewpointMode,
  };
};
export default buildRelationshipsViewBaseProperties;
