import { TabularMapping } from 'integrations/common/streams/tabularMappings/types';
import {
  TransferResponse,
  TableSummary,
  Totals,
} from 'integrations/common/streams/transferState/types';
import fp from 'lodash/fp';
import { TableData } from 'integrations/common/components/transfer/StatusTable';
import {
  TablePreview,
  TablePreviews,
} from 'integrations/common/streams/tablePreviews/types';
import {
  complaintsToDryRunStatus,
  getComplaints,
} from 'integrations/common/streams/transferState/utils';
import { byIndex } from 'integrations/common/utils/common';
import { OverviewProps } from 'integrations/common/components/transfer/Overview';

export const statusTableData = ({
  dryRunResponse,
  tablePreviews,
  tabularMappings,
}: {
  dryRunResponse: TransferResponse;
  tablePreviews: TablePreviews;
  tabularMappings: TabularMapping;
}): TableData[] => {
  const tables = fp.map(({ id, name }: TablePreview): TableData => {
    const summary: TableSummary | undefined = dryRunResponse.tables.find(
      t => t.id === id
    );

    if (!summary) {
      return {
        _id: id,
        name,
      };
    }

    return {
      _id: id,
      name: name,
      status: complaintsToDryRunStatus(summary.validation),
      ...getComplaints(summary.validation),
      effects: { ...fp.pick(['created', 'updated', 'deleted'], summary) },
      ...fp.pick(
        ['rowRepresentation', 'rootWorkspace', 'targetWorkspace'],
        tabularMappings[id]
      ),
    };
  }, Object.values(tablePreviews).sort(byIndex));

  return tables;
};

function nameCountToString(
  names: Record<string, number> = {},
  shouldAddCount: boolean
): string[] {
  return Object.entries(names)
    .map(
      ([name, count]) =>
        `${name} ${count > 1 && shouldAddCount ? `(${count})` : ''}`
    )
    .sort();
}

export function parseResponseTotal(
  totals: Totals
): Pick<OverviewProps, 'totals' | 'preview'> {
  return {
    totals: {
      created: {
        components: totals.created.components || 0,
        workspaces: totals.created.workspaces || 0,
        references: totals.created.references || 0,
        fields: totals.created.fields || 0,
        tags: totals.created.tags || 0,
      },
      updated: {
        components: totals.updated.components || 0,
        workspaces: totals.updated.workspaces || 0,
        references: totals.updated.references || 0,
        fields: totals.updated.fields || 0,
        tags: totals.updated.tags || 0,
      },
      deleted: {
        components: totals.deleted.components || 0,
        workspaces: totals.deleted.workspaces || 0,
        references: totals.deleted.references || 0,
        fields: totals.deleted.fields || 0,
        tags: totals.deleted.tags || 0,
      },
    },
    preview: {
      created: {
        components:
          nameCountToString(totals.created.componentNames, true) || [],
        workspaces:
          nameCountToString(totals.created.workspaceNames, false) || [],
        references:
          nameCountToString(totals.created.referenceNames, true) || [],
        fields: nameCountToString(totals.created.fieldNames, true),
        tags: nameCountToString(totals.created.tagNames, true),
      },
      updated: {
        components:
          nameCountToString(totals.updated.componentNames, true) || [],
        workspaces:
          nameCountToString(totals.updated.workspaceNames, false) || [],
        references:
          nameCountToString(totals.updated.referenceNames, true) || [],
        fields: nameCountToString(totals.updated.fieldNames, true),
        tags: nameCountToString(totals.updated.tagNames, true),
      },
      deleted: {
        components:
          nameCountToString(totals.deleted.componentNames, true) || [],
        workspaces: [],
        references:
          nameCountToString(totals.deleted.referenceNames, true) || [],
        fields: [],
        tags: [],
      },
    },
  };
}
