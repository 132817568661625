import { StatusType, Tag } from '@ardoq/status-ui';
import { BroadcastError, BroadcastStatus } from '@ardoq/api-types';
import { isEmpty } from 'lodash';
import { IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';

const broadcastStatusBrands: Partial<Record<BroadcastStatus, StatusType>> = {
  [BroadcastStatus.RUNNING]: StatusType.SUCCESS,
  [BroadcastStatus.WARNING]: StatusType.WARNING,
  [BroadcastStatus.PAUSED]: StatusType.INFO,
};

type StatusCellProps = {
  status: BroadcastStatus;
  errors?: Partial<Record<BroadcastError, string>> | undefined;
};

const StatusCell = ({ status, errors }: StatusCellProps) => {
  const hasErrors = !isEmpty(errors);
  return (
    <WithPopover
      content={() =>
        hasErrors
          ? 'This broadcast contains errors which will prevent it from sending'
          : null
      }
    >
      <Tag
        leftIcon={hasErrors ? { name: IconName.ERROR } : undefined}
        statusType={
          hasErrors ? StatusType.ERROR : broadcastStatusBrands[status]
        }
        label={status}
      />
    </WithPopover>
  );
};

export default StatusCell;
