import {
  previewSearchResults,
  QuerySectionCommands,
  reportBuilderOperations,
  ReportTemplate,
} from '@ardoq/report-builder';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  advancedSearchQueryChanged,
  newReportPropertySelected,
  reportQueryWasValidated,
  reportSearchWasTriggered,
  resetAllColumnsNames,
} from '../actions';
import {
  APIOrganizationUser,
  APISearchAggregatesResponse,
  ArdoqId,
  DataSourceType,
  SortOrder,
} from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { partition } from 'lodash';
import { Locale } from '@ardoq/locale';
import { addSectionToScrollArrayIfError } from './utils';
import {
  EnhancedSearchResponse,
  isColumnWithCustomLabel,
} from '@ardoq/report-reader';
import { confirm, ModalSize } from '@ardoq/modal';
import { isEmpty } from 'lodash';

const getValidateQueryAndExecuteSearchIfValid =
  (reportTemplate: ReportTemplate, isValidAdvancedSearch: boolean) => () => {
    const isValidQuery = reportBuilderOperations.isValidQuery(
      reportTemplate,
      isValidAdvancedSearch
    );
    const isValidDataSelection =
      reportBuilderOperations.isValidDataSelection(reportTemplate);
    if (!isValidQuery || !isValidDataSelection) {
      dispatchAction(reportQueryWasValidated(false));
    } else {
      const sort = reportBuilderOperations.getSortKeyAndOrder(reportTemplate);
      dispatchAction(reportSearchWasTriggered(sort));
    }
  };

const getShowSearchResultsPreview =
  (
    searchAggregatesResults: Maybe<APISearchAggregatesResponse>,
    searchResults: Maybe<EnhancedSearchResponse>,
    currentUserLocale: Locale,
    users: Record<ArdoqId, APIOrganizationUser>
  ) =>
  () => {
    if (!searchResults) {
      return;
    }
    const [nameColumns, allColumnsExceptName] = partition(
      searchResults.columns,
      column => column.key === 'name'
    ); // sort the columns so that the name column comes first
    previewSearchResults({
      title: 'Preview Search Results',
      columns: [...nameColumns, ...allColumnsExceptName],
      selectedAggregate: null,
      sort: { columnKey: null, order: SortOrder.ASC },
      currentUserLocale,
      users,
      searchAggregatesResults,
      searchResults,
      showAggregate: false,
    });
  };

const getChangeDataSource = async (
  datasource: DataSourceType,
  reportTemplate: ReportTemplate
) => {
  // there are either saved custom names or newly updated column custom names (even just resetting)
  const hasRenamedColumns =
    reportTemplate.columns.some(isColumnWithCustomLabel) ||
    !isEmpty(reportTemplate.updatedColumnNames);
  if (
    hasRenamedColumns &&
    reportTemplate.datasource === DataSourceType.ADVANCED_SEARCH &&
    datasource === DataSourceType.GREMLIN_SEARCH
  ) {
    const shouldContinue = await confirm({
      title: `Continue and reset renamed columns?`,
      text: `Gremlin Graph Search doesn't support renamed columns meaning renamed columns will be reset to their original names. Do you want to proceed?`,
      confirmButtonTitle: 'Yes',
      modalSize: ModalSize.S,
    });

    if (!shouldContinue) return;
    dispatchAction(resetAllColumnsNames());
  }

  if (
    reportTemplate.datasource === DataSourceType.GREMLIN_SEARCH &&
    datasource === DataSourceType.ADVANCED_SEARCH &&
    !isEmpty(reportTemplate.updatedColumnNames)
  ) {
    dispatchAction(
      newReportPropertySelected({ updatedColumnNames: undefined })
    );
  }

  dispatchAction(newReportPropertySelected({ datasource }));
};

export type GetQuerySectionCommandsArgs = {
  searchAggregatesResults: Maybe<APISearchAggregatesResponse>;
  searchResults: Maybe<EnhancedSearchResponse>;
  currentUserLocale: Locale;
  users: Record<ArdoqId, APIOrganizationUser>;
  isValidAdvancedSearch: boolean;
  reportTemplate: ReportTemplate;
};
export const getQuerySectionCommands = ({
  searchAggregatesResults,
  searchResults,
  currentUserLocale,
  users,
  isValidAdvancedSearch,
  reportTemplate,
}: GetQuerySectionCommandsArgs): QuerySectionCommands => ({
  setDataSource: datasource => getChangeDataSource(datasource, reportTemplate),
  showSearchResultsPreview: getShowSearchResultsPreview(
    searchAggregatesResults,
    searchResults,
    currentUserLocale,
    users
  ),
  executeSearch: getValidateQueryAndExecuteSearchIfValid(
    reportTemplate,
    isValidAdvancedSearch
  ),
  setGremlinQuery: gremlinQuery =>
    dispatchAction(newReportPropertySelected({ gremlinQuery })),
  setAdvancedSearchQuery: advancedSearchOnChangeParams => {
    dispatchAction(advancedSearchQueryChanged(advancedSearchOnChangeParams));
  },
  addSectionToScrollArrayIfError,
});
