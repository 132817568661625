import { ExcludeFalsy } from '@ardoq/common-helpers';
import { componentInterface } from '@ardoq/component-interface';
import { hierarchiesWorkspaceIds$ } from 'componentHierarchies/streams/hierarchiesWorkspaceIds$';
import { assetsBrowser2024Commands } from 'components/AssetsBrowser2024/assetsBrowser2024Commands';
import { isEqual, uniq } from 'lodash';
import { map } from 'rxjs';
import { distinctUntilChanged, tap, withLatestFrom } from 'rxjs/operators';
import { loadedGraph$ } from 'traversals/loadedGraph$';

export const getLoadStartSetWorkspacesRoutine = () =>
  // Using loadedGraph$ here to be sure that the according data is indeed
  // loaded (added to the BB collections)
  loadedGraph$.pipe(
    map(
      ({ hierarchyDefinition: [mainHierarchyDefinition] }) =>
        mainHierarchyDefinition?.startSetResult || []
    ),
    distinctUntilChanged<string[]>(isEqual),
    withLatestFrom(hierarchiesWorkspaceIds$),
    tap(([resultStartSet, loadedWorkspaceIds]) => {
      const resultWorkspaceIds = uniq(
        resultStartSet
          .map(componentInterface.getWorkspaceId)
          .filter(ExcludeFalsy)
      );
      const notLoadedIds = resultWorkspaceIds.filter(
        id => !loadedWorkspaceIds.includes(id)
      );
      if (notLoadedIds.length) {
        assetsBrowser2024Commands.openWorkspacesInViewpointMode(notLoadedIds);
      }
    })
  );
