import {
  getWindowLocationPath,
  setWindowLocation,
  setWindowTitle,
} from 'router/routerUtils';
import { Observable, Subscription, empty } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { tag } from 'rxjs-spy/operators';
import history, { clearAllWarnBeforeNavigateListeners } from './history';
import { UnregisterCallback } from 'history';
import { logError } from '@ardoq/logging';
import { isEqual } from 'lodash';

export const INVALID_ROUTE = Symbol('INVALID_ROUTE');

type MaybeInvalid<T> = typeof INVALID_ROUTE | T;

export type RouterLocation = {
  path: string;
  searchParams?: URLSearchParams;
  search?: string;
};

type RouterLocationWithTitle = RouterLocation & {
  title?: string;
};

type RouteConfig<CompleteRouterState, PartialRouterState> = {
  /**
   * Check whether the route matches the location.
   */
  doesLocationMatch: (location: RouterLocation) => boolean;
  /**
   * Check whether the route matches the router state.
   */
  doesRouterStateMatch: (routerState: CompleteRouterState) => boolean;
  /**
   * Convert the location to partial router state.
   */
  locationToRouterState: (location: RouterLocation) => PartialRouterState;
  /**
   * Convert partial router state to a location.
   */
  routerStateToLocation: (
    partialRouterState: PartialRouterState
  ) => RouterLocationWithTitle;
  /**
   * Set application state when the given route is loaded.
   */
  setApplicationStateFromRoute: (
    partialRouterState: PartialRouterState
  ) => void;
  /**
   * The partial route stream should map application state to state
   * that's relevant for this route.
   */
  getPartialRouterStateStream: () => Observable<PartialRouterState>;
};

/**
 *
 * A Route is responsible for handling a subset of the application routing.
 * Only one route can be active at the same time.
 *
 * @see RouteConfig
 */
export class Route<CompleteRouterState, PartialRouterState> {
  config: RouteConfig<CompleteRouterState, PartialRouterState>;
  constructor(config: RouteConfig<CompleteRouterState, PartialRouterState>) {
    this.config = config;
  }

  /**
   * Get the partial router state stream for the current route, which is combined
   * with the other routes to create the complete router state.
   */
  getPartialRouterStateStream() {
    return this.config.getPartialRouterStateStream
      ? this.config.getPartialRouterStateStream()
      : empty();
  }
}

/**
 * The StreamRouter is responsible for application routing.
 *
 * The router contains a set of registered `routes` that are used to:
 * - update the location when the application state changes
 * - set the application state from the location on app init
 * - set the application state when back/forward buttons are clicked
 *
 *
 * Thus, the router works in two directions.
 *
 * 1. Updating the window location as a side-effect:
 *
 *    `application state -> router state ->  set window location & title`
 *
 *     The above flow is handled by combining the router state streams in `start`
 * and calling `routerStateToLocation`.
 *
 * 2. Setting the application state when the application is initialized at a
 * certain location or when the back/forward buttons are clicked:
 *
 *    `location loaded -> router state -> set application state`
 *
 *     The above flow is handled by listening to the browser history and calling
 * `setApplicationStateFromLocation` and `locationToRouterState`
 */
export default class StreamRouter<CompleteRouterState> {
  routes: RouteConfig<CompleteRouterState, any>[] = [];

  private isUpdatingApplicationState = false;
  private unlistenHistory?: UnregisterCallback;
  private routerStateSubscription?: Subscription;

  /**
   * Register a route config (creates a Route instance)
   */
  registerRouteConfig<PartialRouterState>(
    route: RouteConfig<CompleteRouterState, PartialRouterState>
  ) {
    const handler = new Route(route);
    this.routes.push(route);
    return handler;
  }

  /**
   * Register a Route instance
   */
  registerRoute<PartialRouterState>(
    route: Route<CompleteRouterState, PartialRouterState>
  ) {
    this.routes.push(route.config);
    return route;
  }

  /**
   * Check whether the router has a route that matches the location
   */
  canHandleLocation(location: RouterLocation): boolean {
    return this.routes.some(route => route.doesLocationMatch(location));
  }

  /**
   * Convert a location to partial routerState by delegating to a matching route.
   *
   * A matching route is found by calling to `route.doesLocationMatch(location)`
   * on each route.
   *
   * Returns `INVALID_ROUTE` if no matching route is found.
   */
  locationToRouterState(
    location: RouterLocation
  ): MaybeInvalid<Partial<CompleteRouterState>> {
    const activeRoute = this.routes.find(route =>
      route.doesLocationMatch(location)
    );
    if (activeRoute) {
      return activeRoute.locationToRouterState(location);
    }
    return INVALID_ROUTE;
  }

  /**
   * Convert complete routerState to a location by delegating to a matching route
   *
   * A matching route is found by calling `route.doesRouterStateMatch(state)`
   * on each route.
   *
   * Returns `INVALID_ROUTE` if no matching route is found.
   */
  routerStateToLocation(
    state: CompleteRouterState
  ): MaybeInvalid<RouterLocationWithTitle> {
    const activeRoute = this.routes.find(route =>
      route.doesRouterStateMatch(state)
    );
    if (activeRoute) {
      return activeRoute.routerStateToLocation(state);
    }
    return INVALID_ROUTE;
  }

  /**
   * Set the application state for a given location and update the window title accordingly.
   *
   * The application state is set by finding a match in the routes.
   * If a match is found, the application state is loaded in two steps:
   * 1. The route converts the location to routeState `activeRoute.routerStateToLocation`
   * 2. The route sets the application state from the routeState `activeRoute.setApplicationStateFromRoute`
   *
   * The `isUpdatingApplicationState` flag is used to prevent the router stream
   * from updating when the application state is being set.
   *
   */
  setApplicationStateFromLocation(location: RouterLocation) {
    // `isUpdatingApplicationState` is not really reliable,
    // `setApplicationStateFromRoute` is very likely async, so the flag will be
    // reset much to early. Seems that doesn't matter though currently, but
    // it's not a solid design.
    // In the current code flow the location subscription further down updates
    // the location much too often in the startup phase because the state of
    // that flag is not correct, but as above, it seems that doesn't matter.
    this.isUpdatingApplicationState = true;
    const activeRoute = this.routes.find(route =>
      route.doesLocationMatch({
        path: location.path,
        searchParams: location.searchParams,
        search: location.search,
      })
    );
    if (activeRoute) {
      const routerState = activeRoute.locationToRouterState({
        path: location.path,
        searchParams: location.searchParams,
        search: location.search,
      });
      const { title } = activeRoute.routerStateToLocation(routerState);
      activeRoute.setApplicationStateFromRoute(routerState);
      setWindowTitle(title);
      this.isUpdatingApplicationState = false;
      return true;
    }
    this.isUpdatingApplicationState = false;
    return false;
  }

  /**
   * Start the router by combining a set of streams that stream the application
   * state and convert it to the complete router state.
   *
   * The router state is converted to a location by calling `routerStateToLocation`.
   * If the location is valid, the window location and title are updated.
   *
   * The router also listens to the browser history and calls `setApplicationStateFromLocation`
   * if the back/forward buttons are clicked.
   *
   * @param combineRouterStateStreams A method that combines a set of streams
   * and maps them to the complete router state. The streams are typically combined
   * by calling `getPartialRouterStateStream()` on each route.
   */
  start(combineRouterStateStreams: () => Observable<CompleteRouterState>) {
    const isValidRoute = this.setApplicationStateFromLocation({
      path: getWindowLocationPath(),
      searchParams: new URLSearchParams(window.location.search),
      search: window.location.search,
    });

    this.routerStateSubscription = combineRouterStateStreams()
      .pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        map(routerState => {
          try {
            return this.routerStateToLocation(routerState);
          } catch (e) {
            logError(e as Error, 'Routing failed');
            return INVALID_ROUTE;
          }
        }),
        tag('router$')
      )
      .subscribe(location => {
        // Ensure that we don't update the location if the router is
        // updating the state (could be triggered by the back button)
        if (!this.isUpdatingApplicationState && location !== INVALID_ROUTE) {
          const searchQuery = location.searchParams
            ? `?${location.searchParams.toString()}`
            : '';
          // we are about to change the location, so we should remove any warnings about navigation
          clearAllWarnBeforeNavigateListeners();
          setWindowLocation(location.path + searchQuery);
          if (location.title !== undefined) {
            setWindowTitle(location.title);
          }
        }
      });

    this.unlistenHistory = history.listen((location, action) => {
      // Handle back button
      if (action === 'POP') {
        this.setApplicationStateFromLocation({
          path: location.pathname,
          searchParams: new URLSearchParams(location.search),
          search: location.search,
        });
      }
    });

    return isValidRoute;
  }

  teardown() {
    if (this.routerStateSubscription) {
      this.routerStateSubscription.unsubscribe();
      this.routerStateSubscription = undefined;
    }
    if (this.unlistenHistory) {
      this.unlistenHistory();
      this.unlistenHistory = undefined;
    }
  }
}
