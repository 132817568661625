import { IconName } from '@ardoq/icons';
import { perspectiveGroupingRuleToGroupingRule } from '@ardoq/perspectives';
import { dispatchAction } from '@ardoq/rxbeach';
import { ComponentSearchViewState } from 'viewpointBuilder/selectContextTab/types';
import {
  formattingRulesToFormattingAttributes,
  getReferenceLabelFormattingFilterAttributes,
} from 'modelInterface/filters/formattingRulesToFormattingAttributes';
import {
  CreateViewpointAttributes,
  CreateViewpointInFolderAttributes,
  UpdateTraversalAttributes,
} from '@ardoq/api-types';
import {
  executeViewpoint as executeViewpointAction,
  updateViewpoint,
  createViewpoint,
  createViewpointInFolder,
} from 'viewpointBuilder/actions';
import { ViewpointBuilderState } from 'viewpointBuilder/types';
import { pluralize } from '@ardoq/common-helpers';
import { viewpointBuilderTracking } from '../viewpointBuilderTracking';
import { getComponentSelection } from '../getComponentSelection';
import {
  openComponents as openComponentsAction,
  saveEditedLoadedStateSearch,
} from '../selectContextTab/actions';
import { ViewpointFormattingProps } from '../formattingTabs/viewpointFormattingTypes';
import { labelFormattingToFilterAttributes } from 'modelInterface/filters/util';
import { ReferenceLabelSource } from '@ardoq/data-model';
import { MultiLabelFormattingProps } from '@ardoq/perspectives-sidebar';
import { extractTraversalParamsFromViewpointBuilderState } from '../utils';

export type PrimaryButtonConfig = {
  primaryButtonLabel: string;
  iconName: IconName;
  isDisabled: boolean;
  primaryAction: (closeViewpointBuilder: () => void) => void;
  popoverContentForDisabledItem?: string;
};

export const getPrimaryButtonConfig = (
  state: ViewpointBuilderState
): PrimaryButtonConfig => {
  const context = getContext(state);
  switch (context) {
    case 'editViewpoint':
    case 'createViewpoint': {
      return getPrimaryButtonConfigToSaveViewpoint(state);
    }

    case 'loadNewViewpoint':
    case 'editSubgraph':
    case 'contextMenu':
    case 'editSearch': {
      return getPrimaryButtonConfigToOpenDataset(state);
    }

    case 'openViewpoint':
      return getPrimaryButtonConfigToOpenViewpoint(state);

    default:
      context satisfies never;
      return primaryButtonConfigForUnknownContext;
  }
};

const getOpenComponentsButtonLabel = (
  state: ComponentSearchViewState
): string => {
  const count = state.selectedComponentsCount;

  if (count === 0) {
    return 'Open';
  }
  return `Open ${count} ${pluralize('component', count)}`;
};

const getPrimaryButtonLabel = (state: ViewpointBuilderState): string => {
  const context = getContext(state);
  switch (context) {
    case 'editViewpoint':
      return 'Save changes';
    case 'createViewpoint':
      return 'Save viewpoint';

    case 'contextMenu':
    case 'loadNewViewpoint':
      return hasTraversalPaths(state)
        ? 'Open dataset'
        : getOpenComponentsButtonLabel(state.searchComponentsState);

    case 'editSubgraph':
    case 'editSearch':
      return 'Update dataset';

    case 'openViewpoint':
      return 'Open';

    default:
      context satisfies never;
      return 'Missing';
  }
};

type ViewpointBuilderStateWithMetaInfo = ViewpointBuilderState & {
  metaInfoState: { initialState: { _id: string } };
};
const isEditingSavedViewpointFromView = (
  state: ViewpointBuilderState
): state is ViewpointBuilderStateWithMetaInfo =>
  Boolean(
    getContext(state) === 'editSubgraph' &&
      state.metaInfoState.initialState?._id
  );

export const buildAttributesForSavingViewpoint = (
  state: ViewpointBuilderState
):
  | CreateViewpointAttributes
  | UpdateTraversalAttributes
  | CreateViewpointInFolderAttributes => {
  const metaInfoAttributes = state.metaInfoState.stateAsSavableAttributes;
  const { paths, filters, startType, pathMatching, pathCollapsingRules } =
    state.editTraversalsState.stateAsSavableAttributes;
  const { groupBys, conditionalFormatting } =
    getGroupsAndFormattingFromState(state);

  return {
    ...metaInfoAttributes,
    paths,
    startType: startType!, // TODO AM - this one must be set at this point, we should not allow it to be null
    filters,
    groupBys,
    // @ts-expect-error We have a bit too many filter types, seems on some level they are incompatible. We should obviously fix this, but this seems a bit bigger task.
    conditionalFormatting,
    pathMatching,
    pathCollapsingRules,
  };
};

const getMultiLabelFormattingAttributes = ({
  labelFormatting,
  showReferenceType,
}: MultiLabelFormattingProps) => [
  ...(showReferenceType
    ? [
        getReferenceLabelFormattingFilterAttributes(
          ReferenceLabelSource.REFERENCE_TYPE,
          undefined
        ),
      ]
    : [
        getReferenceLabelFormattingFilterAttributes(
          ReferenceLabelSource.NONE,
          undefined
        ),
      ]),
  ...labelFormatting.flatMap(labelFormattingToFilterAttributes),
];

const getLabelAndColorFormattingRulesToFormattingAttributes = (
  formatting: ViewpointFormattingProps
) => {
  const labelFormatting = getMultiLabelFormattingAttributes(
    formatting.multiLabelFormatting
  );

  return [
    ...formattingRulesToFormattingAttributes(
      formatting.conditionalFormatting.formattingRules
    ),
    ...labelFormatting,
  ];
};

const isEmptyStartSet = (componentSearchState: ComponentSearchViewState) =>
  componentSearchState.selectedComponentsCount === 0;

/**
 * Paths gets length = 1 when entering the edit subgraph-tab, before adding any paths.
 * Its first element contains the actual user-added paths
 */
const hasTraversalPaths = (state: ViewpointBuilderState) => {
  const paths = state.editTraversalsState.stateAsSavableAttributes.paths;
  return paths.length > 0 && paths[0].length > 0;
};

const getContext = (state: ViewpointBuilderState) => {
  return state.viewpointBuilderNavigationState.context;
};

const hasMoreThanOneComponentTypeSelected = (state: ComponentSearchViewState) =>
  state.selectedStartComponentTypeNames.length > 1;

const isUpdateTraversal = (
  attributes: CreateViewpointAttributes | UpdateTraversalAttributes
): attributes is UpdateTraversalAttributes => {
  return '_id' in attributes && Boolean(attributes._id);
};

const isCreateViewpointInFolder = (
  attributes: CreateViewpointAttributes | CreateViewpointInFolderAttributes
): attributes is CreateViewpointInFolderAttributes => {
  return 'folderId' in attributes && Boolean(attributes.folderId);
};

type SaveViewpointAttributes =
  | UpdateTraversalAttributes
  | CreateViewpointAttributes
  | CreateViewpointInFolderAttributes;

const getOnSaveAction = (attributes: SaveViewpointAttributes) => {
  if (isUpdateTraversal(attributes)) {
    return updateViewpoint(attributes);
  }
  if (isCreateViewpointInFolder(attributes)) {
    return createViewpointInFolder(attributes);
  }
  return createViewpoint(attributes);
};

const getPrimaryButtonConfigToSaveViewpoint = (
  state: ViewpointBuilderState
): PrimaryButtonConfig => {
  const isDisabled =
    !state.metaInfoState.canSaveViewpoint || !hasTraversalPaths(state);
  return {
    primaryButtonLabel: getPrimaryButtonLabel(state),
    iconName: IconName.CHEVRON_RIGHT,
    isDisabled,
    popoverContentForDisabledItem:
      getContext(state) === 'createViewpoint' && isDisabled
        ? 'To save your Viewpoint, you need a context component type, at least 1 reference type and Viewpoint details.'
        : undefined,

    primaryAction: closeViewpointBuilder => {
      const attributes = buildAttributesForSavingViewpoint(state);
      dispatchAction(getOnSaveAction(attributes));
      closeViewpointBuilder();

      viewpointBuilderTracking.trackMultiLabelApply(
        state.metaInfoState.viewName,
        state.formattingState.multiLabelFormatting?.labelFormatting ?? []
      );
    },
  };
};

/**
 * This should never be used, typescript should catch context that is not handled.
 */
const primaryButtonConfigForUnknownContext: PrimaryButtonConfig = {
  primaryButtonLabel: 'Missing',
  iconName: IconName.NONE,
  isDisabled: true,
  primaryAction: () => {},
};

const primaryButtonConfigWhenWaitingForInitialisation: PrimaryButtonConfig = {
  primaryButtonLabel: 'Loading ...',
  iconName: IconName.NONE,
  isDisabled: true,
  primaryAction: () => {},
};

const getPrimaryButtonConfigToOpenViewpoint = (
  state: ViewpointBuilderState
): PrimaryButtonConfig => {
  {
    if (!state.metaInfoState.initialState) {
      /**
       * This is only possible for opening the viewpoint builder to select components for saved viewpoint.
       * We can display the Loading button until the state is initialised with the viewpoint details.
       */
      return primaryButtonConfigWhenWaitingForInitialisation;
    }

    const { viewName, _id: viewpointId } = state.metaInfoState.initialState;

    return {
      primaryButtonLabel: getPrimaryButtonLabel(state),
      iconName: IconName.CHEVRON_RIGHT,
      isDisabled: isEmptyStartSet(state.searchComponentsState),
      primaryAction: closeViewpointBuilder => {
        const { groupBys, conditionalFormatting } =
          getGroupsAndFormattingFromState(state);

        dispatchAction(
          executeViewpointAction({
            type: 'EXECUTE_SAVED_VIEWPOINT',
            viewName,
            filters: state.editTraversalsState.initialFilters ?? {},
            viewpointId: viewpointId!,
            paths: state.editTraversalsState.initialPaths ?? [],
            componentSelection: getComponentSelection(
              state.searchComponentsState
            ),
            startType:
              state.editTraversalsState.stateAsSavableAttributes.startType,
            groupBys,
            // @ts-expect-error We have a bit too many filter types, seems on some level they are incompatible. We should obviously fix this, but this seems a bit bigger task.
            conditionalFormatting,
            pathMatching: state.editTraversalsState.pathMatching,
            pathCollapsingRules: state.editTraversalsState.pathCollapsing.rules,
          })
        );
        closeViewpointBuilder();
        viewpointBuilderTracking.trackOpenSavedViewpoint(state);
      },
    };
  }
};

const getIsDisabledAndPopoverContent = (state: ViewpointBuilderState) => {
  const isInvalidStartContext =
    hasTraversalPaths(state) &&
    hasMoreThanOneComponentTypeSelected(state.searchComponentsState);
  const hasUnsavedCollapsedPath = Boolean(
    state.editTraversalsState.pathCollapsing.currentEditableRule
  );
  if (isEmptyStartSet(state.searchComponentsState)) {
    return {
      isDisabled: true,
      popoverContentForDisabledItem: undefined,
    };
  }
  if (isInvalidStartContext) {
    return {
      isDisabled: true,
      popoverContentForDisabledItem:
        'You can only open a dataset with references if all context components are the same type.',
    };
  }
  if (hasUnsavedCollapsedPath) {
    return {
      isDisabled: true,
      popoverContentForDisabledItem:
        'You have an unsaved collapsed path. Please save or discard it before continuing.',
    };
  }
  return { isDisabled: false, popoverContentForDisabledItem: undefined };
};

const getPrimaryButtonConfigToOpenDataset = (
  state: ViewpointBuilderState
): PrimaryButtonConfig => {
  const { isDisabled, popoverContentForDisabledItem } =
    getIsDisabledAndPopoverContent(state);
  return {
    primaryButtonLabel: getPrimaryButtonLabel(state),
    iconName: IconName.CHEVRON_RIGHT,
    isDisabled,
    popoverContentForDisabledItem,
    primaryAction: closeViewpointBuilder => {
      if (hasTraversalPaths(state)) {
        executeViewpoint(state);
      } else {
        openSelectedComponents(state);
      }
      viewpointBuilderTracking.trackOpenDataSet(state);
      closeViewpointBuilder();
    },
  };
};

export const executeViewpoint = (state: ViewpointBuilderState) => {
  if (isEditingSavedViewpointFromView(state)) {
    const { groupBys, conditionalFormatting } =
      getGroupsAndFormattingFromState(state);
    const { _id: viewpointId, viewName } = state.metaInfoState.initialState;
    const savableAttributes =
      state.editTraversalsState.stateAsSavableAttributes;
    const isMainLoadedState =
      state.metaInfoState.initialState?.isMainLoadedState ?? undefined;
    const loadedStateHash = isMainLoadedState
      ? undefined
      : (state.metaInfoState.initialState?.loadedStateHash ?? undefined);

    dispatchAction(
      executeViewpointAction({
        type: 'EXECUTE_SAVED_VIEWPOINT',
        componentSelection: getComponentSelection(state.searchComponentsState),
        ...savableAttributes,
        groupBys,
        viewpointId,
        loadedStateHash,
        viewName,
        // @ts-expect-error We have a bit too many filter types, seems on some level they are incompatible. We should obviously fix this, but this seems a bit bigger task.
        conditionalFormatting,
        isMainLoadedState,
      })
    );

    return;
  }

  dispatchAction(
    executeViewpointAction({
      type: 'EXECUTE_UNSAVED_VIEWPOINT',
      ...extractTraversalParamsFromViewpointBuilderState(state),
      loadedStateHash:
        state.metaInfoState.initialState?.loadedStateHash ?? undefined,
      isMainLoadedState:
        state.metaInfoState.initialState?.isMainLoadedState ?? undefined,
    })
  );
};

const openSelectedComponents = (state: ViewpointBuilderState) => {
  const loadedStateHash = state.metaInfoState.initialState?.loadedStateHash;
  if (loadedStateHash) {
    dispatchAction(
      saveEditedLoadedStateSearch({
        loadedStateHash,
        componentSelection: getComponentSelection(state.searchComponentsState),
      })
    );
  } else {
    dispatchAction(
      openComponentsAction({
        componentSelection: getComponentSelection(state.searchComponentsState),
      })
    );
  }
};

const getGroupsAndFormattingFromState = (state: ViewpointBuilderState) => {
  const groupBys = state.groupingsState.groupingRules.map(
    perspectiveGroupingRuleToGroupingRule
  );

  const conditionalFormatting =
    getLabelAndColorFormattingRulesToFormattingAttributes(
      state.formattingState
    );
  return { groupBys, conditionalFormatting };
};
