import {
  type APITagAttributes,
  ArdoqId,
  GroupingRule,
  GroupType,
} from '@ardoq/api-types';
import { PerspectivesGroupsOptions } from '@ardoq/perspectives';
import { action$, derivedStream, ofType } from '@ardoq/rxbeach';
import { switchMap } from 'rxjs/operators';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { groupingRuleInterface } from 'modelInterface/groupingRules/groupingRuleInterface';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import { perspectiveInterface } from 'modelInterface/perspectives/perspectiveInterface';
import { context$ } from '../context/context$';
import {
  notifyFieldAdded,
  notifyFieldRemoved,
  notifyFieldUpdated,
} from '../fields/FieldActions';
import { notifyModelChanged } from '../models/actions';
import {
  notifyWorkspaceModelChanged,
  notifyWorkspaceNameChanged,
} from '../workspaces/actions';
import {
  editorOpened,
  notifyPerspectiveApplied,
} from '../../perspective/actions';
import { combineLatest } from 'rxjs';
import {
  notifyTagAdded,
  notifyTagRemoved,
  notifyTagUpdated,
} from '../tags/TagActions';
import { groupBy, mapValues, uniq } from 'lodash';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { mapFetchedOptionsToGroupOptions } from './fetchSavedPerspectiveEntriesFromClosedWorkspaces';
import { setActivePerspective } from '../filters/FilterActions';
import {
  notifyGroupingAdded,
  notifyGroupingRemoved,
  notifyGroupingSynced,
  notifyGroupingUpdated,
} from '../grouping/GroupingActions';
import { mapToPerspectiveGroupingRule } from './mapToPerspectiveGroupingRule';
import { dateRangeOperations } from '@ardoq/date-range';
import { tagApi } from '@ardoq/api';
import { logError } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';
import { APIFieldAttributes } from '@ardoq/api-types';
import { viewpointOptionsSourceData$ } from './viewpointOptionsSourceData$';

const getOptionsReducer =
  (typeNames?: string[]) =>
  (
    acc: { id: number | string; name: string }[],
    type: { id: number | string; name: string }
  ) => {
    if (typeNames && !typeNames.includes(type.name)) {
      return acc;
    }
    return [
      ...acc,
      {
        id: type.id,
        name: type.name,
      },
    ];
  };

const getComponentTypeOptionsPerWorkspace = (
  workspaceIds: ArdoqId[],
  componentTypeNames?: string[]
) => {
  return workspaceIds.reduce((previousValue, workspaceId) => {
    const componentTypes = workspaceInterface.getComponentTypes(workspaceId);
    const componentTypeOptions = componentTypes.reduce(
      getOptionsReducer(componentTypeNames),
      []
    );
    return {
      ...previousValue,
      [workspaceId]: componentTypeOptions,
    };
  }, {});
};

const getWorkspaceOptions = (workspaceIds: ArdoqId[]) => {
  return workspaceIds.map(workspaceId => ({
    id: workspaceId,
    name: workspaceInterface.getWorkspaceName(workspaceId) ?? '',
  }));
};

const getReferenceTypeOptionsPerWorkspace = (
  workspaceIds: ArdoqId[],
  referenceTypeNames?: string[]
) => {
  return workspaceIds.reduce((previousValue, workspaceId) => {
    const referenceTypes = workspaceInterface.getReferenceTypes(workspaceId);
    const virtualReferenceTypes =
      workspaceInterface.getVirtualReferenceTypes(workspaceId);
    const typeOptions = [
      ...Object.values(referenceTypes ?? {}),
      ...Object.values(virtualReferenceTypes ?? {}),
    ].reduce(getOptionsReducer(referenceTypeNames), []);
    return {
      ...previousValue,
      [workspaceId]: typeOptions,
    };
  }, {});
};

/**
 * We're interested only in component fields as grouping is made only for components.
 * There's no point offering user grouping by a custom field that is available only for references.
 */
const getComponentFieldOptionsPerWorkspace = (
  workspaceIds: ArdoqId[],
  availableComponentFields?: APIFieldAttributes[]
) =>
  workspaceIds.reduce((previousValue, workspaceId) => {
    const fields = fieldInterface.getAllFieldsOfWorkspace(workspaceId);
    const filteredFields = availableComponentFields
      ? fields.filter(field =>
          availableComponentFields.some(
            availableField => availableField.name === field.name
          )
        )
      : fields;

    const fieldOptions = dateRangeOperations
      .mergeDateTimeFieldsToDateRangeFields(
        filteredFields.filter(
          field => field.global || field.componentType.length
        )
      )
      .fields.flatMap(dateRangeOperations.getFieldIdAndLabel)
      .map(({ id, label }) => ({ id, name: label }));
    return {
      ...previousValue,
      [workspaceId]: fieldOptions,
    };
  }, {});

const getTagOptionsPerWorkspace = (workspaceIds: ArdoqId[]) => {
  return workspaceIds.reduce((previousValue, workspaceId) => {
    const tags = tagInterface.getTagsByWorkspaceId(workspaceId);
    const tagOptions = tags.map(tag => ({
      id: tag._id,
      name: tag.name,
    }));

    return {
      ...previousValue,
      [workspaceId]: tagOptions,
    };
  }, {});
};

export const toGroupingOptions = (
  workspaceIds: ArdoqId[]
): PerspectivesGroupsOptions => {
  return {
    appliedGroupingRules: groupingRuleInterface
      .getAll()
      .map(mapToPerspectiveGroupingRule),
    workspaces: getWorkspaceOptions(workspaceIds),
    componentTypesPerWorkspace:
      getComponentTypeOptionsPerWorkspace(workspaceIds),
    referenceTypesPerWorkspace:
      getReferenceTypeOptionsPerWorkspace(workspaceIds),
    fieldsPerWorkspace: getComponentFieldOptionsPerWorkspace(workspaceIds),
    tagsPerWorkspace: getTagOptionsPerWorkspace(workspaceIds),
    shouldShowSubdivisionsOption: hasFeature(Features.PERMISSION_ZONES),
  };
};

type ToGroupingOptionsForTraversalsParams = {
  workspaceIds: ArdoqId[];
  componentTypeNames: string[];
  referenceTypeNames: string[];
  availableComponentFields: APIFieldAttributes[];
  tags: APITagAttributes[];
};

export const toGroupingOptionsForTraversals = ({
  workspaceIds,
  componentTypeNames,
  referenceTypeNames,
  availableComponentFields,
  tags,
}: ToGroupingOptionsForTraversalsParams) => {
  return {
    appliedGroupingRules: groupingRuleInterface
      .getAll()
      .map(mapToPerspectiveGroupingRule),
    workspaces: getWorkspaceOptions(workspaceIds),
    componentTypesPerWorkspace: getComponentTypeOptionsPerWorkspace(
      workspaceIds,
      componentTypeNames
    ),
    referenceTypesPerWorkspace: getReferenceTypeOptionsPerWorkspace(
      workspaceIds,
      referenceTypeNames
    ),
    fieldsPerWorkspace: getComponentFieldOptionsPerWorkspace(
      workspaceIds,
      availableComponentFields
    ),
    tagsPerWorkspace: groupTagOptionsPerWorkspace(tags, workspaceIds),
    shouldShowSubdivisionsOption: hasFeature(Features.PERMISSION_ZONES),
  };
};

type ValidTagGroupingRule = Required<
  Pick<GroupingRule, 'type' | 'workspaceId' | 'targetId'>
>;
const isValidTagGroupingRule = (
  groupingRule: GroupingRule
): groupingRule is ValidTagGroupingRule => {
  return Boolean(
    groupingRule.type === GroupType.TAG &&
      groupingRule.workspaceId &&
      groupingRule.targetId
  );
};

const getTagIdsAndWorkspaceIdsOfClosedWorkspacesUsedInGroupingRules = (
  appliedGroupingRules: GroupingRule[],
  workspaceIds: ArdoqId[]
) => {
  const tagIdsAndWorkspaceIdsOfClosedWorkspaces = appliedGroupingRules
    .filter(
      (groupingRule): groupingRule is ValidTagGroupingRule =>
        isValidTagGroupingRule(groupingRule) &&
        !workspaceIds.includes(groupingRule.workspaceId)
    )
    .map(groupingRule => ({
      id: groupingRule.targetId,
      workspaceId: groupingRule.workspaceId,
    }));

  return uniq(tagIdsAndWorkspaceIdsOfClosedWorkspaces);
};

type ValidFieldGroupingRule = Required<
  Pick<GroupingRule, 'type' | 'workspaceId' | 'targetId'>
>;
const isValidFieldGroupingRule = (
  groupingRule: GroupingRule
): groupingRule is ValidFieldGroupingRule => {
  return Boolean(
    groupingRule.type === GroupType.FIELD &&
      groupingRule.workspaceId &&
      groupingRule.targetId
  );
};

const getFieldIdsAndWorkspaceIdsOfClosedWorkspacesUsedInGroupingRules = (
  appliedGroupingRules: GroupingRule[],
  workspaceIds: ArdoqId[]
) => {
  const fieldIdsAndWorkspaceIdsOfClosedWorkspaces = appliedGroupingRules
    .filter(
      (groupingRule): groupingRule is ValidFieldGroupingRule =>
        isValidFieldGroupingRule(groupingRule) &&
        !workspaceIds.includes(groupingRule.workspaceId)
    )
    .map(groupingRule => {
      const field = fieldInterface.getFieldData(groupingRule.targetId);
      return {
        id: groupingRule.targetId,
        workspaceId: groupingRule.workspaceId,
        label: field?.label ?? '',
      };
    });

  return uniq(fieldIdsAndWorkspaceIdsOfClosedWorkspaces);
};

const fetchTag = async ({
  id,
  workspaceId,
}: {
  id: ArdoqId;
  workspaceId: ArdoqId;
}) => {
  const result = await tagApi.fetchTag(id);
  if (isArdoqError(result)) {
    logError(
      result,
      'Could not fetch tag for selected saved perspective in perspective editor',
      { tagId: id, workspaceId }
    );
    return null;
  }
  return { id, label: result.name, workspaceId };
};

const fetchAvailiableTags = async (
  appliedGroupingRules: GroupingRule[],
  workspaceIds: ArdoqId[]
) => {
  const tags = await Promise.all(
    getTagIdsAndWorkspaceIdsOfClosedWorkspacesUsedInGroupingRules(
      appliedGroupingRules,
      workspaceIds
    ).map(fetchTag)
  );
  return tags.filter(tag => tag !== null);
};

export const toGroupingOptionsIncludingSavedPerspectiveEntriesFromClosedWorkspaces =
  async (workspaceIds: ArdoqId[]): Promise<PerspectivesGroupsOptions> => {
    const appliedGroupingRules: GroupingRule[] = groupingRuleInterface
      .getAll()
      .map(mapToPerspectiveGroupingRule);

    const closedWorkspaceIdsUsedInGroupingRules = uniq(
      appliedGroupingRules
        .map(groupingRule => groupingRule.workspaceId)
        .filter(ExcludeFalsy)
        .filter(workspaceId => !workspaceIds.includes(workspaceId))
    );

    const fieldsOfClosedWorkspaces =
      getFieldIdsAndWorkspaceIdsOfClosedWorkspacesUsedInGroupingRules(
        appliedGroupingRules,
        workspaceIds
      );

    const availiableTags = await fetchAvailiableTags(
      appliedGroupingRules,
      workspaceIds
    );

    const allWorkspaceIds = [
      ...workspaceIds,
      ...closedWorkspaceIdsUsedInGroupingRules,
    ];

    const groupOptionsWithClosedWorkspacesEntities = {
      appliedGroupingRules: appliedGroupingRules.map(
        mapToPerspectiveGroupingRule
      ),
      workspaces: getWorkspaceOptions(allWorkspaceIds),
      componentTypesPerWorkspace:
        getComponentTypeOptionsPerWorkspace(allWorkspaceIds),
      referenceTypesPerWorkspace:
        getReferenceTypeOptionsPerWorkspace(allWorkspaceIds),
      fieldsPerWorkspace: {
        ...mapFetchedOptionsToGroupOptions(fieldsOfClosedWorkspaces),
        ...getComponentFieldOptionsPerWorkspace(workspaceIds),
      },
      tagsPerWorkspace: {
        ...mapFetchedOptionsToGroupOptions(availiableTags),
        ...getTagOptionsPerWorkspace(workspaceIds),
      },
      shouldShowSubdivisionsOption: hasFeature(Features.PERMISSION_ZONES),
    };

    return groupOptionsWithClosedWorkspacesEntities;
  };

const tagToTagOption = (tag: APITagAttributes) => ({
  id: tag._id,
  name: tag.name,
});

const groupTagOptionsPerWorkspace = (
  tags: APITagAttributes[],
  workspaceIds: string[]
) => {
  const tagsPerWorkspace = groupBy(
    tags.filter(({ rootWorkspace }) => workspaceIds.includes(rootWorkspace)),
    'rootWorkspace'
  );

  return mapValues(tagsPerWorkspace, tags => tags.map(tagToTagOption));
};

const groupOptionsHaveChanged$ = action$.pipe(
  ofType(
    notifyFieldAdded,
    notifyFieldRemoved,
    notifyFieldUpdated,
    notifyModelChanged,
    notifyTagAdded,
    notifyTagRemoved,
    notifyTagUpdated,
    notifyWorkspaceNameChanged,
    notifyWorkspaceModelChanged,
    editorOpened,
    setActivePerspective,
    notifyGroupingRemoved,
    notifyGroupingAdded,
    notifyGroupingUpdated,
    notifyGroupingSynced,
    notifyPerspectiveApplied
  )
);

const perspectiveEditorGroupingOptionsWithoutFieldsChange$ = derivedStream(
  'perspectiveEditorGroupingOptions$',
  context$
);

export const perspectiveEditorGroupingOptions$ = combineLatest([
  perspectiveEditorGroupingOptionsWithoutFieldsChange$,
  viewpointOptionsSourceData$,
  groupOptionsHaveChanged$,
]).pipe(
  switchMap(async ([[context], viewpointOptionsSourceData]) => {
    if (viewpointOptionsSourceData) {
      return toGroupingOptionsForTraversals(viewpointOptionsSourceData);
    }

    if (perspectiveInterface.isSavedPerspectiveSelected()) {
      return await toGroupingOptionsIncludingSavedPerspectiveEntriesFromClosedWorkspaces(
        context.workspacesIds
      );
    }

    return toGroupingOptions(context.workspacesIds);
  })
);
