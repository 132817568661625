import styled from 'styled-components';
import { connect } from '@ardoq/rxbeach';
import { OptionsGroup } from '@ardoq/settings-bar';
import { WithPopover } from '@ardoq/popovers';
import { WithPointerEvents } from '@ardoq/style-helpers';
import { SplitButtonWithDropdown } from '@ardoq/dropdown-menu';
import { ButtonType } from '@ardoq/button';
import { DropdownSize } from '@ardoq/dropdown-menu-ui';
import { ExtractStreamShape } from 'tabview/types';
import { rightControls$ } from './rightControls$';
import { FlexBox } from '@ardoq/layout';

// I have to use this clumsy last-child selector in order to style the split button's outer container.
const RightControlsFlexBox = styled(FlexBox)`
  > div:last-child {
    min-width: fit-content;
  }
`;

type ViewPaneHeaderRightControlsProperties = ExtractStreamShape<
  typeof rightControls$
>;

const ViewPaneHeaderRightControls = ({
  mainViewId,
  isSaveAsViewpointSidePanelOpened,
  saveAsViewpointButtonState,
  isSaveButtonVisible,
  rightControlsOptions,
  saveButtonOptions,
  onSplitButtonClick,
}: ViewPaneHeaderRightControlsProperties) => (
  <RightControlsFlexBox gap="xsmall">
    <OptionsGroup
      viewId={mainViewId}
      isViewpointModeViewPaneHeader={true}
      options={rightControlsOptions}
    />
    {isSaveButtonVisible && (
      <WithPopover content={saveAsViewpointButtonState.disabledDescription}>
        <WithPointerEvents>
          <SplitButtonWithDropdown
            buttonType={ButtonType.BRAND}
            dropdownSize={DropdownSize.S}
            isSplitButtonDisabled={saveAsViewpointButtonState.isDisabled}
            onSplitButtonClick={onSplitButtonClick}
            isSplitButtonActive={isSaveAsViewpointSidePanelOpened}
            options={saveButtonOptions}
            mainButtonDataTestId="save-as-viewpoint"
            dropdownButtonDataTestId="save-as-viewpoint-dropdown"
            dropdownButtonAriaLabel="More save as options"
          >
            Save as viewpoint
          </SplitButtonWithDropdown>
        </WithPointerEvents>
      </WithPopover>
    )}
  </RightControlsFlexBox>
);

const ConnectedViewPaneHeaderRightControls = connect(
  ViewPaneHeaderRightControls,
  rightControls$
);
export default ConnectedViewPaneHeaderRightControls;
