import { componentInterface } from '@ardoq/component-interface';
import { GraphModelShape } from '@ardoq/data-model';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { logError } from '@ardoq/logging';
import { referenceInterface } from '@ardoq/reference-interface';
import { dispatchAction } from '@ardoq/rxbeach';
import { ContextToolBarState } from 'contextToolBar/contextToolBar$';
import { last } from 'lodash';
import { getPathsForLoadedState } from 'viewpointBuilder/getPathsFromStartSetToSelectedComponents';
import { toggleIsSelectedGraphNode } from 'viewpointBuilder/traversals/editTraversalActions';
import { editTraversalOperations } from 'viewpointBuilder/traversals/editTraversalOperations';
import { ViewpointBuilderViewState } from 'viewpointBuilder/types';

type SelectViewpointTypeNodeWithContextToolbarSelectionArgs = {
  editTraversalsState: ViewpointBuilderViewState['editTraversalsState'];
  mainHierarchyDefinition: LoadedGraphWithViewpointMode['hierarchyDefinition'][0];
  currentContext: NonNullable<ContextToolBarState['currentContext']>;
  graphModel: GraphModelShape;
};
export const selectViewpointTypeNodeWithContextToolbarSelection = ({
  editTraversalsState,
  mainHierarchyDefinition,
  currentContext,
  graphModel,
}: SelectViewpointTypeNodeWithContextToolbarSelectionArgs) => {
  // We need to see how this works, but we can basically only get the an
  // according type node for one instance id.
  const selectedComponentId = currentContext.selectedIds[0];
  if (mainHierarchyDefinition.startSetResult.includes(selectedComponentId)) {
    if (!editTraversalsState.startNode) {
      logError(
        Error(
          'We should not be here, at this point we expect to have a `selectedGraphNodeId`'
        )
      );
      return;
    }
    dispatchAction(
      toggleIsSelectedGraphNode({
        graphNodeId: editTraversalsState.startNode.id,
        isForceSelected: true,
      })
    );
    return;
  }
  const pathsWithIds =
    editTraversalOperations.getTraversalsWithGraphIds(editTraversalsState);
  const selectedPaths = getPathsForLoadedState({
    paths: pathsWithIds,
    startSetResult: mainHierarchyDefinition.startSetResult,
    selectedComponentId: currentContext.selectedIds[0],
    graphModel,
    getComponentTypeName: componentInterface.getTypeName,
    getReferenceTypeName: referenceInterface.getReferenceTypeNameByReferenceId,
  });
  const lastTriple = last(selectedPaths?.[0]);
  if (lastTriple) {
    const { direction, sourceGraphId, targetGraphId } = lastTriple;
    const selectedGraphNodeId =
      direction === 'outgoing' ? targetGraphId : sourceGraphId;
    dispatchAction(
      toggleIsSelectedGraphNode({
        graphNodeId: selectedGraphNodeId,
        isForceSelected: true,
      })
    );
  }
};
