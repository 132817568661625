import { useCallback, useState } from 'react';
import SplitPane from 'react-split-pane';
import { ResizerStylesProvider } from 'components/CloseableSplitPane/atoms';
import MainAppModuleSidebar from './MainAppModuleSidebar/MainAppModuleSidebar';
import { connect } from '@ardoq/rxbeach';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { combineLatest, map } from 'rxjs';
import { stateToMainAppModuleProps } from './mainAppModuleHelpers';
import MainAppLayout from 'appLayout/MainAppLayout';
import { Features, hasFeature } from '@ardoq/features';
import LinkSvgOverlay from 'createReferences2024/LinkSvgOverlay';
import Navbar from 'views/navbar/Navbar';
import MainToolbar from 'menus/topbar/MainToolbar';
import selectedModule$ from 'appContainer/selectedModule$';
import { PageBody, PageWrapper } from '@ardoq/page-layout';
import { AppModules } from 'appContainer/types';
import {
  ResizableHorizontal,
  HorizontalResizeableContainerWrapper,
} from '@ardoq/resizable';
import { CollapsibleSidePanel } from './MainAppModuleSidebar/CollapsibleSidePanel';

const LEFT_NAV_PANE_INITIAL_WIDTH = 310;

type MainAppModuleProps = {
  hide?: boolean;
  /**
   * Whether to show the new nav bar inside `MainAppModule`, as opposed to using
   * the old global nav bar which is not part of `MainAppModule`, but displayed
   * above it. This is just a temporary solution until we start using the new
   * nav bar everywhere in the workspaces module. For the time being the new nav
   * bar is only used in viewpoint mode in the workspaces module when the visualization is not shown.
   */
  shouldShowNavBar: boolean;
  isViewpointMode: boolean;
};
const MainAppModule = ({
  hide,
  shouldShowNavBar,
  isViewpointMode,
}: MainAppModuleProps) => {
  const [isResizing, setIsResizing] = useState(false);
  const updateLayoutStart = useCallback(() => {
    if (!isResizing) {
      setIsResizing(true);
    }
  }, [isResizing]);

  const updateLayout = useCallback(() => {
    setIsResizing(false);
  }, []);

  if (hasFeature(Features.CANVAS_PROTOTYPE) && isViewpointMode) {
    return (
      <PageWrapper style={{ display: hide ? 'none' : 'flex' }}>
        {shouldShowNavBar && (
          <Navbar
            primaryContent="Current session"
            secondaryContent="View builder"
            toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
          />
        )}
        <PageBody skipPadding backgroundColor="surfaceStatusNeutral">
          <ResizableHorizontal>
            <CollapsibleSidePanel
              minWidth={24}
              collapsedWidth={24}
              initialWidth={LEFT_NAV_PANE_INITIAL_WIDTH}
              shouldPreserveValue={true}
            >
              <MainAppModuleSidebar />
            </CollapsibleSidePanel>
            <HorizontalResizeableContainerWrapper>
              <MainAppLayout />
            </HorizontalResizeableContainerWrapper>
          </ResizableHorizontal>
        </PageBody>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper style={{ display: hide ? 'none' : 'flex' }}>
      {shouldShowNavBar && (
        <Navbar
          primaryContent="Current session"
          secondaryContent="View builder"
          toolbarContent={<MainToolbar shouldUseNewJourneyVersion={true} />}
        />
      )}
      <PageBody skipPadding backgroundColor="surfaceStatusNeutral">
        <ResizerStylesProvider
          style={{
            position: 'relative',
            flex: 1,
          }}
        >
          <SplitPane
            defaultSize={LEFT_NAV_PANE_INITIAL_WIDTH}
            minSize={250}
            onChange={updateLayoutStart}
            onDragFinished={updateLayout}
          >
            <MainAppModuleSidebar />
            <MainAppLayout />
          </SplitPane>
          <LinkSvgOverlay />
        </ResizerStylesProvider>
      </PageBody>
    </PageWrapper>
  );
};
export default connect(
  MainAppModule,
  combineLatest([isViewpointMode$, selectedModule$]).pipe(
    map(([{ isViewpointMode }, { selectedModule }]) => ({
      isViewpointMode,
      hasNewCoreJourneyFeature: hasFeature(Features.NEW_CORE_JOURNEY),
      visualizationShown: selectedModule === AppModules.WORKSPACES,
    })),
    map(stateToMainAppModuleProps)
  )
);
