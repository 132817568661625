import { connect } from '@ardoq/rxbeach';
import { IPAllowlistingStreamShape, IPAllowlistingTab } from './types';
import AllowlistEntryForm from './AllowlistEntryForm';
import AllowlistEntryList from './AllowlistEntryList';
import AllowlistBlockedMessageForm from './AllowlistBlockedMessageForm';
import { AqLayout } from '@ardoq/layout';
import {
  AllowlistStatus,
  IPAlert,
  IPTab,
  IPTabDescription,
  IPTabHeader,
} from './atoms';
import { ipTabMetadata } from './consts';
import ipAllowlist$ from './ipAllowlist$';
import { Island } from '@ardoq/page-layout';

type ActiveTabProps = Pick<IPAllowlistingStreamShape, 'activeTab'>;

const getTabProps = ({ activeTab }: ActiveTabProps) =>
  Object.entries(ipTabMetadata).map(([id, { tabLabel }]) => ({
    id: id as IPAllowlistingTab,
    label: tabLabel,
    isActive: activeTab === id,
  }));

const Content = ({ activeTab }: ActiveTabProps) => {
  switch (activeTab) {
    case 'CREATE':
      return <AllowlistEntryForm />;
    case 'MANAGE':
      return <AllowlistEntryList />;
    case 'STATUS':
      return <AllowlistStatus />;
    case 'BLOCKED_MESSAGE':
      return <AllowlistBlockedMessageForm />;
    default:
      return <>Missing content for tab {activeTab}</>;
  }
};

const ManageIPAllowlist = ({ alert, activeTab }: IPAllowlistingStreamShape) => {
  return (
    <Island bodySpacing="none">
      <AqLayout
        renderLeftSidebar={() => (
          <>
            {getTabProps({ activeTab }).map(props => (
              <IPTab {...props} key={props.id} />
            ))}
          </>
        )}
      >
        <IPTabHeader activeTab={activeTab} />
        <IPTabDescription activeTab={activeTab} />
        <IPAlert alert={alert} />
        <Content activeTab={activeTab} />
      </AqLayout>
    </Island>
  );
};

export default connect(ManageIPAllowlist, ipAllowlist$);
