import { actionCreator } from '@ardoq/rxbeach';
import { AnalyzeState } from './types';

const NAMESPACE = '[integrations lucid-import] ANALYZE';

export const setAnalyzeState = actionCreator<AnalyzeState>(
  `${NAMESPACE}_SET_ANALYSIS_STATE`
);

export type AnalyzeLucidDocument = {
  documentId: string;
  workspaceIds: string[];
};

export const analyzeLucidDocument = actionCreator<AnalyzeLucidDocument>(
  `${NAMESPACE}_IMPORT_LUCIDCHART`
);

export const resetAnalyze = actionCreator(`${NAMESPACE}_RESET_ANALYZE`);
