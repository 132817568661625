import { combineLatest, map } from 'rxjs';
import { dispatchAction } from '@ardoq/rxbeach';
import { IconName } from '@ardoq/icons';
import { SettingsType, type ButtonConfig } from '@ardoq/view-settings';
import saveAsViewpointButtonState$ from 'streams/saveAsViewpointButtonState$';
import { ViewSidePanelContentIds } from 'views/mainApp/viewSidePanel/types';
import { viewSidePanel$ } from 'views/mainApp/viewSidePanel/viewSidePanel$';
import viewModifiers$, { asyncNoop } from './viewModifiers$';
import { isRightEditorPaneOpen$ } from '../../isRightEditorPaneOpen$';
import currentViewpointUserPermissions$ from '../currentViewpointUserPermissions$';
import {
  hideViewSidePanel,
  showViewSidePanel,
} from 'views/mainApp/viewSidePanel/actions';
import { hideRightPane, showRightPane } from 'appContainer/actions';
import { GetContentOptionsType } from 'appModelStateEdit/legacyTypes';
import {
  DropdownOptionType,
  DropdownHeader,
  DropdownOption,
} from '@ardoq/dropdown-menu';
import { addToPresentation } from 'viewSettings/exportHandlers';
import { trackOpenViewSettingsPanel, trackClickOnSaveAs } from './tracking';
import { noop } from 'lodash';
import { isViewEligibleForNewAssets } from 'viewDeprecation/restrictedViews';
import { Features, hasFeature } from '@ardoq/features';
import { exportToLucidchart } from 'integrations/lucidchart/utils';
import { ViewIds } from '@ardoq/api-types';

const onSaveAsViewpointClick = (isOpen: boolean) => {
  if (isOpen) {
    dispatchAction(hideViewSidePanel());
  } else {
    dispatchAction(showViewSidePanel(ViewSidePanelContentIds.SAVE_VIEWPOINT));
    trackClickOnSaveAs('viewpoint');
  }
};

const onSaveAsBookmarkClick = (isOpen: boolean) => {
  if (isOpen) {
    dispatchAction(hideViewSidePanel());
  } else {
    dispatchAction(showViewSidePanel(ViewSidePanelContentIds.SAVE_BOOKMARK));
  }
};

const viewOptionsPanelRelatedContentIdsSet = new Set([
  ViewSidePanelContentIds.VIEW_OPTIONS,
  ViewSidePanelContentIds.PERSPECTIVES_GROUPS,
  ViewSidePanelContentIds.PERSPECTIVES_LABELS,
  ViewSidePanelContentIds.PERSPECTIVES_CONDITIONAL_FORMAT,
]);

const getIsViewOptionsSidePanelOpened = (
  viewSidePanelContentId: ViewSidePanelContentIds | null
) =>
  Boolean(
    viewSidePanelContentId &&
      viewOptionsPanelRelatedContentIdsSet.has(viewSidePanelContentId)
  );

export const rightControls$ = combineLatest([
  isRightEditorPaneOpen$,
  viewSidePanel$,
  viewModifiers$,
  currentViewpointUserPermissions$,
  saveAsViewpointButtonState$,
]).pipe(
  map(
    ([
      isRightEditorPaneOpen,
      { contentId: viewSidePanelContentId },
      { exportToPng, viewContext, mainViewId },
      { canCreateViewpoint, canUpdateViewpoint },
      saveAsViewpointButtonState,
    ]) => {
      const { toggleLegend, isLegendActive } = viewContext;
      const isSaveButtonVisible = canCreateViewpoint || canUpdateViewpoint;
      const isViewOptionsOpened = getIsViewOptionsSidePanelOpened(
        viewSidePanelContentId
      );
      const isSaveAsViewpointSidePanelOpened =
        viewSidePanelContentId === ViewSidePanelContentIds.SAVE_VIEWPOINT;

      const isSaveAsBookmarkSidePanelOpened =
        viewSidePanelContentId === ViewSidePanelContentIds.SAVE_BOOKMARK;

      const rightControlsOptions = [
        {
          id: 'viewOptions',
          type: SettingsType.BUTTON,
          iconName: IconName.TUNE_SETTINGS,
          label: 'View style, options and settings',
          isActive: isViewOptionsOpened,
          onClick: () => {
            if (isViewOptionsOpened) {
              dispatchAction(hideViewSidePanel());
            } else {
              dispatchAction(
                showViewSidePanel(ViewSidePanelContentIds.VIEW_OPTIONS)
              );
              trackOpenViewSettingsPanel();
            }
          },
        } satisfies ButtonConfig,
        {
          id: 'toggleLegend',
          label: 'Toggle legend',
          iconName: IconName.INFO,
          type: SettingsType.TOGGLE,
          onClick: toggleLegend,
          isActive: isLegendActive,
          isDisabled: toggleLegend === noop,
        } satisfies ButtonConfig,
        {
          id: 'hamburger',
          type: SettingsType.BUTTON,
          iconName: IconName.SETTINGS,
          label: 'Metamodel, workspace, component and reference',
          isActive: isRightEditorPaneOpen,
          onClick: () =>
            dispatchAction(
              isRightEditorPaneOpen
                ? hideRightPane()
                : showRightPane({ type: GetContentOptionsType.MENU })
            ),
        } satisfies ButtonConfig,
        {
          id: 'export',
          type: SettingsType.BUTTON,
          iconName: IconName.EXPORT,
          label: 'Export as PNG',
          isActive: false,
          onClick: exportToPng,
          isDisabled: exportToPng === asyncNoop,
        } satisfies ButtonConfig,
      ];
      const isViewConform = isViewEligibleForNewAssets(mainViewId);

      const saveButtonOptions = isSaveButtonVisible
        ? [
            {
              type: DropdownOptionType.HEADER,
              label: 'Save As',
            } satisfies DropdownHeader,
            {
              type: DropdownOptionType.OPTION,
              iconName: IconName.VIEWPOINT,
              label: 'Viewpoint',
              isDisabled: saveAsViewpointButtonState.isDisabled,
              description: saveAsViewpointButtonState.disabledDescription,
              onClick: isViewConform
                ? () => {
                    onSaveAsViewpointClick(isSaveAsViewpointSidePanelOpened);
                  }
                : noop,
              isFocusedByDefault: true,
            } satisfies DropdownOption,
            {
              type: DropdownOptionType.OPTION,
              iconName: IconName.ADD_PRESENTATION,
              label: 'Presentation slide',
              onClick: isViewConform
                ? () => {
                    addToPresentation(mainViewId);
                    trackClickOnSaveAs('slide');
                  }
                : noop,
              isDisabled: !isViewConform,
            } satisfies DropdownOption,
            ...(hasFeature(Features.BOOKMARKS)
              ? [
                  {
                    type: DropdownOptionType.OPTION,
                    iconName: IconName.BOOKMARK,
                    label: 'Bookmark',
                    onClick: () =>
                      onSaveAsBookmarkClick(isSaveAsBookmarkSidePanelOpened),
                  } satisfies DropdownOption,
                ]
              : []),
            ...(hasFeature(Features.LUCID_CHART_ENABLE) &&
            (mainViewId === ViewIds.BLOCK_DIAGRAM ||
              mainViewId === ViewIds.MODERNIZED_BLOCK_DIAGRAM)
              ? [
                  {
                    type: DropdownOptionType.OPTION,
                    iconName: IconName.LUCIDCHART,
                    label: 'Lucidchart document',
                    onClick: () => exportToLucidchart(isLegendActive),
                  } satisfies DropdownOption,
                ]
              : []),
          ]
        : [];

      const onSplitButtonClick = () =>
        onSaveAsViewpointClick(isSaveAsViewpointSidePanelOpened);

      return {
        isRightEditorPaneOpen,
        exportToPng,
        viewContext,
        isSaveButtonVisible,
        saveAsViewpointButtonState,
        isViewOptionsOpened,
        rightControlsOptions,
        saveButtonOptions,
        isSaveAsViewpointSidePanelOpened,
        mainViewId,
        onSplitButtonClick,
      };
    }
  )
);
