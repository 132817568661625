import { workspaceApi, handleError } from '@ardoq/api';
import {
  action$,
  ofType,
  extractPayload,
  streamReducer,
  reducer,
  dispatchAction,
} from '@ardoq/rxbeach';
import {
  filter,
  merge,
  shareReplay,
  switchMap,
  map,
  from,
  withLatestFrom,
  Observable,
  tap,
} from 'rxjs';
import { requestLoadWorkspaces } from '../createScopeDataRequest';
import { isArdoqError } from '@ardoq/common-helpers';
import workspaces$ from 'streams/workspaces/workspaces$';
import { reducedStream } from '@ardoq/rxbeach';
import { scopeDataOperations } from '@ardoq/scope-data';
import { APIScopeData } from '@ardoq/api-types';
import {
  closeAllOpenData,
  closeWorkspace,
  hierarchiesWorkspaceIdsChanged,
} from 'componentHierarchies/actions';
import { logError } from '@ardoq/logging';

const loadScopeData$ = action$.pipe(
  ofType(requestLoadWorkspaces),
  extractPayload(),
  map(({ workspaceIds }) => workspaceIds),
  switchMap(workspaceApi.loadWorkspaceHierarchies),
  shareReplay({ bufferSize: 1, refCount: true })
);

export const loadingScopeDataNotification$ = merge(
  action$.pipe(
    ofType(requestLoadWorkspaces),
    extractPayload(),
    withLatestFrom(workspaces$),
    map(([{ workspaceIds }, { byId }]) => {
      const label = workspaceIds
        .map(workspaceId => byId[workspaceId]?.name)
        .filter(Boolean)
        .join(', ');
      return `Loading workspace ${label}...`;
    })
  ),
  loadScopeData$.pipe(map(() => null)),
  from([null])
);

export const loadScopeDataError$ = merge(
  loadScopeData$.pipe(
    filter(data => isArdoqError(data)),
    map(() => "Sorry, we couldn't load the workspace")
  ),
  action$.pipe(
    ofType(requestLoadWorkspaces),
    map(() => null)
  ),
  from([null])
);

/* scopeData$ */

const loadedScopeDataNoErrors$ = loadScopeData$.pipe(
  handleError(),
  map(scopeData => ({ ...scopeData, scopeComponents: [] }))
);

const mergeScopeData = (
  state: APIScopeData,
  update: APIScopeData
): APIScopeData => scopeDataOperations.merge(state, update);

const clearScopeData = (): APIScopeData => scopeDataOperations.getEmpty();

const handleCloseWorkspace = (
  state: APIScopeData,
  { workspaceId }: { workspaceId: string }
): APIScopeData => {
  const workspace = state.workspaces.find(
    workspace => workspace._id === workspaceId
  );
  if (!workspace) {
    logError(Error('Workspace not found in ComponentHierarchies'));
    return state;
  }
  const { componentModel } = workspace;
  return {
    ...state,
    workspaces: state.workspaces.filter(
      workspace => workspace._id !== workspaceId
    ),
    models: state.models.filter(model => model._id !== componentModel),
    fields: state.fields.filter(field => field.model !== componentModel),
    components: state.components.filter(
      component => component.rootWorkspace !== workspaceId
    ),
    references: [],
    scopeComponents: [],
  };
};

export const scopeData$: Observable<APIScopeData> = reducedStream(
  'scopeData$',
  scopeDataOperations.getEmpty(),
  [
    streamReducer(loadedScopeDataNoErrors$, mergeScopeData),
    reducer(closeAllOpenData, clearScopeData),
    reducer(closeWorkspace, handleCloseWorkspace),
  ]
).pipe(
  tap(({ workspaces }) =>
    // To keep the scopeData$ bound to the live cycle of the
    // ComponentHierarchies live cycle (the hierarchiesWorkspaceIds$ must be
    // persistent, it is part of the workspace route state)
    dispatchAction(
      hierarchiesWorkspaceIdsChanged(workspaces.map(workspace => workspace._id))
    )
  )
);
