import { RepresentationData } from '@ardoq/data-model';
import { IconName } from '@ardoq/icons';
import { NavigatorEventListeners } from '../types';
import { NodeModelTypes } from '../utils/consts';
import { CSSProperties } from 'react';
import { LoaderColor } from '@ardoq/ardoq-loader-component';

export type ComponentItemProps = {
  type: NodeModelTypes;
  name: string;
  showContextMenuButton: boolean;
  containerStyle: {
    paddingLeft: string;
  };
  containerClassNames: string;
  componentRepresentationData: RepresentationData | null;
  shouldShowSubdivisionWarning: boolean;
  iconWrapperClassNames: string;
  iconWrapperStyle: {
    fill: string;
    color: string;
  };
  isExternallyManaged: boolean;
  isExternallyMissing: boolean;
  isLocked: boolean;
  isDiffTypeChanged: boolean;
  hasWriteAccess: boolean;
  linkIconName: IconName;
  isInDiffMode: boolean;
  aggregatedVisualDiffState: number;
  nodeId: string;
  hasVisibleContent: boolean;
  isExpanded: boolean;
  isSelectedContext?: boolean;
};

export const isComponentItemProps = (
  candidate: RenderProps
): candidate is ComponentItemProps =>
  candidate.type === NodeModelTypes.COMPONENT;

export type WorkspaceItemProps = {
  // common?
  type: NodeModelTypes;
  name: string;
  nodeId: string;
  containerClassNames: string;
  containerStyle: {
    paddingLeft: string;
  };
  hasVisibleContent: boolean;
  isExpanded: boolean;
  isExternallyManaged: boolean;
  isSelectedContext?: boolean;
  // common?
  hasCloseButton: boolean;
  hasContextMenu: boolean;
};

export const isWorkspaceItemProps = (
  candidate: RenderProps
): candidate is WorkspaceItemProps =>
  candidate.type === NodeModelTypes.WORKSPACE;

export type ScenarioItemProps = {
  type: NodeModelTypes;
  name: string;
  nodeId: string;
  containerClassNames: string;
  containerStyle: {
    paddingLeft: string;
  };
  hasVisibleContent: boolean;
  isExpanded: boolean;
  isSelectedContext?: boolean;
  scenarioIconName: IconName;
};

export const isScenarioItemProps = (
  candidate: RenderProps
): candidate is ScenarioItemProps => candidate.type === NodeModelTypes.SCENARIO;

export type RenderProps = ComponentItemProps | Record<string, any>;

export type DragTargetProps = {
  shouldShowGhost: boolean;
  ghostStyle: CSSProperties;
  ghostText: string;
  onTransitionEnd?: () => void;
  targetId: string;
};

export type NavigatorContentProps = {
  renderProps: RenderProps[];
  navigatorContentStyle: { height: string };
  parentDropTargetIndicatorStyle: CSSProperties | null;
  layoutBoxesContainerStyle: CSSProperties;
  actionNamespace?: string;
};

export type LoadDataNotificationProps = {
  loadingScopeDataNotification: string | null;
  loaderColor?: LoaderColor;
};

export type LoadDataErrorProps = {
  errorMessage: string;
};

export type NavigatorContainerProps = NavigatorContentProps & {
  eventListeners: NavigatorEventListeners;
  dragElementRoot?: HTMLElement;
  navigatorContainerClassName: string;
  dragGhostProps: DragTargetProps;
  isInitialized: boolean;
  dataLoadingMessage: string | null;
  dataErrorMessage: string | null;
};
