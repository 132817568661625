import {
  composeComponentFormattingGroups,
  composeReferencesFormattingGroups,
  getAddFormattingRuleButtonOptions,
} from '@ardoq/perspectives';
import {
  MultiLabelFormattingProps,
  getAddLabelButtonProps,
} from '@ardoq/perspectives-sidebar';
import {
  ViewpointFormattingState,
  ViewpointFormattingProps,
} from './viewpointFormattingTypes';
import { formattingCommands } from 'perspective/perspectiveEditor/formattingCommands';
import { disabledOptionProps } from 'viewpointBuilder/components/disabledOptionProps';
import { getFormattingForInvalidRuleByRuleId } from 'perspectiveSidebar/perspectiveEditor/getFormattingForInvalidRuleByRuleId';
import {
  getLabelFormattingElementProps,
  getFieldOptionLabel,
  getDisabledReferenceFormattingMessage,
  getRestrictedMultiLabelFormattingWarningProps,
} from 'perspectiveSidebar/perspectiveEditor/multiLabelUtils';

import { LabelFormattingInfo } from '@ardoq/data-model';
import { APIFieldAttributes, ViewIds } from '@ardoq/api-types';

const getMultiLabelProps = (
  labelFormatting: LabelFormattingInfo[],
  showReferenceType: boolean,
  componentTypeNames: string[],
  referenceTypeNames: string[],
  componentFieldsByType: Map<string, APIFieldAttributes[]>,
  referenceFieldsByType: Map<string, APIFieldAttributes[]>,
  viewName: ViewIds
): MultiLabelFormattingProps => {
  const {
    onLabelFormattingReordered,
    onShowReferenceTypeToggled,
    onUpdateLabelFormattingAt,
    onLabelFormattingDeleted,
    onLabelFormattingAdded,
  } = formattingCommands;

  const componentFieldOptionsByTypes = createFieldOptionsByTypes(
    componentTypeNames,
    componentFieldsByType
  );
  const referenceFieldOptionsByTypes = createFieldOptionsByTypes(
    referenceTypeNames,
    referenceFieldsByType
  );

  const disableReferenceFormattingMessage =
    getDisabledReferenceFormattingMessage(viewName);

  const labelFormattingElementProps = getLabelFormattingElementProps(
    labelFormatting,
    componentFieldOptionsByTypes,
    referenceFieldOptionsByTypes,
    Boolean(disableReferenceFormattingMessage)
  );

  const addLabelButtonProps = getAddLabelButtonProps({
    onLabelFormattingAdded: onLabelFormattingAdded,
    hasNoReferences: !referenceFieldOptionsByTypes.size,
    disableReferenceFormattingMessage,
  });

  const restrictedMultiLabelFormattingWarningProps =
    getRestrictedMultiLabelFormattingWarningProps({
      labelFormatting,
      viewId: viewName,
    });

  return {
    labelFormatting,
    showReferenceType,
    disableReferenceFormattingMessage,
    labelFormattingElementProps,
    addLabelButtonProps,
    restrictedMultiLabelFormattingWarningProps,
    onLabelFormattingReordered,
    onShowReferenceTypeToggled,
    onUpdateLabelFormattingAt,
    onLabelFormattingDeleted,
    onLabelFormattingAdded,
  };
};

const createFieldOptionsByTypes = (
  typeNames: string[],
  fieldsByType: Map<string, APIFieldAttributes[]>
) =>
  new Map(
    typeNames.map(typeName => [
      typeName,
      (fieldsByType.get(typeName) || []).map(field => ({
        value: field.name,
        label: getFieldOptionLabel(field),
      })),
    ])
  );

export const mapToViewpointBuilderFormattingViewState = ({
  viewName,
  formattingTab: {
    showReferenceType,
    labelFormatting,
    conditionalFormattingRules,
  },
  viewpointBuilderFormattingOptions: {
    asyncSuggestionsLoaders,
    availableComponentFields,
    availableReferenceFields,
    componentFieldsByType,
    referenceFieldsByType,
    componentTypeNames,
    referenceTypeNames,
    tagOptions,
    asyncLabelLoaders,
  },
}: ViewpointFormattingState): ViewpointFormattingProps => {
  const getRuleNotificationParams =
    // TODO: This is a temporary solution.
    // To prevent rule warning notifications from appearing when the Filters tab is updated,
    // while other tabs still do not have applied rules.
    // !shouldResetEditorStateToAppliedRules
    //  ? getFormattingRuleNotificationParamsFunction({
    //      componentFieldsFilteredByWorkspaceFilter,
    //      referenceFieldsFilteredByWorkspaceFilter,
    // })
    // :
    undefined;

  const {
    onFormattingRuleDeleted,
    onFormattingRuleReordered,
    onFormattingRuleAdded,
    onFormattingRuleUpdated,
  } = formattingCommands;

  const componentsFormattingGroups = composeComponentFormattingGroups({
    relatedFields: availableComponentFields,
    componentTypeNames: componentTypeNames,
  });

  const referencesFormattingGroups = composeReferencesFormattingGroups({
    relatedFields: availableReferenceFields,
    referenceTypeNames: referenceTypeNames,
  });

  const formattingForInvalidRuleByRuleId = getFormattingForInvalidRuleByRuleId({
    conditionalFormattingRules,
    componentsFormattingGroups,
    referencesFormattingGroups,
  });

  const getFormattingForInvalidRule = (ruleId: string) =>
    formattingForInvalidRuleByRuleId[ruleId];

  const addRuleButtonOptions = getAddFormattingRuleButtonOptions({
    onFormattingRuleAdded,
    referencesFormattingGroups,
    tagsFormattingGroups: tagOptions,
    getDisabledOptionPopoverContent:
      disabledOptionProps.getAddButtonDisabledPopoverContent,
  });

  return {
    multiLabelFormatting: getMultiLabelProps(
      labelFormatting,
      showReferenceType,
      componentTypeNames,
      referenceTypeNames,
      componentFieldsByType,
      referenceFieldsByType,
      viewName
    ),
    conditionalFormatting: {
      formattingRules: conditionalFormattingRules,
      asyncSuggestionsLoaders,
      asyncLabelLoaders,
      componentsFormattingGroups,
      referencesFormattingGroups,
      tagsFormattingGroups: tagOptions,
      getRuleNotificationParams,
      disabledOptionProps,
      getFormattingForInvalidRule,
      onFormattingRuleDeleted,
      onFormattingRuleReordered,
      onFormattingRuleUpdated,
      addRuleButtonOptions,
    },
  };
};
