import {
  action$,
  dispatchAction,
  reducer,
  reduceState,
  streamReducer,
} from '@ardoq/rxbeach';
import { map } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import {
  ViewSidePanelContentIds,
  ViewSidePanelReducerFn,
  ViewSidePanelState,
} from './types';
import { hideViewSidePanel, showViewSidePanel } from './actions';
import { isWorkspaceModule$ } from 'streams/isWorkspaceModule$';
import { trackStreamStateChange } from 'tracking/tracking';
import { openedViewpointData$ } from 'appLayout/ardoqStudio/saveAsViewpoint/saveAsViewpoint$/openedViewpointData$';
import { ExtractStreamShape } from 'tabview/types';

const viewOptionsPanelContentIds = [
  ViewSidePanelContentIds.VIEW_OPTIONS,
  ViewSidePanelContentIds.PERSPECTIVES_CONDITIONAL_FORMAT,
  ViewSidePanelContentIds.PERSPECTIVES_GROUPS,
  ViewSidePanelContentIds.PERSPECTIVES_LABELS,
];

export const isViewSettingsPanelClosed = ([prev, next]: [
  ViewSidePanelState,
  ViewSidePanelState,
]) =>
  Boolean(
    prev.contentId &&
      viewOptionsPanelContentIds.includes(prev.contentId) &&
      (!next.contentId || !viewOptionsPanelContentIds.includes(next.contentId))
  );

const showViewSidePanelReducer: ViewSidePanelReducerFn<
  ViewSidePanelContentIds
> = (state, contentId) => ({
  ...state,
  contentId,
});

const hideViewSidePanelReducer: ViewSidePanelReducerFn = state => ({
  ...state,
  contentId: null,
});

const setIsViewpointModeReducer: ViewSidePanelReducerFn<{
  isViewpointMode: boolean;
}> = (state, { isViewpointMode }) => ({
  ...state,
  isViewpointMode,
});

const selectedModuleReducer: ViewSidePanelReducerFn<boolean> = (
  state,
  isWorkspaceModule
) => ({
  ...state,
  contentId: isWorkspaceModule ? state.contentId : null,
});

const openedViewpointDataReducer: ViewSidePanelReducerFn<
  ExtractStreamShape<typeof openedViewpointData$>
> = (state, { isViewpointSavable }) => {
  if (
    state.contentId === ViewSidePanelContentIds.SAVE_VIEWPOINT &&
    !isViewpointSavable
  ) {
    return {
      ...state,
      contentId: null,
    };
  }
  return state;
};

const defaultState: ViewSidePanelState = {
  contentId: null,
  isViewpointMode: false,
};

export const viewSidePanel$ = action$.pipe(
  reduceState('viewSidePanel$', defaultState, [
    reducer(showViewSidePanel, showViewSidePanelReducer),
    reducer(hideViewSidePanel, hideViewSidePanelReducer),
    streamReducer(isViewpointMode$, setIsViewpointModeReducer),
    streamReducer(isWorkspaceModule$, selectedModuleReducer),
    streamReducer(openedViewpointData$, openedViewpointDataReducer),
  ]),
  trackStreamStateChange({
    predicate: isViewSettingsPanelClosed,
    eventName: 'View side panel closed',
  })
);

export const isViewSidePanelOpened$ = viewSidePanel$.pipe(
  map(state => Boolean(state.contentId)),
  distinctUntilChanged()
);

export const closeViewSidePanel = () => dispatchAction(hideViewSidePanel());
