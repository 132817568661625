import { ViewIds } from '@ardoq/api-types';
import { DropdownItem } from '@ardoq/dropdown-menu';
import transformComponentMenu from 'tabview/blocks/view/transformComponentMenu';

type DropdownMenuReducer = (
  result: DropdownItem[],
  current: DropdownItem
) => DropdownItem[];
const componentMenuTransforms: Partial<Record<ViewIds, DropdownMenuReducer>> = {
  [ViewIds.BLOCKS]: transformComponentMenu,
};

export default componentMenuTransforms;
