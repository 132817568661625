import { colors, r8, s8, shadowM, zIndex } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { VIEW_PANE_BAR_HEIGHT_WITH_PADDING } from './ardoqStudio/viewPaneHeader/consts';
import {
  HorizontalResizeableContainerWrapper,
  ResizableHorizontal,
} from '@ardoq/resizable';
import { ReactNode } from 'react';

const ResizableSideBarEditorContainer = styled.div<{
  $isRightEditorPaneOpen: boolean;
  $isFullHeight?: boolean;
}>`
  /* The side bar editor must always be in the DOM to work properly, no matter
  if it's displayed or not. */
  display: ${props => (props.$isRightEditorPaneOpen ? 'block' : 'none')};
  position: absolute;
  top: ${({ $isFullHeight }) =>
    $isFullHeight ? 8 : VIEW_PANE_BAR_HEIGHT_WITH_PADDING}px;
  left: ${s8};
  right: ${s8};
  bottom: ${s8};
  pointer-events: none;
  z-index: ${zIndex.VIEW_LEGEND + 1};
`;

const RightPaneContainer = styled.div`
  height: 100%;
  background-color: ${colors.white};
  border-radius: ${r8};
  box-shadow: ${shadowM};
  overflow: auto;
  pointer-events: all;
`;

export const ResizableSidebarEditorAsOverlay = ({
  isRightEditorPaneOpen,
  children,
  initialWidth,
  isFullHeight,
  minWidth,
}: {
  isRightEditorPaneOpen: boolean;
  children: ReactNode;
  initialWidth?: number;
  minWidth?: number;
  isFullHeight?: boolean;
}) => (
  <ResizableSideBarEditorContainer
    $isRightEditorPaneOpen={isRightEditorPaneOpen}
    $isFullHeight={isFullHeight}
  >
    <ResizableHorizontal>
      <HorizontalResizeableContainerWrapper />
      <HorizontalResizeableContainerWrapper
        initialWidth={initialWidth}
        minWidth={minWidth}
      >
        <RightPaneContainer>{children}</RightPaneContainer>
      </HorizontalResizeableContainerWrapper>
    </ResizableHorizontal>
  </ResizableSideBarEditorContainer>
);
