import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import * as actions from './actions';
import * as editTraversalActions from './traversals/editTraversalActions';
import {
  debounceTime,
  map,
  switchAll,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { loadedState$ } from 'loadedState/loadedState$';
import {
  APITagAttributes,
  CreateViewpointResponse,
  isStartQueryTraversal,
  isTraversalLoadedState,
  LoadedStateHash,
  StartContextSelectionType,
  StartQueryTraversalParams,
  StartSetTraversalParams,
  TraversalCreatedInViewLoadedState,
  TraversalLoadedState,
  ViewIds,
} from '@ardoq/api-types';
import { traversalInterface } from 'modelInterface/traversals/traversalInterface';
import { showToast, ToastType } from '@ardoq/status-ui';
import { loadedStateOperations } from 'loadedState/loadedStateOperations';
import { ViewpointBuilderContext } from './types';
import * as viewpointBuilderGroupingActions from './addGroupingRulesTab/viewpointGroupingRulesActions';
import { setGroupingOptions } from './addGroupingRulesTab/viewpointGroupingRulesActions';
import * as viewpointBuilderFormattingActions from './formattingTabs/viewpointBuilderFormattingActions';
import { GetFormattingOptionsPayload } from './formattingTabs/viewpointBuilderFormattingActions';
import { getViewpointComponentSuggestionLoaders } from './formattingTabs/getFormattingSuggestionLoader';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import {
  fetchTags,
  notifyFetchingTagsFailed,
  notifyFetchingTagsSucceeded,
} from 'streams/tags/actions';
import { viewpointBuilderFormattingCommands } from './formattingTabs/viewpointBuilderFormattingCommands';
import getFilterTabProps from 'perspective/perspectiveEditor/getFilterTabProps';
import { ViewpointFormattingState } from './formattingTabs/viewpointFormattingTypes';
import { Features, hasFeature } from '@ardoq/features';
import { openViewpointBuilder } from './openViewpointBuilder/openViewpointBuilder';
import { ArdoqError, ExcludeFalsy } from '@ardoq/common-helpers';
import { openTraversalInVisualization } from './openTraversalInVisualization';
import { prototypeInterface } from 'modelInterface/prototype/prototypeInterface';
import { ComponentSearchData } from 'viewpointBuilder/selectContextTab/types';
import { getStartTypeAndTermFromQuery } from './selectContextTab/componentSearchRoutines';
import currentViewId$ from 'streams/currentViewId$';
import { isViewpointMode$ } from 'traversals/loadedGraph$';
import { toGroupingOptionsForTraversals } from 'streams/perspectiveEditorData/perspectiveEditorGroupingOptions$';
import { getViewpointFormattingOptionsSourceData } from 'streams/perspectiveEditorData/perspectivesRelatedData$';
import { context$ } from '../streams/context/context$';
import { exitTraversalMode } from 'traversals/exitTraversalMode';
import { api, handleError, traversalApi } from '@ardoq/api';
import { componentSearchInterface } from './selectContextTab/componentSearchInterface';
import { getSupportedTraversalViewIdOrDefault } from '../traversals/getViewpointModeSupportedViews';
import { addPermissionForResource } from 'streams/currentUserPermissions/actions';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { confirmClosingPreviousViewIfOpen } from './utils';
import { logError } from '@ardoq/logging';
import { alert } from '@ardoq/modal';
import {
  consolidateOperatorsForComponentSelection,
  consolidateLegacyOperatorsForFilters,
} from './consolidateLegacyFilterOperators';

const openTraversalDialogAndCloseLoadedViewpointsRoutine = routine(
  ofType(actions.openViewpointBuilderWithComponentsAndCloseLoadedViewpoints),
  extractPayload(),
  withLatestFrom(loadedState$, context$),
  tap(async ([{ componentIds, components }, loadedStates, context]) => {
    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      const closingConfirmed = await confirmClosingPreviousViewIfOpen(
        loadedStates,
        context,
        'openNewDataset'
      );
      if (closingConfirmed) {
        exitTraversalMode();

        const { loadedComponents, startType } =
          await getComponentSearchDataAndType(componentIds, components);

        openViewpointBuilder({
          activeTab: 'SUBGRAPH_TAB',
          context: 'contextMenu',
          initialConfiguration: {
            loadedComponents,
            componentSelection: {
              startSet: componentIds,
              startContextSelectionType:
                StartContextSelectionType.MANUAL_SELECTION,
            },
            startType,
          },
        });
      }
    }
  })
);

const openTraversalDialogRoutine = routine(
  ofType(actions.openViewpointBuilderWithComponents),
  extractPayload(),
  tap(async ({ componentIds, components }) => {
    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      const { loadedComponents, startType } =
        await getComponentSearchDataAndType(componentIds, components);

      openViewpointBuilder({
        activeTab: 'SUBGRAPH_TAB',
        context: 'contextMenu',
        initialConfiguration: {
          loadedComponents,
          componentSelection: {
            startSet: componentIds,
            startContextSelectionType:
              StartContextSelectionType.MANUAL_SELECTION,
          },
          startType,
        },
      });
    }
  })
);

const getComponentSearchDataAndType = async (
  componentIds: string[],
  components: ComponentSearchData[] | undefined
) => {
  if (componentIds.length === 0) {
    return {
      loadedComponents: [],
      startType: undefined,
    };
  }

  if (
    components &&
    components.length > 0 &&
    componentIds.every(id => components.some(c => c.id === id))
  ) {
    return {
      loadedComponents: components,
      startType: components[0].entityType,
    };
  }

  const { results: loadedComponents } =
    await prototypeInterface.loadComponentsByIdFromAdvancedSearch(componentIds);

  return {
    loadedComponents,
    startType:
      loadedComponents.length > 0 ? loadedComponents[0].entityType : undefined,
  };
};

const requestFetchInstanceCountsRoutine = routine(
  ofType(editTraversalActions.requestFetchInstanceCounts),
  extractPayload(),
  map(traversalApi.countInstances),
  switchAll(),
  tap(response => api.logErrorIfNeeded(response)),
  tap(result =>
    dispatchAction(editTraversalActions.responseInstanceCounts(result))
  )
);

const errorHandlerUpdateViewpoint = (error: ArdoqError) => {
  alert({
    title: 'Error',
    subtitle: 'Update failed',
    text: `Failed to update viewpoint: ${error.userMessage}. Please contact support.`,
  });
  logError(error);
};

const updateTraversalRoutine = routine(
  ofType(actions.updateViewpoint),
  extractPayload(),
  switchMap(traversalApi.update),
  handleError(errorHandlerUpdateViewpoint),
  tap(traversal => {
    showToast(`Viewpoint "${traversal.name}" updated.`, ToastType.SUCCESS);
    dispatchAction(actions.updateViewpointSuccess());
  })
);

const handleCreateViewpointSuccess = ({
  permission,
  traversal,
}: CreateViewpointResponse) => {
  dispatchAction(
    addPermissionForResource({
      permission,
      currentUser: currentUserInterface.getCurrentUserAttributes(),
    })
  );
  showToast(`Viewpoint "${traversal.name}" created.`, ToastType.SUCCESS);
  dispatchAction(actions.createViewpointSuccess(traversal._id));
};

const errorHandlerCreateViewpoint = (error: ArdoqError) => {
  alert({
    title: 'Error',
    subtitle: 'Save failed',
    text: `Failed to create viewpoint: ${error.userMessage}. Please contact support.`,
  });
  logError(error);
};

const createTraversalRoutine = routine(
  ofType(actions.createViewpoint),
  extractPayload(),
  switchMap(traversalApi.createTraversal),
  handleError(errorHandlerCreateViewpoint),
  tap(handleCreateViewpointSuccess)
);

const createTraversalInFolderRoutine = routine(
  ofType(actions.createViewpointInFolder),
  extractPayload(),
  switchMap(({ folderId, ...attributes }) =>
    traversalApi.createInFolder(attributes, folderId)
  ),
  handleError(errorHandlerCreateViewpoint),
  tap(handleCreateViewpointSuccess)
);

const openViewpointRoutine = routine(
  ofType(actions.openViewpoint),
  extractPayload(),
  withLatestFrom(loadedState$, context$),
  tap(
    async ([{ viewpointId, components }, loadedStates, workspacesContext]) => {
      const closingConfirmed = await confirmClosingPreviousViewIfOpen(
        loadedStates,
        workspacesContext,
        'openSavedViewpoint'
      );
      if (!closingConfirmed) {
        return;
      }
      exitTraversalMode();

      const viewpoint = traversalInterface.getById(viewpointId);
      if (!viewpoint) return;
      const {
        viewName,
        name,
        paths,
        filters,
        groupBys,
        conditionalFormatting,
        pathMatching,
        pathCollapsingRules,
        _version,
      } = viewpoint;

      openViewpointBuilder({
        activeTab: 'SELECT_CONTEXT_COMPONENT_INSTANCES_TAB',
        context: 'openViewpoint',
        initialConfiguration: {
          viewName,
          name,
          initialPaths: paths,
          filters,
          groupBys,
          conditionalFormatting,
          viewpointId,
          viewpointVersion: _version,
          componentSelection: {
            startSet: components?.map(({ id }) => id) ?? [],
            startContextSelectionType:
              StartContextSelectionType.MANUAL_SELECTION,
          },
          loadedComponents: components ?? [],
          pathMatching,
          pathCollapsingRules,
        },
      });
    }
  )
);

type EditTraversalArgs = {
  loadedStateHash: LoadedStateHash;
  context: ViewpointBuilderContext | undefined;
  viewpointId?: string;
  viewpointVersion?: number;
  isMainLoadedState?: boolean;
};

type EditStartSetTraversalArgs = EditTraversalArgs & {
  traversal: StartSetTraversalParams;
};

type EditStarQueryTraversalArgs = EditTraversalArgs & {
  traversal: StartQueryTraversalParams;
  viewName?: ViewIds;
};

const getEditStartSetTraversalConfiguration = async ({
  loadedStateHash,
  context,
  traversal,
  viewpointId,
  isMainLoadedState,
}: EditStartSetTraversalArgs) => {
  const { paths, filters, componentSelection, pathCollapsingRules } = traversal;
  const loadedComponents = componentSelection.startSet
    .map(componentSearchInterface.buildSearchDataFromComponentId)
    .filter(ExcludeFalsy);
  let initialComponents = loadedComponents;
  if (
    loadedComponents.length !== componentSelection.startSet.length &&
    context === 'editSubgraph'
  ) {
    // If all the start context components aren't loaded when editing a dataset,
    // likely due to required paths and/or the context switcher having been used,
    // we need to load them first.
    const { results: searchResults } =
      await prototypeInterface.loadComponentsByIdFromAdvancedSearch(
        componentSelection.startSet
      );
    initialComponents = searchResults;
  }

  return {
    loadedComponents: initialComponents,
    componentSelection,
    initialPaths: paths,
    loadedStateHash,
    filters: consolidateLegacyOperatorsForFilters(filters),
    pathMatching: traversal.pathMatching,
    pathCollapsingRules,
    ...getPartialConfigWithViewpointIdAndMainLoadedState(
      viewpointId,
      isMainLoadedState
    ),
  };
};

const getEditStartQueryTraversalConfiguration = async ({
  loadedStateHash,
  traversal,
  viewpointId,
  viewpointVersion,
  viewName,
  isMainLoadedState,
}: EditStarQueryTraversalArgs) => {
  const { paths, filters, componentSelection, pathCollapsingRules } = traversal;
  const { term: startSearchTerm, type: startType } =
    getStartTypeAndTermFromQuery(traversal.componentSelection.startQuery);
  return {
    ...{
      initialPaths: paths,
      loadedStateHash,
      filters: consolidateLegacyOperatorsForFilters(filters),
      startType,
      startSearchTerm,
      componentSelection:
        consolidateOperatorsForComponentSelection(componentSelection),
      viewpointVersion,
      viewName,
      pathMatching: traversal.pathMatching,
      pathCollapsingRules,
      isMainLoadedState,
    },
    ...getPartialConfigWithViewpointIdAndMainLoadedState(
      viewpointId,
      isMainLoadedState
    ),
  };
};

const getPartialConfigWithViewpointIdAndMainLoadedState = (
  viewpointId?: string,
  isMainLoadedState?: boolean
) => ({
  ...(viewpointId
    ? {
        viewpointId,
      }
    : {}),
  ...(isMainLoadedState ? { isMainLoadedState, loadedStateHash: '' } : {}),
});

export type GetInitialConfigurationArgs = {
  loadedTraversal: TraversalLoadedState | TraversalCreatedInViewLoadedState;
  context: ViewpointBuilderContext | undefined;
  currentViewId?: ViewIds;
  isMainLoadedState?: boolean;
};

export const getInitialConfiguration = ({
  loadedTraversal,
  context,
  currentViewId,
  isMainLoadedState = false,
}: GetInitialConfigurationArgs) => {
  const loadedStateHash = loadedStateOperations.stateToHash(loadedTraversal);
  const viewpointId = isTraversalLoadedState(loadedTraversal)
    ? loadedTraversal.traversalId
    : undefined;
  if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
    if (isStartQueryTraversal(loadedTraversal.data)) {
      return getEditStartQueryTraversalConfiguration({
        loadedStateHash,
        context,
        traversal: loadedTraversal.data,
        viewpointId,
        viewName: currentViewId,
        isMainLoadedState,
      });
    }
    return getEditStartSetTraversalConfiguration({
      loadedStateHash,
      context,
      traversal: loadedTraversal.data,
      viewpointId,
      isMainLoadedState,
    });
  }
  return Promise.resolve(undefined);
};

const editTraversalRoutine = routine(
  ofType(actions.editViewpoint),
  extractPayload(),
  withLatestFrom(loadedState$, currentViewId$),
  tap(async ([{ loadedStateHash, context }, loadedState, currentViewId]) => {
    const loadedTraversal =
      loadedStateOperations.getTraversalWithLoadedStateHash(
        loadedState,
        loadedStateHash
      );

    if (loadedTraversal) {
      const initialConfiguration = await getInitialConfiguration({
        loadedTraversal,
        context,
        currentViewId,
      });

      if (!initialConfiguration) {
        return;
      }

      openViewpointBuilder({
        activeTab: 'SUBGRAPH_TAB',
        context,
        initialConfiguration,
      });
    }
  })
);

const executeViewpointRoutine = routine(
  ofType(actions.executeViewpoint),
  extractPayload(),
  withLatestFrom(currentViewId$, isViewpointMode$, loadedState$),
  tap(([payload, currentViewId, { isViewpointMode }, loadedState]) => {
    const viewDefinedInViewpointOrDefault =
      getSupportedTraversalViewIdOrDefault(payload.viewName);

    // to prevent changing the View when adding datasets
    const viewName =
      isViewpointMode && currentViewId && loadedState.length > 0
        ? currentViewId
        : viewDefinedInViewpointOrDefault;

    openTraversalInVisualization({ ...payload, viewName });
  })
);

const getAllTags = async () => {
  const { payload: fetchTagsResponse } = await dispatchActionAndWaitForResponse(
    fetchTags(),
    notifyFetchingTagsSucceeded,
    notifyFetchingTagsFailed
  );
  return 'error' in fetchTagsResponse ? [] : fetchTagsResponse;
};

const getGroupingOptions = routine(
  ofType(viewpointBuilderGroupingActions.getGroupingOptions),
  extractPayload(),
  tap(async payload => {
    const tags = await getAllTags();
    const groupingOptions = toGroupingOptionsForTraversals({
      ...payload,
      tags,
    });
    dispatchAction(setGroupingOptions(groupingOptions));
  })
);

const getFormattingOptionsContext = (
  payload: GetFormattingOptionsPayload,
  tags: APITagAttributes[]
): ViewpointFormattingState['viewpointBuilderFormattingOptions'] => {
  const viewpointFormattingOptionsSourceData =
    getViewpointFormattingOptionsSourceData({ ...payload, tags });

  const organizationId = currentUserInterface.getCurrentOrgId();

  const {
    tagOptions,
    asyncLabelLoaders,
    asyncSuggestionsLoaders: asyncSuggestionsLoadersFromAvailableData,
  } = getFilterTabProps({
    relatedComponents: [],
    relatedReferences: [],
    organizationId: organizationId,
    currentWorkspaceId: null,
    isScenarioMode: false,
    ...viewpointFormattingOptionsSourceData,
  });

  const componentFormattingCustomLabels = payload.availableComponentFields.map(
    ({ label, name }) => ({ label, value: name })
  );

  const referenceFormattingCustomLabels = payload.availableReferenceFields.map(
    ({ label, name }) => ({ label, value: name })
  );

  const { relatedTags, relatedWorkspaces } =
    viewpointFormattingOptionsSourceData;

  return {
    ...payload,
    availableWorkspaces: relatedWorkspaces,
    organizationId: currentUserInterface.getCurrentOrgId(),
    availableTags: relatedTags,
    componentFormattingCustomLabels,
    referenceFormattingCustomLabels,
    tagOptions,
    asyncLabelLoaders,
    asyncSuggestionsLoaders: {
      ...asyncSuggestionsLoadersFromAvailableData,
      ...getViewpointComponentSuggestionLoaders(
        payload.workspaceIds,
        payload.componentTypeNames
      ),
    },
  };
};

const getFormattingOptions = routine(
  ofType(viewpointBuilderFormattingActions.getFormattingOptions),
  extractPayload(),
  tap(async payload => {
    const tags = await getAllTags();
    viewpointBuilderFormattingCommands.setFormattingOptions({
      viewpointBuilderFormattingOptions: getFormattingOptionsContext(
        payload,
        tags
      ),
    });
  })
);

const fetchOptionInstanceCounts = routine(
  ofType(editTraversalActions.requestFetchOptionsInstanceCounts),
  extractPayload(),
  // It's safe to debounce here since the last request would always overwrite
  // the result of the previous one.
  debounceTime(32),
  map(traversalApi.countInstances),
  switchAll(),
  tap(response => api.logErrorIfNeeded(response)),
  tap(result =>
    dispatchAction(editTraversalActions.responseOptionsInstanceCounts(result))
  )
);

const openViewpointBuilderAndCloseLoadedViewpointsRoutine = routine(
  ofType(actions.openViewpointBuilderAndCloseLoadedViewpoints),
  withLatestFrom(loadedState$, context$),
  tap(async ([, loadedStates, context]) => {
    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      const closingConfirmed = await confirmClosingPreviousViewIfOpen(
        loadedStates,
        context,
        'openNewDataset'
      );
      if (closingConfirmed) {
        exitTraversalMode();
        openViewpointBuilder({
          context: 'loadNewViewpoint',
        });
      }
    }
  })
);

const openViewpointBuilderWithoutClosingLoadedViewpointsRoutine = routine(
  ofType(actions.openViewpointBuilderWithoutClosingLoadedViewpoints),
  tap(async () => {
    if (hasFeature(Features.SUPPORT_LARGE_DATASETS)) {
      openViewpointBuilder({
        context: 'loadNewViewpoint',
      });
    }
  })
);

export const viewpointBuilderRoutines = collectRoutines(
  openTraversalDialogAndCloseLoadedViewpointsRoutine,
  openTraversalDialogRoutine,
  updateTraversalRoutine,
  createTraversalRoutine,
  createTraversalInFolderRoutine,
  editTraversalRoutine,
  requestFetchInstanceCountsRoutine,
  executeViewpointRoutine,
  getGroupingOptions,
  getFormattingOptions,
  fetchOptionInstanceCounts,
  openViewpointRoutine,
  openViewpointBuilderAndCloseLoadedViewpointsRoutine,
  openViewpointBuilderWithoutClosingLoadedViewpointsRoutine
);
