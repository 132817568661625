import { SecondaryButton, PrimaryButton } from '@ardoq/button';
import { FlexBox, Stack } from '@ardoq/layout';
import { StatusType, SuccessNotification } from '@ardoq/status-ui';
import { TabsGroup, TabButton } from '@ardoq/tabs';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { VisualComponentsMappings } from './VisualComponentsMappings';
import { VisualReferencesMappings } from './VisualReferencesMappings';
import { VisualMapperProps } from './types';
import { popoverRegistry, SimpleTextPopover } from '@ardoq/popovers';

const LUCID_VISUAL_IMPORT_UNAVAILABLE = 'lucid-import-unavailable';
popoverRegistry.set(LUCID_VISUAL_IMPORT_UNAVAILABLE, () => (
  <SimpleTextPopover
    text={'Import is unavailable until the warnings has been fixed.'}
  />
));

export const VisualMappings = ({
  mappingsStatus,
  mappingsComponents,
  mappingsReferences,
  mappingsErrors,
  componentTypeOptions,
  referenceTypeOptions,
  componentsOptions,
  onAnalyze,
  onImport,
  onComponentMapping,
  onComponentTypeChange,
  onAddNewComponentMapping,
  onDeleteComponentMapping,
  onReferenceMapping,
  onAddNewReferenceMapping,
  onDeleteReferenceMapping,
}: VisualMapperProps) => {
  const componentTabProps =
    mappingsErrors.hasErrors && mappingsErrors.components.length
      ? {
          tagStatusType: StatusType.WARNING,
          tag: `${mappingsErrors.components.length}`,
        }
      : {};

  const referenceTabProps =
    mappingsErrors.hasErrors && mappingsErrors.references.length
      ? {
          tagStatusType: StatusType.WARNING,
          tag: `${mappingsErrors.references.length}`,
        }
      : {};

  return (
    <FlexBox flexDirection="column" gap="medium" width="full">
      <TabsGroup defaultActiveTabId="components">
        <TabButton label="Components" tabId="components" {...componentTabProps}>
          <VisualComponentsMappings
            mappingsStatus={mappingsStatus}
            componentTypeOptions={componentTypeOptions}
            components={mappingsComponents}
            onComponentMapping={onComponentMapping}
            onComponentTypeChange={onComponentTypeChange}
            onAddNewComponentMapping={onAddNewComponentMapping}
            onDeleteComponentMapping={onDeleteComponentMapping}
          />
        </TabButton>
        <TabButton label="References" tabId="references" {...referenceTabProps}>
          <VisualReferencesMappings
            mappingsStatus={mappingsStatus}
            references={mappingsReferences}
            componentsOptions={componentsOptions}
            referenceTypeOptions={referenceTypeOptions}
            onReferenceMapping={onReferenceMapping}
            onAddNewReferenceMapping={onAddNewReferenceMapping}
            onDeleteReferenceMapping={onDeleteReferenceMapping}
          />
        </TabButton>
      </TabsGroup>
      <Stack gap="small">
        {mappingsStatus === ASYNC_STATUS.SUCCESS && (
          <SuccessNotification>Imported data successfully.</SuccessNotification>
        )}
        <FlexBox flex={1} gap="medium" justify="end">
          <SecondaryButton onClick={onAnalyze}>Analyze again</SecondaryButton>
          {mappingsStatus !== ASYNC_STATUS.SUCCESS && (
            <PrimaryButton
              isDisabled={mappingsErrors.hasErrors}
              onClick={onImport}
              popoverId={
                mappingsErrors.hasErrors
                  ? LUCID_VISUAL_IMPORT_UNAVAILABLE
                  : undefined
              }
            >
              Import to Ardoq
            </PrimaryButton>
          )}
        </FlexBox>
      </Stack>
    </FlexBox>
  );
};
