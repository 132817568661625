import { APIModelAttributes, Workspace } from '@ardoq/api-types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { WorkspacesMetadata } from './types';

export const getWorkspacesMetadata = (
  workspaceById: Record<string, Workspace>,
  modelById: Record<string, APIModelAttributes>,
  workspaceIds: string[]
): WorkspacesMetadata => {
  return workspaceIds
    .map(workspaceId => workspaceById[workspaceId])
    .filter(ExcludeFalsy)
    .map(ws => ({
      workspace: ws,
      model: modelById[ws.componentModel],
    }));
};
