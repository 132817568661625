import { useState } from 'react';
import { modal, ModalLayout, ModalSize, ModalTemplate } from '@ardoq/modal';
import { FlexBox, Stack } from '@ardoq/layout';
import { WorkspacesSelector } from 'integrations/common/components/workspacesSelector';
import styled from 'styled-components';
import { Header1, Text } from '@ardoq/typography';
import { DoqFace, DoqSize, DoqType } from '@ardoq/doq';
import { openWorkspaces } from 'appContainer/DashboardContainer/utils';

const Workspaces = styled(WorkspacesSelector)`
  width: 450px;
`;

type AffectedWorkspace = {
  id: string;
  name: string;
};

type AffectedWorkspacesProps = {
  affectedWorkspaces: AffectedWorkspace[];
  onCancel: VoidFunction;
  onSubmit: (workspacesIds: string[]) => void;
};

const AffectedWorkspaces = ({
  affectedWorkspaces,
  onSubmit,
  onCancel,
}: AffectedWorkspacesProps) => {
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<string[]>(
    affectedWorkspaces.map(workspace => workspace.id)
  );

  return (
    <ModalTemplate
      modalSize={ModalSize.M}
      headerText="Open affected workspaces?"
      primaryButtonText="Open workspaces"
      secondaryButtonText="Cancel"
      onPrimaryButtonClick={() => {
        onSubmit(selectedWorkspaces);
      }}
      onSecondaryButtonClick={onCancel}
    >
      <ModalLayout>
        <FlexBox gap="xlarge" padding="xlarge">
          <DoqFace doqSize={DoqSize.SMALL} doqType={DoqType.IMPORT_SUCCESS} />
          <Stack gap="small">
            <Header1>Data is imported!</Header1>
            <Text variant="text2">Select the affected workspaces to open</Text>
            <Workspaces
              title="Workspaces"
              selectedWorkspaces={selectedWorkspaces}
              onSelectionChange={setSelectedWorkspaces}
              workspaces={affectedWorkspaces}
            />
          </Stack>
        </FlexBox>
      </ModalLayout>
    </ModalTemplate>
  );
};

export const showAffectedWorkspaces = (
  affectedWorkspaces: AffectedWorkspace[]
) => {
  return modal<boolean>(
    resolve => (
      <AffectedWorkspaces
        onSubmit={wss => {
          resolve(true);
          openWorkspaces(wss);
        }}
        onCancel={() => resolve(false)}
        affectedWorkspaces={affectedWorkspaces}
      />
    ),
    {
      autoFocus: true,
      shouldCloseOnEscapeKey: true,
      shouldCloseOnBackdropClick: false,
    }
  );
};
