import {
  APICountInstancesResponse,
  APICurrentUser,
  DirectedTriple,
  DirectedTripleWithFilters,
  DirectedTripleWithFiltersWithGraphIds,
  PathCollapsingRule,
  PathCollapsingRuleNonNullablePath,
  PersonalSetting,
  ReferenceDirection,
  TraversalPathMatchingType,
} from '@ardoq/api-types';
import {
  GraphId,
  InstanceCounts,
  PathCollapsingRuleInternal,
  SelectedGraph,
  SelectedGraphModelMap,
  TraversalBuilderAsSavableAttributes,
  TraversalBuilderFiltersPerNode,
  TraversalBuilderState,
  TraversalBuilderViewState,
  TripleOption,
  TripleOptions,
  VirtualEdgeState,
} from '../types';
import { PARENT_REF_TYPE_NAME } from '../utils';
import { SimpleReferenceModel } from '@ardoq/data-model';
import {
  GraphEdge,
  GraphEdgeWithMetaData,
  GraphNode,
  GraphNodeWithMetaData,
} from '@ardoq/graph';
import { LoadMetaModelAsScopedDataState } from 'viewpointBuilder/metaModel/loadMetaModelTypes';
import {
  SetSectionFilterPayload,
  SetStartTypeAndTraversalStartSetOrCount,
  SetTraversalsPayload,
  SetTripleSortOrderPayload,
  ToggleIsSelectedGraphEdgePayload,
  ToggleIsSelectedGraphNodePayload,
  ToggleTripleOptionPayload,
  UpdateTraversalStartContextPayload,
} from './editTraversalActions';
import { getId } from '../getId';
import { logError } from '@ardoq/logging';
import { enhancedScopeDataOperations } from '../enhancedScopeData/enhancedScopeDataOperations';
import { pathToKey } from './pathToKey';
import { pathsToGraph } from './pathsToGraph';
import { getTripleId } from './getTripleId';
import { toConnectedSubgraph } from './toConnectedSubgraph';
import { updateSelectedGraph } from './updateSelectedGraph';
import { createOptions, sortByKey } from './createOptions';
import { getEmptySelectedNodeData } from './getEmptySelectedNodeData';
import {
  addNodeAndEdge,
  getGraphEdgeToReferenceMapEntry,
} from './addNodeAndEdge';
import {
  removeEdgesForTriples,
  removeEdgesForGraphNodeId,
} from './removeEdges';
import { countBy, has, last, pick, uniq } from 'lodash';
import { viewpointBuilderFiltersOperations } from './viewpointBuilderFiltersOperations';
import { namedDirectedTripleToBackendFormat } from './namedDirectedTripleToBackendFormat';
import { getWorkspaceIdsAndTypeNamesFromTraversalPaths } from 'streams/perspectiveEditorData/getWorkspaceIdsAndTypeNamesFromTraversalPaths';
import {
  ExcludeFalsy,
  filterObject,
  isArdoqError,
  partialPayload,
  pipeReducers,
  Result,
} from '@ardoq/common-helpers';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { getPathsWithDependencies } from './getPathsWithDependencies';
import { getMatchingPaths, processMatches } from 'traversals/pathAndGraphUtils';
import { EditablePathCollapsingRule } from 'viewpointBuilder/collapsePathsTab/types';
import { collapsedPathToHash } from 'viewpointBuilder/collapsePathsTab/collapsedPathToHash';

const initialTraversalEditState: TraversalBuilderState = {
  enhancedScopeData: null,
  filters: {},
  baseGraph: {
    sourceMap: new Map(),
    targetMap: new Map(),
    referenceMap: new Map(),
  },
  triples: new Map(),
  selectedGraph: {
    sourceMap: new Map(),
    targetMap: new Map(),
    referenceMap: new Map(),
  },
  startComponentType: null,
  startComponentTypeId: null,
  startNode: null,
  // Maps
  rawGraphNodes: new Map(),
  rawGraphEdges: new Map(),
  graphNodes: new Map(),
  graphEdges: new Map(),
  graphGroups: new Map(),
  instanceCounts: new Map(),
  selectedGraphNodeId: null,
  selectedGraphEdgeId: null,
  tripleOptions: {
    incoming: [],
    outgoing: [],
    selectedNode: getEmptySelectedNodeData(),
    fetchCountsArgs: null,
    optionCountLoadingState: 'idle',
    showOnlyOptionsWithInstanceCounts: true,
    instanceCountsResponse: null,
  },
  hasTripleOptions: {
    outgoingReferences: false,
    incomingReferences: false,
  },
  filteredTripleOptions: {
    incoming: [],
    outgoing: [],
    selectedNode: getEmptySelectedNodeData(),
    fetchCountsArgs: null,
    optionCountLoadingState: 'idle',
    showOnlyOptionsWithInstanceCounts: true,
    instanceCountsResponse: null,
  },
  errorState: 'NONE',
  initialPaths: null,
  traversalStartSet: null,
  traversalStartQuery: null,
  fieldMap: new Map(),
  componentNameAndFieldsIndex: new Map(),
  referenceNameAndFieldsIndex: new Map(),
  componentNameAndWorkspacesIndex: new Map(),
  triplesSortAndFiltersState: {
    filterTerm: '',
    showOnlyOptionsWithInstanceCounts: true,
    tripleSortOrder: 'reference_type_a_z',
  },
  initialFilters: null,
  shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings: true,
  isMetaModelLoaded: false,
  referenceTypes: [],
  pathMatching: TraversalPathMatchingType.LOOSE,
  initialPathCollapsingRules: null,
  pathCollapsing: {
    rules: [],
    currentEditableRule: null,
  },
  shouldIncludeInstanceCounts: true,
  requiredNodeIds: [],
  highlightedPaths: {},
};

const getInitialTraversalEditState = (): TraversalBuilderState =>
  structuredClone(initialTraversalEditState);

export type GetTripleId = {
  sourceId: string;
  targetId: string;
  referenceId: string;
};

const getTriples = (referenceMap: Map<string, SimpleReferenceModel>) =>
  new Map(
    Array.from(referenceMap).map(([referenceId, { sourceId, targetId }]) => [
      getTripleId({ referenceId, sourceId, targetId }),
      { sourceId, targetId, referenceId },
    ])
  );

const getTriplesOptions = (
  state: TraversalBuilderState,
  { isFetchCounts = true } = {}
): TripleOptions => {
  const {
    selectedGraphNodeId,
    selectedGraphEdgeId,
    rawGraphEdges,
    baseGraph,
    graphNodes,
    graphEdges,
    tripleOptions: { showOnlyOptionsWithInstanceCounts },
  } = state;
  if (selectedGraphEdgeId) {
    const edgeModelId = graphEdges.get(selectedGraphEdgeId)!.modelId;
    return {
      outgoing: [],
      incoming: [],
      instanceCountsResponse: null,
      selectedNode: getEmptySelectedNodeData(),
      selectedEdge: {
        metaDataComponent: rawGraphEdges.get(edgeModelId)!.metaData,
      },
      fetchCountsArgs: null,
      optionCountLoadingState: 'idle',
      showOnlyOptionsWithInstanceCounts,
    };
  }

  if (!selectedGraphNodeId)
    return {
      outgoing: [],
      incoming: [],
      instanceCountsResponse: null,
      selectedNode: getEmptySelectedNodeData(),
      fetchCountsArgs: null,
      optionCountLoadingState: 'idle',
      showOnlyOptionsWithInstanceCounts,
    };
  const graphNode = graphNodes.get(selectedGraphNodeId)!;
  const id = graphNode.modelId;
  const outgoingEdges = (baseGraph.sourceMap.get(id) ?? []).map(
    ({ referenceId, componentId }) => ({
      sourceId: id,
      targetId: componentId,
      referenceId,
    })
  );
  const incomingEdges = (baseGraph.targetMap.get(id) ?? []).map(
    ({ referenceId, componentId }) => ({
      targetId: id,
      sourceId: componentId,
      referenceId,
    })
  );

  const referenceIdsToStartContext = new Set(
    getReferenceIdsBetweenStartAndSelectedContext({
      ...state.selectedGraph,
      currentContextId: state.startNode!.id,
      targetContextId: selectedGraphNodeId,
    })
  );

  const triplePathForSelectedNode = getTraversals(state, selectedGraphNodeId);
  const filters =
    viewpointBuilderFiltersOperations.mapFiltersToBackendFormat(state);

  return createOptions({
    state,
    graphNode,
    outgoingEdges,
    incomingEdges,
    triplePathForSelectedNode,
    filters,
    shouldFetchCounts: isFetchCounts,
    startSetFilterId: getStartSetFilterId(state),
    referenceIdsToStartContext,
  });
};

const setTriplesOptions = (
  state: TraversalBuilderState,
  instanceCountsResponse: APICountInstancesResponse | null = null
): TraversalBuilderState => {
  const tripleOptions = getTriplesOptions(state, {
    isFetchCounts: !instanceCountsResponse,
  });

  const stateWithTripleOptionsWithInstanceCounts = setOptionsInstanceCounts(
    {
      ...state,
      tripleOptions,
    },
    instanceCountsResponse
  );

  const filteredAndSortedTripleOptions =
    tripleOptionToFilteredAndSortedTripleOptions(
      stateWithTripleOptionsWithInstanceCounts.tripleOptions,
      state.triplesSortAndFiltersState
    );

  return {
    ...stateWithTripleOptionsWithInstanceCounts,
    hasTripleOptions: getHasTripleOptions(
      tripleOptions,
      state.triplesSortAndFiltersState
    ),
    filteredTripleOptions: filteredAndSortedTripleOptions,
  };
};

/**
 *
 * This operation clears the graph if the provided start type is different to
 * the current one.
 */
const setStartTypeAndTraversalStartSetOrCount = (
  state: TraversalBuilderState,
  {
    startType,
    traversalStartSet = [],
    traversalStartQuery = null,
    traversalStartQueryResultCount,
  }: SetStartTypeAndTraversalStartSetOrCount
): TraversalBuilderState => {
  const { enhancedScopeData, rawGraphNodes } = state;
  if (!enhancedScopeData) {
    return {
      ...state,
      startComponentType: startType,
      traversalStartQuery,
      traversalStartSet,
    };
  }

  if (
    startType &&
    startType === state.startComponentType &&
    state.startNode &&
    traversalStartSet
  ) {
    const newState = { ...state, traversalStartSet, traversalStartQuery };
    // When opening the viewpoint builder the options are potentially set
    // before the start set or query are set. If that happens we should make
    // sure here that we request the instance counts (which `setTriplesOptions`
    // will take care of).
    if (!state.tripleOptions.fetchCountsArgs) {
      return setTriplesOptions(newState);
    }
    return newState;
  }

  const startTypeComponent = enhancedScopeData.components.find(
    ({ name }) => name === startType
  );
  if (!startTypeComponent) return state;
  const id = startTypeComponent._id;
  const { metaData } = rawGraphNodes.get(id)!;
  const counts =
    traversalStartSet.length > 0
      ? traversalStartSet.length
      : (traversalStartQueryResultCount ?? undefined);
  const startNode = GraphNode.createWithMetaData(id, false, getId(), {
    ...metaData,
    isContext: true,
    shouldDisplayClickOtherComponentsHint: false,
    labelWithCount: counts !== undefined ? `${metaData.label} (${counts})` : '',
  });
  startNode.setIsSelected(true);
  const instanceCounts: TraversalBuilderState['instanceCounts'] = new Map([
    [
      startNode.id,
      {
        namedDirectedTriplePath: [],
        graphNodeId: startNode.id,
        pathKey: pathToKey([]),
        label: startNode.getLabel(),
        counts,
      },
    ],
  ]);

  const selectedGraph = {
    sourceMap: new Map([[startNode.id, []]]),
    targetMap: new Map(),
    referenceMap: new Map(),
  };
  const graphNodes = new Map([[startNode.id, startNode]]);
  const graphEdges = new Map();

  return setTriplesOptions({
    ...state,
    startComponentType: startType,
    startComponentTypeId: id,
    selectedGraph,
    startNode,
    graphNodes,
    graphEdges,
    selectedGraphNodeId: startNode.id,
    traversalStartSet,
    traversalStartQuery,
    instanceCounts,
    requiredNodeIds: [],
    pathMatching: TraversalPathMatchingType.LOOSE,
    pathCollapsing: {
      rules: [],
      currentEditableRule: null,
    },
    highlightedPaths: {},
  });
};

// Impure version, setTraversalInternal uses the side effecting getId function
// as default argument.
const setTraversal = (
  state: TraversalBuilderState,
  payload: SetTraversalsPayload
): TraversalBuilderState => setTraversalInternal(state, payload);

const setTraversalInternal = (
  state: TraversalBuilderState,
  { paths, filters }: SetTraversalsPayload,
  getId_: () => string = getId
): TraversalBuilderState => {
  if (state.rawGraphNodes.size === 0 || !state.startComponentType) {
    return {
      ...state,
      initialPaths: paths ?? null,
      initialFilters: filters ?? null,
    };
  }

  const {
    graphEdges,
    graphNodes,
    referenceMap,
    startGraphNode: startNode,
    instanceCounts,
    filterIdToGraphNodeIdMap,
    requiredNodeIds,
  } = pathsToGraph({
    paths,
    filters,
    startType: state.startComponentType,
    startNode: state.startNode,
    getId: getId_,
    rawGraphEdges: state.rawGraphEdges,
    rawGraphNodes: state.rawGraphNodes,
    instanceCounts: state.instanceCounts,
  });

  const selectedGraph = updateSelectedGraph(referenceMap);

  const selectedGraphNodeId =
    state.selectedGraphNodeId && graphNodes.has(state.selectedGraphNodeId)
      ? state.selectedGraphNodeId
      : startNode.id;

  if (selectedGraphNodeId) {
    const selectedGraphNode = graphNodes.get(selectedGraphNodeId);
    if (selectedGraphNode) {
      selectedGraphNode.setIsSelected(true);
    }
  }

  return viewpointBuilderFiltersOperations.viewpointFiltersToTraversalBuilderFilters(
    setTriplesOptions({
      ...state,
      initialPaths: paths ?? null,
      initialFilters: filters ?? null,
      startNode,
      selectedGraphNodeId,
      graphEdges,
      graphNodes,
      selectedGraph,
      instanceCounts,
      requiredNodeIds,
    }),
    filters,
    filterIdToGraphNodeIdMap
  );
};

const processMetaModel = (
  state: TraversalBuilderState,
  loadMetaModelAsScopedDataState: LoadMetaModelAsScopedDataState
) => {
  if (loadMetaModelAsScopedDataState.status !== 'DATA_LOADED') return state;
  const {
    data,
    componentNameAndWorkspacesIndex,
    componentNameAndFieldsIndex,
    referenceNameAndFieldsIndex,
    referenceTypes,
  } = loadMetaModelAsScopedDataState;

  const {
    graphData: { referenceMap, targetMap, sourceMap },
  } = data;
  const baseGraph = { referenceMap, targetMap, sourceMap };
  const triples = getTriples(referenceMap);
  const selectedGraph = {
    referenceMap: new Map(),
    targetMap: new Map(),
    sourceMap: new Map(),
  };
  const rawGraphNodes = enhancedScopeDataOperations.createRawGraphNodes(data);
  const rawGraphEdges = enhancedScopeDataOperations.createRawGraphEdges(data);

  let updatedState = {
    ...state,
    enhancedScopeData: data,
    baseGraph,
    triples,
    selectedGraph,
    rawGraphNodes,
    rawGraphEdges,
    componentNameAndWorkspacesIndex,
    componentNameAndFieldsIndex,
    referenceNameAndFieldsIndex,
    referenceTypes,
    isMetaModelLoaded: true,
  };

  if (state.startComponentType) {
    updatedState = {
      ...setStartTypeAndTraversalStartSetOrCount(updatedState, {
        startType: state.startComponentType,
        traversalStartSet: state.traversalStartSet ?? undefined,
        traversalStartQuery: state.traversalStartQuery ?? null,
      }),
      enhancedScopeData: data,
    };
  }

  if (state.initialPaths) {
    updatedState = {
      ...setTraversal(updatedState, {
        paths: state.initialPaths,
        filters: state.initialFilters,
      }),
      enhancedScopeData: data,
    };
  }

  if (state.initialPathCollapsingRules) {
    updatedState = {
      ...updatePathCollapsingRules(
        updatedState,
        state.initialPathCollapsingRules
      ),
      enhancedScopeData: data,
    };
  }

  return updateEdgesToBeRemovedOnCollapsing(updatedState);
};

const updatePathCollapsingRules = (
  state: TraversalBuilderState,
  pathCollapsingRules: PathCollapsingRule[]
): TraversalBuilderState => {
  const collapsingRuleFilter = getCollapsingRuleFilter(state);
  const { currentEditableRule } = state.pathCollapsing;
  const currentEditHash = currentEditableRule?.currentRuleHash ?? null;
  return {
    ...state,
    pathCollapsing: {
      rules: updateRules(
        state,
        pathCollapsingRules,
        currentEditHash,
        collapsingRuleFilter
      ),
      currentEditableRule: updateCurrentEditableRule(
        currentEditableRule,
        collapsingRuleFilter
      ),
    },
  };
};

const updateRules = (
  state: TraversalBuilderState,
  pathCollapsingRules: PathCollapsingRule[],
  currentEditHash: string | null,
  collapsingRuleFilter: (triple: DirectedTriple) => boolean
) => {
  const isCurrentEditRule = (rule: PathCollapsingRule) =>
    collapsedPathToHash(rule) === currentEditHash;
  const isExpanded = false;
  const includePathIndexInEqualityCheck = false;

  return (
    (pathCollapsingRules || [])
      // Remove any rule which uses triples which are no longer part of the
      // state, we cannot handle them
      .filter(
        rule => isCurrentEditRule(rule) || rule.path.every(collapsingRuleFilter)
      )
      .map(rule =>
        toInternalPathCollapsingRule(
          state,
          rule,
          isExpanded,
          includePathIndexInEqualityCheck
        )
      )
  );
};

const updateCurrentEditableRule = (
  currentEditableRule: EditablePathCollapsingRule | null,
  collapsingRuleFilter: (triple: DirectedTriple) => boolean
) => {
  if (!currentEditableRule) return currentEditableRule;

  return {
    ...currentEditableRule,
    path: currentEditableRule.path.every(collapsingRuleFilter)
      ? currentEditableRule.path
      : [],
  };
};

const getCollapsingRuleFilter = (state: TraversalBuilderState) => {
  const allTypeNames = new Set([
    ...Array.from(state.graphNodes.values()).map(node => node.getLabel()),
    ...Array.from(state.graphEdges.values()).map(edge =>
      toBELabel(edge.getLabel())
    ),
  ]);
  return ({ sourceType, targetType, referenceType }: DirectedTriple) =>
    allTypeNames.has(sourceType) &&
    allTypeNames.has(targetType) &&
    allTypeNames.has(toBELabel(referenceType));
};

const toggleTripleOption = (
  state: TraversalBuilderState,
  { tripleId, direction, namedDirectedTriple }: ToggleTripleOptionPayload
): TraversalBuilderState => {
  const { tripleOptions, selectedGraphNodeId } = state;
  const graphNodes = new Map(state.graphNodes);
  const graphEdges = new Map(state.graphEdges);
  const referenceMap = new Map(state.selectedGraph.referenceMap);
  const instanceCounts = new Map(state.instanceCounts);
  if (!selectedGraphNodeId) {
    throw Error('Option selected with no selected graph node context');
  }
  const isOutgoing = direction === ReferenceDirection.OUTGOING;

  const option = (
    isOutgoing ? tripleOptions.outgoing : tripleOptions.incoming
  ).find(({ tripleId: tripleIdCandidate }) => tripleIdCandidate === tripleId);
  if (!option) {
    throw Error('Missing option in handleToggleOption');
  }

  const { instanceCountsResponse } = tripleOptions;

  const referenceIdsToStartContext = new Set(
    getReferenceIdsBetweenStartAndSelectedContext({
      ...state.selectedGraph,
      currentContextId: state.startNode!.id,
      targetContextId: selectedGraphNodeId,
    })
  );

  if (option.isSelected) {
    removeEdgesForTriples({
      graphNodeId: selectedGraphNodeId,
      // TODO fix this
      tripleIds: [tripleId],
      direction,
      selectedGraph: state.selectedGraph,
      referenceMap,
      referenceIdsToStartContext,
      excludeReferenceIds: new Set(
        state.pathCollapsing.rules.flatMap(({ virtualEdgeStates }) =>
          virtualEdgeStates.flatMap(({ collapsedEdges }) => collapsedEdges)
        )
      ),
    });
  } else {
    const { instanceCounts: instanceCountsOption } = option;
    const { graphNode } = addNodeAndEdge({
      graphNodeId: selectedGraphNodeId,
      tripleId,
      direction,
      state,
      graphNodes,
      graphEdges,
      referenceMap,
      getId,
      instanceCountsOption,
      shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings:
        state.shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings,
    });
    const instanceCount = instanceCounts.get(selectedGraphNodeId);
    if (instanceCount) {
      const namedDirectedTriplePath = [
        ...instanceCount.namedDirectedTriplePath,
        namedDirectedTripleToBackendFormat(namedDirectedTriple),
      ];
      instanceCounts.set(graphNode.id, {
        namedDirectedTriplePath,
        pathKey: pathToKey(namedDirectedTriplePath),
        label: graphNode.getLabel(),
        graphNodeId: graphNode.id,
        counts:
          instanceCountsOption === null ? undefined : instanceCountsOption,
      });
    }
  }

  const {
    selectedGraph,
    removedNodes,
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
  } = rebuildSelectedGraph({
    startNodeId: state.startNode!.id,
    referenceMap: referenceMap,
    graphNodes,
    graphEdges,
    pathCollapsingRules: state.pathCollapsing.rules,
  });

  return pipeReducers(
    {
      ...state,
      graphNodes: updatedGraphNodes,
      graphEdges: updatedGraphEdges,
      selectedGraph,
      instanceCounts,
      requiredNodeIds: state.requiredNodeIds.filter(
        id => !removedNodes.has(id)
      ),
    },
    partialPayload(updatePathCollapsingRules, state.pathCollapsing.rules),
    updateEdgesToBeRemovedOnCollapsing,
    partialPayload(setTriplesOptions, instanceCountsResponse),
    updateFilters
  );
};

const updateFilters = (state: TraversalBuilderState): TraversalBuilderState => {
  const traversals = getTraversals(state, null);
  const localFilterIds = new Set(
    traversals.flatMap(path =>
      path.flatMap(({ sourceFilter, targetFilter, referenceFilter }) =>
        [sourceFilter, targetFilter, referenceFilter].filter(ExcludeFalsy)
      )
    )
  );
  const filters = filterObject(state.filters, (_, { localFilterId }) =>
    localFilterIds.has(localFilterId)
  );
  const updateMetaData = getUpdateFilterFlagInMetaData(filters);
  const graphEdges = new Map(state.graphEdges);
  const graphNodes = new Map(state.graphNodes);
  graphEdges.forEach(updateMetaData);
  graphNodes.forEach(updateMetaData);
  return {
    ...state,
    filters,
    graphEdges,
    graphNodes,
  };
};

const getUpdateFilterFlagInMetaData = (
  filters: Record<string, TraversalBuilderFiltersPerNode>
) => {
  return (item: GraphEdge | GraphNode) => {
    const metaData = item.metaData;
    if (metaData && metaData.hasFilters && !has(filters, item.id)) {
      // @ts-expect-error: typescript has a union type comprehension problem
      item.setMetaData({
        ...metaData,
        hasFilters: false,
      });
    }
  };
};

type GetReferenceIdsBetweenStartAndSelectedContextArgs = SelectedGraph & {
  currentContextId: string;
  targetContextId: string;
  referenceIds?: string[];
  visited?: Set<string>;
};

const getReferenceIdsBetweenStartAndSelectedContext = (
  args: GetReferenceIdsBetweenStartAndSelectedContextArgs
): string[] => {
  const {
    currentContextId,
    targetContextId,
    referenceIds = [],
    visited = new Set(),
    sourceMap,
    targetMap,
  } = args;

  if (currentContextId === targetContextId) return referenceIds;
  const targets = (sourceMap.get(currentContextId) ?? []).filter(
    ({ componentId }) => !visited.has(componentId)
  );
  const sources = (targetMap.get(currentContextId) ?? []).filter(
    ({ componentId }) => !visited.has(componentId)
  );
  const linkedComponents = [...targets, ...sources];
  if (linkedComponents.length === 0) return [];
  linkedComponents.forEach(({ componentId }) => visited.add(componentId));
  return linkedComponents.flatMap(({ referenceId, componentId }) =>
    getReferenceIdsBetweenStartAndSelectedContext({
      ...args,
      currentContextId: componentId,
      referenceIds: [...referenceIds, referenceId],
      visited,
    })
  );
};

const removeNodeFromGraph = (
  state: TraversalBuilderState,
  graphNodeId: string
): TraversalBuilderState => {
  const { graphNodes, graphEdges } = state;
  const referenceMapWithRemovedEdges = removeEdgesForGraphNodeId({
    graphNodeId,
    selectedGraph: state.selectedGraph,
    referenceMap: state.selectedGraph.referenceMap,
  });

  const {
    selectedGraph,
    removedNodes,
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
  } = rebuildSelectedGraph({
    startNodeId: state.startNode!.id,
    referenceMap: referenceMapWithRemovedEdges,
    graphNodes,
    graphEdges,
    pathCollapsingRules: state.pathCollapsing.rules,
  });

  const stateWithoutRemovedGraphNode = {
    ...state,
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
    selectedGraph,
    requiredNodeIds: state.requiredNodeIds.filter(id => !removedNodes.has(id)),
  };

  return updateFilters(
    toggleIsSelectedGraphNodeOrEdge(stateWithoutRemovedGraphNode, {
      graphNodeId,
      type: 'graphNode',
    })
  );
};

type RebuildSelectedGraphArgs = {
  startNodeId: string;
  referenceMap: Map<
    string,
    {
      sourceId: GraphId;
      targetId: GraphId;
      tripleId: string;
    }
  >;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  pathCollapsingRules: PathCollapsingRuleInternal[];
};
const rebuildSelectedGraph = ({
  startNodeId,
  referenceMap,
  graphNodes,
  graphEdges,
  pathCollapsingRules,
}: RebuildSelectedGraphArgs): {
  selectedGraph: SelectedGraph;
  graphNodes: Map<string, GraphNodeWithMetaData>;
  graphEdges: Map<string, GraphEdgeWithMetaData>;
  removedNodes: Map<string, GraphNodeWithMetaData>;
  removedEdges: Map<string, GraphEdgeWithMetaData>;
} => {
  const virtualEdgeStates = pathCollapsingRules.flatMap(
    rule => rule.virtualEdgeStates
  );

  const collapsedEdges = uniq(
    virtualEdgeStates.flatMap(({ collapsedEdges }) => collapsedEdges)
  ).map(id => graphEdges.get(id)!);

  // If a collapsed path has branches they will be shown in the viewpoint
  // builder graph, that means edges of a collapsed path will be shown in the
  // graph. The user can remove them by toggling the according triple option.
  // That means we need to ensure that all edges of all collapsed paths are
  // in the reference map, the source to build the selected graph, when we
  // rebuild the graph.
  collapsedEdges.forEach(
    ({
      id: referenceId,
      source: sourceId,
      target: targetId,
      modelId,
      sourceModelId,
      targetModelId,
    }) => {
      if (referenceMap.has(referenceId)) {
        return;
      }
      referenceMap.set(referenceId, {
        sourceId,
        targetId,
        tripleId: getTripleId({
          referenceId: modelId,
          sourceId: sourceModelId,
          targetId: targetModelId,
        }),
      });
    }
  );

  const selectedGraph = updateSelectedGraph(
    toConnectedSubgraph(startNodeId, referenceMap)
  );

  const allGraphNodesIds = new Set([
    ...Array.from(selectedGraph.sourceMap.keys()),
    ...Array.from(selectedGraph.targetMap.keys()),
  ]);
  const allGraphEdgeIds = new Set(selectedGraph.referenceMap.keys());

  const [updatedGraphNodes, removedNodes] = partitionMap(
    graphNodes,
    ([key]) => allGraphNodesIds.has(key) || key === startNodeId
  );
  const [updateGraphEdges, removedEdges] = partitionMap(graphEdges, ([key]) =>
    allGraphEdgeIds.has(key)
  );

  return {
    selectedGraph,
    removedNodes,
    removedEdges,
    graphNodes: updatedGraphNodes,
    graphEdges: updateGraphEdges,
  };
};

const partitionMap = <K, V>(
  map: Map<K, V>,
  filter: ([key, value]: [key: K, value: V]) => boolean
): [Map<K, V>, Map<K, V>] => {
  const map1 = new Map<K, V>();
  const map2 = new Map<K, V>();
  map.forEach((value, key) => {
    if (filter([key, value])) {
      map1.set(key, value);
    } else {
      map2.set(key, value);
    }
  });
  return [map1, map2];
};

type ToggleIsSelectedGraphNodeOrEdgePayload =
  | {
      graphNodeId: string;
      type: 'graphNode';
      isForceSelected?: boolean;
    }
  | {
      graphEdgeId: string;
      type: 'graphEdge';
      isForceSelected?: boolean;
    };

const toggleIsSelectedGraphNodeOrEdge = (
  state: TraversalBuilderState,
  payload: ToggleIsSelectedGraphNodeOrEdgePayload
): TraversalBuilderState => {
  const graphNodeId = payload.type === 'graphNode' ? payload.graphNodeId : null;
  const graphEdgeId = payload.type === 'graphEdge' ? payload.graphEdgeId : null;

  const isNewNodeSelected = Boolean(
    graphNodeId &&
      (payload.isForceSelected || graphNodeId !== state.selectedGraphNodeId)
  );

  const shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings =
    isNewNodeSelected && state.startNode?.id !== graphNodeId
      ? false
      : state.shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings;

  const newGraphNodes = new Map(state.graphNodes);
  Array.from(newGraphNodes.values()).forEach(graphNode => {
    graphNode.setIsSelected(false);
    // remove pulsing from nodes if non-context component type node has been selected
    graphNode.setMetaData({
      ...graphNode.metaData,
      shouldDisplayClickOtherComponentsHint:
        shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings,
    });
  });

  if (isNewNodeSelected && graphNodeId) {
    newGraphNodes.get(graphNodeId)!.setIsSelected(true);
  }

  const newGraphEdges = new Map(state.graphEdges);
  Array.from(newGraphEdges.values()).forEach(graphEdge => {
    graphEdge.setIsSelected(false);
  });

  const isNewEdgeSelected = Boolean(
    graphEdgeId && graphEdgeId !== state.selectedGraphEdgeId
  );

  if (isNewEdgeSelected && graphEdgeId) {
    newGraphEdges.get(graphEdgeId)?.setIsSelected(true);
  }

  return {
    ...state,
    graphNodes: newGraphNodes,
    graphEdges: newGraphEdges,
    selectedGraphNodeId: isNewNodeSelected ? graphNodeId : null,
    selectedGraphEdgeId: isNewEdgeSelected ? graphEdgeId : null,
    shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings,
    triplesSortAndFiltersState: {
      ...state.triplesSortAndFiltersState,
      filterTerm: '',
    },
  };
};

const buildCollapsedGraph = (
  state: TraversalBuilderState
): {
  collapsedGraphNodes: Map<string, GraphNodeWithMetaData>;
  collapsedGraphEdges: Map<string, GraphEdgeWithMetaData>;
} => {
  const {
    pathCollapsing: { rules },
    startNode,
  } = state;
  const activeRules = rules.filter(rule => rule.isActive && !rule.isExpanded);
  if (!startNode || activeRules.length === 0)
    return {
      collapsedGraphNodes: state.graphNodes,
      collapsedGraphEdges: state.graphEdges,
    };
  const collapsedGraphNodesCandidates = new Map([...state.graphNodes]);
  const collapsedGraphEdgesCandidates = new Map([...state.graphEdges]);
  const referenceMap = new Map(state.selectedGraph.referenceMap);
  const graphEdgeToReferenceMapEntry =
    editTraversalOperations.getGraphEdgeToReferenceMapEntry(
      collapsedGraphNodesCandidates
    );
  activeRules.forEach(rule => {
    rule.virtualEdgeStates.forEach(
      ({ virtualEdge, edgesToBeRemovedOnCollapsing }) => {
        collapsedGraphEdgesCandidates.set(virtualEdge.id, virtualEdge);
        referenceMap.set(
          virtualEdge.id,
          graphEdgeToReferenceMapEntry(virtualEdge)
        );
        edgesToBeRemovedOnCollapsing.forEach(id => referenceMap.delete(id));
      }
    );
  });
  const { graphNodes: collapsedGraphNodes, graphEdges: collapsedGraphEdges } =
    rebuildSelectedGraph({
      startNodeId: startNode.id,
      referenceMap,
      graphNodes: collapsedGraphNodesCandidates,
      graphEdges: collapsedGraphEdgesCandidates,
      pathCollapsingRules: [] as PathCollapsingRuleInternal[],
    });

  return {
    collapsedGraphNodes,
    collapsedGraphEdges,
  };
};

const toggleIsSelectedGraphNodeAndUpdateTripleOptions = (
  state: TraversalBuilderState,
  { graphNodeId, isForceSelected = false }: ToggleIsSelectedGraphNodePayload
) => {
  return setTriplesOptions(
    toggleIsSelectedGraphNodeOrEdge(state, {
      graphNodeId,
      type: 'graphNode',
      isForceSelected,
    })
  );
};

const toggleIsSelectedGraphEdgeAndUpdateTripleOptions = (
  state: TraversalBuilderState,
  { graphEdgeId }: ToggleIsSelectedGraphEdgePayload
): TraversalBuilderState => {
  return setTriplesOptions(
    toggleIsSelectedGraphNodeOrEdge(state, { graphEdgeId, type: 'graphEdge' })
  );
};

const setTripleSortOrder = (
  state: TraversalBuilderState,
  { tripleSortOrder }: SetTripleSortOrderPayload
) => {
  const { triplesSortAndFiltersState, tripleOptions } = state;
  const newState: TraversalBuilderState = {
    ...state,
    triplesSortAndFiltersState: {
      ...triplesSortAndFiltersState,
      tripleSortOrder,
    },
  };
  return setTriplesOptions(newState, tripleOptions.instanceCountsResponse);
};

const getTraversalsWithGraphIds = (state: TraversalBuilderState) =>
  getTraversals(state, null, true);

function getTraversals(
  state: Pick<
    TraversalBuilderState,
    | 'selectedGraph'
    | 'graphNodes'
    | 'graphEdges'
    | 'startNode'
    | 'filters'
    | 'requiredNodeIds'
  >,
  targetNodeId: string | null,
  includeGraphIds: true
): DirectedTripleWithFiltersWithGraphIds[][];

function getTraversals(
  {
    selectedGraph: { sourceMap, targetMap },
    graphNodes,
    graphEdges,
    startNode,
    filters,
    requiredNodeIds,
  }: Pick<
    TraversalBuilderState,
    | 'selectedGraph'
    | 'graphNodes'
    | 'graphEdges'
    | 'startNode'
    | 'filters'
    | 'requiredNodeIds'
  >,
  targetNodeId: string | null,
  includeGraphIds?: false
): DirectedTripleWithFilters[][];

function getTraversals(
  {
    selectedGraph: { sourceMap, targetMap },
    graphNodes,
    graphEdges,
    startNode,
    filters,
    requiredNodeIds,
  }: Pick<
    TraversalBuilderState,
    | 'selectedGraph'
    | 'graphNodes'
    | 'graphEdges'
    | 'startNode'
    | 'filters'
    | 'requiredNodeIds'
  >,
  targetNodeId: string | null,
  includeGraphIds = false
): DirectedTripleWithFilters[][] | DirectedTripleWithFiltersWithGraphIds[][] {
  if (!startNode || graphEdges.size === 0) return [];

  return getPaths({
    graphNodeId: startNode.id,
    sourceMap,
    targetMap,
    graphNodes,
    graphEdges,
    path: [],
    visited: new Set([startNode.id]),
    filters,
    targetNodeId,
    requiredNodeIds,
    includeGraphIds,
  }).filter(path => path.length > 0);
}

type GetPathsArgs = {
  graphNodeId: string;
  sourceMap: SelectedGraphModelMap;
  targetMap: SelectedGraphModelMap;
  graphNodes: Map<string, GraphNode>;
  graphEdges: Map<string, GraphEdge>;
  path: DirectedTriple[];
  visited: Set<string>;
  filters: Record<GraphId, TraversalBuilderFiltersPerNode>;
  /**
   * If `targetNodeId` is provided, the traversal will stop when the target node
   * is reached and only return the path to that node.
   */
  targetNodeId: string | null;
  requiredNodeIds: string[];
  includeGraphIds: boolean;
};

const getPaths = (args: GetPathsArgs): DirectedTripleWithFilters[][] => {
  const {
    graphNodeId,
    sourceMap,
    targetMap,
    graphNodes,
    graphEdges,
    path,
    visited,
    filters,
    targetNodeId,
    requiredNodeIds,
    includeGraphIds,
  } = args;
  const targets = (sourceMap.get(graphNodeId) ?? []).filter(
    ({ componentId }) => !visited.has(componentId)
  );
  const sources = (targetMap.get(graphNodeId) ?? []).filter(
    ({ componentId }) => !visited.has(componentId)
  );

  const linkedComponents = [...targets, ...sources];
  const linkedTargetNode =
    targetNodeId &&
    linkedComponents.find(({ componentId }) => componentId === targetNodeId);
  if (linkedTargetNode) {
    const direction = targets.find(
      ({ componentId }) => componentId === targetNodeId
    )
      ? 'outgoing'
      : 'incoming';
    const isOutgoing = direction === 'outgoing';
    return [
      [
        ...path,
        toDirectedTripleWithFilters({
          sourceId: isOutgoing ? graphNodeId : targetNodeId,
          targetId: isOutgoing ? targetNodeId : graphNodeId,
          referenceId: linkedTargetNode.referenceId,
          direction,
          graphNodes,
          graphEdges,
          filters,
          isRequired: requiredNodeIds.includes(
            isOutgoing ? targetNodeId : graphNodeId
          ),
          includeGraphIds,
        }),
      ],
    ];
  }

  [...targets, ...sources].forEach(({ componentId }) =>
    visited.add(componentId)
  );

  if (sources.length === 0 && targets.length === 0) {
    // If the `targetNodeId` is provided return an empty path for all other leaf
    // nodes.
    return targetNodeId ? [] : [path];
  }

  return [
    ...targets.flatMap(({ referenceId, componentId }) =>
      getPaths({
        ...args,
        graphNodeId: componentId,
        path: [
          ...path,
          toDirectedTripleWithFilters({
            sourceId: graphNodeId,
            targetId: componentId,
            referenceId,
            direction: 'outgoing',
            graphNodes,
            graphEdges,
            filters,
            isRequired: requiredNodeIds.includes(componentId),
            includeGraphIds,
          }),
        ],
      })
    ),
    ...sources.flatMap(({ referenceId, componentId }) =>
      getPaths({
        ...args,
        graphNodeId: componentId,
        path: [
          ...path,
          toDirectedTripleWithFilters({
            sourceId: componentId,
            targetId: graphNodeId,
            referenceId,
            direction: 'incoming',
            graphNodes,
            graphEdges,
            filters,
            isRequired: requiredNodeIds.includes(componentId),
            includeGraphIds,
          }),
        ],
      })
    ),
  ];
};

type ToDirectedTriplesArgs = {
  sourceId: string;
  targetId: string;
  referenceId: string;
  direction: 'outgoing' | 'incoming';
  graphNodes: Map<string, GraphNode>;
  graphEdges: Map<string, GraphEdge>;
  filters: Record<GraphId, TraversalBuilderFiltersPerNode>;
  isRequired: boolean;
  includeGraphIds: boolean;
};

const toDirectedTripleWithFilters = ({
  sourceId,
  targetId,
  referenceId,
  direction,
  graphNodes,
  graphEdges,
  filters,
  isRequired,
  includeGraphIds,
}: ToDirectedTriplesArgs):
  | DirectedTripleWithFilters
  | DirectedTripleWithFiltersWithGraphIds => {
  const directedTripleWithFilters: DirectedTripleWithFilters = {
    sourceType: getComponentTypeLabel(sourceId, graphNodes),
    targetType: getComponentTypeLabel(targetId, graphNodes),
    referenceType: getReferenceTypeLabel(referenceId, graphEdges),
    direction,
    qualifier: isRequired ? 'required' : undefined,
    sourceFilter: filters[sourceId]?.localFilterId ?? undefined,
    targetFilter: filters[targetId]?.localFilterId ?? undefined,
    referenceFilter: filters[referenceId]?.localFilterId ?? undefined,
  };

  if (!includeGraphIds) return directedTripleWithFilters;

  return {
    ...directedTripleWithFilters,
    sourceGraphId: sourceId,
    targetGraphId: targetId,
    referenceGraphId: referenceId,
  };
};

const getComponentTypeLabel = (
  id: string,
  graphNodes: Map<string, GraphNode>
) => {
  const node = graphNodes.get(id);
  return node?.metaData?.label ?? '';
};

const getReferenceTypeLabel = (
  id: string,
  graphEdges: Map<string, GraphEdge>
) => {
  const edge = graphEdges.get(id);
  const label = edge?.metaData?.representationData?.displayLabel ?? '';
  return toBELabel(label);
};

const toBELabel = (label: string) =>
  label === PARENT_REF_TYPE_NAME ? 'ardoq_parent' : label;

const getZeroedInstanceCounts = (
  instanceCounts: Map<string, InstanceCounts>
): Map<string, InstanceCounts> => {
  const resetInstanceCounts = Array.from(
    instanceCounts,
    ([key, instanceCount]) =>
      [key, { ...instanceCount, counts: 0 }] satisfies [string, InstanceCounts]
  );
  return new Map(resetInstanceCounts);
};

const setInstanceCounts = (
  state: TraversalBuilderState,
  result: Result<APICountInstancesResponse>
) => {
  if (isArdoqError(result)) {
    return state;
  }

  const { traversalCounts, startSetCount } = result;
  const updatedGraphNodes = new Map(state.graphNodes);
  const updatedInstanceCounts = getZeroedInstanceCounts(state.instanceCounts);
  const pathKeyToGraphNodeId = new Map(
    Array.from(state.instanceCounts.values()).map(
      ({ pathKey, graphNodeId }) => [pathKey, graphNodeId]
    )
  );

  const { startNode } = state;
  if (!startNode) {
    return state;
  }

  updateInstanceCounts(
    updatedInstanceCounts,
    state.instanceCounts,
    startNode.id,
    // TODO only for a short time till the new endpoint is deployed.
    startSetCount >= 0 ? startSetCount : undefined
  );
  updateLabelWithCounts(
    startNode,
    // TODO only for a short time till the new endpoint is deployed.
    startSetCount >= 0 ? startSetCount : undefined
  );

  traversalCounts.forEach(({ path, counts: countsList }) => {
    const currentPath: DirectedTriple[] = [];
    path.forEach((directedTriple, index) => {
      currentPath.push(directedTriple);
      // The first triple represents basically two counts, the start node
      // and the first traversal step. The start set count doesn't need to be
      // updated, the count for that is the size of the start set.
      const counts = countsList[index + 1];
      const graphNodeId = pathKeyToGraphNodeId.get(pathToKey(currentPath));
      const graphNode = graphNodeId ? updatedGraphNodes.get(graphNodeId) : null;
      if (!(graphNodeId && graphNode)) {
        logError(Error('Missing graph node in setInstanceCounts'));
        return;
      }

      const prevCount = updatedInstanceCounts.get(graphNodeId)?.counts ?? 0;
      if (counts < prevCount) {
        // For strict matching, the returned counts for the various nodes in
        // a path can vary depending on the path. We will never know the exact
        // count for a node – showing the biggest number is the best we can do.
        // So, we skip any updates if the count is less than or equal to the
        // previous.
        return;
      }
      updateInstanceCounts(
        updatedInstanceCounts,
        state.instanceCounts,
        graphNodeId,
        counts
      );
      updateLabelWithCounts(graphNode, counts);
    });
  });

  return {
    ...state,
    graphNodes: updatedGraphNodes,
    instanceCounts: updatedInstanceCounts,
  };
};

const updateInstanceCounts = (
  newInstanceCounts: Map<string, InstanceCounts>,
  currentInstanceCounts: Map<string, InstanceCounts>,
  graphNodeId: string,
  counts?: number
) => {
  newInstanceCounts.set(graphNodeId, {
    ...currentInstanceCounts.get(graphNodeId)!,
    counts,
  });
};

const updateLabelWithCounts = (
  graphNode: GraphNode,
  counts: number | undefined
) => {
  graphNode.setMetaData({
    ...graphNode.metaData!,
    labelWithCount:
      typeof counts === 'number'
        ? `${graphNode.metaData!.label} (${counts})`
        : undefined,
  });
};

const getTraversalAsSavableAttributes = (
  state: TraversalBuilderState
): TraversalBuilderAsSavableAttributes => {
  const paths = getPathsWithDependencies(getTraversals(state, null));
  return {
    paths,
    filters: viewpointBuilderFiltersOperations.mapFiltersToBackendFormat(state),
    startType: state.startComponentType,
    startSetFilterId: getStartSetFilterId(state),
    pathMatching: state.pathMatching,
    pathCollapsingRules: state.pathCollapsing.rules.map(internalRule =>
      pick(internalRule, ['path', 'displayText', 'referenceStyle', 'isActive'])
    ),
  };
};

const getInitialTraversalBuilderViewState = (): TraversalBuilderViewState => {
  const initialState = editTraversalOperations.getInitialTraversalEditState();
  return {
    ...initialState,
    triplesSortAndFiltersState: {
      ...initialState.triplesSortAndFiltersState,
      incomingTriplesHiddenCount: 0,
      outgoingTriplesHiddenCount: 0,
    },
    filtersDefinitions:
      viewpointBuilderFiltersOperations.getFiltersDefinitions(initialState),
    asyncLoaders:
      viewpointBuilderFiltersOperations.getViewpointBuilderSuggestionsLoaders({
        relatedWorkspaces: [],
        organizationId: currentUserInterface.getCurrentOrgId(),
        componentTypeName: null,
      }),
    stateAsSavableAttributes: getTraversalAsSavableAttributes(initialState),
    perspectivesOptionsArgs: {
      workspaceIds: [],
      componentTypeNames: [],
      referenceTypeNames: [],
      availableComponentFields: [],
      availableReferenceFields: [],
      componentFieldsByType: new Map(),
      referenceFieldsByType: new Map(),
    },
    pathCollapsingViewState: {
      rules: [],
      currentEditableRule: null,
    },
    shouldShowDeleteCurrentNodeButton: false,
    requiredComponentsState: null,
    shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings:
      false,
    shouldIncludeClickOtherComponentTypeNodesNotification: false,
  };
};

const getStartSetFilterId = (state: TraversalBuilderState) =>
  state.startNode
    ? (state.filters[state.startNode.id]?.localFilterId ?? null)
    : null;

const getWorkspaceIdsAndComponentAndReferenceTypeNamesFromTraversals = (
  state: TraversalBuilderState
) => {
  const {
    componentNameAndWorkspacesIndex,
    referenceNameAndFieldsIndex,
    componentNameAndFieldsIndex,
    fieldMap,
  } = state;
  const paths = getTraversals(state, null);
  return getWorkspaceIdsAndTypeNamesFromTraversalPaths({
    paths,
    fieldMap,
    componentNameAndWorkspacesIndex,
    referenceNameAndFieldsIndex,
    componentNameAndFieldsIndex,
  });
};

const setSectionFilter = (
  state: TraversalBuilderState,
  { filterTerm }: SetSectionFilterPayload
): TraversalBuilderState => {
  const filterTermLower = filterTerm.toLowerCase();
  const filteredOptionsIncoming = filterOptions(
    state.tripleOptions.incoming,
    filterTermLower,
    state.triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
  );
  const filteredOptionsOutgoing = filterOptions(
    state.tripleOptions.outgoing,
    filterTermLower,
    state.triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
  );

  return {
    ...state,
    filteredTripleOptions: {
      ...state.filteredTripleOptions,
      outgoing: filteredOptionsOutgoing,
      incoming: filteredOptionsIncoming,
    },
    triplesSortAndFiltersState: {
      ...state.triplesSortAndFiltersState,
      filterTerm,
    },
  };
};

const setOptionsInstanceCounts = (
  state: TraversalBuilderState,
  // TODO (chriskr): Use the Result type when it's ready.
  instanceCountsResponse: Result<APICountInstancesResponse> | null
): TraversalBuilderState => {
  if (instanceCountsResponse === null) {
    return state;
  }

  if (isArdoqError(instanceCountsResponse)) {
    return {
      ...state,
      tripleOptions: {
        ...state.tripleOptions,
        optionCountLoadingState: 'loaded',
      },
      filteredTripleOptions: {
        ...state.filteredTripleOptions,
        optionCountLoadingState: 'loaded',
      },
    };
  }

  const { traversalCounts } = instanceCountsResponse;

  const counts = new Map(
    traversalCounts.map(({ path, counts: currentCounts }) => [
      pathToKey(path),
      last(currentCounts),
    ])
  );
  const { tripleOptions } = state;

  const newTripleOptions: TripleOptions = {
    ...tripleOptions,
    optionCountLoadingState: 'loaded',
    instanceCountsResponse,
    incoming: tripleOptions.incoming.map(option => ({
      ...option,
      instanceCounts: counts.get(option.countKey) ?? null,
    })),
    outgoing: tripleOptions.outgoing.map(option => ({
      ...option,
      instanceCounts: counts.get(option.countKey) ?? null,
    })),
  };

  const filteredTripleOptions = tripleOptionToFilteredAndSortedTripleOptions(
    newTripleOptions,
    state.triplesSortAndFiltersState
  );

  return {
    ...state,
    tripleOptions: newTripleOptions,
    filteredTripleOptions,
    hasTripleOptions: getHasTripleOptions(
      newTripleOptions,
      state.triplesSortAndFiltersState
    ),
  };
};

export const filterOptions = (
  options: TripleOption[],
  filterTermLower: string,
  showOnlyOptionsWithInstanceCounts: boolean
) =>
  options
    .filter(
      ({ instanceCounts }) =>
        !showOnlyOptionsWithInstanceCounts ||
        instanceCounts === null ||
        instanceCounts > 0
    )
    .filter(
      ({
        metaDataComponent: { label },
        metaDataReference: { representationData },
      }) =>
        label.toLowerCase().includes(filterTermLower) ||
        !representationData ||
        representationData.displayLabel.toLowerCase().includes(filterTermLower)
    );

const tripleOptionToFilteredAndSortedTripleOptions = (
  tripleOptions: TripleOptions,
  triplesSortAndFiltersState: TraversalBuilderState['triplesSortAndFiltersState']
) => ({
  ...tripleOptions,
  outgoing: filterOptions(
    tripleOptions.outgoing,
    triplesSortAndFiltersState.filterTerm,
    triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
  ).sort(sortByKey(triplesSortAndFiltersState.tripleSortOrder)),
  incoming: filterOptions(
    tripleOptions.incoming,
    triplesSortAndFiltersState.filterTerm,
    triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts
  ).sort(sortByKey(triplesSortAndFiltersState.tripleSortOrder)),
});

const getHasTripleOptions = (
  tripleOptions: TripleOptions,
  triplesSortAndFiltersState: TraversalBuilderState['triplesSortAndFiltersState']
) => {
  return {
    outgoingReferences:
      tripleOptions.outgoing.length > 0 &&
      (!triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts ||
        tripleOptions.outgoing.some(
          ({ instanceCounts }) => instanceCounts === null || instanceCounts > 0
        )),
    incomingReferences:
      tripleOptions.incoming.length > 0 &&
      (!triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts ||
        tripleOptions.incoming.some(
          ({ instanceCounts }) => instanceCounts === null || instanceCounts > 0
        )),
  };
};

const setOptionCountLoadingState = (
  state: TraversalBuilderState,
  optionCountLoadingState: TripleOptions['optionCountLoadingState']
): TraversalBuilderState => {
  return {
    ...state,
    tripleOptions: {
      ...state.tripleOptions,
      optionCountLoadingState,
    },
    filteredTripleOptions: {
      ...state.filteredTripleOptions,
      optionCountLoadingState,
    },
  };
};

const toggleShowOnlyOptionsWithInstanceCounts = (
  state: TraversalBuilderState
) => {
  const newState: TraversalBuilderState = {
    ...state,
    triplesSortAndFiltersState: {
      ...state.triplesSortAndFiltersState,
      showOnlyOptionsWithInstanceCounts:
        !state.triplesSortAndFiltersState.showOnlyOptionsWithInstanceCounts,
    },
  };

  const filteredTripleOptions = tripleOptionToFilteredAndSortedTripleOptions(
    newState.tripleOptions,
    newState.triplesSortAndFiltersState
  );
  return {
    ...newState,
    filteredTripleOptions,
    hasTripleOptions: getHasTripleOptions(
      newState.tripleOptions,
      newState.triplesSortAndFiltersState
    ),
  };
};

const dontShowAgainClickOtherComponentTypeNodesHints = (
  state: TraversalBuilderState
) => {
  const newGraphNodes = new Map(state.graphNodes);
  Array.from(newGraphNodes.values()).forEach(graphNode => {
    graphNode.setMetaData({
      ...graphNode.metaData,
      shouldDisplayClickOtherComponentsHint: false,
    });
  });
  return {
    ...state,
    graphNodes: newGraphNodes,
    shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings:
      false,
  };
};

const processCurrentUserSettings = (
  state: TraversalBuilderState,
  { settings }: APICurrentUser
) => {
  return {
    ...state,
    shouldIncludeClickOtherComponentTypeNodesHintsRestoredFromUserSettings:
      settings[
        PersonalSetting
          .INCLUDE_CLICK_OTHER_COMPONENT_TYPE_NODES_HINTS_IN_VIEWPOINT_BUILDER
      ] ?? true,
  };
};

const updateTraversalStartContext = (
  state: TraversalBuilderState,
  {
    startComponentType,
    traversalStartQuery,
    traversalStartSet,
  }: UpdateTraversalStartContextPayload
): TraversalBuilderState => {
  // The starting type is determined by the components selected by the user.
  // Therefore, we should avoid resetting the graph based on this.
  // There could be instances where the user modifies the selection, resulting
  // in a temporary state where no components are selected.
  if (!startComponentType) {
    return state;
  }

  if (state.startComponentType === startComponentType) {
    return setTriplesOptions({
      ...state,
      traversalStartQuery,
      traversalStartSet,
    });
  }

  // This will clear the current selected graph.
  return setStartTypeAndTraversalStartSetOrCount(state, {
    startType: startComponentType,
    traversalStartQuery: traversalStartQuery,
    traversalStartSet: traversalStartSet ?? undefined,
  });
};

const getSelectedNodeComponentType = (state: TraversalBuilderState) => {
  if (!state.selectedGraphNodeId) {
    return null;
  }
  return getComponentTypeLabel(state.selectedGraphNodeId, state.graphNodes);
};

const setPathMatchingType = (
  state: TraversalBuilderState,
  pathMatching: TraversalPathMatchingType
) => ({
  ...state,
  pathMatching,
});

const resetTraversalBuilderState = (
  state: TraversalBuilderState
): TraversalBuilderState => {
  return {
    // We need to keep the metamodel data, the according stream is persistent,
    // so there is no guarantee that the metamodel data would get added to the
    // TraversalBuilderState.
    ...state,
    filters: {},
    pathCollapsing: {
      rules: [],
      currentEditableRule: null,
    },
    traversalStartQuery: null,
    pathMatching: TraversalPathMatchingType.LOOSE,
    requiredNodeIds: [],
    highlightedPaths: {},
    selectedGraphNodeId: null,
    selectedGraphEdgeId: null,
  };
};

const toInternalPathCollapsingRule = <T extends PathCollapsingRule>(
  state: TraversalBuilderState,
  rule: T,
  isExpanded = false,
  includePathIndexInEqualityCheck = true
): PathCollapsingRuleInternal => {
  return {
    ...rule,
    isExpanded,
    virtualEdgeStates: getAllMatches(
      state,
      rule,
      includePathIndexInEqualityCheck
    ),
  };
};

// The viewpoint builder graph is essentially a tree, represented by paths from
// the root to the leaves. Each edge in the graph has a unique ID. Collapsing
// rules specify paths to be collapsed using this notation.
// Collapsed edges are those that match a collapsing rule against the entire
// graph's paths. To determine if a collapsed edge can be removed, we count its
// occurrences in the entire graph's paths and compare it with its occurrences
// in all collapsed paths. If both counts are the same, indicating the edge
// appears the same number of times in the entire graph and in all collapsed
// paths, we can safely remove it.
const updateEdgesToBeRemovedOnCollapsing = (
  state: TraversalBuilderState
): TraversalBuilderState => {
  const pathsWithGraphIds = (
    getTraversals(
      state,
      null,
      true
    ) as DirectedTripleWithFiltersWithGraphIds[][]
  ).flatMap(path => path.map(({ referenceGraphId }) => referenceGraphId));
  const countAllIndex = countBy(pathsWithGraphIds);
  const collapsingRules = state.pathCollapsing.rules;
  const collapsedEdges = collapsingRules.flatMap(rule =>
    rule.isExpanded || !rule.isActive
      ? []
      : rule.virtualEdgeStates.flatMap(({ collapsedEdges, matchCount }) =>
          Array(matchCount).fill(collapsedEdges).flat()
        )
  );
  const countIndex = countBy(collapsedEdges);
  const edgesToBeRemoved = new Set(
    collapsedEdges.filter(id => countIndex[id] === countAllIndex[id])
  );
  const updatedCollapsingRules = collapsingRules.map(rule => {
    const virtualEdgeStates = rule.virtualEdgeStates.map(virtualEdgeState => {
      const edgesToBeRemovedOnCollapsing =
        virtualEdgeState.collapsedEdges.filter(id => edgesToBeRemoved.has(id));
      return {
        ...virtualEdgeState,
        edgesToBeRemovedOnCollapsing,
      };
    });
    return {
      ...rule,
      virtualEdgeStates,
    };
  });

  return {
    ...state,
    pathCollapsing: {
      ...state.pathCollapsing,
      rules: updatedCollapsingRules,
    },
  };
};

const getAllMatches = (
  state: TraversalBuilderState,
  rule: PathCollapsingRuleNonNullablePath,
  includePathIndexInEqualityCheck = true
): VirtualEdgeState[] => {
  const pathsWithGraphIds = getTraversals(
    state,
    null,
    true
  ) as DirectedTripleWithFiltersWithGraphIds[][];
  const matches = getMatchingPaths(
    pathsWithGraphIds,
    rule,
    includePathIndexInEqualityCheck
  );
  const virtualAndCollapsedEdges = processMatches(state, matches);
  return virtualAndCollapsedEdges;
};

export const editTraversalOperations = {
  buildCollapsedGraph,
  filterOptions,
  getGraphEdgeToReferenceMapEntry,
  getInitialTraversalBuilderViewState,
  getInitialTraversalEditState,
  getPaths,
  getSelectedNodeComponentType,
  getTraversalAsSavableAttributes,
  getTraversals,
  getTraversalsWithGraphIds,
  getTriples,
  getWorkspaceIdsAndComponentAndReferenceTypeNamesFromTraversals,
  pathsToGraph,
  processCurrentUserSettings,
  processMetaModel,
  removeNodeFromGraph,
  resetTraversalBuilderState,
  dontShowAgainClickOtherComponentTypeNodesHints,
  setInstanceCounts,
  setOptionCountLoadingState,
  setOptionsInstanceCounts,
  setPathMatchingType,
  setSectionFilter,
  setStartTypeAndTraversalStartSetOrCount,
  setTraversal,
  setTripleSortOrder,
  toggleIsSelectedGraphEdgeAndUpdateTripleOptions,
  toggleIsSelectedGraphNodeAndUpdateTripleOptions,
  toggleShowOnlyOptionsWithInstanceCounts,
  toggleTripleOption,
  toInternalPathCollapsingRule,
  tripleOptionToFilteredAndSortedTripleOptions,
  updateTraversalStartContext,
  updateEdgesToBeRemovedOnCollapsing,
  updatePathCollapsingRules,
  toBELabel,
};
