import {
  LabelEntityTypes,
  LabelFormattingInfo,
  ComponentLabelSource,
  ReferenceLabelSource,
} from '@ardoq/data-model';
import { validate as validateUuid } from 'uuid';
import { SelectOption, SelectOptionsOrGroups } from '@ardoq/select';
import { APIFieldAttributes, ViewIds } from '@ardoq/api-types';
import { isNil, uniq, uniqBy } from 'lodash';
import { fieldInterface } from '@ardoq/field-interface';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  getFieldOptionsWithDefault,
  RestrictedMultiLabelFormattingWarningProps,
} from '@ardoq/perspectives-sidebar';
import { dateRangeOperations } from '@ardoq/date-range';
import { viewIdToUserFriendlyName } from 'views/metaInfoTabs';
import { getRestrictedMultilabelFormattingWarningMessage } from 'getRestrictedMultilabelFormattingWarningMessage';
import { isViewWithoutFullyMultiLabelsSupport } from '../../traversals/getViewpointModeSupportedViews';

const DIVIDER = '~~\\~~';
const composeLabelRuleKey = (labelFormatting: LabelFormattingInfo) => {
  const { type, typeName, showFieldName } = labelFormatting;

  return [type, typeName, showFieldName].join(DIVIDER);
};
const decomposeLabelRuleKey = (key: string) => {
  const [type, typeName, showFieldName] = key.split(DIVIDER);

  return {
    type,
    typeName,
    showFieldName: showFieldName === 'true',
  };
};

const ALL_COMPONENT_TYPES = 'All component types';
const ALL_REFERENCE_TYPES = 'All reference types';

const defaultFieldNamesSet = new Set<string>([
  ComponentLabelSource.TYPE,
  ReferenceLabelSource.DISPLAY_TEXT,
]);

const getAllTypesLabel = (type: LabelEntityTypes) =>
  type === 'component' ? ALL_COMPONENT_TYPES : ALL_REFERENCE_TYPES;

const notifications = {
  fieldUsedInAllTypes: (type: string) =>
    `This field is already on a label applied to all ${type} types`,
  fieldUsedInSpecificTypes: (types: string, entityType: string) =>
    `This field is already on a label applied to the ${types} ${entityType} type${types.includes(',') ? 's' : ''}.`,
  typeUsedInDifferentLabel: (entityType: string) =>
    `This ${entityType} type is already used in a different label`,
};

const addDisabledStateToOption = (
  option: SelectOption<string>,
  message: string
): SelectOption<string> => ({
  ...option,
  isDisabled: true,
  popoverContent: message,
});

const toTypeOptions = (
  names: string[],
  allTypesLabel: string,
  alreadyUsedTypes: Set<string>,
  entityType: LabelEntityTypes
): SelectOption<string | null>[] =>
  names.map(name => ({
    label: name,
    value: name === allTypesLabel ? null : name,
    ...(alreadyUsedTypes.has(name) && {
      isDisabled: true,
      popoverContent: notifications.typeUsedInDifferentLabel(entityType),
    }),
  }));

type GetFieldOptionLabelArgs = Pick<
  APIFieldAttributes,
  'label' | 'name' | 'type'
>;
export const getFieldOptionLabel = (fieldData: GetFieldOptionLabelArgs) =>
  dateRangeOperations.isPartOfDateRangeField(fieldData)
    ? dateRangeOperations.dashFormatDateRangeFieldLabel(fieldData.label)
    : fieldData.label;

const toFieldOptions = (fields: APIFieldAttributes[]) =>
  fields.map(field => ({
    label: getFieldOptionLabel(field),
    value: field.name,
  }));

const getTypeOptions = (
  labelFormatting: LabelFormattingInfo,
  validTypeOptions: Map<string, SelectOptionsOrGroups<string>>,
  alreadyUsedTypes: Set<string>,
  entityType: LabelEntityTypes
) => {
  const allTypesLabel = getAllTypesLabel(labelFormatting.type);
  const typeName = labelFormatting.typeName || allTypesLabel;
  const isUnset = validateUuid(typeName);
  const isSelectedTypeValid = isUnset || validTypeOptions.has(typeName);

  // Move AllTypes to the beginning
  const typeNames = [
    allTypesLabel,
    ...Array.from(validTypeOptions.keys()).filter(
      name => name !== allTypesLabel
    ),
  ];

  const typeOptions = isUnset
    ? toTypeOptions(typeNames, allTypesLabel, alreadyUsedTypes, entityType)
    : toTypeOptions(
        uniq([typeName, ...typeNames]),
        allTypesLabel,
        alreadyUsedTypes,
        entityType
      );

  return {
    typeOptions: typeOptions,
    isSelectedTypeValid,
  };
};

const enhanceOptionsByTypeWithAllTypes = (
  optionsByType: Map<string, SelectOption<string>[]>,
  allTypesLabel: string
): Map<string, SelectOption<string>[]> => {
  const allPossibleOptions = uniqBy(
    Array.from(optionsByType.values()).flat(),
    'value'
  );
  const enhancedOptionsByType = new Map(optionsByType);
  enhancedOptionsByType.set(allTypesLabel, allPossibleOptions);
  return enhancedOptionsByType;
};

const createBaseFieldOptions = (
  labelFormatting: LabelFormattingInfo,
  isSelectedTypeValid: boolean,
  validTypeOptions: Map<string, SelectOption<string>[]>,
  allTypesLabel: string
): SelectOption<string>[] => {
  if (isSelectedTypeValid) {
    return (
      validTypeOptions.get(labelFormatting.typeName || allTypesLabel) ?? []
    );
  }

  return labelFormatting.fields
    .map(fieldName => {
      const field = fieldInterface.getByName(fieldName, {
        acrossWorkspaces: true,
      });

      if (!field) {
        return {
          label: fieldName,
          value: fieldName,
        };
      }

      return {
        label: getFieldOptionLabel(field),
        value: fieldName,
      };
    })
    .filter(({ value }) => !defaultFieldNamesSet.has(value));
};

const getFieldsUsedInRules = (labelFormattingArray: LabelFormattingInfo[]) => {
  const fieldsUsedByType = new Map<string, Set<string>>();

  labelFormattingArray.forEach(labelFormatting => {
    const typeKey = composeLabelRuleKey(labelFormatting);

    if (!fieldsUsedByType.has(typeKey)) {
      fieldsUsedByType.set(typeKey, new Set<string>());
    }

    const fieldSet = fieldsUsedByType.get(typeKey)!;
    labelFormatting.fields.forEach(field => {
      fieldSet.add(field);
    });
  });

  return fieldsUsedByType;
};

/**
 * Applies disabled state to an individual option based on field usage in existing rules
 * @returns Original or disabled option with popover message
 */
const maybeDisableOption = (
  option: SelectOption<string>,
  labelFormatting: LabelFormattingInfo,
  fieldsUsedInRules: Map<string, Set<string>>
): SelectOption<string> => {
  const currentRuleKey = composeLabelRuleKey(labelFormatting);
  const allTypeKey = composeLabelRuleKey({
    ...labelFormatting,
    showFieldName: true,
    typeName: null,
  });

  // Check if field is already used in a rule that applies to all types
  // But don't disable if it's the current rule we're checking
  const isFieldUsedInAllTypes =
    fieldsUsedInRules.get(allTypeKey)?.has(option.value) &&
    allTypeKey !== currentRuleKey;

  if (isFieldUsedInAllTypes) {
    return addDisabledStateToOption(
      option,
      notifications.fieldUsedInAllTypes(labelFormatting.type)
    );
  }

  const specificTypesUsingField: string[] = [];

  // Check if field is used in specific type rules
  if (labelFormatting.typeName) {
    // Check if field is used in a rule for the same type but with different showFieldName setting
    const fieldsOfSameTypeWithDifferentShowFieldName = fieldsUsedInRules.get(
      composeLabelRuleKey({
        ...labelFormatting,
        showFieldName: !labelFormatting.showFieldName,
      })
    );

    if (fieldsOfSameTypeWithDifferentShowFieldName?.has(option.value)) {
      specificTypesUsingField.push(labelFormatting.typeName);
    }
  } else {
    // When no specific type is selected, check all types for usage of this field
    fieldsUsedInRules.forEach((fields, key) => {
      const { type, typeName } = decomposeLabelRuleKey(key);

      if (
        type === labelFormatting.type &&
        typeName &&
        fields.has(option.value)
      ) {
        specificTypesUsingField.push(typeName);
      }
    });
  }

  if (specificTypesUsingField.length) {
    return addDisabledStateToOption(
      option,
      notifications.fieldUsedInSpecificTypes(
        specificTypesUsingField.join(', '),
        labelFormatting.type
      )
    );
  }

  return option;
};

const maybeDisableOptions = (
  options: SelectOptionsOrGroups<string>,
  labelFormatting: LabelFormattingInfo,
  fieldsUsedInRules: Map<string, Set<string>>
): SelectOptionsOrGroups<string> =>
  options.map(item => {
    if ('options' in item) {
      return {
        ...item,
        options: item.options.map(option =>
          maybeDisableOption(option, labelFormatting, fieldsUsedInRules)
        ),
      };
    }
    return maybeDisableOption(item, labelFormatting, fieldsUsedInRules);
  });

export const getLabelFormattingElementProps = (
  labelFormattingArray: LabelFormattingInfo[],
  componentFieldOptionsByTypes: Map<string, SelectOption<string>[]>,
  referenceFieldOptionsByTypes: Map<string, SelectOption<string>[]>,
  isReferenceTypeDisabled: boolean
) => {
  const allUsedComponentTypes = new Set<string>(
    labelFormattingArray
      .map(({ type, typeName }) => type === 'component' && typeName)
      .filter(ExcludeFalsy)
  );
  const allUsedReferenceTypes = new Set<string>(
    labelFormattingArray
      .map(({ type, typeName }) => type === 'reference' && typeName)
      .filter(ExcludeFalsy)
  );

  const fieldsUsedInRules = getFieldsUsedInRules(labelFormattingArray);

  return labelFormattingArray.map((labelFormatting, index) => {
    const { type, typeName } = labelFormatting;
    const isComponentFormatting = type === 'component';
    const optionsByType = isComponentFormatting
      ? componentFieldOptionsByTypes
      : referenceFieldOptionsByTypes;
    const allTypesLabel = getAllTypesLabel(type);
    const validTypeOptions = enhanceOptionsByTypeWithAllTypes(
      optionsByType,
      allTypesLabel
    );

    const alreadyUsedTypesCopy = new Set(
      isComponentFormatting ? allUsedComponentTypes : allUsedReferenceTypes
    );
    if (typeName) {
      alreadyUsedTypesCopy.delete(typeName);
    }

    const { isSelectedTypeValid, typeOptions } = getTypeOptions(
      labelFormatting,
      validTypeOptions,
      alreadyUsedTypesCopy,
      labelFormatting.type
    );

    const baseFieldOptions = createBaseFieldOptions(
      labelFormatting,
      isSelectedTypeValid,
      validTypeOptions,
      allTypesLabel
    );

    const fieldOptions = maybeDisableOptions(
      getFieldOptionsWithDefault(baseFieldOptions, isComponentFormatting),
      labelFormatting,
      fieldsUsedInRules
    );

    const isAllTypes = isNil(labelFormatting.typeName);
    const disabledCheckboxMessage = isAllTypes
      ? 'Field name is always shown on labels applied to all component or reference types'
      : null;

    return {
      index,
      typeOptions: groupTypeOptions(typeOptions),
      fieldOptions,
      isComponentFormatting,
      isSelectedTypeValid,
      isDisabled: isReferenceTypeDisabled && !isComponentFormatting,
      disabledCheckboxMessage,
      labelFormatting: {
        ...labelFormatting,
        showFieldName: isAllTypes ? true : labelFormatting.showFieldName,
      },
    };
  });
};

/**
 * Map type options to groups without label to display divider in dropdown
 */
const groupTypeOptions = (
  typeOptions: SelectOption<string | null>[]
): SelectOptionsOrGroups<string | null> => {
  const allTypesOption = typeOptions.find(option => option.value === null);
  const allTypesOptionGroup = allTypesOption
    ? {
        options: [allTypesOption],
      }
    : null;
  const otherTypesOptionGroup = {
    options: typeOptions.filter(option => option.value !== null),
  };

  return [
    ...(allTypesOptionGroup ? [allTypesOptionGroup] : []),
    otherTypesOptionGroup,
  ];
};

type ComponentTypeData = {
  componentType: { id: string; name: string };
  workspaceModelId: string;
};

export const getFieldOptionsByComponentTypes = (
  componentTypes: ComponentTypeData[]
): Map<string, SelectOption<string>[]> =>
  componentTypes.reduce<Map<string, SelectOption<string>[]>>(
    (
      acc,
      { componentType: { id, name: componentTypeName }, workspaceModelId }
    ) => {
      const fieldsOfType = fieldInterface.getFieldsByComponentType(
        id,
        workspaceModelId
      );
      const options = toFieldOptions(fieldsOfType);

      return acc.set(
        componentTypeName,
        uniqBy([...(acc.get(componentTypeName) ?? []), ...options], 'value')
      );
    },
    new Map()
  );
type ReferenceTypeData = {
  referenceType: { name: string };
  typeId: number;
  workspaceModelId: string;
};

export const getFieldOptionsByReferenceTypes = (
  referenceTypes: ReferenceTypeData[]
): Map<string, SelectOption<string>[]> =>
  referenceTypes.reduce<Map<string, SelectOption<string>[]>>(
    (acc, { typeId, referenceType, workspaceModelId }) => {
      const fieldsOfType = fieldInterface
        .getFieldIdsByReferenceType(typeId, workspaceModelId)
        .map(fieldInterface.getFieldData)
        .filter(ExcludeFalsy);

      const options = toFieldOptions(fieldsOfType);

      return acc.set(
        referenceType.name,
        uniqBy([...(acc.get(referenceType.name) ?? []), ...options], 'value')
      );
    },
    new Map()
  );

const viewsWithoutReferenceFormattingSupport = [ViewIds.TIMELINE];

export const getDisabledReferenceFormattingMessage = (viewId: ViewIds) => {
  if (viewsWithoutReferenceFormattingSupport.includes(viewId)) {
    return `Reference Labels are not shown in ${viewIdToUserFriendlyName[viewId]}. Go to Block Diagram or Dependency Map to see and edit reference labels.`;
  }
  return undefined;
};

export const hasReferenceLabelFormattingRules = (
  labelFormatting: LabelFormattingInfo[]
) => labelFormatting.some(({ type }) => type === 'reference');

export const getHasMultipleLabels = (
  labelFormatting: LabelFormattingInfo[],
  entity: 'component' | 'reference'
) => {
  let componentFieldsCount = 0;
  let componentRulesCount = 0;

  for (const rule of labelFormatting) {
    if (rule.type === entity) {
      componentRulesCount++;
      componentFieldsCount += rule.fields.length;
    }

    if (componentFieldsCount > 1 || componentRulesCount > 1) {
      return true;
    }
  }

  return false;
};

export const getIsWarningMessage = (
  labelFormatting: LabelFormattingInfo[],
  viewId: ViewIds
) => {
  switch (viewId) {
    case ViewIds.RELATIONSHIPS_3:
      return (
        getHasMultipleLabels(labelFormatting, 'reference') ||
        getHasMultipleLabels(labelFormatting, 'component')
      );

    case ViewIds.TIMELINE:
      return hasReferenceLabelFormattingRules(labelFormatting);

    case ViewIds.BLOCKS: {
      return getHasMultipleLabels(labelFormatting, 'reference');
    }
    default:
      return false;
  }
};

type GetRestrictedMultiLabelFormattingWarningArgs = {
  viewId: ViewIds;
  labelFormatting: LabelFormattingInfo[];
};
export const getRestrictedMultiLabelFormattingWarningProps = ({
  viewId,
  labelFormatting,
}: GetRestrictedMultiLabelFormattingWarningArgs):
  | RestrictedMultiLabelFormattingWarningProps
  | undefined => {
  const isLabelFormattingRestricted =
    isViewWithoutFullyMultiLabelsSupport(viewId);

  if (!isLabelFormattingRestricted) {
    return;
  }

  const message = getRestrictedMultilabelFormattingWarningMessage(
    false,
    viewId
  );

  const isWarning = message
    ? getIsWarningMessage(labelFormatting, viewId)
    : false;

  return message
    ? {
        message,
        type: isWarning ? 'warning' : 'info',
      }
    : undefined;
};
