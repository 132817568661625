import { useState } from 'react';
import { connect } from '@ardoq/rxbeach';
import { DatasourceTable, newTableTheme } from '@ardoq/table';
import {
  ServiceAccountsViewModel,
  manageServiceAccountsViewModel$,
} from './ManageServiceAccountsViewModel$';
import { Island } from '@ardoq/page-layout';
import { FormSize, SearchInput } from '@ardoq/forms';
import { PaginationBar, PaginationController } from '@ardoq/pagination';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import {
  getServiceAccountsTableColumns,
  getServiceAccountsTableDataSource,
} from './serviceAccountsTabelUtils';
import CopyAndDisappearToken from 'admin/user/TokenView/CopyAndDisappearToken/CopyAndDisappearToken';
import { ErrorMessage } from '@ardoq/error-boundary';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';

import { DoqLoadingAnimation, DoqSize, DoqType } from '@ardoq/doq';
import { Text } from '@ardoq/typography';

const ManageServiceAccounts = ({
  isLoading,
  serviceAccounts,
  commands,
  errorMessage,
  searchKey,
}: ServiceAccountsViewModel) => {
  const [perPage, setPerPage] = useState(10);

  if (errorMessage) {
    return <ErrorMessage />;
  }

  if (isLoading) {
    return (
      <Island>
        <Stack align="center" justify="center" gap="large">
          <Text variant="text1Bold">Loading service accounts...</Text>
          <DoqLoadingAnimation
            delay={700}
            doqSize={DoqSize.MEDIUM}
            doqs={[
              DoqType.SIMPLE,
              DoqType.ALGORITHM,
              DoqType.ERROR,
              DoqType.SUCCESS,
              DoqType.COMPONENT_MERGE_EMPTY,
            ]}
          />
        </Stack>
      </Island>
    );
  }

  return (
    <Island>
      <Stack gap="xlarge">
        <CopyAndDisappearToken />
        {serviceAccounts.length > 0 || searchKey ? (
          <PaginationController
            dataSource={getServiceAccountsTableDataSource(
              serviceAccounts,
              commands
            )}
            perPage={perPage}
            render={({
              currentPageDataSource,
              currentPageNumber,
              perPage,
              totalResults,
              onPageSelect,
            }) => (
              <Stack gap="xlarge">
                <SearchInput
                  value={searchKey}
                  placeholder="Search account name"
                  formSize={FormSize.DEFAULT}
                  onValueChange={commands.changeSearchKey}
                />
                <Box>
                  <DatasourceTable
                    dataSource={currentPageDataSource}
                    components={newTableTheme}
                    columns={getServiceAccountsTableColumns()}
                    renderEmptyTable={{ title: 'No results' }}
                  />
                  {perPage < totalResults && (
                    <PaginationBar
                      style={{ justifyContent: 'center', padding: '20px' }}
                      currentPageNumber={currentPageNumber}
                      perPage={perPage}
                      totalResults={totalResults}
                      onChange={({
                        currentPageNumber: newPageNumber,
                        perPage: newPerPage,
                      }) => {
                        setPerPage(newPerPage);
                        onPageSelect(newPageNumber);
                      }}
                    />
                  )}
                </Box>
              </Stack>
            )}
          />
        ) : (
          <FlexBox justify="center">
            <EmptyState
              title="No service accounts created yet"
              description="Service accounts help manage API tokens and user access, simplifying provisioning and preventing disruptions when users leave. Once created, you can view and manage service accounts here."
              media={<EmptyStateNoResultIllustration />}
            ></EmptyState>
          </FlexBox>
        )}
      </Stack>
    </Island>
  );
};

export default connect(ManageServiceAccounts, manageServiceAccountsViewModel$);
