import { combineLatest, filter, map, Observable, of, tap } from 'rxjs';
import useCaseLandingPage$ from './useCaseLandingPage$';
import { dispatchAction } from '@ardoq/rxbeach';
import { requestLoadMetamodel } from '../actions';
import { useCaseOperations } from '../useCaseOperations';
import {
  getUseCase,
  loadUseCase,
  selectUseCase,
  setActiveLandingPageTab,
} from './actions';
import {
  AugmentationEnablement,
  UseCaseLandingPageIslandProps,
  UseCaseLandingPageMetamodelPreviewProps,
  UseCaseLandingPageProps,
  UseCaseLandingPageState,
  UseCaseLandingPageTab,
  UseCasePreviewContent,
  UseCasePreviewContentProps,
  UseCaseTabsProps,
} from './types';
import currentUser$ from '../../streams/currentUser/currentUser$';
import {
  capitalize,
  getIsOrgAdmin,
  isArdoqError,
  isJust,
  Maybe,
  pluralize,
  Result,
} from '@ardoq/common-helpers';
import { setupOperations } from 'useCases/UseCaseSetup/setupOperations';
import confirmLoadUseCase from './confirmLoadUseCase';
import { getIconNameForAssetType } from '@ardoq/icons';
import {
  APIMetaModelPreview,
  ArdoqId,
  AssetType,
  PersistedUseCase,
  PersonalSetting,
  UseCaseContentRepresentation,
  UseCaseMaterial,
  UseCaseSummary,
} from '@ardoq/api-types';
import CurrentUser from 'models/currentUser';
import {
  trackClickedStartUseCaseButton,
  trackClickedStartUseCaseButtonInConfirmationDialog,
} from '../tracking';
import {
  catchError,
  distinctUntilKeyChanged,
  shareReplay,
} from 'rxjs/operators';
import { logError } from '@ardoq/logging';
import { customUseCaseContentTypeRepresentationLabel } from '../consts';
import { solutionMaterialsBrowserCommands as commands } from './solutionMaterialsBrowserCommands';
import { Features, hasFeature } from '@ardoq/features';
import { PermissionContext } from '@ardoq/access-control';
import { dashboardAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/dashboards';
import currentUserPermissionContext$ from '../../streams/currentUserPermissions/currentUserPermissionContext$';
import { reportAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/report';
import { traversalAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/traversals';
import { viewpointAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/viewpoints';
import { presentationAccessControlOperations } from '../../resourcePermissions/accessControlHelpers/presentations';
import { surveyAccessControlInterface } from '../../resourcePermissions/accessControlHelpers/surveys';
import presentations$ from '../../streams/presentations/presentations$';
import broadcast$ from '../../broadcasts/broadcast$';

const getTabProps = (activeTab: UseCaseLandingPageTab): UseCaseTabsProps[] => [
  {
    tabId: UseCaseLandingPageTab.METAMODEL,
    label: 'Metamodel',
    isActive: activeTab === UseCaseLandingPageTab.METAMODEL,
    onClick: () =>
      dispatchAction(setActiveLandingPageTab(UseCaseLandingPageTab.METAMODEL)),
  },
  {
    tabId: UseCaseLandingPageTab.SOLUTION_MATERIAL,
    label: 'Solution materials',
    isActive: activeTab === UseCaseLandingPageTab.SOLUTION_MATERIAL,
    onClick: () =>
      dispatchAction(
        setActiveLandingPageTab(UseCaseLandingPageTab.SOLUTION_MATERIAL)
      ),
  },
];

const checkForResourcePermission = (
  permissionContext: PermissionContext,
  contentType: UseCaseMaterial,
  id: ArdoqId
): boolean => {
  if (contentType === AssetType.DASHBOARD)
    return dashboardAccessControlInterface.canReadDashboard(
      permissionContext,
      id
    );
  if (contentType === AssetType.REPORT)
    return reportAccessControlInterface.canAccessReport({
      permissionContext,
      reportId: id,
    });
  if (contentType === AssetType.TRAVERSAL)
    return traversalAccessControlInterface.canAccessTraversal(
      permissionContext,
      id
    );
  if (contentType === AssetType.VIEWPOINT)
    return viewpointAccessControlInterface.canReadViewpoint(id);
  if (contentType === AssetType.PRESENTATION)
    return presentationAccessControlOperations.canEditPresentation(
      permissionContext,
      presentations$.state.byId[id]
    );
  if (contentType === AssetType.SURVEY)
    return surveyAccessControlInterface.canEditSurvey(id);
  if (contentType === AssetType.BROADCAST)
    return hasFeature(Features.BROADCASTS);

  return false;
};

const getOnClickHandler = (
  contentType: UseCaseMaterial,
  id: ArdoqId
): VoidFunction | undefined => {
  if (contentType === AssetType.DASHBOARD)
    return () => commands.openDashboard(id);
  if (contentType === AssetType.REPORT) return () => commands.openReport(id);
  if (contentType === AssetType.TRAVERSAL)
    return () => commands.openTraversal(id);
  if (contentType === AssetType.VIEWPOINT)
    return () => commands.openDiscoverViewpoint(id);
  if (contentType === AssetType.PRESENTATION)
    return () => commands.openPresentationEditor(id);
  if (contentType === AssetType.SURVEY)
    return () => commands.openSurveyEditor(id);
  if (contentType === AssetType.BROADCAST)
    return () => {
      const broadcast = broadcast$.state.broadcastsById[id];
      if (broadcast) {
        return commands.openBroadcast(id, broadcast.status);
      }
    };

  return undefined;
};

const getUseCaseContentTypeRepresentation = (
  [contentType, names]: [string, UseCaseContentRepresentation[]],
  isAddedUseCase: boolean,
  permissionContext: PermissionContext
): UseCasePreviewContent => ({
  count: names.length,
  label: pluralize(
    customUseCaseContentTypeRepresentationLabel[contentType] ??
      capitalize(contentType),
    names.length
  ),
  names: names.map(({ name, id }) => {
    if (!isAddedUseCase && !hasFeature(Features.LIST_USE_CASES_FROM_OWN_ORG))
      return {
        name,
        _id: id,
      };
    const resourceType = contentType as UseCaseMaterial;
    const canAccessResource = checkForResourcePermission(
      permissionContext,
      resourceType,
      id
    );
    const onMaterialClick = canAccessResource
      ? getOnClickHandler(resourceType, id)
      : undefined;
    return {
      name,
      _id: id,
      onMaterialClick,
    };
  }),
  iconName: getIconNameForAssetType(contentType as UseCaseMaterial),
});

const getPreviewContentPropsFromUseCasePreview = ({
  useCasePreview,
  selectedUseCase,
  permissionContext,
}: {
  useCasePreview: PersistedUseCase;
  selectedUseCase?: Maybe<UseCaseSummary>;
  permissionContext: PermissionContext;
}): UseCasePreviewContentProps => {
  const isAddedUseCase = useCaseOperations.isAddedUseCase(selectedUseCase);
  return {
    content: Object.entries(useCasePreview?.contentByType ?? {}).map(content =>
      getUseCaseContentTypeRepresentation(
        content,
        isAddedUseCase,
        permissionContext
      )
    ),
  };
};

const prepareMetaModelPreviewIslandProps = (
  metaModelPreview: Result<APIMetaModelPreview>,
  selectedUseCase: UseCaseSummary
): UseCaseLandingPageMetamodelPreviewProps => {
  let metamodelPreviewContentProps;
  if (isArdoqError(metaModelPreview))
    metamodelPreviewContentProps = {
      error: metaModelPreview,
    };
  else {
    const { componentTypeFieldsAndTriples, ...metaModel } = metaModelPreview;
    metamodelPreviewContentProps = {
      viewModel: setupOperations.metaModelToSimpleViewModel(metaModel),
      componentTypeFieldsAndTriples,
    };
  }

  return {
    useCaseName: selectedUseCase.name,
    metamodelPreviewContentProps,
  };
};

const getIslandProps = (
  {
    activeTab,
    useCasePreview,
    selectedUseCase,
    selectedUseCaseMetaModelPreview,
  }: UseCaseLandingPageState,
  permissionContext: PermissionContext
): UseCaseLandingPageIslandProps => {
  const tabProps = getTabProps(activeTab);

  if (activeTab === UseCaseLandingPageTab.SOLUTION_MATERIAL && useCasePreview) {
    return {
      tabProps,
      activeTab,
      previewContentProps: getPreviewContentPropsFromUseCasePreview({
        useCasePreview,
        selectedUseCase,
        permissionContext,
      }),
    };
  } else if (
    activeTab === UseCaseLandingPageTab.METAMODEL &&
    isJust(selectedUseCaseMetaModelPreview) &&
    isJust(selectedUseCase)
  ) {
    return {
      tabProps,
      activeTab,
      metamodelPreviewProps: prepareMetaModelPreviewIslandProps(
        selectedUseCaseMetaModelPreview,
        selectedUseCase
      ),
    };
  }
  return {
    tabProps,
    activeTab: null,
  };
};

const markUseCaseUpdateAsSeenInPersonalSettings = (
  useCase: UseCaseSummary
): void => {
  const seenUpdates = CurrentUser.getPersonalSetting(
    PersonalSetting.USE_CASE_UPDATES_SEEN
  );
  if (!seenUpdates.includes(useCase?._id)) {
    CurrentUser.setPersonalSetting(PersonalSetting.USE_CASE_UPDATES_SEEN, [
      ...seenUpdates,
      useCase._id,
    ]);
  }
};

const getLoadUseCase = (
  { selectedUseCase, useCasePreview }: UseCaseLandingPageState,
  permissionContext: PermissionContext
) => {
  // contentRepresentation is used inside the confirmation modal to show a preview of the content that will be loaded
  const contentRepresentation = useCasePreview
    ? getPreviewContentPropsFromUseCasePreview({
        useCasePreview,
        selectedUseCase,
        permissionContext,
      })
    : null;
  return async () => {
    if (selectedUseCase) {
      trackClickedStartUseCaseButton(selectedUseCase.name);
      const isConfirmed = await confirmLoadUseCase(
        contentRepresentation?.content ?? []
      );
      if (isConfirmed) {
        trackClickedStartUseCaseButtonInConfirmationDialog(
          selectedUseCase.name
        );
        dispatchAction(loadUseCase(selectedUseCase._id));
      }
    }
  };
};

const getShouldExpand = (mdString: string | null | undefined) => {
  if (!mdString) return false;
  const numberOfBreaks = mdString.split('\n').length;
  const hasLineBreaks = numberOfBreaks > 0;
  if (mdString.length < 50 && !hasLineBreaks) return false;
  if (hasLineBreaks && numberOfBreaks > 3) return true;
};

const viewModel$: Observable<UseCaseLandingPageProps> = combineLatest([
  useCaseLandingPage$,
  currentUser$,
  currentUserPermissionContext$,
]).pipe(
  map(([useCaseLandingPageState, currentUser, permissionContext]) => {
    const {
      selectedUseCase,
      isLoadingUseCase,
      loadUseCaseErrorProps,
      useCases,
      useCasePreview,
    } = useCaseLandingPageState;

    return {
      selectUseCase: (useCaseId: Maybe<ArdoqId>) => {
        if (useCaseId) dispatchAction(selectUseCase(useCaseId));
      },
      useCaseSelectOptions:
        useCaseOperations.useCasesToSelectOptionsWithUpdateInfo(useCases),
      isLoadingUseCase,
      loadUseCase: getLoadUseCase(useCaseLandingPageState, permissionContext),
      loadUseCaseErrorProps,
      isOrgAdmin: getIsOrgAdmin(currentUser),
      ...(selectedUseCase
        ? {
            selectedUseCase,
            showAugmentations: getShowValueStreamAugmentations(selectedUseCase),
            augmentationEnablement: getAugmentationEnablement(selectedUseCase),
            islandProps: getIslandProps(
              useCaseLandingPageState,
              permissionContext
            ),
            descriptionAndBusinessOutcomesProps: {
              title: selectedUseCase.name,
              description: selectedUseCase.description,
              businessOutcomes: useCasePreview?.businessOutcomes,
              pdfLinks: useCasePreview?.pdfLinks,
              expandable:
                getShouldExpand(useCasePreview?.businessOutcomes) ||
                getShouldExpand(selectedUseCase.description),
              iteration: useCasePreview?.iteration,
            },
          }
        : {
            selectedUseCase: null,
            augmentationEnablement: null,
            showAugmentations: null,
            islandProps: null,
            descriptionAndBusinessOutcomesProps: null,
          }),
    };
  }),
  catchError(error => {
    logError(error);
    return of({ error });
  })
);

const getShowValueStreamAugmentations = (selectedUseCase: UseCaseSummary) => {
  const enabled = hasFeature(Features.AI_VALUE_STREAMS);
  if (!enabled) return false;
  if (selectedUseCase.augmentations === undefined) return false;
  if (selectedUseCase.augmentations.length === 0) return false;
  return true;
};

const getAugmentationEnablement = (
  selectedUseCase: UseCaseSummary
): AugmentationEnablement => {
  const augmentState: AugmentationEnablement = {
    valueProposition: {
      enabled: false,
      disabledReason: undefined,
      completed: false,
      displayName: undefined,
    },
    valueStream: {
      enabled: false,
      disabledReason: undefined,
      completed: false,
      displayName: undefined,
    },
    businessCapabilityMap: {
      enabled: false,
      disabledReason: undefined,
      completed: false,
      displayName: undefined,
    },
    linking: {
      enabled: false,
      disabledReason: undefined,
      completed: false,
      displayName: undefined,
    },
  };

  const updateAugmentState = (
    stateId:
      | 'valueProposition'
      | 'valueStream'
      | 'businessCapabilityMap'
      | 'linking',
    apiId: string
  ) => {
    const augment = selectedUseCase.augmentations!.find(
      augment => augment.id === apiId
    );
    augmentState[stateId].enabled = augment?.enabled ?? false;
    augmentState[stateId].disabledReason = augment?.['disabled-reason'];
    augmentState[stateId].completed = augment?.completed ?? false;
    augmentState[stateId].displayName = augment?.['display-name'];
  };

  updateAugmentState('valueProposition', 'autocomplete-value-proposition');
  updateAugmentState('linking', 'vs-bcm-linking');
  updateAugmentState('businessCapabilityMap', 'business-capability-map');
  updateAugmentState('valueStream', 'value-streams');

  return augmentState;
};

// Think of this as a routine that listens to the stream instead of an action.
// It checks whether 'selectedUseCase' has been changed, and requests the
// metamodel preview if that's the case.
const requestMetamodelPreviewOnUseCaseChange$ = useCaseLandingPage$.pipe(
  map(({ selectedUseCase }) => selectedUseCase),
  filter(Boolean),
  distinctUntilKeyChanged('_id'),
  tap(({ _id, status }) => {
    dispatchAction(
      requestLoadMetamodel({
        id: _id,
        status,
      })
    );
  }),
  tap(selectedUseCase => {
    if (useCaseOperations.hasUpdateAvailable(selectedUseCase))
      markUseCaseUpdateAsSeenInPersonalSettings(selectedUseCase);
  }),
  shareReplay({ bufferSize: 1, refCount: true })
);

const getPreviewAssetsOnUseCaseChange$ = useCaseLandingPage$.pipe(
  map(({ selectedUseCase }) => selectedUseCase),
  filter(Boolean),
  distinctUntilKeyChanged('_id'),
  tap(({ _id, status }) => {
    dispatchAction(
      getUseCase({
        id: _id,
        status,
      })
    );
  }),
  shareReplay({ bufferSize: 1, refCount: true })
);

export default combineLatest({
  useCaseLandingPage: requestMetamodelPreviewOnUseCaseChange$,
  viewModel: viewModel$,
  previewAssets: getPreviewAssetsOnUseCaseChange$,
}).pipe(map(({ viewModel }) => viewModel));
