import {
  collectRoutines,
  dispatchAction,
  routine,
  extractPayload,
  ofType,
} from '@ardoq/rxbeach';
import { deleteGlobalField, openGlobalFieldManager } from './actions';
import { map, tap } from 'rxjs/operators';
import GlobalFieldManagerDialog from './GlobalFieldManagerDialog';
import fields from 'collections/fields';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { confirmDeleteAsset } from 'components/DeleteAssetModal/DeleteAssetModal';
import { DeleteType } from 'components/DeleteAssetModal/types';
import { pushStackPage } from '@ardoq/stack-page-manager';
import { setQueryEditorIsOpenInModal } from '../../search/SearchTabContainer/actions';
import { isArdoqError } from '@ardoq/common-helpers';
import { logError } from '@ardoq/logging';

const openGlobalFieldEditorDialog = (shouldOpenInEditMode: boolean) =>
  pushStackPage(({ closeStackPage }) => (
    <GlobalFieldManagerDialog
      closeHandler={() => {
        dispatchAction(setQueryEditorIsOpenInModal(false));
        closeStackPage();
      }}
      shouldOpenInEditMode={shouldOpenInEditMode}
    />
  ));

const handleOpenGlobalFieldManager = routine(
  ofType(openGlobalFieldManager),
  tap(() => {
    dispatchAction(setQueryEditorIsOpenInModal(true));
    openGlobalFieldEditorDialog(false);
  })
);

const handleDeleteGlobalField = routine(
  ofType(deleteGlobalField),
  extractPayload(),
  map(({ label, ids }) => ({
    label,
    fieldsToDelete: [...new Set(ids)].map(
      fieldId => fields.collection.get(fieldId)!
    ),
  })),
  tap(async ({ fieldsToDelete, label }) => {
    const fieldUsage = await fieldInterface.getCombinedUsages([
      fieldsToDelete[0].id,
    ]);
    if (isArdoqError(fieldUsage)) {
      return logError(fieldUsage);
    }
    const affectedEntities = fieldUsage.reduce(
      (acc, wsUsage) => ({
        componentCount: acc.componentCount + wsUsage.componentCount,
        referenceCount: acc.referenceCount + wsUsage.referenceCount,
        surveyCount: acc.surveyCount + wsUsage.surveyCount,
        workspaceCount: acc.workspaceCount + 1,
      }),
      {
        componentCount: 0,
        referenceCount: 0,
        surveyCount: 0,
        workspaceCount: 0,
      }
    );

    const shouldDeleteField = await confirmDeleteAsset({
      deleteType: DeleteType.FIELD,
      affectedEntities,
      name: label,
    });

    if (shouldDeleteField) {
      fieldInterface.deleteFieldsGlobally(fieldsToDelete.map(f => f.id));
    }
  })
);

export default collectRoutines(
  handleOpenGlobalFieldManager,
  handleDeleteGlobalField
);
