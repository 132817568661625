import {
  APIViewpointAttributes,
  LoadedState,
  StartContextSelectionType,
} from '@ardoq/api-types';
import { dispatchAction } from '@ardoq/rxbeach';
import {
  hideViewSidePanel,
  showViewSidePanel,
} from '../views/mainApp/viewSidePanel/actions';
import { ViewSidePanelContentIds } from '../views/mainApp/viewSidePanel/types';
import { clearLoadedState } from '../loadedState/actions';
import { executeViewpoint } from '../viewpointBuilder/actions';
import { viewpointNavigatorCommands } from '../viewpointNavigator/viewpointNavigatorCommands';

type ContextToolBarCommands = {
  switchViewpoint: () => void;
  exploreGraph: () => void;
  setNewViewpoint: (
    viewpoint: APIViewpointAttributes,
    startSet: string[]
  ) => void;
  openViewpointBuilder: (loadedState: LoadedState) => void;
};

const switchViewpoint = () => {
  dispatchAction(showViewSidePanel(ViewSidePanelContentIds.VIEWPOINT_SWITCHER));
};

const exploreGraph = () => {
  dispatchAction(showViewSidePanel(ViewSidePanelContentIds.EXPLORE_GRAPH));
};

const setNewViewpoint = (
  viewpoint: APIViewpointAttributes,
  startSet: string[]
) => {
  // TODO CL find a less wonky way to replace loadedState
  dispatchAction(clearLoadedState());
  dispatchAction(
    executeViewpoint({
      type: 'EXECUTE_SAVED_VIEWPOINT',
      componentSelection: {
        startSet,
        startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
      },
      viewpointId: viewpoint._id,
      filters: viewpoint.filters ?? {},
      viewName: viewpoint.viewName,
      paths: viewpoint.paths ?? [],
      groupBys: viewpoint.groupBys ?? [],
      conditionalFormatting: viewpoint.conditionalFormatting ?? [],
      pathMatching: viewpoint.pathMatching,
      pathCollapsingRules: viewpoint.pathCollapsingRules ?? [],
    })
  );
  dispatchAction(hideViewSidePanel());
};

const openViewpointBuilder = (loadedState: LoadedState) => {
  viewpointNavigatorCommands.editBlock(loadedState);
};

export const contextToolBarCommands: ContextToolBarCommands = {
  switchViewpoint,
  setNewViewpoint,
  openViewpointBuilder,
  exploreGraph,
};
