import { APIEntityType, ArdoqId } from '@ardoq/api-types';
import { GetContextMenuOptionsArguments } from '@ardoq/context-menu';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { TrackedContextMenus, getTrackingFunction } from './tracking';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  getDeleteReferenceMenuItem,
  getDropdownDivider,
  getEditPropertiesMenuItem,
  getFilterReferenceMenuItem,
  getHistoryMenuItem,
  getMergeEntitiesMenuItem,
  getReferenceNavigateToMenuItem,
  getReverseReferenceMenuItem,
  getToggleLockMenuItem,
} from './menuItems';
import { referenceInterface } from '@ardoq/reference-interface';
import { checkHasWriteAccess } from './utils';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

type GetReferenceMenuArgs = {
  referenceIds: ArdoqId[];
  isViewpointMode: boolean;
} & GetContextMenuOptionsArguments;
export const getReferenceMenu = ({
  referenceIds,
  target,
  isViewpointMode,
}: GetReferenceMenuArgs): DropdownItem[] | null => {
  const menuItemReferenceTrackingFunction = getTrackingFunction(
    target,
    TrackedContextMenus.REFERENCE_CONTEXT_MENU
  );

  const workspaceIds = [
    ...new Set(
      [...referenceIds.map(referenceInterface.getRootWorkspaceId)].filter(
        ExcludeFalsy
      )
    ),
  ];
  const hasWriteAccess = checkHasWriteAccess({ workspaceIds, referenceIds });
  const isSingleReference = referenceIds.length === 1;

  if (!isSingleReference && !hasWriteAccess) {
    // no context menu for multiselect with no write access
    return null;
  }

  const referenceNavigateToMenuItem = isViewpointMode
    ? null
    : getReferenceNavigateToMenuItem(
        referenceIds[0],
        menuItemReferenceTrackingFunction
      );
  const filterReferenceMenuItem = isViewpointMode
    ? null
    : getFilterReferenceMenuItem(
        referenceIds[0],
        menuItemReferenceTrackingFunction
      );
  if (!hasWriteAccess) {
    const menu = [
      ...(referenceNavigateToMenuItem
        ? [referenceNavigateToMenuItem, getDropdownDivider()]
        : []),
      filterReferenceMenuItem,
    ].filter(ExcludeFalsy);

    return menu.length ? menu : null;
  }

  const hasLockedReferences = referenceIds.some(referenceInterface.isLocked);

  const activeScenarioState = getActiveScenarioState();
  const canEditScenario =
    !activeScenarioOperations.isInScenarioMode(activeScenarioState) ||
    scenarioAccessControlInterface.canEditActiveScenario(
      currentUserInterface.getPermissionContext(),
      activeScenarioState
    );
  const canEditAllReferencesTargets =
    canEditScenario &&
    referenceIds.every(referenceId =>
      referenceAccessControlOperation.canEditReferencesTargetComponentByReferenceId(
        referenceId
      )
    );

  const historyMenuItem =
    isSingleReference &&
    getHistoryMenuItem(
      referenceIds[0],
      APIEntityType.REFERENCE,
      menuItemReferenceTrackingFunction
    );

  const editItems = !hasLockedReferences
    ? [
        getEditPropertiesMenuItem(
          [],
          referenceIds,
          menuItemReferenceTrackingFunction
        ),
        !isViewpointMode &&
          getReverseReferenceMenuItem(
            referenceIds,
            menuItemReferenceTrackingFunction,
            canEditAllReferencesTargets
          ),
        getDeleteReferenceMenuItem(
          referenceIds,
          menuItemReferenceTrackingFunction
        ),
        getDropdownDivider(),
      ]
    : [];

  const toggleLockMenuItem = isViewpointMode
    ? null
    : getToggleLockMenuItem(
        [],
        referenceIds,
        menuItemReferenceTrackingFunction
      );
  const menu = [
    ...editItems,
    historyMenuItem,
    toggleLockMenuItem,
    historyMenuItem || toggleLockMenuItem ? getDropdownDivider() : null,
    ...(isSingleReference
      ? [
          referenceNavigateToMenuItem,
          referenceNavigateToMenuItem ? getDropdownDivider() : null,
          filterReferenceMenuItem,
        ]
      : [
          !hasLockedReferences &&
            !isViewpointMode &&
            getMergeEntitiesMenuItem(
              referenceIds,
              APIEntityType.REFERENCE,
              menuItemReferenceTrackingFunction
            ),
        ]),
  ].filter(ExcludeFalsy);

  return menu.length ? menu : null;
};
