import { ArdoqId } from '@ardoq/api-types';
import { pluralize } from '@ardoq/common-helpers';
import { EnhancedScopeData } from '@ardoq/data-model';
import { ComponentDecoratorAsIcon } from '@ardoq/decorators';
import { colors, space } from '@ardoq/design-tokens';
import type { ComponentRepresentationDataWithColorShades } from '@ardoq/graph';
import { WorkspaceIcon, DescriptionIcon } from '@ardoq/icons';
import { InventoryDatasourceSelection } from '@ardoq/inventory';
import { Box, FlexBox } from '@ardoq/layout';
import { WithPopover } from '@ardoq/popovers';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';
import { StatusType, Tag } from '@ardoq/status-ui';
import styled from 'styled-components';
import { getComponentRepresentationDataWithColorShades } from '../componentRepresentationData';

type DatasourceSelectionRepresentationProps = {
  datasourceSelection: InventoryDatasourceSelection;
  scopeData: EnhancedScopeData;
};

export const DatasourceSelectionRepresentation = ({
  datasourceSelection,
  scopeData,
}: DatasourceSelectionRepresentationProps) => {
  switch (datasourceSelection.dataSourceType) {
    case 'none':
      return null;
    case 'report':
      return (
        <FlexBox align="center" gap="small">
          <DescriptionIcon color={colors.iconInfo} />
          {datasourceSelection.report.name}
        </FlexBox>
      );

    case 'workspace':
      return (
        <DisplayMultipleWorkspaces
          workspaceIds={datasourceSelection.selectedWorkspaceIds}
          scopeData={scopeData}
        />
      );

    case 'componentType':
      return (
        <DisplayMultipleComponentTypes
          scopeData={scopeData}
          componentTypeNames={datasourceSelection.componentTypeNames}
        />
      );

    default:
      datasourceSelection satisfies never;
      return null;
  }
};

type DisplayMultipleWorkspacesProps = {
  scopeData: EnhancedScopeData;
  workspaceIds: ArdoqId[];
};

const DisplayMultipleWorkspaces = ({
  workspaceIds,
  scopeData,
}: DisplayMultipleWorkspacesProps) => {
  const [firstWorkspaceId, secondWorkspaceId, ...otherWorkspaces] =
    workspaceIds;
  return (
    <DisplayMultipleRepresentations
      tagLabel={pluralize('Workspace', workspaceIds.length)}
      first={
        firstWorkspaceId ? (
          <WorkspaceRepresentation
            workspaceName={
              scopeData.workspacesById[firstWorkspaceId]?.name ?? ''
            }
          />
        ) : null
      }
      second={
        secondWorkspaceId ? (
          <WorkspaceRepresentation
            workspaceName={
              scopeData.workspacesById[secondWorkspaceId]?.name ?? ''
            }
          />
        ) : null
      }
      rest={
        otherWorkspaces.length > 0 ? (
          <ManyNamesInTooltip
            names={otherWorkspaces.map(
              workspaceId => scopeData.workspacesById[workspaceId]?.name ?? ''
            )}
          />
        ) : null
      }
    />
  );
};

type DisplayMultipleComponentTypesProps = {
  scopeData: EnhancedScopeData;
  componentTypeNames: string[];
};

const getComponentTypeRepresentationData = (
  componentTypeName: string,
  scopeData: EnhancedScopeData
): ComponentRepresentationDataWithColorShades => {
  const [componentType] =
    enhancedScopeDataOperations.getComponentTypesByComponentTypeName(
      scopeData,
      componentTypeName
    );
  const emptyComponentType = { icon: null, image: null, color: null, level: 0 }; // we get empty scope data at the beginning
  return getComponentRepresentationDataWithColorShades(
    componentType ?? emptyComponentType
  );
};

const DisplayMultipleComponentTypes = ({
  componentTypeNames,
  scopeData,
}: DisplayMultipleComponentTypesProps) => {
  const [
    firstComponentTypeName,
    secondComponentTypeName,
    ...otherComponentTypeNames
  ] = componentTypeNames;

  return (
    <DisplayMultipleRepresentations
      tagLabel={pluralize('Component type', componentTypeNames.length)}
      first={
        <ComponentTypeRepresentationRepresentation
          componentTypeName={firstComponentTypeName}
          representationData={getComponentTypeRepresentationData(
            firstComponentTypeName,
            scopeData
          )}
        />
      }
      second={
        secondComponentTypeName ? (
          <ComponentTypeRepresentationRepresentation
            componentTypeName={secondComponentTypeName}
            representationData={getComponentTypeRepresentationData(
              secondComponentTypeName,
              scopeData
            )}
          />
        ) : null
      }
      rest={
        otherComponentTypeNames.length > 0 ? (
          <ManyNamesInTooltip names={otherComponentTypeNames} />
        ) : null
      }
    />
  );
};

type DisplayMultipleRepresentationsProps = {
  first: React.ReactNode;
  second: React.ReactNode | null;
  rest: React.ReactNode | null;
  tagLabel: string;
};
const DisplayMultipleRepresentations = ({
  first,
  second,
  rest,
  tagLabel,
}: DisplayMultipleRepresentationsProps) => {
  return (
    <FlexBox gap="small" align="center">
      <FlexBox align="center">
        {first}
        {<JoinByCommaOrAnd isSecondPresent={!!second} isRestPresent={!!rest} />}
      </FlexBox>
      {second}
      {rest && <>& {rest}</>}
      <Tag statusType={StatusType.INFO} label={tagLabel} />
    </FlexBox>
  );
};

type JoinByCommaOrAndProps = {
  isSecondPresent: boolean;
  isRestPresent: boolean;
};
const JoinByCommaOrAnd = ({
  isSecondPresent,
  isRestPresent,
}: JoinByCommaOrAndProps) => {
  if (isSecondPresent && isRestPresent) {
    return ',';
  }
  if (isSecondPresent) {
    return <Box paddingLeft="small">&</Box>;
  }
  return null;
};

type WorkspaceRepresentationProps = {
  workspaceName: string;
};

const WorkspaceRepresentation = ({
  workspaceName,
}: WorkspaceRepresentationProps) => {
  return (
    <FlexBox align="center" gap="small">
      <NoWrap>
        <WorkspaceIcon color={colors.grey50} /> {workspaceName}
      </NoWrap>
    </FlexBox>
  );
};

type ComponentTypeRepresentationProps = {
  componentTypeName: string;
  representationData: ComponentRepresentationDataWithColorShades;
};

const ComponentTypeRepresentationRepresentation = ({
  componentTypeName,
  representationData,
}: ComponentTypeRepresentationProps) => {
  return (
    <FlexBox align="center" gap="small">
      <ComponentDecoratorAsIcon {...representationData} />
      {componentTypeName}
    </FlexBox>
  );
};

type ManyNamesInTooltipProps = {
  names: string[];
};
const ManyNamesInTooltip = ({ names }: ManyNamesInTooltipProps) => {
  return (
    <NoWrap>
      <WithPopover
        content={() => (
          <>
            {names.map(name => (
              <NoWrap key={name}>{name}</NoWrap>
            ))}
          </>
        )}
      >
        {`${names.length} more`}
      </WithPopover>
    </NoWrap>
  );
};

const NoWrap = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: ${space.xsmall};
`;
