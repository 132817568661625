import { collectRoutines } from '@ardoq/rxbeach';
import documentRoutines from './document/routines';
import analyzeRoutines from './analyze/routines';
import mappingsRoutines from './mappings/routines';
import configsRoutines from './configs/routines';

export default collectRoutines(
  documentRoutines,
  analyzeRoutines,
  mappingsRoutines,
  configsRoutines
);
