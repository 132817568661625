import { TraversalBuilderViewState, TripleOptions } from '../types';
import { DoqContainer, FullHeightSpace } from './atoms';
import { DoqType, ResponsiveDoqWithSpeechBubble } from '@ardoq/doq';
import { EditTraversalCommands } from 'viewpointBuilder/traversals/editTraversalCommands';
import { FlexBox } from '@ardoq/layout';
import { ExploreGraphTriplesToggler } from './ExploreGraphTriplesToggler';

type EditTraversalOptionsProps = {
  selectedGraphNodeId: string | null;
  tripleOptions: TripleOptions;
  commands: EditTraversalCommands;
  triplesSortAndFiltersState: TraversalBuilderViewState['triplesSortAndFiltersState'];
  hasTripleOptions: TraversalBuilderViewState['hasTripleOptions'];
  shouldShowDeleteCurrentNodeButton: boolean;
  isFullWidth?: boolean;
};

export const ExplorerGraphOptionsPanel = ({
  selectedGraphNodeId,
  tripleOptions,
  commands,
  triplesSortAndFiltersState,
  hasTripleOptions,
}: EditTraversalOptionsProps) => {
  if (!selectedGraphNodeId) {
    return (
      <FullHeightSpace>
        <DoqContainer>
          <ResponsiveDoqWithSpeechBubble
            doqType={DoqType.SIMPLE}
            title="Start with a component type"
            message="Select a component type on the canvas on the left to edit the traversal"
          />
        </DoqContainer>
      </FullHeightSpace>
    );
  }

  return (
    <FlexBox padding="small" height="full">
      <ExploreGraphTriplesToggler
        togglerStyle={'checkbox'}
        isDisabledWholeRow={true}
        tripleOptions={tripleOptions}
        commands={commands}
        triplesSortAndFiltersState={triplesSortAndFiltersState}
        hasTripleOptions={hasTripleOptions}
      />
    </FlexBox>
  );
};
