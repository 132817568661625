import {
  QueryBuilderSubquery,
  QueryComponentSelection,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import { mapContainsOperatorForQueryRules } from '../search/AdvancedSearch/utils';
import { LEGACY_OPERATOR_REPLACEMENTS } from '@ardoq/query-builder';

const consolidateLegacyOperators = (
  query: QueryBuilderSubquery
): QueryBuilderSubquery => ({
  ...query,
  rules: mapContainsOperatorForQueryRules(query.rules),
});

// The operators `contains`, `contains_phrase`, `not_contains`, `not_contains_phrase` are deprecated
// but still exists in saved slides and URLs. This is a first step towards consolidating
// these operators to only use `contains_substring` and `not_contains_substring`.
export const consolidateOperatorsForComponentSelection = (
  componentSelection: QueryComponentSelection
): QueryComponentSelection => ({
  ...componentSelection,
  startQuery: {
    ...componentSelection.startQuery,
    rules: [
      componentSelection.startQuery.rules[0], // search context rule
      consolidateLegacyOperators(componentSelection.startQuery.rules[1]), // user-defined rules
    ],
  },
});

export const consolidateLegacyOperatorsForFilters = (
  filters: ViewpointBuilderFilters
) => {
  const newFilters: ViewpointBuilderFilters = {};
  Object.entries(filters).forEach(([id, filter]) => {
    newFilters[id] = {
      ...filter,
      rules: filter.rules.map(rule => ({
        ...rule,
        operator:
          LEGACY_OPERATOR_REPLACEMENTS.get(rule.operator) ?? rule.operator,
      })),
    };
  });
  return newFilters;
};
