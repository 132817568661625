import {
  PerspectiveGroupingRule,
  PerspectivesGroupsOptions,
} from '@ardoq/perspectives';
import { actionCreator } from '@ardoq/rxbeach';
import { APIFieldAttributes } from '@ardoq/api-types';

export type GetGroupingOptionsPayload = {
  workspaceIds: string[];
  componentTypeNames: string[];
  referenceTypeNames: string[];
  availableComponentFields: APIFieldAttributes[];
};

export const getGroupingOptions = actionCreator<GetGroupingOptionsPayload>(
  '[ViewpointGroupingRules] GET_GROUPING_OPTIONS'
);

export type SetGroupingOptionsPayload = {
  workspaceIds: string[];
  componentTypeNames: string[];
  referenceTypeNames: string[];
};

export const setGroupingOptions = actionCreator<PerspectivesGroupsOptions>(
  '[ViewpointGroupingRules] SET_GROUPING_OPTIONS'
);

export type SetGroupingRulesPayload = {
  groupingRules: PerspectiveGroupingRule[];
};

export const setGroupingRules = actionCreator<SetGroupingRulesPayload>(
  '[ViewpointGroupingRules] SET_GROUPING_RULES'
);
