import React, { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const useResizeHeight = (): {
  height: number | undefined;
  ref: React.MutableRefObject<any>;
} => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const { ref } = useResizeDetector({
    handleHeight: true,
    onResize: () => {
      setTimeout(() => {
        if (ref.current) {
          setHeight(ref.current.clientHeight);
        }
      }, 100);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        setHeight(ref.current.clientHeight);
      }
    }, 100);
  }, [ref]);

  return { height, ref: ref as React.MutableRefObject<any> };
};

export default useResizeHeight;
