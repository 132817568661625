import { actionCreator } from '@ardoq/rxbeach';
import { ConfigsState } from './type';

const NAMESPACE = '[integrations lucid-import] CONFIGS';

export const setSelectedWorkspaces = actionCreator<
  ConfigsState['workspaces']['selected']
>(`${NAMESPACE}_SELECT_CONFIG`);

export const resetConfigs = actionCreator(`${NAMESPACE}_RESET_CONFIGS`);

export const restartImport = actionCreator(`${NAMESPACE}_RESTART_IMPORT`);
