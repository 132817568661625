import { PrimaryButton } from '@ardoq/button';
import { connect } from '@ardoq/rxbeach';
import {
  SUBDIVISIONS_STRINGS,
  subdivisionsOperations,
} from '@ardoq/subdivisions';
import { map } from 'rxjs';

import subdivisions$ from 'streams/subdivisions/subdivisions$';
import { subdivisionEditorCommands } from 'subdivisionEditor/subdivisionEditorViewModel$/commands';
import {
  SubdivisionOverviewProps,
  SubdivisionOverviewTable,
} from 'subdivisionEditor/SubdivisionOverview/SubdivisionOverviewTable';
import { Island } from '@ardoq/page-layout';
import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';
import { FlexBox } from '@ardoq/layout';
import { AddIcon } from '@ardoq/icons';

const _SubdivisionOverviewTablePage = (props: SubdivisionOverviewProps) => {
  return (
    <Island>
      {props.subdivisions.length > 0 ? (
        <SubdivisionOverviewTable {...props} />
      ) : (
        <FlexBox justify="center">
          <EmptyState
            title={SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.NO_SUBDIVISIONS_TITLE}
            description={
              SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.NO_SUBDIVISIONS_DESCRIPTION
            }
            media={<EmptyStateNoResultIllustration />}
          >
            <PrimaryButton
              onClick={subdivisionEditorCommands.createSubdivision}
            >
              {SUBDIVISIONS_STRINGS.OVERVIEW_PAGE.CREATE_SUBDIVISION}
              <AddIcon />
            </PrimaryButton>
          </EmptyState>
        </FlexBox>
      )}
    </Island>
  );
};

export const SubdivisionOverviewTablePage = connect(
  _SubdivisionOverviewTablePage,
  subdivisions$.pipe(
    map(subdivisionsContext => ({
      subdivisions:
        subdivisionsOperations.getSubdivisionsList(subdivisionsContext),
      commands: subdivisionEditorCommands,
    }))
  )
);
