import { ArdoqId } from '@ardoq/api-types';
import { dispatchAction, reducedStream, reducer } from '@ardoq/rxbeach';
import { setActiveWsId } from './actions';
import { getInitialState, setWsId } from './reducers';
import { WorkspacePreviewState } from './types';
import { map } from 'rxjs';
import { workspaceInterface } from '@ardoq/workspace-interface';

const setActiveWorkspaceId = (wsId: ArdoqId | undefined, instanceId: string) =>
  dispatchAction(setActiveWsId(instanceId, { wsId }));

export const getWorkspaceSelectorStream = (instanceId: string) => {
  const workspacePreviewStream$ = reducedStream<WorkspacePreviewState>(
    `workspace-preview-${instanceId}`,
    getInitialState(),
    [reducer(setActiveWsId, setWsId)],
    { namespace: instanceId }
  );

  return workspacePreviewStream$.pipe(
    map(({ wsId }) => {
      const workspace = workspaceInterface.get(wsId ?? '');

      if (!workspace || !wsId) {
        return {
          wsInfo: null,
          setActiveWsId: (wsId: ArdoqId | undefined) =>
            setActiveWorkspaceId(wsId, instanceId),
        };
      }

      const componentTypes = workspaceInterface
        .getComponentTypes(wsId)
        .map(({ name }) => name);

      const referenceTypes = Object.values(
        workspaceInterface.getReferenceTypes(wsId) || {}
      ).map(({ name }) => name);

      return {
        wsInfo: {
          id: wsId,
          name: workspace.name,
          description: workspace.description || 'No description',
          componentTypes,
          referenceTypes,
        },
        setActiveWsId: (wsId: ArdoqId | undefined) =>
          setActiveWorkspaceId(wsId, instanceId),
      };
    })
  );
};
