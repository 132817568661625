import { PreviewMatrix } from '@ardoq/integrations';
import { confirm, ModalSize } from '@ardoq/modal';
import { FlexBox } from '@ardoq/layout';

type ReviewCounts = {
  created: {
    components: number;
    references: number;
  };
  updated: {
    workspaces: number;
  };
};

type ReviewProps = {
  counts: ReviewCounts;
};

const supportedEntityTypes = ['workspaces', 'components', 'references'];

const Review = ({ counts }: ReviewProps) => {
  return (
    <FlexBox paddingY="medium" justify="center" align="center">
      <PreviewMatrix
        transferDirection="import"
        entityTypes={supportedEntityTypes}
        totals={counts}
      />
    </FlexBox>
  );
};

export const showReviewModal = (counts: ReviewCounts) => {
  return confirm({
    title: 'Review the import',
    body: <Review counts={counts} />,
    confirmButtonTitle: 'Import data',
    cancelButtonTitle: 'Cancel',
    modalSize: ModalSize.L,
  });
};
