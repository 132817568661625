import { reducedStream, reducer } from '@ardoq/rxbeach';
import { isReadyToUpdate } from 'viewpointBuilder/traversals/editTraversalActions';

const isReadyToUpdateReducer = (_: boolean, isReadyToUpdate: boolean) =>
  isReadyToUpdate;

export const isReadyToUpdate$ = reducedStream<boolean>(
  'isReadyToUpdate',
  false,
  [reducer(isReadyToUpdate, isReadyToUpdateReducer)]
);
