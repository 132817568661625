import { Switch } from '@ardoq/forms';
import { trackEvent } from '../../tracking/tracking';
import { confirmTurnOffChangeApproval } from '../SurveyModals';
import { useEffectOnce } from '@ardoq/hooks';
import { SurveyEditorSectionProps } from './SurveyEditor';
import { Features, hasFeature } from '@ardoq/features';
import { connect, derivedStream, dispatchAction } from '@ardoq/rxbeach';
import {
  APIOrganizationUser,
  APISurveyAttributes,
  ArdoqId,
  isPersistedSurvey,
  MetaModel,
  SurveyApprovalFlow,
} from '@ardoq/api-types';
import permissionGroup$ from '../../admin/accessControl/PermissionGroups/streams/permissionGroups$';
import { PermissionGroup$State } from '../../admin/accessControl/PermissionGroups/types';
import { map } from 'rxjs';
import currentUser$ from 'streams/currentUser/currentUser$';
import { Island } from '@ardoq/page-layout';
import {
  checkIfSurveyConflictsWithChangeApprovalScope,
  getFallbackUserAudience,
  toSurveyApprovalRelationships,
} from './utils';
import FallbackUserSelection from 'surveyAdmin/ChangeApproval/SurveyBuilderElements/FallbackUserSelection';
import PrimaryApproverSelection from 'surveyAdmin/ChangeApproval/SurveyBuilderElements/PrimaryApproverSelection';
import { ErrorNotification } from '@ardoq/status-ui';
import { useEffect } from 'react';
import { requestLoadMetaModel } from 'architectureModel/actions';
import { keyBy } from 'lodash';
import surveyEditor$ from './streams/surveyEditor$';
import {
  getRenderTitle,
  getSubTitle,
} from './LayoutElements/SurveyEditorLayout';
import { fields$ } from 'streams/fields/fields$';
import {
  FieldsByComponentTypeName,
  FieldsByName,
  SurveyValidation,
} from 'surveyAdmin/types';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { getChangeApprovalCompatibillityErrorMessage } from './consts';
import { SurveyErrorName } from 'surveyAdmin/consts';
import { validationHelpers } from 'surveyAdmin/surveyValidation';
import { orgUsers$ } from 'streams/orgUsers/orgUsers$';
import { SurveyResponseApprovalTrialBanner } from './LayoutElements/TrialElements/SurveyResponseApprovalTrialBanner';
import audienceTraversalDrawer$ from 'surveyAdmin/ChangeApproval/SurveyBuilderElements/AudienceTraversalDrawer/audienceTraversalDrawer$';

const getFallbackUserId = (
  approvalFlow: SurveyApprovalFlow | undefined,
  surveyCreatorId: string | undefined,
  currentUserId: string
) => {
  const fallbackUserAudience = getFallbackUserAudience(approvalFlow);
  if (fallbackUserAudience?.audiencesIds.length) {
    return fallbackUserAudience.audiencesIds[0];
  }
  if (surveyCreatorId) {
    return surveyCreatorId;
  }
  return currentUserId;
};

type ChangeApprovalToggleProps = {
  approvalFlow: SurveyApprovalFlow | undefined;
  cannotUseChangeApproval: boolean;
  fallbackUserId: ArdoqId;
  hasPendingApproval: boolean;
  setSurveyAttributes: (newAttributes: Partial<APISurveyAttributes>) => void;
};

const ChangeApprovalToggle = ({
  approvalFlow,
  cannotUseChangeApproval,
  fallbackUserId,
  hasPendingApproval,
  setSurveyAttributes,
}: ChangeApprovalToggleProps) => {
  return (
    <Switch
      isDisabled={
        (cannotUseChangeApproval && !approvalFlow?.enabled) ||
        !hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2)
      }
      label="Enable response approval"
      name="toggle-change-approval"
      isChecked={Boolean(approvalFlow?.enabled)}
      onChange={async enabled => {
        if (
          !enabled &&
          hasPendingApproval &&
          !(await confirmTurnOffChangeApproval())
        )
          return;
        if (!enabled) {
          setSurveyAttributes({
            approvalFlow: {
              enabled,
              fallbackAudience: {
                audienceType: 'user',
                audiencesIds: [fallbackUserId],
              },
            },
          });
        } else {
          setSurveyAttributes({
            approvalFlow: {
              ...approvalFlow,
              enabled,
              fallbackAudience: {
                audienceType: 'user',
                audiencesIds: [fallbackUserId],
              },
            },
          });
        }
        trackEvent('Survey builder: toggle change approval', {
          enabled,
        });
      }}
    />
  );
};

type SurveyEditorChangeApprovalSectionProps = SurveyEditorSectionProps & {
  currentUserId: string;
  metamodel: MetaModel;
  hasPendingApproval: boolean;
  title: JSX.Element | string;
  subtitle: string;
  fieldsByComponentTypeName: FieldsByComponentTypeName;
  surveyValidation: SurveyValidation | undefined;
  usersById: Record<ArdoqId, APIOrganizationUser>;
  isOrgAdmin: boolean;
  isLoadingMetamodel: boolean;
} & Pick<PermissionGroup$State, 'groupsById'>;

const SurveyEditorChangeApprovalSection = ({
  title,
  subtitle,
  surveyAttributes,
  surveyValidation,
  groupsById,
  usersById,
  metamodel,
  currentUserId,
  fieldsByComponentTypeName,
  hasPendingApproval,
  isOrgAdmin,
  isLoadingMetamodel,
  setSurveyAttributes,
}: SurveyEditorChangeApprovalSectionProps) => {
  const { approvalFlow, workspace, componentTypeName } = surveyAttributes;
  const selectableRelationships = toSurveyApprovalRelationships(
    metamodel,
    fieldsByComponentTypeName,
    workspace,
    componentTypeName
  );
  const showAudienceSelecton =
    approvalFlow?.enabled && hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2);

  const fallbackUserId = getFallbackUserId(
    approvalFlow,
    isPersistedSurvey(surveyAttributes)
      ? surveyAttributes.createdBy
      : undefined,
    currentUserId
  );

  const fallbackUserAudience = getFallbackUserAudience(approvalFlow);
  useEffect(() => {
    if (!showAudienceSelecton) return;
    if (fallbackUserAudience?.audiencesIds.length) return;
    setSurveyAttributes({
      approvalFlow: {
        ...approvalFlow,
        fallbackAudience: {
          audienceType: 'user',
          audiencesIds: [fallbackUserId],
        },
      },
    });
  }, [
    approvalFlow,
    fallbackUserId,
    showAudienceSelecton,
    fallbackUserAudience?.audiencesIds.length,
    setSurveyAttributes,
  ]);

  useEffectOnce(() => {
    dispatchAction(requestLoadMetaModel({ currentTriples: [] }));
  });

  const showResponseApprovalTrialBanner =
    hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL) &&
    !hasFeature(Features.SURVEYS_CHANGE_APPROVAL_TRIAL_EXPIRED) &&
    !hasFeature(Features.SURVEYS_CHANGE_APPROVAL_V2);

  return (
    <>
      {showResponseApprovalTrialBanner && (
        <SurveyResponseApprovalTrialBanner isOrgAdmin={isOrgAdmin} />
      )}
      <Island
        title={title}
        subtitle={subtitle}
        preventTitleIconChange
        rightContent={
          <ChangeApprovalToggle
            approvalFlow={surveyAttributes.approvalFlow}
            cannotUseChangeApproval={checkIfSurveyConflictsWithChangeApprovalScope(
              surveyAttributes
            )}
            fallbackUserId={fallbackUserId}
            setSurveyAttributes={setSurveyAttributes}
            hasPendingApproval={hasPendingApproval}
          />
        }
      >
        {showAudienceSelecton && (
          <PrimaryApproverSelection
            approvalFlow={approvalFlow}
            groupsById={groupsById}
            usersById={usersById}
            fieldsByComponentTypeName={fieldsByComponentTypeName}
            selectableRelationships={selectableRelationships}
            componentTypeName={componentTypeName}
            workspaceId={workspace}
            metamodel={metamodel}
            isLoadingMetamodel={isLoadingMetamodel}
            setSurveyAttributes={setSurveyAttributes}
          />
        )}
      </Island>
      {showAudienceSelecton && (
        <FallbackUserSelection
          surveyCreatorId={
            isPersistedSurvey(surveyAttributes)
              ? surveyAttributes.createdBy
              : currentUserId
          }
          approvalFlow={approvalFlow}
          setSurveyAttributes={setSurveyAttributes}
          errorMessage={validationHelpers.getErrorMessageIfExists(
            surveyValidation,
            SurveyErrorName.CHANGE_APPROVAL_FALLBACK_USER
          )}
        />
      )}
      {validationHelpers.containsErrorId(
        surveyValidation,
        SurveyErrorName.CHANGE_APPROVAL_COMPATIBILITY
      ) && (
        <ErrorNotification>
          {getChangeApprovalCompatibillityErrorMessage(
            hasFeature(Features.PERMISSION_ZONES_INTERNAL)
          )}
        </ErrorNotification>
      )}
    </>
  );
};

export default connect(
  SurveyEditorChangeApprovalSection,
  derivedStream(
    'surveyEditorChangeApprovalSection$',
    permissionGroup$,
    audienceTraversalDrawer$,
    currentUser$,
    surveyEditor$,
    fields$,
    orgUsers$
  ).pipe(
    map(
      ([
        { groupsById },
        { metamodel, metamodelStatus },
        currentUser,
        { surveyValidation },
        fields,
        { byId: usersById },
      ]) => {
        const fieldsByName: FieldsByName = keyBy(fields.models, 'name');
        const fieldsByComponentTypeName: FieldsByComponentTypeName =
          Object.fromEntries(
            metamodel.componentTypes.map(({ name, fieldNames }) => [
              name,
              fieldNames
                .map(fieldName => fieldsByName[fieldName])
                .filter(ExcludeFalsy),
            ])
          );
        return {
          currentUserId: currentUser._id,
          groupsById,
          usersById,
          metamodel,
          title: getRenderTitle('changeApprovalSection'),
          subtitle: getSubTitle('changeApprovalSection'),
          fieldsByComponentTypeName,
          surveyValidation,
          isLoadingMetamodel: metamodelStatus === 'loading',
        };
      }
    )
  )
);
