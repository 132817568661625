import { workspaceInterface } from '@ardoq/workspace-interface';
import {
  ArdoqIdMapping,
  ComponentMapping,
  ComponentTypeMapping,
  CustomIdMapping,
  DescriptionMapping,
  DisplayTextMapping,
  FieldMapping,
  PathParentMapping,
  PathReferenceFormat,
  MissingComponentsStrategy,
  IntegrationReferenceDirection,
  ReferenceMapping,
  ReferenceTypeMapping,
  RootReferenceMapping,
  TagsMapping,
  TargetReferenceMapping,
  MissingParentComponentsStrategy,
} from '@ardoq/api-types/integrations';
import { ColumnMapping } from '../transferConfigs/types';
import { TableMappingMap } from './types';
import {
  getDefaultWorkspaceField,
  getNextHierarchyLevel,
  getWorkspaceComponentTypeOptions,
  hasComponentTypeColumn,
  rootWorkspaceExists,
} from './utils';
import { first } from 'lodash/fp';
import { APIFieldAttributes } from '@ardoq/api-types';
import { DEFAULT_REFERENCE_TYPE } from '../references/constants';

type IndexFree<T extends ColumnMapping> = Omit<T, 'index'>;

export const getDefaultColumnMapping = ({
  columnType,
  columnName,
  tableMappingMap,
  allFields,
  isCreationDisabled,
}: {
  columnType: ColumnMapping['columnType'];
  columnName: string;
  tableMappingMap: TableMappingMap;
  allFields: APIFieldAttributes[];
  isCreationDisabled: boolean;
}) => {
  switch (columnType) {
    case 'ardoq-oid':
      return defaultArdoqIdMapping();
    case 'component':
      return defaultComponentMapping({
        columnName,
        tableMappingMap,
      });
    case 'component-type':
      return defaultComponentTypeMapping();
    case 'custom-id':
      return defaultCustomIdMapping({
        columnName,
        tableMappingMap,
      });
    case 'description':
      return defaultDescriptionMapping();
    case 'display-text':
      return defaultDisplayTextMapping();
    case 'field':
      return defaultFieldMapping({ columnName, allFields, isCreationDisabled });
    case 'parent':
      return defaultParentMapping();
    case 'reference':
      return defaultReferenceMapping({
        columnName,
        tableMappingMap,
      });
    case 'reference-type':
      return defaultReferenceTypeMapping();
    case 'root-reference':
      return defaultRootReferenceMapping();
    case 'tags':
      return defaultTagsMapping();
    case 'target-reference':
      return defaultTargetReferenceMapping();
  }
};

export const defaultComponentMapping = ({
  columnName,
  tableMappingMap,
}: {
  columnName: string;
  tableMappingMap: TableMappingMap;
}): IndexFree<ComponentMapping> => {
  const existantRootWorkspace = rootWorkspaceExists(tableMappingMap);
  const constainsComponentTypeColumn = hasComponentTypeColumn(tableMappingMap);
  const typeOptions =
    existantRootWorkspace && !constainsComponentTypeColumn
      ? getWorkspaceComponentTypeOptions(tableMappingMap)
      : [];

  return {
    columnType: 'component',
    componentType: typeOptions.length ? typeOptions[0].value : columnName,
    hierarchyLevel: getNextHierarchyLevel(tableMappingMap),
  };
};

const defaultCustomIdMapping = ({
  columnName,
  tableMappingMap,
}: {
  columnName: string;
  tableMappingMap: TableMappingMap;
}): IndexFree<CustomIdMapping> => {
  const { id: fieldName, label: fieldLabel } = getDefaultWorkspaceField({
    columnName,
    tableMappingMap,
  });
  return {
    columnType: 'custom-id',
    fieldName,
    fieldLabel,
    duplicates: 'error',
  };
};

const defaultReferenceMapping = ({
  columnName,
  tableMappingMap,
}: {
  columnName: string;
  tableMappingMap: TableMappingMap;
}): IndexFree<PathReferenceFormat & ReferenceMapping> => {
  const referenceTypes = tableMappingMap.rootWorkspace?.id
    ? Object.values(
        workspaceInterface.getReferenceTypes(
          tableMappingMap.rootWorkspace?.id
        ) || {}
      ).sort(a => (a.name === DEFAULT_REFERENCE_TYPE ? 1 : -1))
    : [{ name: DEFAULT_REFERENCE_TYPE }];

  return {
    columnType: 'reference',
    referenceType: first(referenceTypes)?.name || DEFAULT_REFERENCE_TYPE,
    referencePathSeparator: '::',
    targetWorkspace: {
      id: tableMappingMap.rootWorkspace.id ?? null,
      name: tableMappingMap.rootWorkspace.name || columnName,
    },
    referenceItemSeparator: ',',
    referenceFormat: 'path',
    referenceDirection: IntegrationReferenceDirection.OUTGOING,
    missingComponents: MissingComponentsStrategy.ERROR,
  };
};

const defaultFieldMapping = ({
  columnName,
  allFields,
  isCreationDisabled,
}: {
  columnName: string;
  allFields: APIFieldAttributes[];
  isCreationDisabled: boolean;
}): IndexFree<FieldMapping> => {
  const existingField = allFields.find(
    ({ label }) => label.toLowerCase() === columnName.trim().toLowerCase()
  );

  if (existingField) {
    return {
      columnType: 'field',
      fieldName: existingField.name,
      fieldLabel: existingField.label,
      fieldType: existingField.type,
    };
  }

  if (isCreationDisabled) {
    const defaultField = allFields[0];
    return {
      columnType: 'field',
      fieldName: defaultField.name,
      fieldLabel: defaultField.label,
      fieldType: defaultField.type,
    };
  }

  return {
    columnType: 'field',
    fieldName: null,
    fieldLabel: columnName || 'New field',
    fieldType: 'Text',
  };
};

const defaultArdoqIdMapping = (): IndexFree<ArdoqIdMapping> => ({
  columnType: 'ardoq-oid',
  duplicates: 'error',
});

const defaultComponentTypeMapping = (): IndexFree<ComponentTypeMapping> => ({
  columnType: 'component-type',
});

const defaultReferenceTypeMapping = (): IndexFree<ReferenceTypeMapping> => ({
  columnType: 'reference-type',
});

const defaultDescriptionMapping = (): IndexFree<DescriptionMapping> => ({
  columnType: 'description',
});

const defaultDisplayTextMapping = (): IndexFree<DisplayTextMapping> => ({
  columnType: 'display-text',
});

const defaultParentMapping = (): IndexFree<PathParentMapping> => ({
  columnType: 'parent',
  parentFormat: 'path',
  parentPathSeparator: null,
  missingComponents: MissingParentComponentsStrategy.ERROR,
});

const defaultRootReferenceMapping = (): IndexFree<
  PathReferenceFormat & RootReferenceMapping
> => ({
  columnType: 'root-reference',
  referenceFormat: 'path',
  referencePathSeparator: '::',
  referenceType: DEFAULT_REFERENCE_TYPE,
  missingComponents: MissingComponentsStrategy.ERROR,
});

const defaultTargetReferenceMapping = (): IndexFree<
  PathReferenceFormat & TargetReferenceMapping
> => ({
  columnType: 'target-reference',
  referenceFormat: 'path',
  referencePathSeparator: '::',
  missingComponents: MissingComponentsStrategy.ERROR,
});

const defaultTagsMapping = (): IndexFree<TagsMapping> => ({
  columnType: 'tags',
  tagsSeparator: ',',
});
