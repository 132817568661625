import { Fragment } from 'react';
import { Stack, FlexBox, Box } from '@ardoq/layout';
import { Select } from '@ardoq/select';
import { VisualReferencesMappingsProps } from './types';
import { IconButton, ButtonSize, SecondaryButton } from '@ardoq/button';
import { Icon, IconName } from '@ardoq/icons';
import { Text } from '@ardoq/typography';
import { colors } from '@ardoq/design-tokens';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { pluralize } from '@ardoq/common-helpers';
import { EmptyMappings } from './EmptyMappings';

export const VisualReferencesMappings = ({
  mappingsStatus,
  references,
  componentsOptions,
  referenceTypeOptions,
  onReferenceMapping,
  onAddNewReferenceMapping,
  onDeleteReferenceMapping,
}: VisualReferencesMappingsProps) => {
  const isSuccess = mappingsStatus === ASYNC_STATUS.SUCCESS;

  return (
    <Stack gap="medium">
      <Box paddingLeft="medium">
        <Text variant="text2">
          {
            'Verify the references. Source -> Reference Type -> Target. Add new referece if missing.'
          }
        </Text>
      </Box>
      <FlexBox
        justify="space-between"
        paddingLeft="medium"
        align="center"
        width="full"
      >
        <Box>
          <Text
            variant="text2Bold"
            color="textSubtle"
          >{`${references.length} ${pluralize('reference', references.length)}`}</Text>
        </Box>
        <SecondaryButton
          onClick={onAddNewReferenceMapping}
          isDisabled={isSuccess}
        >
          <Icon iconName={IconName.ADD} />
          Add new reference
        </SecondaryButton>
      </FlexBox>
      {references.length === 0 && <EmptyMappings entity="reference" />}
      {references.map(reference => {
        // TODO: move the data prep to the viewModel
        const sourceComponentOption = componentsOptions.find(
          ({ component }) => component.id === reference.sourceComponentId
        );

        const wsReferenceTypes =
          referenceTypeOptions.find(
            ({ workspaceId }) =>
              workspaceId === sourceComponentOption?.component.workspaceId
          )?.options || [];

        const referenceTypes = (
          reference.referenceTypeId
            ? wsReferenceTypes
            : [
                {
                  value: reference.referenceTypeId ?? reference.type,
                  label: reference.type,
                  isDisabled: true,
                },
                ...wsReferenceTypes,
              ]
        ).filter(({ label }) => Boolean(label));

        return (
          <Fragment key={reference.id}>
            <FlexBox
              gap="none"
              key={`${reference.source}-${reference.target}-${reference.referenceTypeId}`}
              align="start"
            >
              <FlexBox flex={1} paddingX="medium" paddingY="xsmall">
                <Box width="full">
                  <Select
                    key={`reference-source-select-${reference.id}`}
                    onValueChange={componentId => {
                      if (componentId !== null) {
                        onReferenceMapping({
                          ...reference,
                          sourceComponentId: componentId,
                        });
                      }
                    }}
                    value={reference.sourceComponentId}
                    options={componentsOptions}
                    warningMessage={
                      !reference.sourceComponentId
                        ? 'Missing source component'
                        : undefined
                    }
                  />
                </Box>
              </FlexBox>
              <FlexBox flex={1} paddingX="medium" paddingY="xsmall">
                <Box width="full">
                  <Select
                    key={`reference-type-select-${reference.id}`}
                    onValueChange={id => {
                      if (id !== null) {
                        onReferenceMapping({
                          ...reference,
                          referenceTypeId: id,
                        });
                      }
                    }}
                    warningMessage={
                      !reference.referenceTypeId
                        ? 'Missing reference type'
                        : undefined
                    }
                    value={reference.referenceTypeId ?? reference.type}
                    options={referenceTypes}
                  />
                </Box>
              </FlexBox>
              <FlexBox
                flex={1}
                paddingLeft="medium"
                paddingY="xsmall"
                justify="center"
              >
                <Box width="full">
                  <Select
                    key={`reference-target-select-${reference.id}`}
                    onValueChange={componentId => {
                      if (componentId !== null) {
                        onReferenceMapping({
                          ...reference,
                          targetComponentId: componentId,
                        });
                      }
                    }}
                    value={reference.targetComponentId}
                    options={componentsOptions}
                    warningMessage={
                      !reference.targetComponentId
                        ? 'Missing target component'
                        : undefined
                    }
                  />
                </Box>
                <Box>
                  {isSuccess ? (
                    <Box paddingLeft="xsmall" paddingTop="xsmall">
                      <Icon
                        iconName={IconName.CHECK_CIRCLE}
                        color={colors.surfaceSuccessStrong}
                      />
                    </Box>
                  ) : (
                    <IconButton
                      iconName={IconName.DELETE}
                      buttonSize={ButtonSize.SMALL}
                      onClick={() => onDeleteReferenceMapping(reference)}
                    />
                  )}
                </Box>
              </FlexBox>
            </FlexBox>
          </Fragment>
        );
      })}
    </Stack>
  );
};
