import { ReferenceDirection } from '@ardoq/graph';
import { EnforceContentWidth } from './atoms';
import { TripleOptionsList } from './TripleOptionsList';
import {
  ArdoqLoaderComponent,
  LoaderColor,
} from '@ardoq/ardoq-loader-component';
import { Box, FlexBox, Stack } from '@ardoq/layout';
import { TriplesTogglerProps } from './TriplesToggler';

export const ExploreGraphTriplesToggler = ({
  tripleOptions,
  commands,
  hasTripleOptions,
  togglerStyle,
  isDisabledWholeRow,
}: TriplesTogglerProps) => {
  if (tripleOptions.optionCountLoadingState === 'loading') {
    return (
      <FlexBox
        justify="center"
        align="center"
        padding="xlarge"
        height="full"
        width="full"
      >
        <ArdoqLoaderComponent
          loaderColor={LoaderColor.BRAND}
          size={50}
          loaderText={'Loading reference options'}
        />
      </FlexBox>
    );
  }

  return (
    <EnforceContentWidth>
      <Box padding="medium">
        <Stack gap="large">
          <TripleOptionsList
            options={tripleOptions.outgoing}
            hasTripleOptions={hasTripleOptions.outgoingReferences}
            direction={ReferenceDirection.OUTGOING}
            toggleOption={commands.toggleTripleOption}
            selectedComponent={tripleOptions.selectedNode}
            togglerStyle={togglerStyle}
            isDisabledWholeRow={isDisabledWholeRow}
          />
          <TripleOptionsList
            togglerStyle={togglerStyle}
            options={tripleOptions.incoming}
            hasTripleOptions={hasTripleOptions.incomingReferences}
            direction={ReferenceDirection.INCOMING}
            toggleOption={commands.toggleTripleOption}
            selectedComponent={tripleOptions.selectedNode}
            isDisabledWholeRow={isDisabledWholeRow}
          />
        </Stack>
      </Box>
    </EnforceContentWidth>
  );
};
