import {
  APIEntityType,
  InventoryColumnState,
  LoadedState,
  StartContextSelectionType,
  ViewIds,
} from '@ardoq/api-types';
import {
  ComponentsSelectedInteractiveRowState,
  InventoryDatasourceSelection,
  InventoryGridApi,
  InventoryInteractiveTopRowState,
  InventoryInteractiveTopRowViewState,
  SelectedComponent,
} from '@ardoq/inventory';
import { launchDeleteComponentsAndReferencesConfirmationDialogByIds } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { IconName } from '@ardoq/icons';
import { Maybe } from '@ardoq/common-helpers';
import { DropdownItem, DropdownOptionType } from '@ardoq/dropdown-menu';
import { trackAuditLogEntryPoint } from '../../auditLog/tracking';
import { dispatchAction } from '@ardoq/rxbeach';
import { navigateToAuditLog } from '../../router/navigationActions';
import { openComponents } from 'viewpointBuilder/selectContextTab/actions';
import { confirmClosingPreviousViewIfOpen } from 'viewpointBuilder/utils';
import { ContextShape } from '@ardoq/data-model';
import { exitTraversalMode } from 'traversals/exitTraversalMode';
import { uniq } from 'lodash';
import { openApplySavedViewpointModal } from 'traversals/selectTraversalForContextComponentsModal/actions';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { PermissionContext } from '@ardoq/access-control';
import { GotNextRowsBatchPayload } from './actions';
import { inventoryOperations } from '../inventoryOperations';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { inventoryTracking } from '../inventoryTracking';

const defaultInventoryInteractiveState: InventoryInteractiveTopRowState = {
  mode: 'browseComponents',
  totalNumberOfRows: 0,
};

const enterCreateComponentsModeReducer = (
  state: InventoryInteractiveTopRowState
): InventoryInteractiveTopRowState => {
  return {
    ...state,
    mode: 'createComponents',
    newComponents: [],
  };
};

const exitCreateComponentsModeReducer = (
  state: InventoryInteractiveTopRowState
): InventoryInteractiveTopRowState => {
  return {
    ...state,
    mode: 'browseComponents',
  };
};

const ignoreUpdate = (state: InventoryInteractiveTopRowState) => state;

const getMoreButtonOptions = (
  state: ComponentsSelectedInteractiveRowState,
  inventoryGridApi: Maybe<InventoryGridApi>,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext
): DropdownItem[] => {
  const selectedComponents = state.selectedComponents;
  return [
    {
      label: 'Delete ',
      leftIcon: { name: IconName.DELETE },
      type: DropdownOptionType.OPTION,
      isDisabled: selectedComponents.some(
        component =>
          component.lock ||
          !componentAccessControlOperation.canEditComponent({
            component,
            subdivisionsContext,
            permissionContext,
          })
      ),
      onClick: async () => {
        const deleteConfirmationAndResponse =
          await launchDeleteComponentsAndReferencesConfirmationDialogByIds({
            componentIds: selectedComponents.map(({ _id }) => _id),
            componentNames: selectedComponents.map(({ name }) => name),
            shouldReturnComponentBatchDeleteResponse: true,
          });
        if (
          deleteConfirmationAndResponse &&
          'componentIds' in deleteConfirmationAndResponse &&
          inventoryGridApi
        ) {
          inventoryTracking.trackDeleteSelectedRowsFromTopRow(
            deleteConfirmationAndResponse.componentIds.length
          );
          inventoryGridApi.refreshServerSide();
          inventoryGridApi.deselectAllRows();
        }
      },
    },
    {
      label: 'View history',
      leftIcon: { name: IconName.HISTORY },
      type: DropdownOptionType.OPTION,
      onClick: () => {
        trackAuditLogEntryPoint(`inventory`);
        dispatchAction(
          navigateToAuditLog({
            entities: selectedComponents.map(({ _id, name }) => ({
              id: _id,
              name,
            })),
            entityType: APIEntityType.COMPONENT,
          })
        );
        inventoryTracking.trackNavigateToAuditLogFromTopRow(
          selectedComponents.length
        );
      },
    },
  ];
};

const openSelectedComponentsInVisualization =
  (
    state: ComponentsSelectedInteractiveRowState,
    viewId: ViewIds,
    loadedStates: LoadedState[],
    context: ContextShape
  ) =>
  async () => {
    const isConfirmed = await confirmClosingPreviousViewIfOpen(
      loadedStates,
      context,
      'openNewDataset'
    );
    if (!isConfirmed) return;
    exitTraversalMode();
    dispatchAction(
      openComponents({
        componentSelection: {
          startSet: state.selectedComponents.map(component => component._id),
          startContextSelectionType: StartContextSelectionType.MANUAL_SELECTION,
        },
        viewId,
      })
    );
    inventoryTracking.trackOpenSelectedComponentsInVisualization(
      state.selectedComponents.length,
      viewId
    );
  };

const getVisualizeButtonOptions = (
  state: ComponentsSelectedInteractiveRowState,
  loadedStates: LoadedState[],
  context: ContextShape
): DropdownItem[] => [
  {
    label: 'Block Diagram',
    type: DropdownOptionType.OPTION,
    onClick: openSelectedComponentsInVisualization(
      state,
      ViewIds.MODERNIZED_BLOCK_DIAGRAM,
      loadedStates,
      context
    ),
  },
  {
    label: 'Dependency Map',
    type: DropdownOptionType.OPTION,
    onClick: openSelectedComponentsInVisualization(
      state,
      ViewIds.DEPENDENCY_MAP_2,
      loadedStates,
      context
    ),
  },
  {
    label: 'Relationship',
    type: DropdownOptionType.OPTION,
    onClick: openSelectedComponentsInVisualization(
      state,
      ViewIds.RELATIONSHIPS_3,
      loadedStates,
      context
    ),
  },
  {
    label: 'Timeline',
    type: DropdownOptionType.OPTION,
    onClick: openSelectedComponentsInVisualization(
      state,
      ViewIds.TIMELINE,
      loadedStates,
      context
    ),
  },
  {
    type: DropdownOptionType.DIVIDER,
  },
  {
    label: 'Apply a saved viewpoint',
    type: DropdownOptionType.OPTION,
    isDisabled:
      !inventoryInteractiveTopRowOperations.isSelectedComponentsOfSameType(
        state
      ),
    ...(!inventoryInteractiveTopRowOperations.isSelectedComponentsOfSameType(
      state
    )
      ? {
          popoverProps: {
            content:
              'You can only apply a saved viewpoint to components of the same type',
          },
        }
      : {}),
    onClick: async () => {
      const isConfirmed = await confirmClosingPreviousViewIfOpen(
        loadedStates,
        context,
        'openNewDataset'
      );
      if (!isConfirmed) return;
      const selectedComponents = state.selectedComponents.map(
        ({ _id, name, typeName, representationData }) => ({
          id: _id,
          label: name,
          entityType: typeName,
          representationData,
        })
      );
      exitTraversalMode();
      dispatchAction(
        openApplySavedViewpointModal({
          selectedComponents,
          componentType:
            inventoryInteractiveTopRowOperations.getSelectedComponentTypeNames(
              state
            )[0],
        })
      );
      inventoryTracking.trackOpenApplySavedViewpointModal(
        selectedComponents.length
      );
    },
  },
];

const getInventoryInteractiveTopRowViewState = (
  state: InventoryInteractiveTopRowState,
  inventoryGridApi: InventoryGridApi | null,
  loadedStates: LoadedState[],
  context: ContextShape,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext,
  inventoryDatasourceSelection: InventoryDatasourceSelection
): InventoryInteractiveTopRowViewState => {
  if (state.mode === 'browseComponents') {
    const workspaceIds = inventoryOperations.getWorkspaceIdsFromDatasource(
      inventoryDatasourceSelection
    );
    return {
      ...state,
      selectedColumnsCount: getSelectedColumnsCount(
        inventoryGridApi?.getAllColumns() ?? []
      ),
      searchPhrase: inventoryGridApi?.getNameFilterValue() ?? '',
      canCreateComponent: workspaceIds.some(workspaceId =>
        workspaceAccessControlInterface.canEditWorkspace(
          permissionContext,
          workspaceId,
          null
        )
      ),
    };
  }
  if (state.mode === 'componentsSelected') {
    return {
      ...state,
      selectedColumnsCount: getSelectedColumnsCount(
        inventoryGridApi?.getAllColumns() ?? []
      ),
      moreButtonOptions: getMoreButtonOptions(
        state,
        inventoryGridApi,
        subdivisionsContext,
        permissionContext
      ),
      visualizeButtonOptions: getVisualizeButtonOptions(
        state,
        loadedStates,
        context
      ),
      searchPhrase: inventoryGridApi?.getNameFilterValue() ?? '',
    };
  }
  return state;
};
const resetState = (): InventoryInteractiveTopRowState =>
  defaultInventoryInteractiveState;

const getSelectedColumnsCount = (allColumns: InventoryColumnState[]) => {
  return allColumns.filter(
    c => c.isVisible && c.key !== 'ag-Grid-SelectionColumn'
  ).length;
};

const setSelectedComponents = (
  state: InventoryInteractiveTopRowState,
  selectedComponents: SelectedComponent[]
): InventoryInteractiveTopRowState => {
  if (!selectedComponents.length && state.mode === 'componentsSelected') {
    return { ...state, mode: 'browseComponents' };
  }

  if (!selectedComponents.length && state.mode === 'createComponents') {
    // we deselect all components when we enter create components mode
    return state;
  }

  return {
    ...state,
    mode: 'componentsSelected',
    selectedComponents,
  };
};

const isSelectedComponentsOfSameType = ({
  selectedComponents,
}: ComponentsSelectedInteractiveRowState): boolean =>
  selectedComponents.length > 0 &&
  !selectedComponents.some(
    component => selectedComponents[0].typeName !== component.typeName
  );

const getSelectedComponentTypeNames = ({
  selectedComponents,
}: ComponentsSelectedInteractiveRowState) =>
  uniq(selectedComponents.map(({ typeName }) => typeName));

const setTotalNumberOfRows = (
  state: InventoryInteractiveTopRowState,
  { totalNumberOfRows }: GotNextRowsBatchPayload
) => ({ ...state, totalNumberOfRows });

const incrementTotalNumberOfRows = (
  state: InventoryInteractiveTopRowState
) => ({
  ...state,
  totalNumberOfRows: state.totalNumberOfRows + 1,
});

export const inventoryInteractiveTopRowOperations = {
  getDefaultInventoryInteractiveState: () => defaultInventoryInteractiveState,
  enterCreateComponentsModeReducer,
  exitCreateComponentsModeReducer,
  ignoreUpdate,
  getInventoryInteractiveTopRowViewState,
  resetState,
  setSelectedComponents,
  isSelectedComponentsOfSameType,
  getSelectedComponentTypeNames,
  setTotalNumberOfRows,
  incrementTotalNumberOfRows,
};
