import { colors, s64 } from '@ardoq/design-tokens';
import styled from 'styled-components';
import { Stack } from '@ardoq/layout';
import { EmptyStateToAddIllustration } from '@ardoq/status-ui';
import { SecondaryButton } from '@ardoq/button';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { Text } from '@ardoq/typography';

const Wrapper = styled(Stack)`
  height: 100%;
  width: 100%;
  padding: ${s64} 0;
  background-color: ${colors.bgSubtle};
`;

const Content = styled(Stack)`
  width: 300px;
  text-align: center;
`;

const MediaContainer = styled.div`
  width: 110px;
`;

export const EmptyDocumentSelector = ({
  onSelectDocument,
}: {
  onSelectDocument: () => void;
}) => {
  return (
    <Wrapper align="center" justify="center">
      <Content gap="medium" align="center">
        <MediaContainer>
          <EmptyStateToAddIllustration />
        </MediaContainer>
        <Stack gap="medium" align={'center'}>
          <Text variant="text2">
            Select a Lucidchart document to import from your Lucidchart account.
          </Text>
          <SecondaryButton onClick={onSelectDocument}>
            <Icon iconName={IconName.LUCIDCHART} size={IconSize.REGULAR} />
            Select document
          </SecondaryButton>
        </Stack>
      </Content>
    </Wrapper>
  );
};
