import PrivilegeEditor from 'privileges/PrivilegeEditor';
import DefaultRoleDescription from './DefaultRoleDescription';
import {
  APIOrganizationUser,
  APIRoleAttributes,
  OrgAccessLevel,
  Privilege,
  PrivilegeLabel,
} from '@ardoq/api-types';
import { PrimaryButton } from '@ardoq/button';
import { dispatchAction } from '@ardoq/rxbeach';
import { updateRole } from 'roles/actions';
import { arePrivilegesEqual } from 'privileges/utils';
import { FieldsWrapper } from '@ardoq/forms';
import { FooterWrapper } from '../../manageOrganization/atoms';
import { UserRoleSelector } from 'roles/UserRoleSelector';
import { Island } from '@ardoq/page-layout';

const hasMadeChangesToPrivileges = (
  roles: APIRoleAttributes[],
  selectedRoleLabel: string,
  selectedPrivileges: string[]
) => {
  const initialRole = roles.find(({ label }) => label === selectedRoleLabel);
  if (!initialRole) return false;
  return !arePrivilegesEqual(initialRole.privileges, selectedPrivileges);
};

export type ManageUserRolesFormProps = {
  roles: APIRoleAttributes[];
  users: APIOrganizationUser[];
  configurablePrivileges: Privilege[];
  selectedRoleLabel: OrgAccessLevel;
  selectedPrivileges: PrivilegeLabel[];
  setSelectedRoleLabel: (roleLabel: APIRoleAttributes) => void;
  setSelectedPrivileges: (privileges: PrivilegeLabel[]) => void;
};

const ManageUserRolesForm = ({
  roles,
  users,
  configurablePrivileges,
  selectedRoleLabel,
  selectedPrivileges,
  setSelectedRoleLabel,
  setSelectedPrivileges,
}: ManageUserRolesFormProps) => {
  return (
    <Island
      footerContent={
        <FooterWrapper>
          <PrimaryButton
            isDisabled={
              !hasMadeChangesToPrivileges(
                roles,
                selectedRoleLabel,
                selectedPrivileges
              )
            }
            onClick={() => {
              const role = roles.find(
                ({ label }) => label === selectedRoleLabel
              );
              if (!role) return;
              dispatchAction(
                updateRole({ ...role, privileges: selectedPrivileges })
              );
            }}
          >
            Save changes
          </PrimaryButton>
        </FooterWrapper>
      }
    >
      <FieldsWrapper
        sideNote={<DefaultRoleDescription role={selectedRoleLabel} />}
      >
        <UserRoleSelector
          currRole={selectedRoleLabel}
          users={users}
          onValueChange={roleLabel => {
            const role = roles.find(({ label }) => label === roleLabel);
            if (!role) return;
            setSelectedRoleLabel(role);
          }}
        />
      </FieldsWrapper>
      <PrivilegeEditor
        label={`Edit privileges for the ${selectedRoleLabel} role`}
        role={selectedRoleLabel}
        configurablePrivileges={configurablePrivileges}
        enabledPrivileges={selectedPrivileges}
        onChange={setSelectedPrivileges}
      />
    </Island>
  );
};

export default ManageUserRolesForm;
