import styled from 'styled-components';
import { Notification } from '@ardoq/status-ui';
import { IconName, IconSize, PersonIcon } from '@ardoq/icons';
import {
  normal14,
  popoverUnderline,
  semibold14,
  text2,
  text2Bold,
} from '@ardoq/typography';
import { Icon } from '@ardoq/icons';
import { text1 } from '@ardoq/typography';
import { colors, s16, s24, s32, s4, s8 } from '@ardoq/design-tokens';
import { ViewMigrationGuideLink } from 'admin/migrationChecklist/atoms';
import { Link } from '@ardoq/typography';
import listOrgAdmins from 'components/AppMainSidebar/ListOrgAdmins';
import { capitalize } from 'lodash';
import { ViewIds } from '@ardoq/api-types';
import { orgUsersInterface } from 'modelInterface/orgUsers/orgUsersInterface';

const CANT_ACCESS_SLIDE_MESSAGE = `You don't have access to all of the workspaces or you don't have Write access to this presentation.`;
export const WHY_CANT_I_MIGRATE_SLIDES_POPOVER_ID = 'cant-migrate-popover';

export const CantMigrateSlidesPopover = styled.div.attrs({
  children: CANT_ACCESS_SLIDE_MESSAGE,
})`
  ${normal14}
  width: 224px;
  line-height: 20px;
`;

export const DeprecatedSlidesNotificationElement = styled(Notification)`
  margin: 0 4px;
`;

export const StyledNotificationBody = styled.div`
  display: block;
`;

export const CreatorNameAndEmailContainer = styled.div`
  margin-left: ${s4};
  margin-right: ${s4};
  flex: 1;
`;

export const SlideCreatedByEmail = styled.div`
  margin-left: ${s4};
  white-space: no-wrap;
  display: inline-block;
`;

export const SlideCreatedByName = styled.div`
  ${semibold14}
  margin-left: ${s4};
  white-space: no-wrap;
  display: inline-block;
`;
export const SlideCreatedByPersonIcon = styled(PersonIcon)`
  color: ${colors.grey80};
`;
export const SlideCreatedByInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: ${s4};
  margin-bottom: ${s16};
  line-height: 24px;
`;
export const WhyCantIMigrateSlidesLabel = styled.span`
  ${popoverUnderline};
  width: fit-content;
  margin-top: ${s16};
`;

export const KilldateCountdownContainer = styled.div`
  ${text2Bold}
  margin-bottom: ${s8};
`;

export const CustomDialogSubtitleSection = styled.div`
  padding: ${s32};
  border-bottom: 1px solid ${colors.grey80};
  font-size: ${s16};
  ${text2};
`;
export const SavedSettingDialogcontentBody = styled.div`
  padding: ${s32};
  ${text2};
`;

export const SavedViewModifiersRowsContainer = styled.div`
  ${text1}
  width: 100%;
  line-height: 40px;
  margin-top: ${s16};
`;

export const ValueRowContainer = styled.div`
  padding: 0 ${s8};
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;
export const DiscontinuedSettingContainer = styled(ValueRowContainer)`
  display: flex;
  align-items: center;
  background-color: ${colors.yellow95};
`;
export const StyledIcon = styled(Icon)`
  color: ${colors.yellow50};
  margin-left: auto;
  justify-self: right;
  &:hover {
    cursor: help;
  }
`;
export const DiscontinuedSettingPopoverDiv = styled.div`
  max-width: 240px;
`;

export const NotificationMessageContainer = styled.div`
  line-height: 20px;
  margin-bottom: ${s8};
`;

export const DeprecatedSlideNotificationDetailsContainer = styled.div`
  gap: 0px;
`;

export const ViewStatusTextBadgeContainer = styled.div`
  margin: ${s24} 0 ${s8} 0;
`;

export const ViewMigrationGuideLinkWithProps = () => (
  <ViewMigrationGuideLink style={{ display: 'block', marginTop: s8 }}>
    Learn how to migrate views to the new version
  </ViewMigrationGuideLink>
);

export const LeftBoundFlexContainerNoTextWrap = styled.div`
  display: 'flex';
  flex-shrink: 0;
  justify-content: flex-start;
`;

export const ContactYourAdminLink = () => (
  <Link
    hideIcon
    type="primary"
    typography="text2"
    onClick={() => listOrgAdmins(orgUsersInterface.getOrgAdmins())}
  >
    Contact your admin
  </Link>
);

// discontinued view replacement ----------------- start
export const ButtonLabelLinkContainer = styled.div`
  padding-left: ${s8};
  color: ${colors.white};
  display: flex;
  align-items: center;
`;

export const StyledButtonLabelIcon = styled(Icon)`
  margin-left: ${s8};
`;

export const DoqFaceElementContainer = styled.div`
  width: 879px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const DelteDialogMesageContainer = styled.div`
  display: flex;
  align-items: center;
  ${text2}
  color: ${colors.red50};
`;

export const DeleteDialogMessageIcon = styled(Icon)`
  margin-right: ${s16};
`;
// discontinued view replacement -------------- end

// view deprecation countdown -----------------------start
export const BodyContainer = styled.div`
  padding: ${s32};
  display: flex;
  ${text2}
`;

export const DoqFaceContainer = styled.div`
  margin-right: ${s32};
`;

export const CallToActionMessageContainer = styled.div`
  ${text2};
  margin-bottom: ${s24};
`;

export const AlertMessageContainer = styled.div`
  ${text2Bold}
  color: ${colors.red50};
  display: flex;
  align-items: center;
`;

export const StyledIconForDeprecationCountdown = styled(Icon)`
  color: ${colors.red50};
  margin-right: ${s16};
`;

export const HowToReplaceSlideMessage = () => (
  <div>
    To migrate this slide:
    <ol>
      <li>Select slide in the sidebar</li>
      <li>Open the new view</li>
      <li>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>Select replace slide&nbsp;</span>
          <Icon
            iconName={IconName.SYNC}
            iconSize={IconSize.REGULAR}
            style={{ color: colors.grey50 }}
          />
          <span>&nbsp;in the sidebar</span>
        </div>
      </li>
    </ol>
  </div>
);

type MigrateToImprovedSuccessorMessageArgs = {
  viewId?: ViewIds;
};
export const MigrateToImprovedSuccessorMessage = ({
  viewId,
}: MigrateToImprovedSuccessorMessageArgs) => (
  <div>{`Migrate this slide to the improved ${viewId
    ?.split(' ')
    .map(capitalize)
    .join(' ')} View.`}</div>
);

type ViewDiscontinuedNoPermissionsMessageArgs = { creatorCanMigrate?: boolean };
export const ViewDiscontinuedNoPermissionsMessage = ({
  creatorCanMigrate,
}: ViewDiscontinuedNoPermissionsMessageArgs) => (
  <div>
    The view used in this slide has been discontinued and the slide should be
    migrated by
    {creatorCanMigrate === undefined || creatorCanMigrate
      ? ' the slide creator.'
      : ' an admin.'}
  </div>
);
