import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ConfigsState } from './type';
import { setSelectedWorkspaces, resetConfigs } from './actions';

const defaultState: ConfigsState = {
  workspaces: {
    selected: [],
  },
};

const handleSetSelectedWorkspaces = (
  state: ConfigsState,
  selectedWorkspaces: ConfigsState['workspaces']['selected']
): ConfigsState => {
  return {
    ...state,
    workspaces: {
      selected: selectedWorkspaces,
    },
  };
};

const handleResetConfigs = (_: ConfigsState): ConfigsState => defaultState;

export const configs$ = persistentReducedStream(
  'lucidchartConfigs',
  defaultState,
  [
    reducer(setSelectedWorkspaces, handleSetSelectedWorkspaces),
    reducer(resetConfigs, handleResetConfigs),
  ]
);
