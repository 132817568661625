import { actionCreator } from '@ardoq/rxbeach';

export const closeAllOpenData = actionCreator(
  '[component hierarchies] CLOSE_ALL_OPEN_DATA'
);

export const closeWorkspace = actionCreator<{ workspaceId: string }>(
  '[component hierarchies] CLOSE_WORKSPACE'
);

export const hierarchiesWorkspaceIdsChanged = actionCreator<string[]>(
  '[component hierarchies] HIERARCHIES_WORKSPACE_IDS_CHANGED'
);
