import { hasLoadedState, SearchType, ViewIds } from '@ardoq/api-types';
import { ContextSort } from '@ardoq/common-helpers';
import { Features, hasFeature } from '@ardoq/features';
import {
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { dispatchActionAndWaitForResponse } from 'actions/utils';
import { setAccessControlTab } from 'admin/accessControl/navigation/actions';
import { setOrgTab } from 'admin/manageOrganization/navigation/actions';
import { navigateToUserSettings, openUserSettings } from 'admin/user/actions';
import {
  rejectedNavigation,
  requestShowAppModule,
  showAppModule,
} from 'appContainer/actions';
import {
  getComponentOverviewPageData,
  setComponentOverviewId,
} from 'appContainer/componentOverviewPage/actions';
import {
  openWorkspaces,
  OpenWorkspacesArgs,
  showPresentationEditorIfNeeded,
} from 'appContainer/DashboardContainer/utils';
import selectedModule$ from 'appContainer/selectedModule$';
import { AppModules } from 'appContainer/types';
import { closePane, splitPane } from 'appLayout/actions';
import { isSplitPane$ } from 'appLayout/isSplitPane$';
import Components from 'collections/components';
import Filters from 'collections/filters';
import GroupByCollection from 'collections/groupByCollection';
import clearPerspectives from 'collections/helpers/clearPerspectives';
import loadPerspective from 'collections/helpers/loadPerspective';
import Perspectives from 'collections/perspectives';
import References from 'collections/references';
import Context from 'context';
import { applyDynamicFiltersFromQueryParams } from 'filters/dynamicFilters';
import * as gridEditor2023Actions from 'gridEditor2023/actions';
import { rebuildAndRegisterState } from 'loadedState/buildState';
import {
  selectMetamodel,
  selectMetamodelPane,
} from 'metamodel/navigation/actions';
import * as OrganizationMetamodelRoutes from 'organizationMetamodel/organizationMetamodelRoutes';
import { showPresentationEditor } from 'presentationEditor/actions';
import { from } from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { openScenario, openScenarioSuccess } from 'scope/actions';
import {
  loadCalculatedFieldStoredQuery,
  loadStoredQuery,
} from 'search/actions';
import {
  getQueryEditorNamespace,
  QueryEditorNamespace,
} from 'search/QueryEditor/queryEditor$';
import { selectSearchPane } from 'search/SearchTabContainer/actions';
import { context$ } from 'streams/context/context$';
import { setSelectedSlideId } from 'streams/context/ContextActions';
import activeFilter$ from 'streams/filters/activeFilter$';
import { triggerFiltersChangedEvent } from 'streams/filters/FilterActions';
import storedQueries$ from 'streams/queries/storedQueries$';
import { maybeConfirmDiscard } from 'streams/unsavedState/modal';
import { subscribeToAction } from 'streams/utils/streamUtils';
import {
  setActiveMainTabLeft,
  setActiveMainTabRight,
} from 'streams/views/mainContent/actions';
import activeView$ from 'streams/views/mainContent/activeView$';
import { MAIN_PANE_RIGHT } from 'streams/views/mainContent/types';
import { selectSubdivision } from 'subdivisionEditor/navigation/actions';
import { SubdivisionEditorSteps } from 'subdivisionEditor/navigation/types';
import {
  navigateToChangeApprovalPage,
  navigateToSurveyForm,
  setSurveyOverviewSearchParams,
} from 'surveyAdmin/navigation/actions';
import { SurveyAdminMode } from 'surveyAdmin/navigation/types';
import { openSurveyAdminView } from 'surveyAdmin/navigation/utils';
import {
  activeWorkspaceRegistered,
  workspaceOrderRegistered,
} from 'traversals/stagedLoadedDataAndState$';
import { isSameSet } from 'utils/isSameSet';
import { navigateToUseCases } from '../useCases/actions';
import { navigateToDeclarativeRoute } from './declarative/streamRouterAdapter/actions';
import {
  inventoryModuleWasSelected,
  loadScenarioModuleRoute,
  loadWorkspaceModuleRoute,
  navigateToAccessControlPage,
  navigateToActiveBroadcastForm,
  navigateToAnalyticsOverview,
  navigateToAuditLog,
  navigateToBroadcastOverview,
  navigateToComponentOverviewPage,
  navigateToDashboardModule,
  navigateToDefaultViewpoints,
  navigateToEditBroadcastForm,
  navigateToManageOrganization,
  navigateToMetamodel,
  navigateToNewBroadcastForm,
  navigateToNewViewpoints,
  navigateToOrganizationMetamodel,
  navigateToReportBuilder,
  navigateToReportOverview,
  navigateToReportReader,
  navigateToSearch,
  navigateToSubdivision,
  navigateToSurveyAdmin,
  navigateToViewpointForm,
  navigateToViewpoints,
  setPreviousAppModule,
} from './navigationActions';
import { assetsBrowser2024Commands } from 'components/AssetsBrowser2024/assetsBrowser2024Commands';

const dispatchShowGridEditor = () => {
  dispatchAction(gridEditor2023Actions.showGridEditor());
};

const handleNavigateToSurveyAdmin = routine(
  ofType(navigateToSurveyAdmin),
  extractPayload(),
  tap(({ surveyMode, surveyId, surveyOverviewSearchParams }) => {
    dispatchAction(setSurveyOverviewSearchParams(surveyOverviewSearchParams));
    if (surveyMode === SurveyAdminMode.OVERVIEW) {
      openSurveyAdminView(surveyMode);
      return;
    }
    if (surveyMode === SurveyAdminMode.CHANGE_APPROVAL && surveyId) {
      dispatchAction(navigateToChangeApprovalPage(surveyId));
      return;
    }
    if (
      surveyMode === SurveyAdminMode.EDIT_SURVEY ||
      surveyMode === SurveyAdminMode.NEW_SURVEY
    ) {
      dispatchAction(navigateToSurveyForm(surveyId));
    }
  })
);

const handleNavigateToReport = routine(
  ofType(
    navigateToReportReader,
    navigateToReportBuilder,
    navigateToReportOverview
  ),
  tap(() => {
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.REPORTS,
      })
    );
  })
);

const handleNavigateToDashboardModule = routine(
  ofType(navigateToDashboardModule),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.DASHBOARDS })
    );
  })
);

const handleNavigateToAuditLog = routine(
  ofType(navigateToAuditLog),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.AUDIT_LOG })
    );
  })
);

const handleNavigateToUseCases = routine(
  ofType(navigateToUseCases),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.USE_CASES })
    );
  })
);

const handleNavigateToMetamodel = routine(
  ofType(navigateToMetamodel),
  extractPayload(),
  tap(({ metamodelId, metamodelPane }) => {
    dispatchAction(selectMetamodel(metamodelId));
    dispatchAction(
      selectMetamodelPane({
        pane: metamodelPane,
        trackingLocation: 'directURLVisit',
      })
    );
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.METAMODEL })
    );
  })
);

const handleNavigateToOrganizationMetamodel = routine(
  ofType(navigateToOrganizationMetamodel),
  extractPayload(),
  tap(location => {
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.ORGANIZATION_METAMODEL,
      })
    );
    OrganizationMetamodelRoutes.navigateTo(location.route, location.params);
  })
);

const handleNavigateToComponentOverviewPage = routine(
  ofType(navigateToComponentOverviewPage),
  extractPayload(),
  tap(componentId => {
    dispatchAction(setComponentOverviewId(componentId));
    dispatchAction(getComponentOverviewPageData(componentId));
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.COMPONENT_OVERVIEW_PAGE,
      })
    );
  })
);

const handleNavigateToAnalyticsOverview = routine(
  ofType(navigateToAnalyticsOverview),
  tap(() => {
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.ANALYTICS,
      })
    );
  })
);

const handleNavigateToSubdivision = routine(
  ofType(navigateToSubdivision),
  extractPayload(),
  switchMap(data => {
    return from(
      dispatchActionAndWaitForResponse(
        requestShowAppModule({ selectedModule: AppModules.SUBDIVISION }),
        showAppModule,
        rejectedNavigation
      ).then(response => {
        const confirmed = response.type === showAppModule.type;
        return { ...data, confirmed };
      })
    );
  }),
  tap(
    ({
      subdivisionId,
      subdivisionEditorStep = SubdivisionEditorSteps.DETAILS,
      subdivisionEditorSubStep,
      confirmed,
    }) => {
      if (confirmed) {
        dispatchAction(
          selectSubdivision({
            subdivisionId,
            subdivisionEditorStep,
            subdivisionEditorSubStep,
          })
        );
      }
    }
  )
);

const handleNavigateToSearch = routine(
  ofType(navigateToSearch),
  extractPayload(),
  tap(({ searchId, searchPane }) => {
    dispatchAction(
      requestShowAppModule({
        selectedModule: AppModules.SEARCH,
      })
    );
    dispatchAction(
      selectSearchPane({
        searchPane,
      })
    );
    if (searchId) {
      // Stored queries are fetched on app start
      // Wait for them to be fetched before loading the
      // selected stored query
      storedQueries$
        .pipe(
          filter(({ hasFetchedQueries }) => hasFetchedQueries === true),
          take(1)
        )
        .subscribe(({ storedQueries }) => {
          const selectedStoredQuery = storedQueries.find(
            ({ _id }) => _id === searchId
          );
          if (selectedStoredQuery) {
            if (
              selectedStoredQuery.type === SearchType.CALCULATED_FIELD_QUERY
            ) {
              dispatchAction(
                loadCalculatedFieldStoredQuery({
                  storedQuery: selectedStoredQuery,
                }),
                QueryEditorNamespace.CALCULATED_FIELD_QUERY
              );
            } else {
              dispatchAction(
                loadStoredQuery({
                  storedQuery: selectedStoredQuery,
                }),
                getQueryEditorNamespace(
                  selectedStoredQuery.backend,
                  selectedStoredQuery.type
                )
              );
            }
          }
        });
    }
  })
);

const doHandleBroadcastsNavigation = routine(
  ofType(
    navigateToBroadcastOverview,
    navigateToNewBroadcastForm,
    navigateToEditBroadcastForm,
    navigateToActiveBroadcastForm
  ),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.BROADCASTS })
    );
  })
);

const handleNavigateToViewpoints = routine(
  ofType(
    navigateToViewpoints,
    navigateToNewViewpoints,
    navigateToViewpointForm,
    navigateToDefaultViewpoints
  ),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.VIEWPOINTS })
    );
  })
);

const handleNavigateToManageOrganization = routine(
  ofType(navigateToManageOrganization),
  extractPayload(),
  switchMap(({ manageOrgTab }) => {
    return from(maybeConfirmDiscard()).pipe(
      map(confirmed => ({ manageOrgTab: manageOrgTab, confirmed }))
    );
  }),
  tap(({ manageOrgTab, confirmed }) => {
    if (confirmed) {
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.MANAGE_ORGANIZATION })
      );
      dispatchAction(setOrgTab(manageOrgTab));
    }
  })
);

const handleNavigateToAccessControlPage = routine(
  ofType(navigateToAccessControlPage),
  extractPayload(),
  switchMap(routerState => {
    return from(maybeConfirmDiscard()).pipe(
      map(confirmed => ({ routerState, confirmed }))
    );
  }),
  tap(({ routerState, confirmed }) => {
    if (confirmed) {
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.ACCESS_CONTROL })
      );
      dispatchAction(setAccessControlTab(routerState));
    }
  })
);

const handleNavigateToUserSettings = routine(
  ofType(navigateToUserSettings),
  tap(({ payload }) => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.USER_SETTINGS })
    );
    dispatchAction(openUserSettings(payload));
  })
);

const setInventoryModule = routine(
  ofType(inventoryModuleWasSelected),
  tap(() => {
    dispatchAction(
      requestShowAppModule({ selectedModule: AppModules.INVENTORY })
    );
  })
);

const handleNavigateToDeclarativeRoute = routine(
  ofType(navigateToDeclarativeRoute),
  extractPayload(),
  withLatestFrom(selectedModule$),
  tap(([{ appModule }, { selectedModule }]) => {
    dispatchAction(setPreviousAppModule(selectedModule));
    dispatchAction(
      requestShowAppModule({
        selectedModule: appModule,
      })
    );
  })
);

enum FilterMatch {
  PERSPECTIVE = 'perspective',
  WORKSPACE_FILTER = 'workspaceFilter',
  FILTER = 'filter',
  LABEL_FORMATTING = 'labelFormatting',
  GROUP_BY = 'groupBy',
  DYNAMIC_FILTER = 'dynamicFilter',
}

const loadFiltersFromQueryString = (encodedFilterString: string) => {
  // Do nothing if the filters are already loaded
  if (
    decodeURI(activeFilter$.state.activeFiltersQueryString) ===
    encodedFilterString
  ) {
    return;
  }
  clearPerspectives({
    shouldTriggerChangeEvent: false,
  });
  const filterMatchers: Record<string, RegExp> = {
    [FilterMatch.PERSPECTIVE]: /^(ps|fs)_@id=(.*)/g,
    [FilterMatch.WORKSPACE_FILTER]: /^f_ws_(.*)/g,
    [FilterMatch.FILTER]: /^f_(.*)/g,
    [FilterMatch.LABEL_FORMATTING]: /^l_(.*)/g,
    [FilterMatch.GROUP_BY]: /^g_(.*)/g,
    [FilterMatch.DYNAMIC_FILTER]: /^df_(.*)/g,
  };

  const filterParams = encodedFilterString.split('&');

  filterParams.filter(Boolean).forEach(filterParam => {
    const filterType = Object.keys(filterMatchers).find(key =>
      filterParam.match(filterMatchers[key])
    );
    if (filterType === FilterMatch.WORKSPACE_FILTER) {
      Filters.createWorkspaceFilterFromURL(filterParam);
    } else if (
      filterType === FilterMatch.FILTER ||
      filterType === FilterMatch.LABEL_FORMATTING
    ) {
      Filters.createFilter(filterParam, {
        shouldTriggerChangeEvent: false,
      });
    } else if (filterType === FilterMatch.GROUP_BY) {
      GroupByCollection.addFromString(filterParam);
    } else if (filterType === FilterMatch.DYNAMIC_FILTER) {
      applyDynamicFiltersFromQueryParams(filterParam);
    } else if (filterType === FilterMatch.PERSPECTIVE) {
      const perspectiveId = filterParam.replace(/^(ps|fs)_@id=/g, '');
      const perspective = Perspectives.get(perspectiveId);
      if (perspective) {
        loadPerspective(perspective);
      }
    }
  });

  dispatchAction(triggerFiltersChangedEvent());
};

const isNonEmptySort = (sort: ContextSort) =>
  sort.attr && sort.order !== undefined;

type SetupCommonWorkspacesAndScenarioParamsOptions = {
  isGridEditorShown: boolean;
  mainViewId: ViewIds;
  secondaryViewId: ViewIds | null;
  sort: ContextSort;
  perspectiveId: string | null;
  componentId: string | null;
  referenceId: string | null;
  activeFiltersQueryString: string;
};
const setCommonWorkspaceAndScenarioParams = async ({
  isGridEditorShown,
  mainViewId,
  componentId,
  referenceId,
  secondaryViewId,
  sort,
  perspectiveId,
  activeFiltersQueryString,
}: SetupCommonWorkspacesAndScenarioParamsOptions) => {
  if (isGridEditorShown) {
    dispatchShowGridEditor();
  }

  const viewsToBeShown = [mainViewId, secondaryViewId]
    .filter(Boolean)
    .join('+');

  if (
    activeView$.state.mainViewId !== mainViewId ||
    activeView$.state.secondaryViewId !== secondaryViewId
  ) {
    const [mainViewId, secondViewId] = viewsToBeShown
      .split('+')
      .map(str => (str ? (str.trim() as ViewIds) : undefined));

    if (secondViewId && !isSplitPane$.state) {
      dispatchAction(splitPane());
    } else if (!secondViewId && isSplitPane$.state) {
      dispatchAction(closePane({ location: MAIN_PANE_RIGHT }));
    }
    dispatchAction(
      setActiveMainTabLeft({ activeTabId: mainViewId as ViewIds })
    );
    dispatchAction(
      setActiveMainTabRight({
        activeTabId: secondViewId ?? (ViewIds.NONE as ViewIds),
      })
    );
  }

  Context.setSort(sort.attr, sort.name, sort.order);

  const perspective = perspectiveId && Perspectives.get(perspectiveId);
  if (perspectiveId) {
    if (perspective && Perspectives.getCurrentSet() !== perspective) {
      loadPerspective(perspective);
    }
  } else if (activeFiltersQueryString) {
    loadFiltersFromQueryString(activeFiltersQueryString);
  }

  const reference = referenceId && References.collection.get(referenceId);
  const component = componentId && Components.collection.get(componentId);

  if (reference) {
    await Context.setReference(reference);
  } else if (component) {
    await Context.setComponent(component);
  }
};

const handleLoadWorkspaceModuleRoute = routine(
  ofType(loadWorkspaceModuleRoute),
  extractPayload(),
  tap(
    async ({
      workspaceIds,
      workspaceId,
      componentId,
      referenceId,
      presentationId,
      perspectiveId,
      isGridEditorShown,
      activeFiltersQueryString,
      mainViewId,
      secondaryViewId,
      sort,
      loadedState,
      selectedSlideId,
      hierarchiesWorkspaceIds,
    }) => {
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
      );
      if (
        hasFeature(Features.CANVAS_PROTOTYPE) &&
        hierarchiesWorkspaceIds.length > 0
      ) {
        assetsBrowser2024Commands.openWorkspacesInViewpointMode(
          hierarchiesWorkspaceIds
        );
      }
      if (
        !isSameSet(context$.state.workspacesIds, workspaceIds) ||
        context$.state.workspaceId !== workspaceIds?.[0]
      ) {
        const openWorkspacesOptions: OpenWorkspacesArgs = {
          forPresentationId: presentationId || undefined,
          shouldAwaitPerspectives: true,
          activeWorkspaceId: workspaceId,
          trackingLocation: 'directURLVisit',
        };
        if (
          hasFeature(Features.SUPPORT_LARGE_DATASETS) &&
          hasLoadedState(loadedState)
        ) {
          // If there is a presentation id and a selected slide id the
          // correct slide will be set by dispatching setSelectedSlideId.
          if (!(presentationId && selectedSlideId)) {
            dispatchAction(activeWorkspaceRegistered(workspaceId));
            dispatchAction(workspaceOrderRegistered(workspaceIds));
            await rebuildAndRegisterState({
              loadedState,
            });
          }
          showPresentationEditorIfNeeded(presentationId || undefined);
        } else {
          await openWorkspaces(workspaceIds, openWorkspacesOptions);
        }

        if (selectedSlideId) {
          dispatchAction(setSelectedSlideId({ selectedSlideId }));
        }
      }
      const defaultWorkspaceSort = Context.getSort();
      setCommonWorkspaceAndScenarioParams({
        isGridEditorShown,
        mainViewId,
        secondaryViewId,
        sort: isNonEmptySort(sort) ? sort : defaultWorkspaceSort,
        perspectiveId,
        componentId,
        referenceId,
        activeFiltersQueryString,
      });
    }
  )
);

const handleLoadScenarioModuleRoute = routine(
  ofType(loadScenarioModuleRoute),
  extractPayload(),
  tap(
    async ({
      scenarioId,
      mainViewId,
      perspectiveId,
      componentId,
      referenceId,
      presentationId,
      secondaryViewId,
      sort,
      activeFiltersQueryString,
    }) => {
      dispatchAction(
        requestShowAppModule({ selectedModule: AppModules.WORKSPACES })
      );
      dispatchAction(
        openScenario({
          scenarioId,
          skipLoadingBar: true,
          trackingClickSource: 'directURLVisit',
        })
      );
      // wait for scenario to be opened before further setup
      await new Promise<void>(resolve =>
        subscribeToAction(openScenarioSuccess, resolve)
      );

      if (presentationId) {
        dispatchAction(showPresentationEditor({ presentationId }));
      }

      setCommonWorkspaceAndScenarioParams({
        isGridEditorShown: false,
        mainViewId,
        secondaryViewId,
        sort,
        perspectiveId,
        componentId,
        referenceId,
        activeFiltersQueryString,
      });
    }
  )
);

export default collectRoutines(
  handleNavigateToSurveyAdmin,
  handleNavigateToReport,
  handleNavigateToMetamodel,
  handleNavigateToOrganizationMetamodel,
  handleNavigateToSubdivision,
  handleNavigateToSearch,
  doHandleBroadcastsNavigation,
  handleLoadWorkspaceModuleRoute,
  handleLoadScenarioModuleRoute,
  handleNavigateToViewpoints,
  handleNavigateToDashboardModule,
  handleNavigateToAuditLog,
  handleNavigateToManageOrganization,
  handleNavigateToAccessControlPage,
  handleNavigateToUserSettings,
  handleNavigateToUseCases,
  handleNavigateToComponentOverviewPage,
  handleNavigateToAnalyticsOverview,
  handleNavigateToDeclarativeRoute,
  setInventoryModule
);
