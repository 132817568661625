import { combineLatest, map } from 'rxjs';
import { document$ } from '../streams/document/document$';
import { dispatchAction } from '@ardoq/rxbeach';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { analyzeLucidDocument } from '../streams/analyze/actions';
import { configs$ } from '../streams/configs/configs$';
import { analyze$ } from '../streams/analyze/analyze$';
import { mappings$ } from '../streams/mappings/mappings$';
import {
  addComponentMapping,
  deleteComponentMapping,
  importMappings,
  setComponentMapping,
  setReferenceMapping,
  addReferenceMapping,
  deleteReferenceMapping,
} from '../streams/mappings/actions';
import { MappedComponent, MappedReference } from '../streams/mappings/types';
import workspaces$ from 'streams/workspaces/workspaces$';
import { models$ } from 'streams/models/models$';
import { getWorkspacesMetadata } from '../utils';
import { debounce } from 'lodash';
import { mappingErrors } from '../streams/mappings/utils';

// user typing the component name can cause many events
const onComponentMapping = debounce((component: MappedComponent) => {
  dispatchAction(setComponentMapping(component));
});

const onReferenceMapping = (reference: MappedReference) => {
  dispatchAction(setReferenceMapping(reference));
};

const onImport = () => {
  dispatchAction(importMappings());
};

const onAddNewComponentMapping = () => {
  dispatchAction(addComponentMapping());
};

const onDeleteComponentMapping = (mapping: MappedComponent) => {
  dispatchAction(deleteComponentMapping(mapping));
};

const onAddNewReferenceMapping = () => {
  dispatchAction(addReferenceMapping());
};

const onDeleteReferenceMapping = (mapping: MappedReference) => {
  dispatchAction(deleteReferenceMapping(mapping));
};

export const viewModel$ = combineLatest([
  configs$,
  document$,
  analyze$,
  mappings$,
  workspaces$,
  models$,
]).pipe(
  map(
    ([
      configs,
      document,
      analyze,
      mappings,
      { byId: workspaceById },
      { byId: modelById },
    ]) => {
      const selectedWorkspaces = configs.workspaces.selected.map(
        workspace => workspace.value
      );
      const workspacesMetadata = getWorkspacesMetadata(
        workspaceById,
        modelById,
        selectedWorkspaces
      );

      const componentTypeOptions = workspacesMetadata.map(metadata => ({
        label: metadata.workspace.name,
        options: Object.entries(metadata.model.root).map(([key, { name }]) => ({
          value: key,
          label: name,
        })),
      }));

      const referenceTypeOptions = workspacesMetadata.map(metadata => ({
        workspaceId: metadata.workspace._id,
        options: Object.entries(metadata.model.referenceTypes).map(
          ([key, { name }]) => ({
            value: key,
            label: name,
          })
        ),
      }));

      const componentsOptions = Object.values(mappings.components)
        .map(c => ({
          label: c.name,
          value: c.id,
          component: c,
        }))
        .filter(({ label }) => Boolean(label));

      const onComponentTypeChange = (
        component: MappedComponent,
        typeId: string
      ) => {
        const workapceMeta = workspacesMetadata.find(({ model }) =>
          Object.keys(model.root).find(key => key === typeId)
        );
        const updatedComponent: MappedComponent = workapceMeta
          ? {
              ...component,
              componentTypeId: typeId,
              workspaceId: workapceMeta.workspace._id,
            }
          : { ...component, componentTypeId: typeId };
        onComponentMapping(updatedComponent);
      };

      const onAnalyze = () => {
        if (document.status === ASYNC_STATUS.SUCCESS) {
          dispatchAction(
            analyzeLucidDocument({
              documentId: document.document.id,
              workspaceIds: configs.workspaces.selected.map(
                workspace => workspace.value
              ),
            })
          );
        }
      };

      return {
        mappingsStatus: mappings.status,
        mappingsComponents: Object.values(mappings.components),
        mappingsReferences: Object.values(mappings.references),
        mappingsErrors: mappingErrors(mappings),
        analyze,
        document,
        componentTypeOptions,
        referenceTypeOptions,
        componentsOptions,
        onComponentMapping,
        onComponentTypeChange,
        onAddNewComponentMapping,
        onDeleteComponentMapping,
        onReferenceMapping,
        onAddNewReferenceMapping,
        onDeleteReferenceMapping,
        onAnalyze,
        onImport,
      };
    }
  )
);
