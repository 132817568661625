import { combineLatest, map } from 'rxjs';
import {
  action$,
  ofType,
  persistentReducedStream,
  reducer,
  streamReducer,
} from '@ardoq/rxbeach';
import {
  SaveAsViewpointInputsReducer,
  SaveAsViewpointInputsState,
  UpdateSaveAsViewpointStatePayload,
  ViewStyle,
  SaveAsViewpointInputsErrors,
} from './types';
import { resetSaveAsViewpoint, updateSaveAsViewpointState } from './actions';
import { isWorkspaceModule$ } from 'streams/isWorkspaceModule$';
import activeView$, {
  ActiveViewState,
} from 'streams/views/mainContent/activeView$';
import { AvailableViews, availableViews$ } from 'views/availableViews$';
import {
  SAVE_BUTTON_ERROR_MESSAGE,
  VIEWPOINT_NAME_INPUT_ERROR_MESSAGE,
  UPDATE_BUTTON_ERROR_MESSAGE,
} from './consts';
import { startWith } from 'rxjs/operators';
import { startAction } from 'actions/utils';
import {
  openedViewpointData$,
  OpenedViewpointData,
} from './openedViewpointData$';

const errorsForEmptyNameField = {
  viewpointName: VIEWPOINT_NAME_INPUT_ERROR_MESSAGE,
  saveButton: SAVE_BUTTON_ERROR_MESSAGE,
  updateButton: UPDATE_BUTTON_ERROR_MESSAGE,
};

const getViewpointInputsError = (
  state: SaveAsViewpointInputsState
): SaveAsViewpointInputsErrors => {
  const { viewpointName } = state;

  const hasName = viewpointName.trim() !== '';

  if (hasName) {
    return {};
  }

  return errorsForEmptyNameField;
};

const activeViewChangedReducer: SaveAsViewpointInputsReducer<ViewStyle> = (
  state,
  viewStyle
) => ({ ...state, ...viewStyle });

const validateState = (
  state: SaveAsViewpointInputsState
): SaveAsViewpointInputsState => ({
  ...state,
  errors: getViewpointInputsError(state),
});

const updateStateReducer: SaveAsViewpointInputsReducer<
  UpdateSaveAsViewpointStatePayload
> = (state, payload) =>
  validateState({
    ...state,
    ...payload,
  });

const defaultEditableState = {
  viewpointName: '',
  viewpointDescription: '',
  originalViewpointName: '',
  originalViewpointDescription: '',
  errors: errorsForEmptyNameField,
};

const getCurrentView = ([{ mainViewId }, { byIds }]: [
  activeView: ActiveViewState,
  availabeViews: AvailableViews,
]) => ({
  mainViewId,
  mainViewName: byIds[mainViewId]?.name,
});

export const getDefaultInputsState = (): SaveAsViewpointInputsState => ({
  ...defaultEditableState,
  ...getCurrentView([activeView$.state, availableViews$.state]),
});

const viewPaneHeader$ = combineLatest([activeView$, availableViews$]).pipe(
  map(getCurrentView)
);

const resetReducer = (state: SaveAsViewpointInputsState) => {
  const newState = {
    ...state,
    viewpointName: state.originalViewpointName,
    viewpointDescription: state.originalViewpointDescription,
  };
  return validateState(newState);
};

const reset$ = combineLatest([
  isWorkspaceModule$,
  action$.pipe(ofType(resetSaveAsViewpoint), startWith(startAction)),
]);

const updateActiveViewpointReducer = (
  state: SaveAsViewpointInputsState,
  payload: OpenedViewpointData
) => {
  const { openedViewpoint } = payload;

  const viewpointName = openedViewpoint?.name || '';

  const viewpointDescription = openedViewpoint?.description || '';

  const updatedState = {
    ...state,
    viewpointName,
    viewpointDescription,
    originalViewpointName: viewpointName,
    originalViewpointDescription: viewpointDescription,
  };

  return validateState(updatedState);
};

export const saveAsViewpointInputs$ = persistentReducedStream(
  'saveAsViewpointInputs$',
  getDefaultInputsState(),
  [
    streamReducer(openedViewpointData$, updateActiveViewpointReducer),
    reducer(updateSaveAsViewpointState, updateStateReducer),
    streamReducer(viewPaneHeader$, activeViewChangedReducer),
    streamReducer(reset$, resetReducer),
  ]
);
