import { FC, useRef, useState } from 'react';
import GlobalFieldManager from './GlobalFieldManager';
import { openWorkspaces } from 'appContainer/DashboardContainer/utils';
import CalculatedSearch from 'search/CalculatedSearch/CalculatedSearch';
import {
  DarkBackdropContent,
  ModalBody,
  ModalContainer,
  ModalSize,
  ModalTemplate,
  confirm,
} from '@ardoq/modal';
import { connect } from '@ardoq/rxbeach';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import { map } from 'rxjs';
import currentUserPermissionContext$ from 'streams/currentUserPermissions/currentUserPermissionContext$';
import { UnauthorizedAccessPage } from '@ardoq/manage-resource-permissions';

const getShouldClose = () =>
  confirm({
    title: 'Unsaved changes',
    subtitle: 'Are you sure you want to close this window?',
    confirmButtonTitle: 'Yes',
    isConfirmButtonDanger: true,
  });

interface ViewProps {
  closeHandler: () => void;
  shouldOpenInEditMode: boolean;
  canCreateGraphFilter: boolean;
}

const GlobalFieldManagerDialog: FC<ViewProps> = ({
  closeHandler,
  shouldOpenInEditMode,
  canCreateGraphFilter,
}) => {
  const backDropRef = useRef(null);
  const editGremlinQueryBackdropRef = useRef(null);
  const [isEditMode, setIsEditMode] = useState(shouldOpenInEditMode);
  const [isDirty, setIsDirty] = useState(false);
  const calculatedToggleState = useState(false);
  const shouldShowNoAccessPage = !canCreateGraphFilter && isEditMode;
  const shouldCalculatedSearchPage = canCreateGraphFilter && isEditMode;

  return (
    <DarkBackdropContent
      ref={backDropRef}
      style={{ userSelect: 'text' }}
      onClick={async event => {
        if (event.target !== backDropRef.current) return;

        if (!isDirty || (await getShouldClose())) closeHandler();
      }}
    >
      <ModalTemplate
        headerText="Manage Organization Fields"
        onCloseButtonClick={async () => {
          if (!isDirty || (await getShouldClose())) closeHandler();
        }}
        modalSize={ModalSize.L}
      >
        {shouldShowNoAccessPage && <UnauthorizedAccessPage />}
        {shouldCalculatedSearchPage && (
          <DarkBackdropContent
            ref={editGremlinQueryBackdropRef}
            onClick={async event => {
              if (event.target !== editGremlinQueryBackdropRef.current) return;
              if (!isDirty) {
                setIsEditMode(false);
                return;
              }
              if (await getShouldClose()) {
                setIsEditMode(false);
              }
            }}
          >
            <ModalContainer modalSize={ModalSize.L}>
              <ModalBody>
                <CalculatedSearch
                  setIsDirty={setIsDirty}
                  setIsEditMode={setIsEditMode}
                  shouldHideBackButton={shouldOpenInEditMode}
                />
              </ModalBody>
            </ModalContainer>
          </DarkBackdropContent>
        )}
        {!isEditMode && (
          <GlobalFieldManager
            calculatedToggleState={calculatedToggleState}
            setIsEditMode={setIsEditMode}
            onClickWorkspace={workspaceId => {
              closeHandler();
              openWorkspaces([workspaceId], {
                trackingLocation: 'organizationSettings',
              });
            }}
            canCreateGraphFilter={canCreateGraphFilter}
          />
        )}
      </ModalTemplate>
    </DarkBackdropContent>
  );
};

export default connect(
  GlobalFieldManagerDialog,
  currentUserPermissionContext$.pipe(
    map(permissionContext => {
      return {
        canCreateGraphFilter:
          graphSearchAccessControlHelpers.canCreateGraphFilter(
            permissionContext
          ),
      };
    })
  )
);
