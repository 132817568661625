import { APIFieldAttributes } from '@ardoq/api-types';
import { actionCreator } from '@ardoq/rxbeach';
import { ViewpointFormattingState } from './viewpointFormattingTypes';
import { FormattingTabProps } from '@ardoq/perspectives';
import { LabelFormattingInfo } from '@ardoq/data-model';

export type GetFormattingOptionsPayload = {
  workspaceIds: string[];
  componentTypeNames: string[];
  referenceTypeNames: string[];
  availableComponentFields: APIFieldAttributes[];
  availableReferenceFields: APIFieldAttributes[];
  componentFieldsByType: Map<string, APIFieldAttributes[]>;
  referenceFieldsByType: Map<string, APIFieldAttributes[]>;
};

export const getFormattingOptions = actionCreator<GetFormattingOptionsPayload>(
  '[ViewpointBuilderFormatting] GET_FORMATTING_OPTIONS'
);

export type SetFormattingOptionsPayload = {
  viewpointBuilderFormattingOptions: ViewpointFormattingState['viewpointBuilderFormattingOptions'];
};

export const setFormattingOptions = actionCreator<SetFormattingOptionsPayload>(
  '[ViewpointBuilderFormatting] SET_FORMATTING_OPTIONS'
);

export type SetFormattingRulesPayload = {
  formattingRules: FormattingTabProps['conditionalFormatting']['formattingRules'];
};

export const setFormattingRules = actionCreator<SetFormattingRulesPayload>(
  '[ViewpointBuilderFormatting] SET_FORMATTING_RULES'
);

export type SetLabelFormattingPayload = {
  labelFormatting: LabelFormattingInfo[];
};

export const setLabelFormatting = actionCreator<SetLabelFormattingPayload>(
  '[ViewpointBuilderFormatting] SET_LABEL_FORMATTING'
);

export const resetFormattingState = actionCreator(
  '[ViewpointBuilderFormatting] RESET_FORMATTING_STATE'
);
