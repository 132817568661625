import { launchDeleteComponentsAndReferencesConfirmationDialogByIds } from 'components/Dialogs/confirmDeletion/confirmDeletion';
import { RequireAtLeastOne } from '@ardoq/common-helpers';
import { ensureComponentTypesIncluded } from 'quickPerspectives/legends/componentFilterRuleManager';
import { ArdoqId } from '@ardoq/api-types';
import { filterInterface } from 'modelInterface/filters/filterInterface';
import { ensureReferenceTypeIncluded } from 'quickPerspectives/legends/referenceFilterRuleManager';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { IconName } from '@ardoq/icons';
import { TrackedNonComponentGroupContextMenuLabels } from './tracking';
import { type DropdownOption, DropdownOptionType } from '@ardoq/dropdown-menu';
import Context from 'context';

import { workspaceInterface } from '@ardoq/workspace-interface';
import { componentInterface } from '@ardoq/component-interface';
import { referenceInterface } from '@ardoq/reference-interface';
import * as encodingUtils from '@ardoq/html';
import { metaModelOperations } from 'architectureModel/metaModelOperations';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { subdivisionsInterface } from 'streams/subdivisions/subdivisionInterface';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

type DeleteModelsArgs = RequireAtLeastOne<{
  componentIds?: ArdoqId[];
  referenceIds?: ArdoqId[];
}> & { getNextTreeSelectionCandidate?: () => ArdoqId | null };

export type TrackingFunction = (optionTitle: string) => void;

export const deleteModels = (deleteArgs: DeleteModelsArgs) => {
  if (!(deleteArgs.componentIds || deleteArgs.referenceIds)) {
    return;
  }

  launchDeleteComponentsAndReferencesConfirmationDialogByIds(deleteArgs);
};

export const includeComponentsOfType = (componentTypeName: string) =>
  ensureComponentTypesIncluded(
    [componentTypeName],
    filterInterface.getFiltersAsQueryBuilderQueries().componentRules
  );

export const includeReferencesOfType = (referenceTypeName: string) =>
  ensureReferenceTypeIncluded(
    referenceTypeName,
    filterInterface.getFiltersAsQueryBuilderQueries().referenceRules
  );
type CheckHasWriteAccessArgs = {
  workspaceIds: ArdoqId[];
  componentIds?: ArdoqId[];
  referenceIds?: ArdoqId[];
};
export const checkHasWriteAccess = ({
  workspaceIds,
  componentIds,
  referenceIds,
}: CheckHasWriteAccessArgs) => {
  // Requires special handling. An admin has always write access, but the
  // meta model should never be editable.
  if (workspaceIds.some(metaModelOperations.isMetaModelWorkspace)) return false;

  const activeScenarioState = getActiveScenarioState();
  const permissionContext = currentUserInterface.getPermissionContext();

  const canEditAllWorkSpaces = workspaceIds.every(id =>
    workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      id,
      activeScenarioState
    )
  );
  if (
    // If we are in scenario mode, we only care about the scenarios permissions,
    // Which is checked on the canEditWorkspace function
    activeScenarioOperations.isInScenarioMode(activeScenarioState) ||
    canEditAllWorkSpaces
  ) {
    return canEditAllWorkSpaces;
  }

  // Need to check if components or references have write access from zones
  if (!componentIds && !referenceIds) {
    return false;
  }

  const canEditAllComponents =
    !componentIds ||
    componentIds.every(id => {
      const component = componentInterface.getComponentData(id);
      return (
        component &&
        componentAccessControlOperation.canEditComponent({
          component,
          permissionContext,
          subdivisionsContext:
            subdivisionsInterface.getSubdivisionsStreamState(),
        })
      );
    });
  const canEditAllReferences =
    !referenceIds ||
    referenceIds.every(id => {
      const reference = referenceInterface.getReferenceData(id);
      return (
        reference &&
        referenceAccessControlOperation.canEditReference({
          reference,
          permissionContext,
          subdivisionsContext:
            subdivisionsInterface.getSubdivisionsStreamState(),
        })
      );
    });

  return canEditAllComponents && canEditAllReferences;
};

/**
 * returns typeName if the modelId is not a component, reference or workspace, undefined otherwise
 */
export const getTypeGroupNameByTypeId = (modelId?: string | null) => {
  if (
    !modelId ||
    componentInterface.isComponent(modelId) ||
    referenceInterface.isReference(modelId) ||
    workspaceInterface.isWorkspace(modelId)
  ) {
    return;
  }

  for (const workspaceId of Context.getConnectedWorkspaceIds()) {
    const typeName = workspaceInterface
      .getComponentTypes(workspaceId)
      .find(({ id: typeId }) => typeId === modelId)?.name;
    if (typeName) return typeName;
  }
};

export const getZoomToFitContextMenuItem = (
  onZoomToFitContextMenuItemClick: VoidFunction,
  trackingFunction?: TrackingFunction
): DropdownOption => ({
  name: 'zoom-to-fit',
  testId: 'zoom-to-fit-item',
  label: 'Zoom to fit',
  iconName: IconName.ZOOM_TO_FIT,
  onClick: () => {
    onZoomToFitContextMenuItemClick();
    trackingFunction?.(TrackedNonComponentGroupContextMenuLabels.ZOOM_TO_FIT);
  },
  type: DropdownOptionType.OPTION,
});
export const getExpandOrCollapseGroupMenuItem = (
  isGroupOpen?: boolean,
  onExpandOrCollapseGroupContextMenuItemClick?: VoidFunction,
  trackingFunction?: TrackingFunction
) => ({
  // relying on type safety of expandOrCollapseGroupContextMenuItemProps here
  name: 'toggle-collapse-group',
  testId: 'toggle-collapse-group-item',
  label: `${isGroupOpen ? 'Collapse' : 'Expand'} group`,
  iconName: isGroupOpen ? IconName.COLLAPSE_ALL : IconName.EXPAND_ALL,
  onClick: () => {
    onExpandOrCollapseGroupContextMenuItemClick?.();
    trackingFunction?.(
      isGroupOpen
        ? TrackedNonComponentGroupContextMenuLabels.COLLAPSE_GROUP
        : TrackedNonComponentGroupContextMenuLabels.EXPAND_GROUP
    );
  },
  type: DropdownOptionType.OPTION,
});

export const findElement = (selector: string, target: Element) => {
  const element = target.closest(selector);
  return element instanceof HTMLElement || element instanceof SVGElement
    ? element
    : null;
};

const CREATE_FILTER_OPTIONS = Object.freeze({ shouldTriggerChangeEvent: true });

enum WorkspaceReferenceFilterType {
  SOURCE = 'rootWorkspace',
  TARGET = 'targetWorkspace',
}

export const excludeContentFromWorkspace = (workspaceId: ArdoqId) =>
  filterInterface.createFilter(
    {
      name: 'rootWorkspace',
      value: workspaceId,
      affectReference: true,
      affectComponent: true,
      isNegative: true,
      filterName: encodingUtils.escapeHTML(
        workspaceInterface.getWorkspaceName(workspaceId)
      ),
    },
    CREATE_FILTER_OPTIONS
  );
const excludeWorkspaceReferences = (
  workspaceId: ArdoqId,
  referenceFilterType: WorkspaceReferenceFilterType
) =>
  filterInterface.createFilter(
    {
      name: referenceFilterType,
      value: workspaceId,
      affectReference: true,
      affectComponent: false,
      isNegative: true,
      filterName: encodingUtils.escapeHTML(
        workspaceInterface.getWorkspaceName(workspaceId)
      ),
    },
    CREATE_FILTER_OPTIONS
  );
export const excludeReferencesFromWorkspace = (workspaceId: ArdoqId) =>
  excludeWorkspaceReferences(workspaceId, WorkspaceReferenceFilterType.SOURCE);

export const excludeReferencesToWorkspace = (workspaceId: ArdoqId) =>
  excludeWorkspaceReferences(workspaceId, WorkspaceReferenceFilterType.TARGET);

export const excludeChildComponents = (componentId: ArdoqId) =>
  filterInterface.createFilter(
    {
      name: 'parent',
      value: componentId,
      affectComponent: true,
      affectReference: false,
      isNegative: true,
      filterName:
        componentInterface.getAttribute(componentId, 'name') ?? undefined,
    },
    CREATE_FILTER_OPTIONS
  );

export const excludeComponentsOfType = (componentTypeName: string | null) =>
  filterInterface.createFilter(
    {
      name: 'type',
      value: componentTypeName ?? '',
      affectReference: false,
      affectComponent: true,
      isNegative: true,
    },
    CREATE_FILTER_OPTIONS
  );

export const excludeReferencesOfType = (referenceTypeName: string) =>
  filterInterface.createFilter(
    {
      name: 'type',
      value: referenceTypeName,
      affectReference: true,
      affectComponent: false,
      isNegative: true,
    },
    CREATE_FILTER_OPTIONS
  );

export const createReferencesToTargetFilter = (referenceId: string) => {
  const targetComponentId =
    referenceInterface.getTargetComponentId(referenceId);

  if (!targetComponentId) {
    return;
  }

  return filterInterface.createFilter(
    {
      name: 'target',
      value: targetComponentId,
      affectReference: true,
      affectComponent: false,
      isNegative: false,
      filterName:
        componentInterface.getAttribute(targetComponentId, 'name') ?? undefined,
    },
    CREATE_FILTER_OPTIONS
  );
};

export const createReferencesFromSourceFilter = (referenceId: string) => {
  const sourceComponentId =
    referenceInterface.getSourceComponentId(referenceId);

  if (!sourceComponentId) {
    return;
  }

  return filterInterface.createFilter(
    {
      name: 'source',
      value: sourceComponentId,
      affectReference: true,
      affectComponent: false,
      isNegative: false,
      filterName:
        componentInterface.getAttribute(sourceComponentId, 'name') ?? undefined,
    },
    CREATE_FILTER_OPTIONS
  );
};

export const excludeSelected = (modelId: ArdoqId, isReference: boolean) =>
  filterInterface.createFilter(
    {
      name: '_id',
      value: modelId,
      affectComponent: !isReference,
      affectReference: isReference,
      isNegative: true,
      filterName: isReference
        ? (referenceInterface.getName(modelId) ?? undefined)
        : (componentInterface.getAttribute(modelId, 'name') ?? undefined),
    },
    CREATE_FILTER_OPTIONS
  );
