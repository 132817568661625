import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';
import { combineLatest, switchMap, filter, startWith } from 'rxjs';
import { handleError, inventoryApi, workspaceApi } from '@ardoq/api';
import {
  ApiComponentTypeOverviewRow,
  APIWorkspaceOverview,
} from '@ardoq/api-types';
import {
  DataOverviewRow,
  DataOverviewState,
  DataOverviewTypes,
  DataOverviewComponentTypeRow,
  DataOverviewWorkspaceRow,
  DataOverviewStatus,
} from './types';
import {
  dataOverviewTypeSelected,
  numberOfRowsPerPageSelected,
  dataOverviewSearchValueChanged,
  dataOverviewStatusChanged,
  dataOverviewSelectedItemToggled,
  dataOverviewSelectedItemsCleared,
} from './actions';
import { dataOverviewCommands } from './dataOverviewCommands';
import { DataOverviewProps } from './DataOverview';
import { setStateProperty } from '@ardoq/common-helpers';
import { locale$ } from 'streams/locale$';
import { getComponentRepresentationDataWithColorShades } from 'componentRepresentationData';
import { DEFAULT_NUMBER_OF_ROWS_PER_PAGE } from './consts';
import { ArdoqId } from '@ardoq/api-types';
import { parseDate } from '@ardoq/date-time';
import { groupBy, sumBy, maxBy } from 'lodash';
import { Maybe } from '@ardoq/common-helpers';

const isWorkspacesOverview = ({ dataOverviewType }: DataOverviewState) =>
  dataOverviewType === DataOverviewTypes.WORKSPACE;

const isComponentTypesOverview = ({ dataOverviewType }: DataOverviewState) =>
  dataOverviewType === DataOverviewTypes.COMPONENT_TYPE;

const isSearchActive = ({ searchValue }: DataOverviewState) =>
  searchValue !== '';

const toSearchedDatasource = <T extends DataOverviewRow>({
  dataOverview,
  datasource,
}: {
  dataOverview: DataOverviewState;
  datasource: T[] | null;
}) =>
  isSearchActive(dataOverview) && Array.isArray(datasource)
    ? datasource.filter(row =>
        row.name.toLowerCase().includes(dataOverview.searchValue.toLowerCase())
      )
    : datasource;

const toComponentTypeOverviewRow = ({
  _id,
  name,
  componentCount,
  icon,
  lastUpdated,
  color,
  image,
  level,
}: ApiComponentTypeOverviewRow): DataOverviewComponentTypeRow => {
  return {
    _id,
    name,
    totalNumberOfComponents: componentCount,
    lastUpdated,
    representationData: getComponentRepresentationDataWithColorShades({
      color,
      level,
      image,
      icon,
    }),
    componentTypeIds: [],
  };
};

const toWorkspaceOverviewRow = ({
  _id,
  name,
  componentCount,
  lastUpdated,
  componentTypeIds,
}: APIWorkspaceOverview): DataOverviewWorkspaceRow => ({
  _id,
  name,
  totalNumberOfComponents: componentCount,
  lastUpdated,
  componentTypeIds,
});

const toMergedComponentTypeRows = ({
  dataOverview,
  datasource,
}: {
  dataOverview: DataOverviewState;
  datasource: Maybe<DataOverviewComponentTypeRow[]>;
}) => {
  if (!datasource) {
    return { dataOverview, datasource };
  }

  /**
   * There are multiple component types with the same name coming
   * from different workspaces. We want to treat them
   * as a single component type. To do this we need to merge
   * them together based on their "name"
   */
  const componentTypesGroupedByName = groupBy(datasource, 'name');
  const componentTypesMergedByName: DataOverviewComponentTypeRow[] =
    Object.values(componentTypesGroupedByName).map(componentTypesGroup => {
      const componentType = componentTypesGroup[0];

      if (componentTypesGroup.length === 1) {
        return {
          ...componentType,
          componentTypeIds: [componentType._id],
        };
      }

      // Sum the number of components across all component types with the same name
      const totalNumberOfComponents = sumBy(
        componentTypesGroup,
        'totalNumberOfComponents'
      );
      // Get the latest update date from all component types with the same name
      const lastUpdated =
        maxBy(componentTypesGroup, componentType =>
          parseDate(componentType.lastUpdated)
        )?.lastUpdated ?? componentType.lastUpdated;

      return {
        ...componentType,
        totalNumberOfComponents,
        lastUpdated,
        componentTypeIds: componentTypesGroup.map(
          componentType => componentType._id
        ),
      };
    });

  return { dataOverview, datasource: componentTypesMergedByName };
};

const handleToggleSelectedItem = (
  state: DataOverviewState,
  itemId: ArdoqId
) => {
  const selectedItems = state.selectedItems;

  if (selectedItems.includes(itemId)) {
    return {
      ...state,
      selectedItems: selectedItems.filter(item => item !== itemId),
    };
  }

  return {
    ...state,
    selectedItems: [...selectedItems, itemId],
  };
};

const handleClearSelectedItems = (state: DataOverviewState) => {
  const selectedItems: string[] = [];

  return {
    ...state,
    selectedItems,
  };
};

const getInitialState = (): DataOverviewState => ({
  dataOverviewType: DataOverviewTypes.WORKSPACE,
  numberOfRowsPerPage: DEFAULT_NUMBER_OF_ROWS_PER_PAGE,
  searchValue: '',
  status: DataOverviewStatus.IS_IDLE,
  selectedItems: [],
});

export const dataOverview$ = persistentReducedStream<DataOverviewState>(
  'dataOverview$',
  getInitialState(),
  [
    reducer(dataOverviewTypeSelected, setStateProperty('dataOverviewType')),
    reducer(
      numberOfRowsPerPageSelected,
      setStateProperty('numberOfRowsPerPage')
    ),
    reducer(dataOverviewSearchValueChanged, setStateProperty('searchValue')),
    reducer(dataOverviewStatusChanged, setStateProperty('status')),
    reducer(dataOverviewSelectedItemToggled, handleToggleSelectedItem),
    reducer(dataOverviewSelectedItemsCleared, handleClearSelectedItems),
  ]
);

const fetchedDataOverviewComponentTypes$ = dataOverview$.pipe(
  filter(isComponentTypesOverview),
  map(({ dataOverviewType }) => dataOverviewType),
  distinctUntilChanged(),
  tap(() => {
    dataOverviewCommands.setStatus(DataOverviewStatus.IS_LOADING);
  }),
  switchMap(inventoryApi.fetchComponentTypeOverview),
  handleError(() => {
    dataOverviewCommands.setStatus(DataOverviewStatus.IS_ERROR);
  }),
  map(response => response.map(toComponentTypeOverviewRow)),
  tap(() => {
    dataOverviewCommands.setStatus(DataOverviewStatus.IS_IDLE);
  }),
  startWith(null)
);

const fetchedDataOverviewWorkspaces$ = dataOverview$.pipe(
  filter(isWorkspacesOverview),
  map(({ dataOverviewType }) => dataOverviewType),
  distinctUntilChanged(),
  tap(() => {
    dataOverviewCommands.setStatus(DataOverviewStatus.IS_LOADING);
  }),
  switchMap(workspaceApi.overview),
  handleError(() => {
    dataOverviewCommands.setStatus(DataOverviewStatus.IS_ERROR);
  }),
  map(response => response.map(toWorkspaceOverviewRow)),
  tap(() => {
    dataOverviewCommands.setStatus(DataOverviewStatus.IS_IDLE);
  }),
  startWith(null)
);

const dataOverviewComponentTypes$ = combineLatest({
  datasource: fetchedDataOverviewComponentTypes$,
  dataOverview: dataOverview$,
}).pipe(
  filter(({ dataOverview }) => isComponentTypesOverview(dataOverview)),
  map(toMergedComponentTypeRows),
  map(toSearchedDatasource),
  startWith(null)
);

const dataOverviewWorkspaces$ = combineLatest({
  datasource: fetchedDataOverviewWorkspaces$,
  dataOverview: dataOverview$,
}).pipe(
  filter(({ dataOverview }) => isWorkspacesOverview(dataOverview)),
  map(toSearchedDatasource),
  startWith(null)
);

const dataOverviewViewModel$ = combineLatest({
  locale: locale$,
  workspaces: dataOverviewWorkspaces$,
  componentTypes: dataOverviewComponentTypes$,
  dataOverview: dataOverview$,
}).pipe(
  map(
    ({
      locale,
      workspaces,
      componentTypes,
      dataOverview,
    }): DataOverviewProps => {
      const props = {
        locale,
        dataOverviewCommands,
        numberOfRowsPerPage: dataOverview.numberOfRowsPerPage,
        isSearchActive: isSearchActive(dataOverview),
        isError: dataOverview.status === DataOverviewStatus.IS_ERROR,
        searchValue: dataOverview.searchValue,
        selectedItems: dataOverview.selectedItems,
      };

      if (dataOverview.dataOverviewType === DataOverviewTypes.COMPONENT_TYPE) {
        return {
          ...props,
          dataOverviewType: DataOverviewTypes.COMPONENT_TYPE,
          datasource: Array.isArray(componentTypes) ? componentTypes : [],
          isLoading:
            dataOverview.status === DataOverviewStatus.IS_LOADING ||
            componentTypes === null,
        };
      }

      return {
        ...props,
        dataOverviewType: DataOverviewTypes.WORKSPACE,
        datasource: Array.isArray(workspaces) ? workspaces : [],
        isLoading:
          dataOverview.status === DataOverviewStatus.IS_LOADING ||
          workspaces === null,
      };
    }
  )
);

export default dataOverviewViewModel$;
