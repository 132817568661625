import styled, { css } from 'styled-components';
import { colors, r4, s4, spacing } from '@ardoq/design-tokens';
import { SmallGhostButton } from '@ardoq/button';
import { DragAndDropCardWrapper } from '@ardoq/drag-and-drop';
import { Box, Stack } from '@ardoq/layout';
import { TextArea } from '@ardoq/forms';

export const SlideAction = styled(SmallGhostButton)`
  width: ${spacing.s32};
`;

const textEditorStyles = css`
  h1 {
    font-size: 18px;
    line-height: 20px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h2 {
    font-size: 17px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
    padding-bottom: 0;
  }
  h3,
  h4 {
    font-size: 16px;
    line-height: 18px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h4 {
    color: ${colors.textSubtle};
  }
  h5 {
    font-size: 14px;
    line-height: 16px;
  }
  h6 {
    font-size: 12px;
    line-height: 14px;
  }
  ul {
    padding-inline-start: 30px;
    li p {
      margin-bottom: 5px;
    }
  }
  hr {
    margin: 16px 0;
  }
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 165px;
  overflow: hidden;
  word-break: break-word;

  &.clickable {
    cursor: pointer;
  }
  .tui-editor-contents {
    ${textEditorStyles}
  }
`;

export const PresentationDragAndDropCardWrapper = styled(
  DragAndDropCardWrapper
)<{ $selected?: boolean }>`
  padding: ${spacing.s8};
  background-color: ${colors.surfaceDefault};
  align-items: center;
  border-bottom: 2px solid ${colors.borderSubtle00};
  border-left: 3px solid rgba(255, 255, 255, 0);
  ${props =>
    props.$selected &&
    css`
      border-left: 3px solid ${colors.borderActionActive};
    `}
`;

export const FullHeightStack = styled(Stack)`
  height: 100%;
  overflow: hidden;
`;

export const ActivePresentationWrapper = styled.div`
  background-color: ${colors.surfaceStatusNeutral};
  flex-shrink: 0;
  width: 380px;
  border-left: 1px solid ${colors.borderSubtle00};
`;

export const SidebarContentBody = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

export const SidebarContentFooter = styled.div`
  padding: ${spacing.s16};
  width: 100%;
  min-height: 70px;
  background-color: ${colors.surfaceStatusNeutral};
  border-top: 1px solid ${colors.borderAction};
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: flex-start;
  gap: ${spacing.s16};
`;

export const Section = styled(Box)`
  background-color: ${colors.surfaceDefault};
  border-bottom: 1px solid ${colors.borderAction};
  border-top: 1px solid ${colors.borderAction};
`;

export const SlideImageWrapper = styled.div`
  overflow: hidden;
  height: 125px;
  width: 100%;
  align-items: center;
  border-radius: ${r4};
  border: 1px solid ${colors.borderAction};
  display: flex;
  flex-direction: column;
`;

export const SlideHeader = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  pointer-events: none;
  padding: ${s4};
  line-height: 20px;
`;

export const SlideDescription = styled.div<{ $isExpanded: boolean }>`
  max-height: ${props => (props.$isExpanded ? 'none' : '120px')};
  word-wrap: break-word;
  white-space: normal;
  padding-left: ${spacing.s4};
  margin-bottom: ${spacing.s8};
  .tui-editor-contents {
    padding-bottom: 0;
  }
  overflow-y: clip;
  a {
    cursor: pointer;
  }
  * {
    max-width: 100%;
  }
  > :first-child {
    margin-top: 0;
  }
  ${textEditorStyles};
  ${props =>
    props.$isExpanded &&
    css`
      flex: 1;
    `}
`;

export const SlideTextArea = styled(TextArea)`
  height: 320px;
`;

export const SlideImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
