import { existingWorkspaces$ } from 'integrations/common/streams/workspaces/workspaces$';
import { combineLatest, map } from 'rxjs';
import { configs$ } from '../streams/configs/configs$';
import { SelectOption } from '@ardoq/select';
import { dispatchAction } from '@ardoq/rxbeach';
import { setSelectedWorkspaces } from '../streams/configs/actions';
import { document$ } from '../streams/document/document$';

export const viewModel$ = combineLatest([
  existingWorkspaces$,
  configs$,
  document$,
]).pipe(
  map(([workspaces, configs]) => {
    return {
      value: configs.workspaces.selected,
      options: workspaces.map(workspace => ({
        value: workspace._id,
        label: workspace.name,
      })),
      onChange: (selectedOptions: SelectOption<string>[] | null) => {
        if (!selectedOptions) {
          return;
        }
        dispatchAction(setSelectedWorkspaces(selectedOptions));
      },
    };
  })
);
