import {
  APIFieldAttributes,
  DirectedTripleWithFilters,
} from '@ardoq/api-types';
import { uniq } from 'lodash';
import { ExcludeFalsy } from '@ardoq/common-helpers';

type TraversalPathsAndEntitiesNameIndices = {
  paths: DirectedTripleWithFilters[][];
  componentNameAndWorkspacesIndex: Map<string, string[]>;
  componentNameAndFieldsIndex: Map<string, string[]>;
  referenceNameAndFieldsIndex: Map<string, string[]>;
  fieldMap: Map<string, APIFieldAttributes>;
};

const isParentChildReference = (referenceType: string) =>
  referenceType === 'ardoq_parent';

export const getWorkspaceIdsAndTypeNamesFromTraversalPaths = ({
  paths,
  componentNameAndWorkspacesIndex,
  componentNameAndFieldsIndex,
  referenceNameAndFieldsIndex,
  fieldMap,
}: TraversalPathsAndEntitiesNameIndices) => {
  const { referenceTypeNames, componentTypeNames } = paths.reduce<{
    referenceTypeNames: string[];
    componentTypeNames: string[];
  }>(
    ({ referenceTypeNames, componentTypeNames }, path) =>
      path.reduce(
        (
          { referenceTypeNames, componentTypeNames },
          { sourceType, targetType, referenceType }
        ) => ({
          referenceTypeNames: isParentChildReference(referenceType)
            ? referenceTypeNames
            : uniq([...referenceTypeNames, referenceType]),
          componentTypeNames: uniq([
            ...componentTypeNames,
            sourceType,
            targetType,
          ]),
        }),
        { referenceTypeNames, componentTypeNames }
      ),
    { referenceTypeNames: [], componentTypeNames: [] }
  );
  const workspaceIds = uniq(
    componentTypeNames.flatMap(
      componentTypeName =>
        componentNameAndWorkspacesIndex.get(componentTypeName) ?? []
    )
  );
  const availableComponentFields = componentTypeNames
    .flatMap(typeName => componentNameAndFieldsIndex.get(typeName) ?? [])
    .map(name => fieldMap.get(name) ?? null)
    .filter(ExcludeFalsy);
  const availableReferenceFields = referenceTypeNames
    .flatMap(typeName => referenceNameAndFieldsIndex.get(typeName) ?? [])
    .map(name => fieldMap.get(name) ?? null)
    .filter(ExcludeFalsy);
  const componentFieldsByType = new Map(
    componentTypeNames.map(typeName => [
      typeName,
      (componentNameAndFieldsIndex.get(typeName) || [])
        .map(typeName => fieldMap.get(typeName))
        .filter(ExcludeFalsy),
    ])
  );
  const referenceFieldsByType = new Map(
    referenceTypeNames.map(typeName => [
      typeName,
      (referenceNameAndFieldsIndex.get(typeName) || [])
        .map(name => fieldMap.get(name))
        .filter(ExcludeFalsy),
    ])
  );
  return {
    workspaceIds,
    componentTypeNames,
    referenceTypeNames,
    availableComponentFields,
    availableReferenceFields,
    componentFieldsByType,
    referenceFieldsByType,
  };
};
