import { contextToolBar$ } from '../contextToolBar$';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { traversalState$ } from '../../streams/traversals$';
import { APIViewpointAttributes, MetaModel } from '@ardoq/api-types';
import { componentInterface } from '@ardoq/component-interface';
import { metaModel$ } from '../../architectureModel/metaModel$';
import { editTraversalOperations } from '../../viewpointBuilder/traversals/editTraversalOperations';
import { getId } from '../../viewpointBuilder/getId';
import { isArdoqError } from '@ardoq/common-helpers';
import { GraphId } from '../../viewpointBuilder/types';
import { GraphEdgeWithMetaData, GraphNodeWithMetaData } from '@ardoq/graph';
import { metaModelOperations } from '../../architectureModel/metaModelOperations';
import { CurrentToolbarContext } from '../types';

export type ViewpointSwitcherViewModel = {
  currentContext: CurrentToolbarContext | null;
  viewpoints: {
    attributes: APIViewpointAttributes;
    isCurrent: boolean;
    preview: {
      graphNodes: Map<GraphId, GraphNodeWithMetaData>;
      graphEdges: Map<GraphId, GraphEdgeWithMetaData>;
    };
  }[];
};

const getPreviewForViewpoint = (
  { paths, startType }: APIViewpointAttributes,
  metaModel: MetaModel
) => {
  const extendedMetamodel =
    metaModelOperations.getMetamodelExtendedWithTriplesInPaths(
      metaModel,
      paths
    );

  const { graphEdges, graphNodes } = editTraversalOperations.pathsToGraph({
    paths,
    startType,
    metaModel: extendedMetamodel,
    getId,
    startNode: null,
    filters: null,
  });
  return { graphEdges, graphNodes };
};

export const viewpointSwitcherViewModel$: Observable<ViewpointSwitcherViewModel> =
  combineLatest({
    contextToolBar: contextToolBar$,
    viewpoints: traversalState$,
    metaModel: metaModel$,
  }).pipe(
    map(({ contextToolBar, viewpoints, metaModel }) => {
      const { currentContext } = contextToolBar;
      if (!currentContext || isArdoqError(metaModel)) {
        return {
          viewpoints: [],
          currentContext,
        };
      }

      const startType = componentInterface.getTypeName(
        currentContext!.selectedIds[0]
      )!;
      const viewpointsForType = viewpoints.list
        .filter(viewpoint => viewpoint.startType === startType)
        .sort((a, b) => (a.name > b.name ? 1 : -1));

      return {
        selectedIds: currentContext!.selectedIds,
        currentContext,
        viewpoints: viewpointsForType.map(attributes => ({
          preview: getPreviewForViewpoint(attributes, metaModel),
          attributes,
          isCurrent: contextToolBar.currentViewpointId === attributes._id,
        })),
      };
    })
  );
