import { AnimatedTreeChevron, IconSize } from '@ardoq/icons';
import { NavigatorItemExpander } from './Atoms';
import { colors } from '@ardoq/design-tokens';

type NodeExpanderProps = {
  readonly hasVisibleContent: boolean;
  readonly isExpanded: boolean;
  readonly name: string;
};

const NodeExpander = ({
  hasVisibleContent,
  isExpanded,
  name,
}: NodeExpanderProps) => {
  const ariaLabel = isExpanded ? `Collapse ${name}` : `Expand ${name}`;
  return hasVisibleContent ? (
    <NavigatorItemExpander aria-label={ariaLabel} role="button">
      {hasVisibleContent && (
        <AnimatedTreeChevron
          iconSize={IconSize.SMALL}
          $isUp={isExpanded}
          color={colors.grey25}
        />
      )}
    </NavigatorItemExpander>
  ) : (
    <NavigatorItemExpander />
  );
};

export default NodeExpander;
