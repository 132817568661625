import { ArdoqId } from '@ardoq/api-types';
import { GetContextMenuOptionsArguments } from '@ardoq/context-menu';
import { DropdownItem } from '@ardoq/dropdown-menu';
import { getTrackingFunction } from './tracking';
import { TrackedContextMenus } from './tracking';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  getDeleteMixedEntitiesItem,
  getDropdownDivider,
  getEditPropertiesMenuItem,
  getEditStyleMenuItem,
  getReverseReferenceMenuItem,
  getToggleLockMenuItem,
} from './menuItems';
import { checkHasWriteAccess } from './utils';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { currentUserInterface } from 'modelInterface/currentUser/currentUserInterface';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';

type GetMixedEntitiesMenuArgs = {
  componentIds: ArdoqId[];
  referenceIds: ArdoqId[];
  isViewpointMode: boolean;
} & GetContextMenuOptionsArguments;
export const getMixedEntitiesMenu = ({
  componentIds,
  referenceIds,
  target,
  isViewpointMode,
}: GetMixedEntitiesMenuArgs): DropdownItem[] | null => {
  const workspaceIds = [
    ...new Set(
      [...componentIds.map(componentInterface.getWorkspaceId)].filter(
        ExcludeFalsy
      )
    ),
    ...new Set(
      [...referenceIds.map(referenceInterface.getRootWorkspaceId)].filter(
        ExcludeFalsy
      )
    ),
  ];
  const hasWriteAccess = checkHasWriteAccess({
    workspaceIds,
    componentIds,
    referenceIds,
  });

  if (!hasWriteAccess) {
    // no context menu for multiselect with no write access
    return null;
  }

  const hasLockedReferences = referenceIds.some(referenceInterface.isLocked);

  const hasLockedComponents = componentIds.some(componentInterface.isLocked);

  const hasLockedEntities = hasLockedComponents || hasLockedReferences;
  const activeScenarioState = getActiveScenarioState();
  const canEditScenario =
    !activeScenarioOperations.isInScenarioMode(activeScenarioState) ||
    scenarioAccessControlInterface.canEditActiveScenario(
      currentUserInterface.getPermissionContext(),
      activeScenarioState
    );
  const canEditAllReferencesTargets =
    canEditScenario &&
    referenceIds.every(referenceId =>
      referenceAccessControlOperation.canEditReferencesTargetComponentByReferenceId(
        referenceId
      )
    );

  const menuItemMixedTrackingFunction = getTrackingFunction(
    target,
    TrackedContextMenus.COMPONENTS_AND_REFERENCES_CONTEXT_MENU
  );
  const menu = [
    ...(hasLockedEntities
      ? []
      : [
          getEditPropertiesMenuItem(
            componentIds,
            referenceIds,
            menuItemMixedTrackingFunction
          ),
          !isViewpointMode &&
            getEditStyleMenuItem(
              componentIds,
              referenceIds,
              menuItemMixedTrackingFunction
            ),
        ]),

    ...(hasLockedReferences || isViewpointMode
      ? []
      : [
          getReverseReferenceMenuItem(
            referenceIds,
            menuItemMixedTrackingFunction,
            canEditAllReferencesTargets
          ),
          !hasLockedEntities &&
            getDeleteMixedEntitiesItem(
              componentIds,
              referenceIds,
              menuItemMixedTrackingFunction
            ),
          getDropdownDivider(),
        ]),

    isViewpointMode
      ? null
      : getToggleLockMenuItem(
          componentIds,
          referenceIds,
          menuItemMixedTrackingFunction
        ),
  ].filter(ExcludeFalsy);

  return menu.length ? menu : null;
};
