import { TextInput } from '@ardoq/forms';
import { Icon, IconName } from '@ardoq/icons';
import { FlexBox, Box, Stack } from '@ardoq/layout';
import { SlideContainer, SlideAction } from './atoms';
import { SlideHandler } from './types';
import { Header4 } from '@ardoq/typography';
import { noop } from 'lodash';
import { APISlideAttributes } from '@ardoq/api-types';
import { RichTextEditorTransition } from '@ardoq/rich-text-editor-2024';

const editorConfig = {
  usageStatistics: false,
  initialEditType: 'wysiwyg',
  colorPicker: false,
  hideModeSwitch: true,
  height: 'auto',
  hooks: {
    addImageBlobHook: noop,
  },
  toolbarItems: ['heading', 'bold', 'italic', 'link', 'hr', 'ul', 'ol'],
};

export const SlideEdit = ({
  slide,
  onSlideEditedAttributes,
  onSaveChangesClick,
  onDiscardChangesClick,
  editsToCurrentSlide,
}: {
  slide: APISlideAttributes;
  onSlideEditedAttributes: (
    value: string,
    attributeName: 'name' | 'description'
  ) => void;
  onSaveChangesClick: (slide?: APISlideAttributes) => void;
  onDiscardChangesClick: SlideHandler;
  editsToCurrentSlide:
    | Partial<Record<'name' | 'description', string>>
    | undefined;
}) => {
  return (
    <SlideContainer className={'editing'} data-intercom-target="slide">
      <FlexBox justify="end" align="end">
        <SlideAction
          onClick={() => onSaveChangesClick()}
          data-tooltip-text="Save description & title"
        >
          <Icon iconName={IconName.SAVE} />
        </SlideAction>
        <SlideAction
          onClick={() => onDiscardChangesClick(slide)}
          data-tooltip-text="Discard changes"
        >
          <Icon iconName={IconName.CLOSE} />
        </SlideAction>
      </FlexBox>
      <Box padding="xsmall">
        <Stack gap="small">
          <TextInput
            label="Title"
            value={editsToCurrentSlide?.name}
            onValueChange={value => onSlideEditedAttributes(value, 'name')}
            placeholder="Title"
          />
          <Box marginTop="small">
            <Header4>Description</Header4>
          </Box>
          <RichTextEditorTransition
            autoFocus
            customEditorConfig={editorConfig}
            content={editsToCurrentSlide?.description ?? ''}
            onInputChange={newValue =>
              onSlideEditedAttributes(newValue, 'description')
            }
            placeholder="Description"
            toolbarExtensions={[
              'Heading',
              'Bold',
              'Italic',
              'Link',
              'HorizontalRule',
              'BulletList',
              'OrderedList',
            ]}
          />
        </Stack>
      </Box>
    </SlideContainer>
  );
};
