import { EmptyState, EmptyStateNoResultIllustration } from '@ardoq/status-ui';
import { pluralize } from '@ardoq/common-helpers';

type EmptyMappingsProps = {
  entity: 'component' | 'reference';
};

export const EmptyMappings = ({ entity }: EmptyMappingsProps) => {
  return (
    <EmptyState
      description={`There are no ${pluralize(entity, 0)} defined yet. Click on Add new ${entity} to start.`}
      layout="horizontal"
      media={<EmptyStateNoResultIllustration />}
      title={`No ${pluralize(entity, 0)} added`}
    ></EmptyState>
  );
};
