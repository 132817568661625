import { exhaustMap, filter, map, Observable, withLatestFrom, tap } from 'rxjs';
import {
  APIComponentAttributes,
  APIEntityType,
  APIParentChildReferenceAttributes,
  APIReferenceAttributes,
  ArdoqId,
  CreatedItemsLoadedState,
  DirectedTriple,
  isAnyTraversalLoadedState,
  isComponent,
  isReference,
  LoadedState,
  LoadedStateType,
  TraversalCreatedInViewLoadedState,
  TraversalLoadedState,
} from '@ardoq/api-types';
import { scopeDataOperations } from '@ardoq/scope-data';
import { websocket$ } from 'sync/websocket$';
import { ArdoqEvent, isWebSocketCreate } from 'sync/types';
import { uniq, uniqBy } from 'lodash';
import { Action, allocateToBuffer, dispatchAction } from '@ardoq/rxbeach';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import currentUser$ from 'streams/currentUser/currentUser$';
import { ardoqEventOperations } from 'sync/ardoqEventOperations';
import { catchErrorLogWithMessageAndContinue } from 'streams/utils/streamOperators';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { isSameTriple } from 'traversals/pathAndGraphUtils';
import { getParentChildTriples } from './getParentChildTriples';
import {
  componentOperations,
  referenceAttributesOperations,
} from '@ardoq/core';
import { loadedState$ } from './loadedState$';
import { buildTraversalState, isError, LoadedGraphOrError } from './buildState';
import { scopeDataLoaded } from 'traversals/stagedLoadedDataAndState$';
import {
  createdItemsProcessed,
  itemsCreated,
  loadedStateUpdated,
} from './actions';
import { loadedStateOperations } from './loadedStateOperations';
import { logError } from '@ardoq/logging';

const resourceTypes = new Set([
  APIEntityType.COMPONENT,
  APIEntityType.REFERENCE,
]);

const isWebSocketCreateComponentOrReference = (
  event: ArdoqEvent<unknown>
): event is
  | ArdoqEvent<APIComponentAttributes>
  | ArdoqEvent<APIReferenceAttributes> => {
  const entityType = ardoqEventOperations.getEntityType(event);
  return (
    isWebSocketCreate(event) &&
    entityType !== 'unknown' &&
    resourceTypes.has(entityType)
  );
};

type AllocatedComponentsAndReferences = {
  components: APIComponentAttributes[];
  references: APIReferenceAttributes[];
};

const reduceBuffer = (
  buffer: (APIComponentAttributes | APIReferenceAttributes)[]
): AllocatedComponentsAndReferences =>
  buffer.reduce<AllocatedComponentsAndReferences>(
    (acc, data) => {
      if (isComponent(data)) {
        return {
          components: uniqBy([...acc.components, data], '_id'),
          references: acc.references,
        };
      }

      return {
        components: acc.components,
        references: uniqBy([...acc.references, data], '_id'),
      };
    },
    {
      components: [],
      references: [],
    }
  );

const toActions = async (
  { components, references }: AllocatedComponentsAndReferences,
  loadedGraph: LoadedGraphWithViewpointMode,
  loadedStates: LoadedState[]
): Promise<Action<unknown>[]> => {
  const loadedStateUpdateCandidatesFromCreatedReferences =
    references.reduce<LoadedStateMap>(
      reloadLoadedStateWithMatchingTriples(loadedStates),
      new Map()
    );
  const loadedStateUpdateCandidates = components.reduce<LoadedStateMap>(
    reloadLoadedStateWithMatchingParentChildTriples(loadedStates),
    loadedStateUpdateCandidatesFromCreatedReferences
  );

  await Promise.all(
    Array.from(loadedStateUpdateCandidates.values()).map(
      ({ request }) => request
    )
  );

  const result = Array.from(
    loadedStateUpdateCandidates.entries()
  ).reduce<ProcessReloadedStatesResult>(processReloadedLoadedStateCandidates, {
    actions: [],
    processedComponentIds: [],
    processedReferenceIds: [],
  });

  if (
    result.processedComponentIds.length > 0 ||
    result.processedReferenceIds.length > 0
  ) {
    result.actions.push(
      createdItemsProcessed({
        componentIds: result.processedComponentIds,
        referenceIds: result.processedReferenceIds,
      })
    );
  }

  const newlyCreatedComponents = components.filter(
    ({ _id }) => !result.processedComponentIds.includes(_id)
  );
  const newlyCreatedReferences = references.filter(
    ({ _id }) => !result.processedReferenceIds.includes(_id)
  );
  const loadedStateCreatedItems = getLoadedStateCreatedItems(
    newlyCreatedComponents,
    newlyCreatedReferences,
    loadedGraph
  );

  if (loadedStateCreatedItems) {
    result.actions.unshift(itemsCreated(loadedStateCreatedItems));
  }

  return result.actions;
};

const reloadLoadedStateWithMatchingTriples =
  (loadedStates: LoadedState[]) =>
  (
    loadedStateMap: LoadedStateMap,
    {
      _id: referenceId,
      source: sourceId,
      target: targetId,
    }: APIReferenceAttributes
  ) => {
    const triples = getTriplesFromInstance(referenceId, sourceId, targetId);
    if (!triples) {
      return loadedStateMap;
    }

    const loadedStateWithMatchingTriples = findLoadedStatesWithMatchingTriples(
      loadedStates,
      triples
    );
    if (!loadedStateWithMatchingTriples) {
      return loadedStateMap;
    }
    const componentAndReferenceIds = loadedStateMap.get(
      loadedStateWithMatchingTriples
    ) ?? { componentIds: [], referenceIds: [], request: null };

    loadedStateMap.set(loadedStateWithMatchingTriples, {
      componentIds: uniq([
        ...componentAndReferenceIds.componentIds,
        ...[sourceId, targetId],
      ]),
      referenceIds: uniq([
        ...componentAndReferenceIds.referenceIds,
        referenceId,
      ]),
      request:
        componentAndReferenceIds.request ??
        requestUpdateLoadedState(
          loadedStateMap,
          loadedStateWithMatchingTriples
        ),
      response: null,
    });
    return loadedStateMap;
  };

const reloadLoadedStateWithMatchingParentChildTriples =
  (loadedStates: LoadedState[]) =>
  (loadedStateMap: LoadedStateMap, { _id, parent }: APIComponentAttributes) => {
    if (parent === null) {
      return loadedStateMap;
    }
    const triples = getParentChildTriples(_id, parent);
    const loadedStateWithMatchingTriples = findLoadedStatesWithMatchingTriples(
      loadedStates,
      triples
    );
    if (!loadedStateWithMatchingTriples) {
      return loadedStateMap;
    }
    const componentAndReferenceIds = loadedStateMap.get(
      loadedStateWithMatchingTriples
    ) ?? { componentIds: [], referenceIds: [], request: null };

    loadedStateMap.set(loadedStateWithMatchingTriples, {
      componentIds: uniq([...componentAndReferenceIds.componentIds, _id]),
      referenceIds: componentAndReferenceIds.referenceIds,
      request:
        componentAndReferenceIds.request ??
        requestUpdateLoadedState(
          loadedStateMap,
          loadedStateWithMatchingTriples
        ),
      response: null,
    });
    return loadedStateMap;
  };

type ProcessReloadedStatesResult = {
  actions: Action<unknown>[];
  processedComponentIds: string[];
  processedReferenceIds: string[];
};

const processReloadedLoadedStateCandidates = (
  result: ProcessReloadedStatesResult,
  [loadedState, { componentIds, referenceIds, response }]: [
    TraversalLoadedState | TraversalCreatedInViewLoadedState,
    {
      componentIds: string[];
      referenceIds: string[];
      response: LoadedGraphOrError | null;
    },
  ]
) => {
  if (!response || isError(response)) {
    logError(Error('Reloading loaded state failed'));
    return result;
  }

  const { scopeData } = response;
  const processedComponentIds = componentIds.filter(id =>
    scopeData.components.some(({ _id }) => _id === id)
  );
  const processedReferenceIds = referenceIds.filter(id =>
    scopeData.references.some(({ _id }) => _id === id)
  );

  result.processedComponentIds.push(...processedComponentIds);
  result.processedReferenceIds.push(...processedReferenceIds);

  if (processedComponentIds.length > 0 || processedReferenceIds.length > 0) {
    const updatedLoadedState = loadedStateOperations.attachLoadedGraph(
      loadedState,
      response
    );

    result.actions.push(
      scopeDataLoaded({
        scopeData: response.scopeData,
      }),
      loadedStateUpdated({
        existingHash: loadedStateOperations.stateToHash(loadedState),
        loadedState: updatedLoadedState,
      })
    );
  }

  return result;
};

const getLoadedStateCreatedItems = (
  createdComponents: APIComponentAttributes[],
  createdReferences: APIReferenceAttributes[],
  loadedGraph: LoadedGraphWithViewpointMode
): CreatedItemsLoadedState | null => {
  if (createdComponents.length === 0 && createdReferences.length === 0) {
    return null;
  }

  const referencesWithVisibleSourceAndTargets = createdReferences.filter(
    ({ source, target }) =>
      loadedGraph.scopeComponentIds.includes(source) &&
      loadedGraph.scopeComponentIds.includes(target)
  );
  const referenceIds = referencesWithVisibleSourceAndTargets.map(
    ({ _id }) => _id
  );
  const componentIds = uniq([
    ...createdComponents.map(({ _id }) => _id),
    // Ensure that both source and target are included in the loaded state entry
    // to prevent any dangling references and maintain the validity of each
    // loaded state.
    ...referencesWithVisibleSourceAndTargets.flatMap(({ source, target }) => [
      source,
      target,
    ]),
  ]);

  return {
    type: LoadedStateType.CREATED_ITEMS,
    isHidden: false,
    componentIdsAndReferences: {
      componentIds,
      references: createdReferences,
      parentChildReferences: getParentChildReferences(
        createdComponents,
        loadedGraph
      ),
      startSetResult: componentIds,
    },
    // We don't want to fetch scopeData for these components and references
    // as we could end up DDoSing ourselves, and since the websockets don't
    // include scopeData for now we have to rely on the surrounding context.
    // In theory the scopeData for these components and references should be
    // contained in the related scopeData contexts, so we'll rely on this until
    // (batch) websocket events include scopeData.
    scopeData: scopeDataOperations.getEmpty(),
    data: {
      componentIds,
      referenceIds,
    },
  };
};

const getParentChildReferences = (
  components: APIComponentAttributes[],
  loadedGraph: LoadedGraphWithViewpointMode
): APIParentChildReferenceAttributes[] => {
  const scopeComponentIds = new Set(loadedGraph.scopeComponentIds);
  const triplesOfHierarchyDefinitions = loadedGraph.hierarchyDefinition
    .map(({ paths }) => paths)
    .filter(ExcludeFalsy)
    .flat(2);
  return components
    .filter(componentOperations.isComponentWithParent)
    .filter(({ parent, _id }) => {
      if (!scopeComponentIds.has(parent)) return false;
      const triples = getParentChildTriples(_id, parent);
      return triples.some(tripleCandidate =>
        triplesOfHierarchyDefinitions.some(triple =>
          isSameTriple(triple, tripleCandidate)
        )
      );
    })
    .map(
      referenceAttributesOperations.componentToParentChildReferenceInBEFormat
    );
};

// Let only loaded components and references pass. That means for references
// that the source and target are also loaded.
// In surveys users can e.g. create references. We will get these updates from
// the websocket, but with this check we will only display that reference if
// the source and target are also loaded.
const isLoadedComponentOrReference = (
  entity: APIComponentAttributes | APIReferenceAttributes
) => {
  if (isComponent(entity)) {
    return componentInterface.isComponent(entity._id);
  }
  if (isReference(entity)) {
    return (
      referenceInterface.isReference(entity._id) &&
      componentInterface.isComponent(entity.source) &&
      componentInterface.isComponent(entity.target)
    );
  }
  return false;
};

type LoadedStateMap = Map<
  TraversalLoadedState | TraversalCreatedInViewLoadedState,
  {
    referenceIds: string[];
    componentIds: string[];
    request: Promise<null>;
    response: LoadedGraphOrError | null;
  }
>;

const requestUpdateLoadedState = async (
  loadedStateMap: LoadedStateMap,
  loadedState: TraversalLoadedState | TraversalCreatedInViewLoadedState
) => {
  const response = await buildTraversalState(loadedState.data);
  const loadedStateContext = loadedStateMap.get(loadedState);
  if (!loadedStateContext) {
    throw Error('Loaded state not found in map');
  }
  loadedStateMap.set(loadedState, {
    ...loadedStateContext,
    response,
  });
  return null;
};

const findLoadedStatesWithMatchingTriples = (
  states: LoadedState[],
  triples: DirectedTriple[]
): TraversalLoadedState | TraversalCreatedInViewLoadedState | undefined =>
  states.find(
    loadedState =>
      isAnyTraversalLoadedState(loadedState) &&
      loadedState.data.paths.some(path =>
        path.some(tripleCandidate =>
          triples.some(triple => isSameTriple(tripleCandidate, triple))
        )
      )
  ) as TraversalLoadedState | TraversalCreatedInViewLoadedState | undefined;

const getTriplesFromInstance = (
  referenceId: ArdoqId,
  sourceId: ArdoqId,
  targetId: ArdoqId
): DirectedTriple[] | null => {
  const referenceType =
    referenceInterface.getReferenceTypeNameByReferenceId(referenceId);
  if (!referenceType) {
    return null;
  }
  const sourceType = componentInterface.getTypeName(sourceId);
  const targetType = componentInterface.getTypeName(targetId);
  if (!(sourceType && targetType)) {
    return null;
  }
  return [
    {
      sourceType,
      targetType,
      referenceType,
      direction: 'outgoing',
    },
    {
      sourceType,
      targetType,
      referenceType,
      direction: 'incoming',
    },
  ];
};

const viewpointWebsocketFromCurrentUser$ = websocket$.pipe(
  withLatestFrom(loadedGraph$, currentUser$),
  filter(
    ([event, loadedGraph, user]) =>
      event.user?.id === user._id && loadedGraph.isViewpointMode
  ),
  map(([websocket]) => websocket),
  catchErrorLogWithMessageAndContinue(
    'Error in viewpointWebsocketFromCurrentUser$'
  )
);

export const createComponentsAndReferencesStateRoutine: Observable<
  Action<unknown>[]
> = viewpointWebsocketFromCurrentUser$.pipe(
  filter(isWebSocketCreateComponentOrReference),
  map(({ data }) => data),
  filter(isLoadedComponentOrReference),
  allocateToBuffer(),
  withLatestFrom(loadedGraph$, loadedState$),
  exhaustMap(([buffer, loadedGraph, loadedStates]) =>
    toActions(reduceBuffer(buffer), loadedGraph, loadedStates)
  ),
  tap(actions => {
    actions.forEach(action => dispatchAction(action));
  }),
  catchErrorLogWithMessageAndContinue(
    'Error in createComponentsAndReferencesStateRoutine'
  )
);
