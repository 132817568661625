import { Icon, IconName } from '@ardoq/icons';
import {
  action$,
  connect,
  extractPayload,
  ofType,
  withNamespace,
} from '@ardoq/rxbeach';
import { Box, FlexBox } from '@ardoq/layout';
import InventoryRoot from 'componentHierarchies/InventoryRoot';
import { setFilterTerm } from '../actions';
import { FormSize, TextInput } from '@ardoq/forms';
import { componentHierarchyConfiguration } from 'componentHierarchies/componentHierarchyConfiguration';
import { identity } from 'lodash';
import { combineLatest, from, Observable, startWith } from 'rxjs';
import {
  sidebarInventoryCommands,
  SidebarInventoryCommands,
} from './sidebarInventoryCommands';
import { IconButton, SecondaryButton } from '@ardoq/button';
import { VisuallyHidden } from '@react-aria/visually-hidden';

const filterTerm$ = action$.pipe(
  componentHierarchyConfiguration.actionNamespace
    ? withNamespace(componentHierarchyConfiguration.actionNamespace)
    : identity,
  ofType(setFilterTerm),
  extractPayload(),
  startWith('')
);

const sidebarInventory$: Observable<SidebarInventoryProps> = combineLatest({
  filterTerm: filterTerm$,
  sidebarInventoryCommands: from([sidebarInventoryCommands]),
});

type SidebarInventoryProps = {
  filterTerm: string;
  sidebarInventoryCommands: SidebarInventoryCommands;
};

const SidebarInventory = ({
  filterTerm,
  sidebarInventoryCommands,
}: SidebarInventoryProps) => {
  return (
    <>
      <FlexBox padding="small" justify="space-between">
        <SecondaryButton
          onClick={() => sidebarInventoryCommands.openWorkspace()}
        >
          <Icon iconName={IconName.WORKSPACE} />
          Open workspace
        </SecondaryButton>
        <IconButton
          iconName={IconName.CLOSE}
          data-tooltip-text="Close all data and exit"
          onClick={() => sidebarInventoryCommands.closeAll()}
        >
          <VisuallyHidden>Close all open data</VisuallyHidden>
        </IconButton>
      </FlexBox>
      <Box paddingX="small" paddingY="xxsmall">
        <TextInput
          formSize={FormSize.FULL}
          value={filterTerm}
          placeholder="Search in opened data"
          leftIconName={IconName.SEARCH}
          onValueChange={term => sidebarInventoryCommands.setFilterTerm(term)}
        />
      </Box>
      <InventoryRoot />
    </>
  );
};

export default connect(SidebarInventory, sidebarInventory$);
