import { isEqual } from 'lodash';
import { distinctUntilChanged, filter, map, tap, withLatestFrom } from 'rxjs';
import { executeViewpoint } from 'viewpointBuilder/openViewpointBuilder/getPrimaryButtonConfig';
import { getViewpointBuilderRoutines } from 'viewpointBuilder/openViewpointBuilder/subscribeToViewpointBuilderStreamAndRoutines';
import { ViewpointBuilderViewState } from 'viewpointBuilder/types';
import { exploreGraph$ } from './exploreGraph$';
import { isReadyToUpdate$ } from './isReadyToUpdate$';
import { loadedGraph$ } from 'traversals/loadedGraph$';
import { contextToolBar$ } from 'contextToolBar/contextToolBar$';
import graphModel$ from 'modelInterface/graphModel$';
import { selectViewpointTypeNodeWithContextToolbarSelection } from './selectViewpointTypeNodeWithContextToolbarSelection';

const getStateAsSavableAttributes = (
  state: ViewpointBuilderViewState
): ViewpointBuilderViewState['editTraversalsState']['stateAsSavableAttributes'] =>
  state.editTraversalsState.stateAsSavableAttributes;

const isStateAsSavableAttributesEqual = (
  previousState: ViewpointBuilderViewState,
  currentState: ViewpointBuilderViewState
) =>
  isEqual(
    getStateAsSavableAttributes(previousState),
    getStateAsSavableAttributes(currentState)
  );

const loadTraversalRoutine = exploreGraph$.pipe(
  distinctUntilChanged(isStateAsSavableAttributesEqual),
  withLatestFrom(isReadyToUpdate$, loadedGraph$),
  tap(
    ([
      viewpointBuilderState,
      isReadyToUpdate,
      {
        hierarchyDefinition: [mainHierarchyDefinition],
      },
    ]) => {
      if (
        isReadyToUpdate &&
        mainHierarchyDefinition &&
        !isEqual(
          viewpointBuilderState.editTraversalsState.stateAsSavableAttributes
            .paths,
          mainHierarchyDefinition.paths
        )
      ) {
        executeViewpoint(viewpointBuilderState);
      }
    }
  )
);

const selectViewpointTypeNodeRoutine = contextToolBar$.pipe(
  filter(({ isMultipleTypesSelected }) => !isMultipleTypesSelected),
  map(({ currentContext }) => currentContext),
  distinctUntilChanged(isEqual),
  withLatestFrom(exploreGraph$, loadedGraph$, graphModel$, isReadyToUpdate$),
  filter(([, , , , isReadyToUpdate]) => isReadyToUpdate),
  tap(
    ([
      currentContext,
      { editTraversalsState },
      {
        hierarchyDefinition: [mainHierarchyDefinition],
      },
      graphModel,
    ]) => {
      if (mainHierarchyDefinition && currentContext) {
        selectViewpointTypeNodeWithContextToolbarSelection({
          editTraversalsState,
          mainHierarchyDefinition,
          currentContext,
          graphModel,
        });
      }
    }
  )
);

export const exploreGraphRoutines = getViewpointBuilderRoutines(
  'editSubgraph',
  [loadTraversalRoutine, selectViewpointTypeNodeRoutine]
);
