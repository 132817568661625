import { actionCreator } from '@ardoq/rxbeach';
import { RequiredAuth } from 'integrations/lucidchart/types';
import { LucidchartDocumentImportState } from './types';

const NAMESPACE = '[integrations lucid-import] Document';

export const selectDocument = actionCreator<RequiredAuth>(
  `${NAMESPACE}_SELECT_DOCUMENT`
);

export const setDocumentState = actionCreator<LucidchartDocumentImportState>(
  `${NAMESPACE}_SET_DOCUMENT_STATE`
);

export const resetDocument = actionCreator(`${NAMESPACE}_RESET_DOCUMENT`);
