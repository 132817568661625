import {
  ColumnsProps,
  getLastModifiedByColumn,
  getLastModifiedDateColumn,
  getNameColumnIncludingSelectAllCheckbox,
} from '../components/AssetsBrowser/columns';
import {
  ColumnsOptions,
  Folder,
  SortColumnOptions,
} from '../components/EntityBrowser/types';
import { AssetRow, ReportRow } from 'components/AssetsBrowser/types';
import SortHeader from '../components/EntityBrowser/SortHeader';
import { DataSourceType, APIReportError } from '@ardoq/api-types';
import { HeaderCell, RowType } from '@ardoq/table';
import AssetsBrowserActionsMenu from '../appContainer/DashboardContainer/AssetsBrowserActionsMenu';
import { DropdownOption } from '@ardoq/dropdown-menu';
import { Icon, IconName } from '@ardoq/icons';
import { WithPopover } from '@ardoq/popovers';
import { colors } from '@ardoq/design-tokens';
import { formatDateOnly, formatInTimeZone, parseDate } from '@ardoq/date-time';
import { getCurrentLocale } from '@ardoq/locale';
import { isReportRow } from './utils';
import { FlexBox } from '@ardoq/layout';
import { EmptyFolder } from '../components/EntityBrowser/types';
import { isEmpty } from 'lodash';

// recursively gets the errors from a row or its children
const getRowErrors = (
  row: AssetRow | Folder<AssetRow> | EmptyFolder
): APIReportError[] => {
  if (row.rowType === RowType.FOLDER) {
    return row.children.flatMap(getRowErrors);
  }
  return isReportRow(row) && row.error ? [row.error] : [];
};

const getErrorIcon = (errors: APIReportError[], isFolderRow?: boolean) => {
  const locale = getCurrentLocale();
  return (
    <FlexBox align={'center'}>
      <WithPopover
        content={
          isFolderRow
            ? `The folder contains ${errors.length > 1 ? `${errors.length} reports` : `report`} with error. Please open the folder to review the issue.`
            : `The report contains an error. Please open the report to review the issue.\n\nDate: ${formatDateOnly(
                errors[0].firstSeen,
                locale
              )}\nTime: ${formatInTimeZone(
                parseDate(errors[0].firstSeen),
                'UTC',
                'HH:MM:ss'
              )} UTC\nError key: ${errors[0].errorKey}${
                errors[0].display ? `\nDetails: ${errors[0].display}` : ''
              } `
        }
      >
        <Icon iconName={IconName.ERROR} color={colors.red50} />
      </WithPopover>
    </FlexBox>
  );
};

export const getDataSourceColumn = (props: SortColumnOptions) => ({
  title: 'Data source',
  dataIndex: 'datasource',
  headerStyle: {
    width: 300,
  },
  headerRender: ({ title, dataIndex = 'datasource' }: HeaderCell) => (
    <SortHeader
      dataIndex={dataIndex}
      title={title}
      dataClickId="datasource"
      sortByField={props.sortByField}
      sortOrder={props.sortOrder}
      onSortChanged={props.setSortByField}
    />
  ),
  valueRender: (value: string, row: AssetRow | Folder<AssetRow>) => {
    if (!isReportRow(row)) return null;
    return value === DataSourceType.ADVANCED_SEARCH
      ? 'Advanced search'
      : value === DataSourceType.GREMLIN_SEARCH
        ? 'Gremlin graph search'
        : 'Survey';
  },
});

export const getReportErrorColumn = (props: ColumnsProps) => ({
  dataIndex: 'error.firstSeen',
  headerStyle: {
    width: 30,
  },
  headerRender: ({ dataIndex = 'error.firstSeen' }: HeaderCell) => (
    <SortHeader
      onSortChanged={props.setSortByField}
      sortOrder={props.sortOrder}
      dataClickId="error.firstSeen"
      title={''}
      sortByField={props.sortByField}
      dataIndex={dataIndex}
      iconProps={{ iconName: IconName.ERROR }}
      tooltipText={'Report status'}
    />
  ),
  valueRender: (_name: string, row: AssetRow | Folder<AssetRow>) => {
    if (
      row.rowType === RowType.FOLDER &&
      props.expandedFoldersIds?.includes(row._id)
    ) {
      return null;
    }

    const errors = getRowErrors(row);
    return !isEmpty(errors)
      ? getErrorIcon(errors, row.rowType === RowType.FOLDER)
      : null;
  },
});

const getMenuOptionsColumn = ({
  getMenuOptions,
}: {
  getMenuOptions: (report: ReportRow) => DropdownOption[];
}) => ({
  headerStyle: {
    width: 45,
  },
  valueRender: (_name: string, row: AssetRow | Folder<AssetRow>) => {
    const menuOptions = getMenuOptions(row as ReportRow);
    return menuOptions.length ? (
      <AssetsBrowserActionsMenu
        rowType={RowType.REPORT}
        menuOptions={menuOptions}
      />
    ) : null;
  },
});

export const getReportOverviewColumns =
  (
    columnsProps: ColumnsProps & {
      getMenuOptions: (report: ReportRow) => DropdownOption[];
    }
  ) =>
  (columnsOptions: ColumnsOptions<AssetRow>) => {
    return [
      getNameColumnIncludingSelectAllCheckbox({
        ...columnsProps,
        ...columnsOptions,
      }),
      getDataSourceColumn(columnsProps),
      getLastModifiedDateColumn(columnsProps),
      getLastModifiedByColumn(columnsProps),
      getMenuOptionsColumn(columnsProps),
      getReportErrorColumn(columnsProps),
    ];
  };
