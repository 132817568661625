import { DASHBOARD_MAX_WIDTH } from '@ardoq/dashboard';
import { AugmentationsProps } from '../types';
import { Island } from '@ardoq/page-layout';
import { Drawer, showDrawer } from '@ardoq/snowflakes';
import { DescriptionTitle } from '../ExpandableDescriptionAndBusinessOutcomes';
import { Badge, StatusType } from '@ardoq/status-ui';
import { Text } from '@ardoq/typography';
import { SparklesIcon } from '@ardoq/icons';
import { colors } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import ValuePropositionModal from './ValuePropositionModal';
import ValueStreamModal from './ValueStreamModal';
import BusinessCapabilityMapModal from './BusinessCapabilityMapModal';
import BCMLinkingModal from './BCMLinkingModal';
import { HorizontalStepper, StepState } from '@ardoq/steppers';
import { AIStep } from './AIStep';

const drawerProps = {
  shouldCloseOnEscapeKey: true,
  shouldCloseOnBackdropClick: true,
};

const drawerRenderProps = {
  drawerSize: 'small',
  icon: <SparklesIcon color={colors.brand50} />,
  orientation: 'vertical',
  paddingX: 'none',
} as const;

const Augmentations = ({ enabledState }: AugmentationsProps) => {
  const openValuePropositionModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'generateValuePropositionDrawer',
      renderDrawer: () => (
        <Drawer
          {...drawerRenderProps}
          title={enabledState.valueProposition.displayName}
        >
          <ValuePropositionModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  const openValueStreamModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'generateValueStreamDrawer',
      renderDrawer: () => (
        <Drawer
          {...drawerRenderProps}
          title={enabledState.valueStream.displayName}
        >
          <ValueStreamModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  const openBusinessCapabilityMapModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'generateBusinessCapabilityMapDrawer',
      renderDrawer: () => (
        <Drawer
          {...drawerRenderProps}
          title={enabledState.businessCapabilityMap.displayName}
        >
          <BusinessCapabilityMapModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  const openBCMLinkingModal = async () => {
    const { closeDrawer } = showDrawer({
      ...drawerProps,
      stackPageName: 'bcmLinkingDrawer',
      renderDrawer: () => (
        <Drawer {...drawerRenderProps} title={enabledState.linking.displayName}>
          <BCMLinkingModal closeModal={closeDrawer} />
        </Drawer>
      ),
    });
  };

  return (
    <Island
      maxWidth={`${DASHBOARD_MAX_WIDTH}px`}
      isScrollable={false}
      bodySpacing="xlarge"
    >
      <FlexBox gap="small">
        <DescriptionTitle>AI Augmentations</DescriptionTitle>
        <Badge statusType={StatusType.NEW} label="New" />
      </FlexBox>
      <Text color={'textSubtle'}>
        AI support for this use case, see the{' '}
        <a target="_blank" href="help.ardoq.com">
          help center
        </a>{' '}
        for more instructions
      </Text>
      <FlexBox marginTop="xsmall" />
      <HorizontalStepper>
        <AIStep
          isDisabled={!enabledState.valueProposition.enabled}
          subheading={enabledState.valueProposition.disabledReason}
          heading={enabledState.valueProposition.displayName}
          onPress={openValuePropositionModal}
          state={
            enabledState.valueProposition.completed
              ? StepState.DONE
              : StepState.ACTIVE
          }
        />
        <AIStep
          isDisabled={!enabledState.valueStream.enabled}
          subheading={enabledState.valueStream.disabledReason}
          heading={enabledState.valueStream.displayName}
          onPress={openValueStreamModal}
          state={
            enabledState.valueStream.completed
              ? StepState.DONE
              : StepState.ACTIVE
          }
        />
        <AIStep
          isDisabled={!enabledState.businessCapabilityMap.enabled}
          subheading={enabledState.businessCapabilityMap.disabledReason}
          heading={enabledState.businessCapabilityMap.displayName}
          onPress={openBusinessCapabilityMapModal}
          state={
            enabledState.businessCapabilityMap.completed
              ? StepState.DONE
              : StepState.ACTIVE
          }
        />
        <AIStep
          isDisabled={!enabledState.linking.enabled}
          subheading={enabledState.linking.disabledReason}
          heading={enabledState.linking.displayName}
          onPress={openBCMLinkingModal}
          state={
            enabledState.linking.completed ? StepState.DONE : StepState.ACTIVE
          }
        />
      </HorizontalStepper>
    </Island>
  );
};

export default Augmentations;
