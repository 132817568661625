import { connectInstance } from '@ardoq/rxbeach';
import { ExtendedWorkspaceSelectorComponent } from './ExtendedWorkspaceSelectorComponent';
import { getWorkspaceSelectorStream } from './stream';
import { ExtendedWorkspaceMultiSelectorComponent } from './ExtendedWorkspaceMultiSelectorComponent';

export const ExtendedWorkspaceSelector = connectInstance(
  ExtendedWorkspaceSelectorComponent<string>,
  getWorkspaceSelectorStream
);

export const ExtendedWorkspaceMultiSelector = connectInstance(
  ExtendedWorkspaceMultiSelectorComponent<string>,
  getWorkspaceSelectorStream
);
