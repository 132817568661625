import { AIFeaturesSection } from './AIFeaturesSection';
import { featureSettingsViewModel$ } from './featureSettingsViewModel$';
import { FeatureSettingsViewModelState } from './types';
import { connect } from '@ardoq/rxbeach';
import { EaseOfUseFeatureSection } from './EaseOfUseFeatureSection';
import { DataGovernanceFeatureSection } from './DataGovernanceFeatureSection';
import { InfoNotification } from '@ardoq/status-ui';

const FeatureSettings = ({
  isUpdating,
  isAiSectionDisabled,
  aiSectionFeatures,
  commands,
  easeOfUseFeature,
  dataGovernanceFeatures,
}: FeatureSettingsViewModelState) => {
  return (
    <>
      <InfoNotification>
        Please note that beta features are still in development. They may have
        bugs, be modified or become part of a paid add-on in future.
      </InfoNotification>
      <EaseOfUseFeatureSection
        isUpdating={isUpdating}
        easeOfUseFeature={easeOfUseFeature}
        commands={commands}
      />
      <DataGovernanceFeatureSection
        isUpdating={isUpdating}
        dataGovernanceFeatures={dataGovernanceFeatures}
        commands={commands}
      />
      <AIFeaturesSection
        isUpdating={isUpdating}
        aiFeatures={aiSectionFeatures}
        isDisabled={isAiSectionDisabled}
        commands={commands}
      />
    </>
  );
};

export default connect(FeatureSettings, featureSettingsViewModel$);
