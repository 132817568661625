import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { LucidchartDocumentImportState } from './types';
import { setDocumentState, resetDocument } from './actions';
import { ASYNC_STATUS } from 'integrations/common/types/api';

const defaultState: LucidchartDocumentImportState = {
  status: ASYNC_STATUS.INIT,
};

const handleDocumentState = (
  _: LucidchartDocumentImportState,
  newState: LucidchartDocumentImportState
): LucidchartDocumentImportState => newState;

const handleResetDocument = (
  _: LucidchartDocumentImportState
): LucidchartDocumentImportState => defaultState;

export const document$ = persistentReducedStream(
  'lucidchartDocument',
  defaultState,
  [
    reducer(setDocumentState, handleDocumentState),
    reducer(resetDocument, handleResetDocument),
  ]
);
