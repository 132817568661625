import NodeExpander from './NodeExpander';
import {
  NavigatorItemCloseScenario,
  NavigatorItemContextMenu,
  NonComponentNavigatorItem,
  ScenarioIcon,
} from './Atoms';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ScenarioItemProps } from './PropTypes';
import { memo } from 'react';

const ScenarioItem = memo(
  ({
    name,
    nodeId,
    containerClassNames,
    containerStyle,
    hasVisibleContent,
    isExpanded,
    scenarioIconName,
    isSelectedContext,
  }: ScenarioItemProps) => {
    return (
      <NonComponentNavigatorItem
        className={containerClassNames}
        data-id={nodeId}
        style={containerStyle}
        aria-current={isSelectedContext ? 'true' : undefined}
      >
        <NodeExpander
          name={name}
          hasVisibleContent={hasVisibleContent}
          isExpanded={isExpanded}
        />
        <ScenarioIcon iconName={scenarioIconName} />
        <span className="scenario-name"> {name}</span>
        <NavigatorItemContextMenu>
          <Icon iconName={IconName.MORE_VERT} iconSize={IconSize.SMALL} />
        </NavigatorItemContextMenu>
        <NavigatorItemCloseScenario>
          <i className="material-icons-round">close</i>
        </NavigatorItemCloseScenario>
      </NonComponentNavigatorItem>
    );
  }
);

export default ScenarioItem;
