import { s32 } from '@ardoq/design-tokens';
import { FlexBox } from '@ardoq/layout';
import { Stack } from '@ardoq/layout';
import { header2 } from '@ardoq/typography';
import { ConfigSelector } from 'integrations/unified/import/containers/configSelector/ConfigSelector';
import { useResizeDetector } from 'react-resize-detector';
import styled from 'styled-components';
import { ConnectionSelector } from './containers/connectionSelector/ConnectionSelector';
import FieldSelector from './containers/fieldSelector/FieldSelector';
import TableSelector from './containers/tableSelector/TableSelector';
import { FetchProblem } from './containers/fetchProblem/FetchProblem';
import { Island } from '@ardoq/page-layout';

export const SelectData = () => {
  const { height, ref } = useResizeDetector({
    handleHeight: true,
  });

  return (
    <Container>
      <ConfigSelector />
      <IslandContainer>
        <Island footerJustify="end" maxWidth="100%">
          <Stack gap="xlarge">
            <FetchProblem />
            <ConnectionSelector />
            <Stack gap="medium">
              <StepLabel>2. Select tables and fields</StepLabel>
            </Stack>
            <FlexBox gap="medium">
              <TableSelector
                selectionRef={ref as React.MutableRefObject<HTMLDivElement>}
              />
              <FieldSelector maxHeight={height} />
            </FlexBox>
          </Stack>
        </Island>
      </IslandContainer>
    </Container>
  );
};

const StepLabel = styled.div`
  ${header2};
`;

const IslandContainer = styled.div`
  padding: ${s32};
  width: 100%;
`;

const Container = styled.div`
  overflow: auto;
  padding-bottom: ${s32};
`;
