import { reducedStream, reducer, streamReducer } from '@ardoq/rxbeach';
import { metaModelAsScopeData$ } from './loadMetaModelAsScopeData$';
import { metamodelViewOperations } from './metamodelViewOperations';
import { fields$ } from 'streams/fields/fields$';
import { loadMetaModel$ } from 'architectureModel/loadMetaModel';
import workspaces$ from 'streams/workspaces/workspaces$';
import { toggleExpandableSection, toggleIsSelectedGraphNode } from './actions';

export const metamodelView$ = reducedStream(
  'metamodelView$',
  metamodelViewOperations.getInitialState(),
  [
    streamReducer(
      metaModelAsScopeData$,
      metamodelViewOperations.createMetamodel
    ),
    streamReducer(fields$, metamodelViewOperations.createFieldMap),
    streamReducer(workspaces$, metamodelViewOperations.createWorkspaceMap),
    streamReducer(
      loadMetaModel$,
      metamodelViewOperations.typeNameToWorkspaceAndFieldMaps
    ),
    reducer(
      toggleIsSelectedGraphNode,
      metamodelViewOperations.toggleIsSelectedGraphNode
    ),
    reducer(
      toggleExpandableSection,
      metamodelViewOperations.toggleExpandableSection
    ),
  ]
);
