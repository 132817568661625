import { colors } from '@ardoq/design-tokens';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { HorizontalResizable } from '@ardoq/resizable';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
  border-right: 1px solid ${colors.borderSubtle00};
  border-left-width: 0;
  display: flex;
  flex-direction: row;
`;

const CollapseButton = styled.button<{ $isCollapsed: boolean }>`
  appearance: none;
  border: none;
  width: 24px;
  background-color: ${props =>
    props.$isCollapsed ? colors.surfaceDefault : colors.grey95};
  border-left: ${props => (props.$isCollapsed ? 0 : 1)}px solid
    ${colors.borderSubtle00};
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${colors.surfaceHover};
  }
`;

const FlexContainer = styled.div<{
  $isCollapsed?: boolean;
}>`
  width: calc(100% - 24px);
  height: 100%;
  overflow-x: hidden;
  display: ${props => (props.$isCollapsed ? 'none' : 'block')};
`;

type SectionProps = {
  children: ReactNode;
} & HorizontalResizable;

export const CollapsibleSidePanel = ({
  children,
  setCollapsed,
  isCollapsed,
}: SectionProps) => (
  <Content>
    <FlexContainer $isCollapsed={isCollapsed}>{children}</FlexContainer>
    <CollapseButton
      onClick={() => setCollapsed && setCollapsed(!isCollapsed)}
      $isCollapsed={Boolean(isCollapsed)}
    >
      <Icon
        iconName={isCollapsed ? IconName.CHEVRON_RIGHT : IconName.CHEVRON_LEFT}
        iconSize={IconSize.MEDIUM}
      />
    </CollapseButton>
  </Content>
);
