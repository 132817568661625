import { Integration } from 'integrations/types';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ReactNode } from 'react';
import { BetaBadge, NewBadge } from '@ardoq/status-ui';
import { FlexBox, Stack } from '@ardoq/layout';
import { Paragraph } from '@ardoq/typography';
import { colors, s8, shadowM } from '@ardoq/design-tokens';
import styled, { css } from 'styled-components';

// The boxes which represent each component

const ItemBoxContainer = styled(FlexBox)<{
  $isDisabled?: boolean;
  $size: IntegrationTileSize;
}>`
  flex-direction: column;
  width: 212px;
  height: 212px;
  border: 1px solid ${colors.borderSubtle00};
  border-radius: ${s8};
  padding-bottom: ${props =>
    props.$size === IntegrationTileSize.small ? '20px' : '40px'};

  ${props =>
    props.$size && props.$size === IntegrationTileSize.medium
      ? css`
          width: 180px;
          height: 180px;
        `
      : props.$size === IntegrationTileSize.small &&
        css`
          width: 120px;
          height: 120px;
        `}

  &:hover {
    cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};
    box-shadow: ${shadowM};
    transition: box-shadow 200ms ease-in-out;
  }
`;

const ItemBoxHeader = styled(FlexBox)<{ $size: IntegrationTileSize }>`
  z-index: 1;
  min-height: ${props =>
    props.$size === IntegrationTileSize.small ? '20px' : '40px'};
`;

const ItemBoxBody = styled(Stack)`
  height: 100%;
`;

type LogoContainerProps = {
  $isDisabled?: boolean;
  $size: IntegrationTileSize;
};

const LogoContainer = styled.div<LogoContainerProps>`
  height: ${props =>
    props.$size === IntegrationTileSize.small ? '50px' : '60px'};
  width: auto;
  max-width: 120px;

  img {
    max-width: ${props =>
      props.$size === IntegrationTileSize.small ? '75px' : '120px'};
    max-height: 64px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
    filter: ${props =>
      props.$isDisabled ? 'grayscale(100%)' : 'grayscale(0)'};
  }
`;

const ItemBox = ({
  name,
  logo,
  isBeta,
  isNew,
  disableOptions,
  helpUrl,
  onClick,
  size,
}: {
  name: string;
  logo: ReactNode;
  isBeta?: boolean;
  isNew?: boolean;
  disableOptions?: Integration['disableOptions'];
  helpUrl?: string;
  onClick?: VoidFunction;
  size: IntegrationTileSize;
}) => {
  const isDisabled = Boolean(disableOptions?.isDisabled);
  return (
    <ItemBoxContainer
      align="center"
      backgroundColor="bgDefault"
      DEPRECATED_onClick={isDisabled ? undefined : onClick}
      $isDisabled={isDisabled}
      $size={size}
      data-tooltip-text={disableOptions?.reason?.hint || undefined}
    >
      <ItemBoxHeader
        $size={size}
        width="full"
        justify="flex-end"
        gap="small"
        padding="small"
      >
        {isBeta && <BetaBadge />}
        {!isBeta && isNew && <NewBadge />}
        {disableOptions?.reason && (
          <Icon
            iconName={disableOptions.reason.icon}
            color={colors.iconOnInverted}
          />
        )}
      </ItemBoxHeader>
      <ItemBoxBody justify="space-evenly" align="center" gap="small">
        <LogoContainer $isDisabled={isDisabled} $size={size}>
          {logo}
        </LogoContainer>
        <FlexBox align="center" gap="small" paddingX="small">
          <Paragraph
            variant={
              size === IntegrationTileSize.large
                ? 'text1'
                : size === IntegrationTileSize.medium
                  ? 'text2'
                  : 'caption'
            }
            color={isDisabled ? 'textSubtle' : undefined}
            align="center"
          >
            {name}
          </Paragraph>
          {helpUrl && !isDisabled && (
            <Icon
              iconName={IconName.OPEN_IN_NEW}
              iconSize={IconSize.SMALL}
              color={colors.brand50}
            />
          )}
        </FlexBox>
      </ItemBoxBody>
    </ItemBoxContainer>
  );
};

export enum IntegrationTileSize {
  'small',
  'medium',
  'large',
}

export const IntegrationBox = ({
  integration,
  size = IntegrationTileSize.large,
  onClick,
}: {
  integration: Integration;
  size?: IntegrationTileSize;
  onClick?: (integration: Integration) => void;
}) => {
  const logo = integration.logoIcon ? (
    <Icon
      iconName={integration.logoIcon}
      style={{
        height: '100%',
        width: '100%',
        color: integration.logoIconColor,
      }}
    />
  ) : (
    <img src={integration.logoUrl} alt={`${integration.name} logo`} />
  );

  return (
    <ItemBox
      name={integration.name}
      logo={logo}
      isBeta={integration.isBeta}
      disableOptions={integration.disableOptions}
      isNew={integration.isNew}
      helpUrl={integration.helpUrl}
      onClick={onClick && (() => onClick(integration))}
      size={size}
    />
  );
};
