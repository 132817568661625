import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { AnalyzeState } from './types';
import { setAnalyzeState, resetAnalyze } from './actions';

const defaultState: AnalyzeState = {
  status: ASYNC_STATUS.INIT,
};

const handleAnalysisState = (
  _: AnalyzeState,
  newState: AnalyzeState
): AnalyzeState => newState;

const handleResetAnalyze = (_: AnalyzeState): AnalyzeState => defaultState;

export const analyze$ = persistentReducedStream(
  'lucidchartImportAnalyze$',
  defaultState,
  [
    reducer(setAnalyzeState, handleAnalysisState),
    reducer(resetAnalyze, handleResetAnalyze),
  ]
);
