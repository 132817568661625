import {
  carry,
  collectRoutines,
  dispatchAction,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { importMappings, setMappingsStatus } from './actions';
import { filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { mappings$ } from './mappings$';
import { batchApi, handleError } from '@ardoq/api';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { showToast, ToastType } from '@ardoq/status-ui';
import { ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import { APIComponentAttributes } from '@ardoq/api-types';
import { showReviewModal } from '../../Review/Review';
import { showAffectedWorkspaces } from '../../Review/Workspaces';
import workspaces$ from 'streams/workspaces/workspaces$';

export const handleImportMappings = routine(
  ofType(importMappings),
  withLatestFrom(mappings$),
  switchMap(([_, mappings]) => {
    const components = Object.values(mappings.components);
    const references = Object.values(mappings.references);

    const workspaceIds = new Set(
      components.map(c => c.workspaceId).filter(ExcludeFalsy)
    );

    const counts = {
      created: {
        workspaces: 0,
        components: components.length,
        references: references.length,
      },
      updated: {
        workspaces: workspaceIds.size,
      },
    };

    return showReviewModal(counts).then(result => {
      if (!result) {
        return null;
      }
      return mappings;
    });
  }),
  filter(Boolean),
  tap(() => {
    dispatchAction(setMappingsStatus(ASYNC_STATUS.LOADING));
  }),
  map(mappings => {
    return {
      mappings,
      components: Object.values(mappings.components).map(c => ({
        name: c.name,
        typeId: c.componentTypeId,
        rootWorkspace: c.workspaceId,
        description: '',
      })),
    };
  }),
  carry(
    switchMap(({ components }) =>
      batchApi.execute({
        options: { includeEntities: true },
        components: { create: components },
      })
    )
  ),
  handleError(() => {
    dispatchAction(setMappingsStatus(ASYNC_STATUS.FAILURE));
  }),
  map(([{ mappings }, result]) => {
    if (isArdoqError(result)) {
      return null;
    }
    const createdComponents: Record<string, APIComponentAttributes> =
      Object.fromEntries(
        Object.values(mappings.components)
          .map(mappedComponent => {
            const createdComponent = Object.values(
              result.components.created ?? {}
            ).find(x => x.name === mappedComponent.name);
            return createdComponent
              ? [mappedComponent.id, createdComponent]
              : null;
          })
          .filter(ExcludeFalsy)
      );

    const references = Object.values(mappings.references)
      .map(r => {
        const sourceComponent = createdComponents[r.sourceComponentId ?? ''];
        const targetComponent = createdComponents[r.targetComponentId ?? ''];

        if (!sourceComponent || !targetComponent) {
          return null;
        }
        return {
          source: sourceComponent._id,
          target: targetComponent._id,
          roootWorkspace: sourceComponent.rootWorkspace,
          targetWorkspace: targetComponent.rootWorkspace,
          type: Number(r.referenceTypeId),
        };
      })
      .filter(ExcludeFalsy);

    return {
      references,
      components: Object.values(result.components.created ?? {}),
    };
  }),
  tap(request => {
    if (!request) {
      dispatchAction(setMappingsStatus(ASYNC_STATUS.FAILURE));
    }
  }),
  filter(Boolean),
  switchMap(({ components, references }) => {
    if (!references.length) {
      return of({
        components,
        references: [],
      });
    }
    return batchApi
      .execute({
        options: { includeEntities: true },
        references: { create: references },
      })
      .then(result => {
        if (isArdoqError(result)) {
          throw result;
        }
        return {
          components,
          references: Object.values(result.references.created ?? {}),
        };
      });
  }),
  handleError(() => {
    dispatchAction(setMappingsStatus(ASYNC_STATUS.FAILURE));
  }),
  tap(() => {
    showToast('Imported mappings successfully', ToastType.SUCCESS);
    dispatchAction(setMappingsStatus(ASYNC_STATUS.SUCCESS));
  }),
  withLatestFrom(workspaces$),
  switchMap(([{ components }, { byId: workspaceById }]) => {
    const workspacesIds: string[] = Array.from(
      new Set(
        components
          .map((c: APIComponentAttributes) => c.rootWorkspace)
          .filter(ExcludeFalsy)
      )
    );

    const affectedWorkspaces = workspacesIds
      .map(workspaceId => workspaceById[workspaceId])
      .filter(ExcludeFalsy)
      .map(ws => ({ id: ws._id, name: ws.name }));

    return showAffectedWorkspaces(affectedWorkspaces);
  })
);

export default collectRoutines(handleImportMappings);
