import { reducedStream, reducer, toPersistentStream } from '@ardoq/rxbeach';
import { hierarchiesWorkspaceIdsChanged } from 'componentHierarchies/actions';

const handleHierarchiesWorkspaceIdsChanged = (_: string[], payload: string[]) =>
  payload;

export const hierarchiesWorkspaceIds$ = toPersistentStream(
  'hierarchiesWorkspaceIds$',
  reducedStream(
    'hierarchiesInternal$',
    [],
    [
      reducer(
        hierarchiesWorkspaceIdsChanged,
        handleHierarchiesWorkspaceIdsChanged
      ),
    ]
  ),
  []
);
