import GroupByCollection from 'collections/groupByCollection';
import { GroupByBackboneModel } from 'aqTypes';
import {
  APIAttachment,
  APIComponentAttributes,
  ArdoqId,
  EnhancedPresentation,
  HeaderWidgetAttributes,
  WidgetDataSourceTypes,
} from '@ardoq/api-types';
import { Maybe } from '@ardoq/common-helpers';
import { EnhancedSearchResponse } from '@ardoq/report-reader';
import {
  ChartWidgetWithEditPermissions,
  TableWidgetWithEditPermissions,
  widgetOperations,
} from '@ardoq/dashboard';

export function setGroupBys(
  groupBys: GroupByBackboneModel[] | GroupByBackboneModel['attributes'][]
) {
  GroupByCollection.removeAll();
  groupBys.forEach(groupBy => {
    GroupByCollection.add(groupBy);
  });
}

/**
 * Returns all ancestor components of the provided descendant components, except
 * for those ancestors that are among the provided descendants.
 */
export const getAllAncestors = (
  descendants: APIComponentAttributes[],
  allComponentsById: Partial<Record<ArdoqId, APIComponentAttributes>>
) => {
  const descendantIds = new Set(descendants.map(({ _id }) => _id));
  const ancestors = new Set<APIComponentAttributes>();
  const remainingDescendants = [...descendants];
  while (remainingDescendants.length) {
    const descendant = remainingDescendants.pop();
    if (!descendant) continue;
    if (!descendant.parent) continue;
    const parent = allComponentsById[descendant.parent];
    if (!parent) continue;
    if (descendantIds.has(parent._id)) continue;
    if (ancestors.has(parent)) continue;
    ancestors.add(parent);
    if (!parent.parent) continue;
    remainingDescendants.push(parent);
  }
  return ancestors;
};

export const getDataRelevantToWorkspaces = (
  presentation: EnhancedPresentation,
  workspaceIds: Set<ArdoqId>,
  consideredWorkspaceIds: Set<ArdoqId>
) => {
  const relevantReferences = presentation.references.filter(
    ({ rootWorkspace, targetWorkspace }) =>
      workspaceIds.has(rootWorkspace) || workspaceIds.has(targetWorkspace)
  );

  const componentsInTheseWorkspaces = presentation.components.filter(
    ({ rootWorkspace }) => workspaceIds.has(rootWorkspace)
  );

  const connectedComponentsInOtherWorkspaces =
    new Set<APIComponentAttributes>();
  relevantReferences.forEach(
    ({ rootWorkspace, targetWorkspace, target, source }) => {
      if (
        workspaceIds.has(rootWorkspace) &&
        workspaceIds.has(targetWorkspace)
      ) {
        return;
      }
      if (workspaceIds.has(rootWorkspace)) {
        const targetComponent = presentation.componentsById[target];
        if (!targetComponent) return;
        connectedComponentsInOtherWorkspaces.add(targetComponent);
      } else {
        const sourceComponent = presentation.componentsById[source];
        if (!sourceComponent) return;
        connectedComponentsInOtherWorkspaces.add(sourceComponent);
      }
    }
  );

  const relevantComponents = [
    ...componentsInTheseWorkspaces,
    ...connectedComponentsInOtherWorkspaces,
    ...getAllAncestors(
      [...connectedComponentsInOtherWorkspaces],
      presentation.componentsById
    ),
  ];

  const tags = presentation.tags.filter(({ rootWorkspace }) =>
    consideredWorkspaceIds.has(rootWorkspace)
  );
  return {
    components: relevantComponents,
    references: relevantReferences,
    tags,
  };
};

const removeAttachmentsUri = (attachments: APIAttachment[]) =>
  attachments.map(atta => ({
    ...atta,
    uri: '', // when file click is disabled, set uri to '' to disable file click
  }));

const removeExploreDataSourcePermissionsFromSurveyWidget = (
  widget: HeaderWidgetAttributes | ChartWidgetWithEditPermissions
) =>
  !widgetOperations.isReadHeaderWidgetData(widget) &&
  widget.datasource?.sourceType === WidgetDataSourceTypes.SURVEY
    ? {
        ...widget,
        permissions: {
          ...widget.permissions,
          canExploreDatasource: false,
        },
      }
    : widget;

// type narrowing
const isTableWidgetData = (
  widget: HeaderWidgetAttributes | ChartWidgetWithEditPermissions
): widget is TableWidgetWithEditPermissions => {
  return widgetOperations.isTableWidgetData(widget);
};

export const getWidgetForDashboardSlide = (
  widget: HeaderWidgetAttributes | ChartWidgetWithEditPermissions,
  isAnonymousUser: boolean
) => {
  // for anonymous users, file click is not allowed
  if (isAnonymousUser && isTableWidgetData(widget)) {
    return {
      ...widget,
      scopeData: {
        ...widget.scopeData,
        attachments: removeAttachmentsUri(widget.scopeData.attachments),
      },
    };
  }

  return removeExploreDataSourcePermissionsFromSurveyWidget(widget);
};

export const getSearchResultsForReportSlide = (
  searchResults: Maybe<EnhancedSearchResponse>,
  isAnonymousUser: boolean
): Maybe<EnhancedSearchResponse> => {
  const searchResultsScopeData = searchResults?.scopeData;

  // for anonymous users, file click is not allowed
  return isAnonymousUser && searchResultsScopeData
    ? {
        ...searchResults,
        scopeData: {
          ...searchResultsScopeData,
          attachments: removeAttachmentsUri(searchResultsScopeData.attachments),
        },
      }
    : searchResults;
};
