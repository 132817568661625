import { combineLatest, map, switchMap } from 'rxjs';
import { getTabularMappingStream } from 'integrations/common/streams/tabularMappings/getTabularMappingStream';

import { extractUsedFields } from './utils';
import { fields$ as rawfields$ } from 'streams/fields/fields$';
import { uniqBy } from 'lodash/fp';
import { integrationId$ } from '../integrationId/integrationId$';

const usedFields$ = integrationId$.pipe(
  switchMap(integrationId => {
    return getTabularMappingStream(integrationId);
  }),
  map(extractUsedFields)
);

export const fields$ = combineLatest([usedFields$, rawfields$]).pipe(
  map(([usedFields, { models }]) => {
    const allFields = uniqBy('name', models);

    return {
      used: usedFields,
      all: allFields,
    };
  })
);
