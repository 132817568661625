import { persistentReducedStream, reducer } from '@ardoq/rxbeach';
import { MappedComponent, MappedReference, MappingsState } from './types';
import {
  setComponentMapping,
  setReferenceMapping,
  initMappings,
  setMappingsStatus,
  addComponentMapping,
  deleteComponentMapping,
  addReferenceMapping,
  deleteReferenceMapping,
  resetMappings,
} from './actions';
import { ASYNC_STATUS, AsyncStatus } from 'integrations/common/types/api';
import fp from 'lodash/fp';

const defaultState: MappingsState = {
  status: ASYNC_STATUS.INIT,
  components: {},
  references: {},
};

const handleSetComponent = (
  state: MappingsState,
  component: MappedComponent
): MappingsState => ({
  ...state,
  components: {
    ...state.components,
    [component.id]: component,
  },
});

const handleSetReference = (
  state: MappingsState,
  reference: MappedReference
): MappingsState => ({
  ...state,
  references: {
    ...state.references,
    [reference.id]: reference,
  },
});

const handleMappingsStatus = (state: MappingsState, status: AsyncStatus) => ({
  ...state,
  status,
});

const handleInitMappings = (
  state: MappingsState,
  newState: Pick<MappingsState, 'components' | 'references'>
): MappingsState => ({ ...state, ...newState });

const handleAddComponentMapping = (state: MappingsState): MappingsState => {
  const newId = Math.random().toString(36).substring(2, 15);
  return {
    ...state,
    components: fp.set(newId, { id: newId, name: '' }, state.components),
  };
};

const handleDeleteComponentMapping = (
  state: MappingsState,
  mapping: MappedComponent
): MappingsState => {
  return {
    ...state,
    components: fp.omit(mapping.id, state.components),
  };
};

const handleAddReferenceMapping = (state: MappingsState): MappingsState => {
  const newId = Math.random().toString(36).substring(2, 15);
  return {
    ...state,
    references: fp.set(
      newId,
      { id: newId, source: '', target: '' },
      state.references
    ),
  };
};

const handleDeleteReferenceMapping = (
  state: MappingsState,
  mapping: MappedReference
): MappingsState => {
  return {
    ...state,
    references: fp.omit(mapping.id, state.references),
  };
};

const handleResetMappings = (_state: MappingsState): MappingsState =>
  defaultState;

export const mappings$ = persistentReducedStream(
  'lucidchartImportMappings$',
  defaultState,
  [
    reducer(initMappings, handleInitMappings),
    reducer(setComponentMapping, handleSetComponent),
    reducer(setMappingsStatus, handleMappingsStatus),
    reducer(setReferenceMapping, handleSetReference),
    reducer(addComponentMapping, handleAddComponentMapping),
    reducer(deleteComponentMapping, handleDeleteComponentMapping),
    reducer(addReferenceMapping, handleAddReferenceMapping),
    reducer(deleteReferenceMapping, handleDeleteReferenceMapping),
    reducer(resetMappings, handleResetMappings),
  ]
);
