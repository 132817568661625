import { APIFieldType, APIFieldAttributes } from '@ardoq/api-types';
import { ExistingField } from '../types';
import { fieldOps } from 'models/utils/fieldOps';

export function findDateRangePairs(fields: ExistingField[]) {
  const pairs: Record<string, ExistingField> = {};
  const prefixes: string[] = [];
  const postfixes: string[] = [];

  for (const field of fields) {
    if (field.name.endsWith('_start_date')) {
      const name = field.name.replace(/_start_date$/, '');
      prefixes.push(name);
      if (postfixes.includes(name)) {
        pairs[name] = {
          ...field,
          name,
          label: field.label.replace(/\.StartDate$/, ''),
        };
      }
    }
    if (field.name.endsWith('_end_date')) {
      const name = field.name.replace(/_end_date$/, '');
      postfixes.push(name);
      if (prefixes.includes(name)) {
        pairs[name] = {
          ...field,
          name,
          label: field.label.replace(/\.EndDate$/, ''),
        };
      }
    }
  }
  return Object.values(pairs).sort((a, b) => (a.name > b.name ? 1 : -1));
}

export function groupFields(fields: APIFieldAttributes[]) {
  const text: ExistingField[] = [];
  const list: ExistingField[] = [];
  const url: ExistingField[] = [];
  const dates: ExistingField[] = [];
  const allFields = fields.map(field => ({
    name: field.name,
    label: field.label,
    isCalulatedField: fieldOps.isCalculatedField(field),
  }));

  fields.forEach(field => {
    switch (field.type) {
      case APIFieldType.TEXT:
      case APIFieldType.TEXT_AREA:
        if (!text.some(el => el.name === field.name))
          text.push({
            ...field,
            isCalulatedField: fieldOps.isCalculatedField(field),
          });
        break;
      case APIFieldType.LIST:
        if (!list.some(el => el.name === field.name))
          list.push({
            ...field,
            isCalulatedField: fieldOps.isCalculatedField(field),
          });
        break;
      case APIFieldType.URL:
        if (!url.some(el => el.name === field.name))
          url.push({
            ...field,
            isCalulatedField: fieldOps.isCalculatedField(field),
          });
        break;
      case APIFieldType.DATE_ONLY:
        if (!dates.some(el => el.name === field.name))
          dates.push({
            ...field,
            isCalulatedField: fieldOps.isCalculatedField(field),
          });
        break;
    }
  });

  return {
    allFields,
    text,
    list,
    url,
    dateRange: findDateRangePairs(dates),
  };
}
