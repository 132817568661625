import {
  APIEntityType,
  ArdoqId,
  BooleanOperator,
  Operator,
  QueryBuilderRule,
  ViewpointBuilderFilters,
} from '@ardoq/api-types';
import {
  TraversalBuilderState,
  ViewpointBuilderFilter,
  ViewpointBuilderFiltersDefinitions,
} from '../types';
import {
  AddDefaultFilterPayload,
  ChangeFilterConditionPayload,
  DeleteFilterPayload,
  UpdateFilterPayload,
} from '../addFiltersTab/viewpointBuilderFilterActions';
import {
  getInternalRuleFromRuleProps,
  getRulePropsFromInternalRule,
  getSelectFieldOptions,
  inputAndFilterByQueryBuilderFilterType,
  LEGACY_OPERATOR_REPLACEMENTS,
  QueryBuilderFilterDefinition,
  QueryBuilderFilterType,
} from '@ardoq/query-builder';
import { v4 as uuidv4 } from 'uuid';
import {
  composeViewpointBuilderComponentsFilterGroups,
  composeViewpointBuilderReferencesFilterGroups,
  QueryBuilderFilterDefinitionGroups,
  toLabeledGroups,
} from '../addFiltersTab/composeViewpointBuilderComponentsFilterGroups';
import { getViewpointBuilderSuggestionsLoaders } from '../addFiltersTab/getViewpointBuilderSuggestionsLoaders';
import { logError } from '@ardoq/logging';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import { generateLocalFilterId } from '../getId';

const addDefaultFilter = (
  state: TraversalBuilderState,
  { graphNodeId }: AddDefaultFilterPayload
): TraversalBuilderState => {
  const existingFiltersForNode = state.filters[graphNodeId];
  const isFirstFilter = !existingFiltersForNode?.filters.length;

  const defaultFilterDefinition =
    getFiltersDefinitions(state).componentsFilterGroups
      .defaultFilterDefinitions[0];

  const defaultFilter: ViewpointBuilderFilter = {
    nonPersistentId: uuidv4(),
    graphNodeId: graphNodeId,
    filterBy: defaultFilterDefinition.id,
    operator: defaultFilterDefinition.operators[0],
    value: undefined,
    filterDefinition: defaultFilterDefinition,
  };

  return {
    ...state,
    ...(isFirstFilter
      ? updateGraphNodeOrEdgeHasFilters(state, graphNodeId, true)
      : {}),
    filters: {
      ...state.filters,
      [graphNodeId]: {
        condition: existingFiltersForNode?.condition ?? BooleanOperator.AND,
        filters: [...(existingFiltersForNode?.filters ?? []), defaultFilter],
        localFilterId: existingFiltersForNode?.localFilterId
          ? existingFiltersForNode?.localFilterId
          : (() => generateLocalFilterId())(), // lazy init
      },
    },
  };
};

const changeFilterCondition = (
  state: TraversalBuilderState,
  { condition, graphNodeId }: ChangeFilterConditionPayload
): TraversalBuilderState => {
  return {
    ...state,
    filters: {
      ...state.filters,
      [graphNodeId]: {
        ...state.filters[graphNodeId],
        condition,
      },
    },
  };
};

const getComponentFilterTypeOptions = (
  state: TraversalBuilderState,
  options: FilterDefinitionOptions
) => {
  const filterGroups = getComponentsFilterGroups(state, options);
  return getSelectFieldOptions(toLabeledGroups(filterGroups));
};

const getCustomReferenceFields = (
  state: TraversalBuilderState,
  referenceName: string
) => {
  const customFieldNames =
    state.referenceNameAndFieldsIndex.get(referenceName) ?? [];

  return customFieldNames
    .map(name => state.fieldMap.get(name))
    .filter(ExcludeFalsy);
};

const getCustomComponentFields = (
  state: TraversalBuilderState,
  componentName: string
) => {
  if (!componentName) {
    return [];
  }

  const customFieldNames =
    state.componentNameAndFieldsIndex.get(componentName) ?? [];
  return customFieldNames
    .map(name => state.fieldMap.get(name))
    .filter(ExcludeFalsy);
};

const replaceOperators = (filterDefinition: QueryBuilderFilterDefinition) => ({
  ...filterDefinition,
  operators: filterDefinition.operators.map(
    operator => LEGACY_OPERATOR_REPLACEMENTS.get(operator) ?? operator
  ),
});

const replaceLegacyFilterOperators = ({
  customFilterDefinitions,
  defaultFilterDefinitions,
}: QueryBuilderFilterDefinitionGroups) => ({
  customFilterDefinitions: customFilterDefinitions.map(replaceOperators),
  defaultFilterDefinitions: defaultFilterDefinitions.map(replaceOperators),
});

const getComponentsFilterGroups = (
  state: TraversalBuilderState,
  { typeName, entityType }: FilterDefinitionOptions
) => {
  if (entityType === APIEntityType.REFERENCE) {
    const definitionGroups = composeViewpointBuilderReferencesFilterGroups({
      customFields: getCustomReferenceFields(state, typeName),
    });
    return replaceLegacyFilterOperators(definitionGroups);
  }

  const definitionGroups = composeViewpointBuilderComponentsFilterGroups({
    customFields: getCustomComponentFields(state, typeName),
  });
  return replaceLegacyFilterOperators(definitionGroups);
};

type FilterDefinitionOptions = {
  typeName: string;
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE;
};

const getFiltersDefinitions = (
  state: TraversalBuilderState,
  options: FilterDefinitionOptions = {
    typeName: state.tripleOptions.selectedEdge
      ? (state.tripleOptions.selectedEdge?.metaDataComponent.representationData
          ?.displayLabel ?? '')
      : state.tripleOptions.selectedNode.metaDataComponent.label,
    entityType: state.tripleOptions.selectedEdge
      ? APIEntityType.REFERENCE
      : APIEntityType.COMPONENT,
  }
): ViewpointBuilderFiltersDefinitions => {
  return {
    componentFilterTypeOptions: getComponentFilterTypeOptions(state, options),
    componentsFilterGroups: getComponentsFilterGroups(state, options),
  };
};

const updateFilter = (
  state: TraversalBuilderState,
  { nonPersistentId, partialFilter, graphNodeId }: UpdateFilterPayload
): TraversalBuilderState => {
  const filterWithCondition = state.filters[graphNodeId];
  return {
    ...state,
    filters: {
      ...state.filters,
      [graphNodeId]: {
        ...filterWithCondition,
        filters:
          filterWithCondition.filters.map(filter =>
            filter.nonPersistentId === nonPersistentId
              ? getUpdatedFilter(state, {
                  originalFilter: filter,
                  partialFilter,
                })
              : filter
          ) ?? [],
      },
    },
  };
};

const deleteFilter = (
  state: TraversalBuilderState,
  { nonPersistentId, graphNodeId }: DeleteFilterPayload
): TraversalBuilderState => {
  const filterWithCondition = state.filters[graphNodeId];
  const filtersWithoutRemovedCondition = filterWithCondition.filters.filter(
    filter => filter.nonPersistentId !== nonPersistentId
  );
  const isLastFilterRemoved = filtersWithoutRemovedCondition.length === 0;

  if (isLastFilterRemoved) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [graphNodeId]: removedFilter, ...filtersWithoutTheRemovedOne } =
      state.filters;

    return {
      ...state,
      ...updateGraphNodeOrEdgeHasFilters(state, graphNodeId, false),
      filters: filtersWithoutTheRemovedOne,
    };
  }

  return {
    ...state,
    graphNodes: state.graphNodes,
    filters: {
      ...state.filters,
      [graphNodeId]: {
        ...filterWithCondition,
        filters: filtersWithoutRemovedCondition,
      },
    },
  };
};

const updateGraphNodeOrEdgeHasFilters = (
  { graphNodes, graphEdges }: TraversalBuilderState,
  graphNodeId: string,
  hasFilters: boolean
): Pick<TraversalBuilderState, 'graphEdges' | 'graphNodes'> => {
  const updatedGraphNodes = new Map(graphNodes);
  const graphNode = updatedGraphNodes.get(graphNodeId);
  const updatedGraphEdges = new Map(graphEdges);
  const graphEdge = updatedGraphEdges.get(graphNodeId);

  const graphNodeOrEdge = graphNode ?? graphEdge;

  if (!graphNodeId || !graphNodeOrEdge) {
    logError(
      Error(
        `Missing graphNode or graphEdge to update hasFilters for ${graphNodeId}`
      )
    );
    return { graphNodes, graphEdges };
  }

  // @ts-expect-error: typescript has a union type comprehension problem
  graphNodeOrEdge.setMetaData({
    ...graphNodeOrEdge.metaData!,
    hasFilters: hasFilters,
  });

  return {
    graphNodes: updatedGraphNodes,
    graphEdges: updatedGraphEdges,
  };
};

type GetUpdatedFilterArgs = {
  originalFilter: ViewpointBuilderFilter;
  partialFilter: Partial<ViewpointBuilderFilter>;
};

const getUpdatedFilter = (
  state: TraversalBuilderState,
  { originalFilter, partialFilter }: GetUpdatedFilterArgs
): ViewpointBuilderFilter => {
  if (partialFilter.filterBy) {
    const filterGroups = getFiltersDefinitions(state).componentsFilterGroups;
    const filterDefinition = [
      ...filterGroups.customFilterDefinitions,
      ...filterGroups.defaultFilterDefinitions,
    ].find(filterDef => filterDef.id === partialFilter.filterBy)!;

    return {
      ...originalFilter,
      ...partialFilter,
      operator: filterDefinition.operators[0],
      value: undefined,
      filterDefinition,
    };
  }

  return { ...originalFilter, ...partialFilter };
};

const getWorkspaceIdsForSelectedComponentType = (
  state: TraversalBuilderState
): ArdoqId[] => {
  const selectedComponentName =
    state.tripleOptions.selectedNode.metaDataComponent.label;

  const workspaceOptions =
    state.componentNameAndWorkspacesIndex.get(selectedComponentName) ?? [];

  return workspaceOptions;
};

const mapFiltersToBackendFormat = (
  state: TraversalBuilderState
): ViewpointBuilderFilters => {
  return Object.values(state.filters).reduce(
    (acc, traversalBuilderFiltersPerNode) => {
      return {
        ...acc,
        [traversalBuilderFiltersPerNode.localFilterId]: {
          filterId: traversalBuilderFiltersPerNode.localFilterId,
          condition: traversalBuilderFiltersPerNode.condition,
          rules: traversalBuilderFiltersPerNode.filters
            .map(viewpointBuilderFilterToBackendFormat)
            .filter(ExcludeFalsy),
        },
      };
    },
    {}
  );
};

const getDefaultValueForFilter = (filter: ViewpointBuilderFilter) => {
  const isDateWithEqualOperator =
    (filter.filterDefinition.filterType === QueryBuilderFilterType.DATE_TIME ||
      filter.filterDefinition.filterType === QueryBuilderFilterType.DATE) &&
    [Operator.EQUAL].includes(filter.operator);

  const hasOperatorThatDoesNotAcceptNullValue = [
    Operator.CONTAINS,
    Operator.NOT_CONTAINS,
    Operator.CONTAINS_SUBSTRING,
    Operator.NOT_CONTAINS_SUBSTRING,
    Operator.GREATER,
    Operator.LESS,
  ].includes(filter.operator);

  const filterHasNumberType =
    filter.filterDefinition.filterType === QueryBuilderFilterType.NUMBER;

  if (hasOperatorThatDoesNotAcceptNullValue || isDateWithEqualOperator) {
    return filterHasNumberType ? 0 : '';
  }

  return null;
};

const viewpointBuilderFilterToBackendFormat = (
  filter: ViewpointBuilderFilter
): QueryBuilderRule | null => {
  const tweakedValueAndOperator = getRulePropsFromInternalRule({
    field: filter.filterBy,
    operator: filter.operator,
    value: filter.value ?? getDefaultValueForFilter(filter),
  });

  if (!tweakedValueAndOperator) {
    return null;
  }

  return {
    id: filter.filterBy,
    field: filter.filterBy,
    ...inputAndFilterByQueryBuilderFilterType(
      filter.filterDefinition.filterType
    ),
    value: tweakedValueAndOperator.value,
    operator: tweakedValueAndOperator.operator,
  };
};

const viewpointFiltersToTraversalBuilderFilters = (
  state: TraversalBuilderState,
  filters: ViewpointBuilderFilters | null,
  filterIdToGraphNodeIdMap: Map<string, string>
): TraversalBuilderState => {
  if (!(filters && filterIdToGraphNodeIdMap.size > 0)) {
    return state;
  }

  const updatedGraphNodes = new Map(state.graphNodes);
  const filtersPerNode = Object.fromEntries(
    Object.entries(filters)
      .map(([filterId, filter]) => {
        const graphId = filterIdToGraphNodeIdMap.get(filterId)!;

        const graphNode = state.graphNodes.get(graphId);
        const graphEdge = state.graphEdges.get(graphId);

        const graphNodeOrEdge = graphNode ?? graphEdge;

        if (!graphNodeOrEdge) {
          logError(Error('Missing graphNodeOrEdge for filter'));
          return null;
        }

        // @ts-expect-error: typescript has a union type comprehension problem
        graphNodeOrEdge.setMetaData({
          ...graphNodeOrEdge.metaData!,
          hasFilters: filter.rules.length > 0,
        });

        const {
          componentsFilterGroups: {
            customFilterDefinitions,
            defaultFilterDefinitions,
          },
        } = getFiltersDefinitions(state, {
          typeName: graphNodeOrEdge.getLabel(),
          entityType: graphEdge
            ? APIEntityType.REFERENCE
            : APIEntityType.COMPONENT,
        });

        const allFilterDefinitions = [
          ...customFilterDefinitions,
          ...defaultFilterDefinitions,
        ];

        return [
          graphNodeOrEdge.id,
          {
            condition: filter.condition,
            filters: filter.rules.map(rule => {
              const filterDefinition = allFilterDefinitions.find(
                filterDef => filterDef.id === rule.field
              )!;

              const { value, operator } = getInternalRuleFromRuleProps(rule);

              return {
                nonPersistentId: uuidv4(),
                graphNodeId: graphNodeOrEdge.id,
                filterBy: rule.field,
                operator,
                value,
                filterDefinition,
              };
            }),
            localFilterId: generateLocalFilterId(),
          },
        ];
      })
      .filter(ExcludeFalsy)
  );

  return {
    ...state,
    graphNodes: updatedGraphNodes,
    filters: filtersPerNode,
  };
};

export const viewpointBuilderFiltersOperations = {
  addDefaultFilter,
  changeFilterCondition,
  getComponentFilterTypeOptions,
  getFiltersDefinitions,
  updateFilter,
  deleteFilter,
  getWorkspaceIdsForSelectedComponentType,
  getViewpointBuilderSuggestionsLoaders,
  mapFiltersToBackendFormat,
  viewpointFiltersToTraversalBuilderFilters,
};
