import styled from 'styled-components';
import { ComponentRepresentation } from '@ardoq/renderers';
import NodeExpander from './NodeExpander';
import { Icon, IconName, IconSize } from '@ardoq/icons';
import { ExternallyManagedIndicatorWrapper } from 'externallyManaged/ExternallyManagedIndicator';
import {
  NavigatorItem,
  NavigatorItemContextMenu,
  NavigatorItemExpander,
  NavigatorItemLink,
  VisualDiffIcon,
} from './Atoms';
import { colors } from '@ardoq/design-tokens';
import { TextOverflow } from '@ardoq/popovers';
import { ComponentItemProps } from './PropTypes';
import { WithSubdivisionPopover } from './WithSubdivisionPopover';
import { memo } from 'react';

const Container = styled(NavigatorItem)`
  line-height: 30px;
  height: 30px;

  &.linking {
    ${NavigatorItemLink} {
      opacity: 1;
    }
  }

  &.active {
    border-left-color: ${colors.blue50};
    border-top-color: ${colors.grey80};
    font-weight: bold;
  }
  ${NavigatorItemLink},
  > ${NavigatorItemContextMenu} {
    opacity: 0;
    cursor: pointer;
  }
  &:hover > ${NavigatorItemLink}, &:hover > ${NavigatorItemContextMenu} {
    opacity: 0.3;
  }

  &:hover
    > ${NavigatorItemLink}:hover,
    &:hover
    > ${NavigatorItemContextMenu}:hover {
    opacity: 1;
  }
  &.no-write-access > ${NavigatorItemContextMenu} {
    margin-right: 24px;
  }
  &.excluded {
    > ${NavigatorItemExpander}, > .icon-container,
    > .component-name {
      opacity: 0.5;
    }
  }
`;

const ComponentItem = memo(
  ({
    name,
    showContextMenuButton,
    containerStyle,
    containerClassNames,
    componentRepresentationData,
    shouldShowSubdivisionWarning,
    iconWrapperClassNames,
    iconWrapperStyle,
    isExternallyManaged,
    isExternallyMissing,
    isLocked,
    isDiffTypeChanged,
    hasWriteAccess,
    linkIconName,
    isInDiffMode,
    aggregatedVisualDiffState,
    nodeId,
    hasVisibleContent,
    isExpanded,
    isSelectedContext,
  }: ComponentItemProps) => (
    <Container
      className={containerClassNames}
      data-id={nodeId}
      style={containerStyle}
      role="treeitem"
      aria-label={name}
      aria-current={isSelectedContext ? 'true' : undefined}
    >
      <NodeExpander
        hasVisibleContent={hasVisibleContent}
        isExpanded={isExpanded}
        name={name}
      />
      <span className={iconWrapperClassNames} style={iconWrapperStyle}>
        {componentRepresentationData && (
          <ComponentRepresentation
            isImage={componentRepresentationData.isImage}
            value={componentRepresentationData.value}
            icon={componentRepresentationData.icon}
          />
        )}
      </span>
      <TextOverflow
        role="button"
        className="component-name component"
        popoverAcceptsMouse={false}
      >
        {name}
      </TextOverflow>
      {shouldShowSubdivisionWarning && <WithSubdivisionPopover id={nodeId} />}
      {isExternallyManaged && (
        <ExternallyManagedIndicatorWrapper className="lock-icon">
          <Icon
            iconName={IconName.EXTERNALLY_MANAGED}
            iconSize={IconSize.SMALL}
            data-tooltip-text="This component is managed by an external source"
          />
        </ExternallyManagedIndicatorWrapper>
      )}
      {isExternallyMissing && (
        <ExternallyManagedIndicatorWrapper className="lock-icon">
          <Icon
            iconName={IconName.EXTERNALLY_MISSING}
            iconSize={IconSize.SMALL}
            data-tooltip-text="This component was deleted from an external source"
          />
        </ExternallyManagedIndicatorWrapper>
      )}
      {isLocked && (
        <span className="lock-icon">
          <Icon iconName={IconName.LOCK} iconSize={18 as IconSize} />
        </span>
      )}
      {showContextMenuButton && (
        <NavigatorItemContextMenu>
          <Icon
            iconName={IconName.MORE_VERT}
            iconSize={18 as IconSize}
            data-test-id="navigator-context-shortcut"
          />
        </NavigatorItemContextMenu>
      )}
      {(isInDiffMode && (
        <VisualDiffIcon
          aggregatedDiffState={aggregatedVisualDiffState}
          isDiffTypeChanged={isDiffTypeChanged}
          data-component-id={nodeId}
        />
      )) ||
        (hasWriteAccess && (
          <NavigatorItemLink
            data-test-id="NavigatorItemLink"
            role="button"
            aria-label="Link"
          >
            <Icon iconName={linkIconName} iconSize={18 as IconSize} />
          </NavigatorItemLink>
        ))}
    </Container>
  )
);

export default ComponentItem;
