import { AccessControlPageProps, AccessControlPageStream } from '../types';
import { isNavigationMenuGroup } from '../NavigationSidebar/types';
import { Features, hasFeature } from '@ardoq/features';
import currentUser from 'models/currentUser';
import {
  AccessControlMenuOptions,
  AccessControlMenuSingleOption,
} from './types';
import { getUsersManagementMenuOption } from './getUsersManagementMenuOption';
import { getAccessManagementMenuOption } from './getAccessManagementMenuOption';
import { getSSOIntegrationsMenuOption } from './getSSOIntegrationsMenuOption';

const ExcludeEmptyGroups = (
  item: AccessControlMenuOptions | undefined
): item is AccessControlMenuOptions => {
  if (!item) {
    return false;
  }
  if (!isNavigationMenuGroup(item)) {
    return true;
  }
  return item.items.length > 0;
};

export const getAccessControlMenuOptionsArgs = (
  props: AccessControlPageProps
): [
  {
    showManageUserRoles: boolean;
    showIPAllowlisting: boolean;
    showSCIM: boolean;
    showSSOAttributeMapping: boolean;
    subdivisionFeature: boolean;
  },
  AccessControlPageProps['resources'],
] => {
  const showManageUserRoles = props.configurablePrivileges.length > 0;
  const showIPAllowlisting = hasFeature(Features.IP_ALLOWLIST_MANAGEMENT);
  const showSCIM = hasFeature(Features.SCIM_PROVISIONING);
  const showSSOAttributeMapping = currentUser.isOrgAdmin();
  const subdivisionFeature = hasFeature(Features.PERMISSION_ZONES);

  return [
    {
      showManageUserRoles,
      showIPAllowlisting,
      showSCIM,
      showSSOAttributeMapping,
      subdivisionFeature,
    },
    props.resources,
  ];
};

export const getAccessControlMenuOptions = (
  args: {
    showManageUserRoles: boolean;
    showIPAllowlisting: boolean;
    showSCIM: boolean;
    showSSOAttributeMapping: boolean;
    subdivisionFeature: boolean;
  },
  resources: AccessControlPageStream['resources']
): AccessControlMenuOptions[] =>
  [
    getUsersManagementMenuOption(args, resources),
    getAccessManagementMenuOption(args, resources),
    getSSOIntegrationsMenuOption(args, resources),
  ].filter(ExcludeEmptyGroups);

export const getAccessControlMenuOptionById = (
  options: AccessControlMenuOptions[],
  id: string
): AccessControlMenuSingleOption | undefined => {
  for (const option of options) {
    if (isNavigationMenuGroup(option)) {
      const sub = getAccessControlMenuOptionById(
        option.items as AccessControlMenuOptions[],
        id
      );
      if (sub) {
        return sub;
      }
    } else if (option.id === id) {
      return option;
    }
  }
  return undefined;
};
