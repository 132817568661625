import {
  collectRoutines,
  dispatchAction,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { filter, switchMap, tap } from 'rxjs';
import { confirm } from '@ardoq/modal';
import { resetConfigs, restartImport } from './actions';
import { resetAnalyze } from '../analyze/actions';
import { resetMappings } from '../mappings/actions';
import { resetDocument } from '../document/actions';

const handleResetImport = routine(
  ofType(restartImport),
  switchMap(() =>
    confirm({
      title: 'Restart the import?',
      text: `You’re about to restart the importer`,
      text2: 'Once done, this action can’t be undone.',
      confirmButtonTitle: 'Restart',
      cancelButtonTitle: 'Cancel',
    })
  ),
  filter(Boolean),
  tap(() => {
    dispatchAction(resetAnalyze());
    dispatchAction(resetConfigs());
    dispatchAction(resetMappings());
    dispatchAction(resetDocument());
  })
);

export default collectRoutines(handleResetImport);
