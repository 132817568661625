import {
  carry,
  collectRoutines,
  dispatchAction,
  extractPayload,
  ofType,
  routine,
} from '@ardoq/rxbeach';
import { switchMap, tap, withLatestFrom } from 'rxjs';
import { handleError, integrationsLucidChartApi } from '@ardoq/api';

import { analyzeLucidDocument, setAnalyzeState } from './actions';
import { ASYNC_STATUS } from 'integrations/common/types/api';
import { LUCIDCHART_INVALID_SESSION } from 'integrations/lucidchart/const';
import { initMappings, resetMappings } from '../mappings/actions';
import { analyzeResponseToMappings } from './utils';
import workspaces$ from 'streams/workspaces/workspaces$';
import { models$ } from 'streams/models/models$';
import { isArdoqError } from '@ardoq/common-helpers';
import { getWorkspacesMetadata } from '../../utils';

export const handleAnalysisLucidDocument = routine(
  ofType(analyzeLucidDocument),
  extractPayload(),
  tap(() => {
    dispatchAction(resetMappings());
    dispatchAction(setAnalyzeState({ status: ASYNC_STATUS.LOADING }));
  }),
  carry(
    switchMap(payload =>
      integrationsLucidChartApi.analyzeDocument(payload.documentId, {
        workspaceIds: payload.workspaceIds,
      })
    )
  ),
  handleError(response => {
    if (response.userMessage === LUCIDCHART_INVALID_SESSION) {
      // OAuth invalid session is not an error
      // the user will be prompted to re-authenticate
      return;
    }
    dispatchAction(
      setAnalyzeState({
        status: ASYNC_STATUS.FAILURE,
        error: response.error.message,
      })
    );
  }),
  withLatestFrom(workspaces$, models$),
  tap(
    ([
      [{ workspaceIds }, response],
      { byId: workspaceById },
      { byId: modelById },
    ]) => {
      if (isArdoqError(response)) {
        dispatchAction(
          setAnalyzeState({
            status: ASYNC_STATUS.FAILURE,
            error: response.error.message,
          })
        );
        return;
      }

      dispatchAction(
        setAnalyzeState({
          status: ASYNC_STATUS.SUCCESS,
          components: response.components,
          references: response.references,
        })
      );
      const workspacesMetadata = getWorkspacesMetadata(
        workspaceById,
        modelById,
        workspaceIds
      );

      dispatchAction(
        initMappings(analyzeResponseToMappings(workspacesMetadata, response))
      );
    }
  )
);

export default collectRoutines(handleAnalysisLucidDocument);
