import { reducedStream, Reducer, reducer } from '@ardoq/rxbeach';
import {
  generateValueProposition,
  generateValueStream,
  setCompany,
  setDescription,
  setIndustry,
  setLink,
  setValueProposition,
  submitValuePropositionModal,
  submitValueStreamModal,
  createValuePropositionComponent,
  showValueProposition,
  generateBusinessCapabilityMap,
  submitBusinessCapabilityMapModal,
  submitBCMLinkingModal,
  generateBCMLinking,
} from './actions';
import { defer, map } from 'rxjs';
import { handleError, augmentationsApi } from '@ardoq/api';

export type AugmentationModalState = {
  description: string;
  company: string;
  industry: string;
  valueProposition: string;
  showVP: boolean;
  loading: boolean;
  disableGenerateValueProposition: boolean;
  disableSubmit: boolean;
  disableGenerate: boolean;
  generationLink: string;
  generationError: boolean;
};

const initialState: AugmentationModalState = {
  description: '',
  company: '',
  industry: '',
  valueProposition: '',
  showVP: false,
  loading: false,
  disableGenerateValueProposition: true,
  disableGenerate: true,
  disableSubmit: true,
  generationLink: '',
  generationError: false,
};

const handleSetCompany: Reducer<AugmentationModalState, string> = (
  state,
  company
) =>
  setDisabledButtons({
    ...state,
    company,
  });

const handleSetDescription: Reducer<AugmentationModalState, string> = (
  state,
  description
) =>
  setDisabledButtons({
    ...state,
    description,
  });

const handleSetIndustry: Reducer<AugmentationModalState, string> = (
  state,
  industry
) =>
  setDisabledButtons({
    ...state,
    industry,
  });

const handleSetValueProposition: Reducer<AugmentationModalState, string> = (
  state,
  valueProposition
) =>
  setDisabledButtons({
    ...state,
    valueProposition,
  });

const handleShowValueProposition: Reducer<AugmentationModalState> = state =>
  setDisabledButtons({
    ...state,
    loading: false,
    showVP: true,
  });

const setDisabledButtons = (
  state: AugmentationModalState
): AugmentationModalState => {
  return {
    ...state,
    disableGenerateValueProposition: !(state.company && state.description),
    disableGenerate: !(
      state.company &&
      state.description &&
      state.valueProposition &&
      (state.industry || state.showVP)
    ),
  };
};

const handleGenerateValueProposition = (state: AugmentationModalState) => ({
  ...state,
  loading: true,
});

const handleGenerate = (state: AugmentationModalState) => ({
  ...state,
  loading: true,
  generationLink: '',
  generationError: false,
});

const handleSetLink: Reducer<AugmentationModalState, string | undefined> = (
  state,
  generationLink
) => ({
  ...state,
  loading: false,
  generationLink: generationLink ? generationLink : '',
  generationError: !generationLink,
  disableSubmit: !generationLink,
});

const handleSubmitModal = (state: AugmentationModalState) => ({
  ...state,
  disableSubmit: true,
});

const initialAugmentations$ = defer(() =>
  augmentationsApi.prefillAugmentations()
).pipe(
  handleError(),
  // TODO if this has an error the state wont load At All, need to fix
  map(prefill => ({
    company: prefill.organization.name || '',
    description: prefill.organization.description || '',
    valueProposition: prefill['value-proposition'].description || '',
    industry: prefill.organization.industry || '',
  }))
);

const initialState$ = initialAugmentations$.pipe(
  map(({ company, description, valueProposition, industry }) => ({
    ...initialState,
    company,
    valueProposition,
    description,
    disableGenerate: !company || !description || !valueProposition || !industry,
    disableGenerateValueProposition: !company || !description,
  }))
);

export const augmentationModal$ = reducedStream(
  'augmentationModal',
  initialState$,
  [
    reducer(setCompany, handleSetCompany),
    reducer(setDescription, handleSetDescription),
    reducer(setIndustry, handleSetIndustry),
    reducer(setLink, handleSetLink),
    reducer(setValueProposition, handleSetValueProposition),
    reducer(showValueProposition, handleShowValueProposition),
    reducer(generateValueProposition, handleGenerateValueProposition),
    reducer(createValuePropositionComponent, handleGenerate),
    reducer(generateValueStream, handleGenerate),
    reducer(generateBusinessCapabilityMap, handleGenerate),
    reducer(generateBCMLinking, handleGenerate),
    reducer(submitValuePropositionModal, handleSubmitModal),
    reducer(submitValueStreamModal, handleSubmitModal),
    reducer(submitBusinessCapabilityMapModal, handleSubmitModal),
    reducer(submitBCMLinkingModal, handleSubmitModal),
  ]
);
